import React, { useCallback, useState } from 'react'; 
import Button from './Button'; 
import Skeleton from './Skeleton';
import Switch from './Switch'; 
import TimeframeSelector from './TimeframeSelector';  
import './AppToolbar.scss';  
import { Tag } from './Tag';
import Dropdown from './Dropdown';
import { APPLICATION_TYPES } from '../../Auth/Application/Application.Api';
import { GlobalStore } from '../../GlobalStore';
import { TIME_FRAME } from '../../ShopNow/Components/Report/Filters/utils';
import { Globals } from '../../Globals';
 

function AppToolbar(props) {
    const { 
        onReset,
        isLoading = false,
        showAnalyticsFilter,
        showEcommerceFilter,
        hideTimeSelect
    } = props;
 
    const [loading, setLoading] = useState(false);



    const [selectedStores, setSelectedStores] = useState([]);
    const onStoreSelected = (value) => { 
        console.log(value);
        setLoading(true);
        let ids = value.filter(v=>v).map(v=>v.value || v.id).filter(v=>v);
        setSelectedStores (ids);
        let currentOptions = GlobalStore.currentToolbarOptions.get();
        currentOptions.store_ids = ids; 
        GlobalStore.currentToolbarOptions.set({...currentOptions})
        setTimeout(()=>{
            setLoading(false);
        }, 1500);
    };



    const [selectedApplications, setSelectedApplications] = useState([]);
    const onApplicationSelected = (value) => { 
        setLoading(true);
        let ids = value.filter(v=>v).map(v=>v.value || v.id).filter(v=>v);
        setSelectedApplications (ids);
        let currentOptions = GlobalStore.currentToolbarOptions.get();
        currentOptions.application_ids = ids; 
        GlobalStore.currentToolbarOptions.set({...currentOptions});
        loadStores();
        setTimeout(()=>{
            setLoading(false);
        }, 1500);
    };


    const [timeframe, setTimeframe] = useState(TIME_FRAME.ALL);
    const onTimeframeSelected = (value) => {
        setLoading(true);
        setTimeframe (value);
        let currentOptions = GlobalStore.currentToolbarOptions.get();
        currentOptions.timeframe = value; 
        GlobalStore.currentToolbarOptions.set({...currentOptions});
        setTimeout(()=>{
            setLoading(false);
        }, 1500);
    };



    const getustomFilter = (bots, suspicious) => {
        if(suspicious){
            return {
                exclude:'suspicious'
            }; 
        }
        if(bots){
            return {
                exclude:'bots'
            };
        }
        if(!suspicious && !bots){
           return null;
        }
       
    };

    const onExcludeBotsSelected = (value) => {
        setLoading(true);
        setExcludeBots(value);
        let currentOptions = GlobalStore.currentToolbarOptions.get();
        currentOptions.excludeBots = value;   
        currentOptions.customFilter=getustomFilter( value, currentOptions.excludeSuspicious);
        GlobalStore.currentToolbarOptions.set({...currentOptions});
        setTimeout(()=>{
            setLoading(false);
        }, 1500);
    };


    const onExcludeSuspiciousSelected = (value) => {
        setLoading(true);
        setExcludeSuspicious(value);
        if(value){
            setExcludeBots(true);
        }
        let currentOptions = GlobalStore.currentToolbarOptions.get();
        currentOptions.excludeSuspicious = value; 
        currentOptions.customFilter=getustomFilter(currentOptions.excludeBots, value);
      
        GlobalStore.currentToolbarOptions.set({...currentOptions});
        setTimeout(()=>{
            setLoading(false);
        }, 1500);
    };

    const [customTime, setCustomTime] = useState(null);
    const onCustomTimeframeSelected = (value) => {
        setCustomTime (value);
     
    };

    const [excludeBots, setExcludeBots] = useState(true);
    const [excludeSuspicious, setExcludeSuspicious] = useState(false);
    

    const renderBotSwitches = () => {
        

        return (
            <>   
                    <Switch
                        tooltip="Visitors explicitly identified themselves as bots, including search engines and crawlers."
                        disabled={excludeSuspicious}
                        className="toolbar-filter-switch"
                        value={excludeBots}
                        label="Exclude bots"
                        onChange={(val) => onExcludeBotsSelected(val)}
                    />
             
                    <Switch
                        tooltip="Users employing tools like Selenium, and spending only a few seconds (indicative of potential automated software)."
                        className="toolbar-filter-switch"
                        value={excludeSuspicious}
                        label="Exclude suspicious & bots"
                        onChange={(val) => onExcludeSuspiciousSelected(val)}
                    />
              
            </>
        );
    };
 

    const [stores, setStores] = useState([])
    const loadStores =  async () => {
        const storeTypesRecords = await Globals.callApi({
            url: 'shop/store/search',
            params: {
                offset: 0,
                page_size: 1000,
            },
        });

        let allStores = storeTypesRecords.records; 
        allStores =  allStores.filter(s=>selectedApplications.indexOf(s.application_id)>-1).map(a=>{
            return {
                label: a.name,
                value: a.id,
                logo: null,
                color:  null,
                key: a.key
            }
        })
        console.log('onStoreSelected', allStores);
        setStores(allStores.filter(s=>s)); 
    }

    let currentAccId = window.currentAccount.id;

    let applicationOptions = window.currentUser.applications.filter(a=>a.account_id==currentAccId).map(a=>{
        
            
        const appData = APPLICATION_TYPES.find(
            (at) => at.value ===  a.type
        );
        
    return {
        label: a.name,
        value: a.id,
        logo: appData.logo,
        color:  appData.color,
        type:a.type
    };
});

if(props.applicationIds?.length){
    applicationOptions= applicationOptions.filter(a=>props.applicationIds.indexOf(a.value)>-1)
}

if(props.applicationTypes?.length){
    applicationOptions= applicationOptions.filter(a=>props.applicationTypes.indexOf(a.type)>-1)
}

console.log(props.applicationTypes,'applicationTypes');

    if (isLoading || loading) return <Skeleton height="68"  />;
    console.log('applicationOptions',currentAccId, applicationOptions)

    return (
        <div className="app-toolbar">
                  <div className="toolbar-filters">
                   
                        <ApplicationSelector
                        singleSelect={props.singleSelect}
                            className="toolbar-applications-filter"
                            useLabel={false}
                            placeholder="All applications"
                            options={applicationOptions}
                            value={selectedApplications}  
                            onChange={({value, items}) => onApplicationSelected(items)}
                        />

                        
                        {/* {showEcommerceFilter?
                        <Dropdown
                                className="toolbar-selection-filter"
                                useLabel={false}
                                placeholder="All stores"
                                options={stores}
                                value={selectedStores}
                                onChange={({items, value}) => onStoreSelected(items)}
                                selectOptions={{
                                    closeOnSelect: false,
                                    isMultiSelect: true,
                                    selectMode: 'highlight',
                                }}
                                selectedItemShape="square"
                                searchOptions={{ enable: true, searchOnServer: false }}
                            />
                        :null} */}
                        {hideTimeSelect ? null :
                        <TimeframeSelector
                            className="toolbar-timeframe-filter"
                            value={timeframe}
                            onChange={(val) => onTimeframeSelected(val)}
                            onCustomDateChange={(val) => onCustomTimeframeSelected(val)}
                            customTimeframe={customTime}
                        />
                        }

                    {showAnalyticsFilter?renderBotSwitches():null}
                </div>
                
                    <Button
                        className="toolbar-clear-button"
                        icon="layers_clear"
                        label="RESET"
                        onClick={() => onReset && onReset()}
                    />
                
        </div>
    );
}

export default AppToolbar;




function ApplicationSelector(props) {
    const {
        options,
        value,
        onChange,
        className,
        placeholder,
        useLabel = true,
        
        // selectOptions = { closeOnSelect: false, isMultiSelect: true, selectMode: 'check' },
        searchOptions = { enable: true, searchOnServer: false },
    } = props;

    
    let currentAccId = window.currentAccount.id;
    let items = window.currentUser.applications.filter(a=>a && a.account_id==currentAccId && value.indexOf( a.id )>-1).filter(a=>a && a.id).map(a=>{
                const appData = APPLICATION_TYPES.find(
                    (at) => at.value ===  a.type
                ); 
                return {
                    label: a.name,
                    value: a.id,
                    logo: appData.logo,
                    color:  appData.color,
                };
        })
    console.log('value app', value, items, props.options);

    let selectOptions = props.selectOptions ||  { closeOnSelect: false, isMultiSelect: true, selectMode: 'check' };
    if(props.singleSelect){
        selectOptions.closeOnSelect = true; 
        selectOptions.isMultiSelect = false;
        selectOptions.selectMode = 'highlight'; 
    }
    return (
        <Dropdown
            className={className}
            useLabel={useLabel}
            placeholder={placeholder}
            options={props.options}
              value={items.map(i=>i.value)}
            items={items}
            onChanged={onChange}
            
            selectOptions={selectOptions}
            searchOptions={searchOptions}
            selectedItemShape="square"
            customTemplate={({ label, value, data }) => (
                <Tag square value={value} label={label} color={data.color} logo={data.logo} />
            )}
        />
    );
}
 

import React, { useCallback, useEffect, useState } from 'react';
import 'leaflet/dist/leaflet.css';

import Button from '../../../components/basic/Button.js';
import Icon from '../../../components/basic/Icon.js';
import Sticker from '../../../components/basic/Sticker.js';
import WorldMap from './WorldMap.js';
import 'flag-icons/css/flag-icons.min.css';
import './WorldMap.scss';
import './AnalyticsWorldMap.scss';
import { formatNumber } from '../../../tmo/tmo.formatters';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import Popup from '../../../components/basic/Popup.js';
import { ChartDataApi } from '../../../Analytics/ChartData.Api';

const LOCATIONS_DESKTOP_SHOW_MORE = 16;
const LOCATIONS_MOBILE_SHOW_MORE = 8;

function AnalyticsWorldMap(props) {
    const [allVisible, setAllVisible] = useState(false);
    const [regionVisits, setRegionsVisits] = useState(null);
    const [regionSelected, setRegionSelected] = useState(null);
    const [isLoadingLocations, setLoadingLocations] = useState(false);
    const isTwoLocationsColumn = useMediaQuery('(min-width : 1200px)');
    const isMobile = useMediaQuery('(max-width : 768px)');

    const handleLocationSelection = (location) => {
        if (props.onLocationSelected) {
            props.onLocationSelected(location);

            if (location !== regionSelected) {
                setRegionSelected(location);
                if (location) {
                    setLoadingLocations(true);
                    loadLocationVisits(location);
                } else {
                    setRegionsVisits(null);
                }
            }
        }
    };

    const handleLocationButtonCLick = (value) => {
        if (allVisible) {
            hideAll();
        }
        setRegionSelected(value);
        setLoadingLocations(true);
        loadLocationVisits(value);
    };

    useEffect(() => {
        setLoadingLocations(true);
        loadLocationVisits(regionSelected);
    }, [props.params]);

    const loadLocationVisits = async (location) => {
        const { applications, timeframe, customTimeframe } = props.params;
        let chartData = await ChartDataApi.getVisitsByCountry({
            application_ids: applications,
            timeframe,
            customTimeframe,
            location,
        });
        setLoadingLocations(false);

        setRegionsVisits(chartData[0].data.chart_model);
    };

    const showAll = () => setAllVisible(true);

    const hideAll = () => setAllVisible(false);

    const getRegionList = () => {
        if (isLoadingLocations) {
            const skeletons = !isMobile ? new Array(12).fill('') : new Array(4).fill('');

            return skeletons.map((s, ind) => (
                <div key={`skeleton-region-${ind}`} className="region-location">
                    <div className="skeleton">
                        <div className="skeleton-content" />
                    </div>
                </div>
            ));
        }

        const visits = regionSelected ? provinceVisits : allVisits;
        const visitsUnique = regionSelected ? provinceUniqueVisits : uniqueVisits;

        return visits
            .filter((a) => a.current_total)
            .sort((a, b) => b.current_total - a.current_total)
            .map(({ label, flag, current_total, previous_total, increase_rate, value }, index) => (
                <div
                    key={`location-${index}`}
                    className="region-location"
                    onClick={() => { 
                        value !== 'none' && !regionSelected && handleLocationButtonCLick(value);
                    }}
                >   
                    {value === 'none' && !flag && <span className={`location-flag fi fi-`} ></span>}
                    {flag ? <span className={`location-flag fi fi-${flag}`} />  :null}
                    <div className="location-data">
                        <div className="location-values">
                            <div className="location-item location-total">
                                {formatNumber(current_total)}
                            </div>
                            <div className="location-item location-human">
                                {formatNumber(
                                    (visitsUnique.filter((v) => v.value === value)[0] || {})
                                        .current_total
                                )}
                                <Icon name="person" />
                            </div>
                            <Sticker
                                tooltip="Increase rate compared to previous time range"
                                className="location-percent"
                                color={
                                    increase_rate === 0 || increase_rate === null
                                        ? ' gray '
                                        : increase_rate > 0
                                        ? 'green'
                                        : 'red'
                                }
                                size="smallest"
                            >{`${increase_rate ?? 0}%`}</Sticker>
                        </div>
                        <div className="location-item location-name">{label}</div>
                    </div>
                </div>
            ));
    };

    //TODO : FIX HERE .
    let uniqueVisits =
        (props.data?.filter((d) => d.data_type !== 'ANALYTICS_VISIT_BY_COUNTRY')[0] || {})
            .datasets || [];

    let allVisits =
        (props.data?.filter((d) => d.data_type === 'ANALYTICS_VISIT_BY_COUNTRY')[0] || {})
            .datasets || [];

    let provinceUniqueVisits =
        regionVisits?.find((d) => d.data_type !== 'ANALYTICS_VISIT_BY_PROVINCE')?.datasets || [];
    let provinceVisits =
        regionVisits?.find((d) => d.data_type === 'ANALYTICS_VISIT_BY_PROVINCE')?.datasets || [];

    const showMoreButton = useCallback(() => {
        if (isLoadingLocations) return false;
        return isTwoLocationsColumn
            ? allVisits.length > LOCATIONS_DESKTOP_SHOW_MORE
            : allVisits.length > LOCATIONS_MOBILE_SHOW_MORE;
    }, [allVisits, isTwoLocationsColumn, isLoadingLocations]);

    const selectedData = regionSelected ? allVisits.find((a) => a.value === regionSelected) : null;
    const selectedUniqueData = regionSelected
        ? uniqueVisits.find((a) => a.value === regionSelected)
        : null;

    let allIncreaseRate = Math.floor(
        (selectedData ? [selectedData] : allVisits)
            .filter((a) => a.increase_rate)
            .map((a) => a.increase_rate)
            .reduce((partialSum, a) => partialSum + a, 0) ||
            1 / (selectedData ? [selectedData] : allVisits).length
    );

    let visitsData = {
        all: allVisits,
        unique: uniqueVisits,
        provinceAll: provinceVisits,
        provinceUnique: provinceUniqueVisits,
    };

    return (
        <div className="map-chart">
            {allVisible && (
                <Popup
                    blurBackground={false}
                    open
                    disableScroll
                    draggable={false}
                    showCloseOnTop
                    showNotes
                    showButtons
                    title="All regions"
                    showCloseButton
                    showOkButton
                    okButtonText="OK"
                    closeButtonText="CLOSE"
                    enableCloseOnBackgoundClick={false}
                    onButtonClick={hideAll}
                    onClose={hideAll}
                >
                    <div className="world-map-regions-popup-content">{getRegionList()}</div>
                </Popup>
            )}
            <div className="map">
                <WorldMap
                    selectedRegion={regionSelected}
                    onSelectedLocation={handleLocationSelection}
                    data={visitsData}
                />
            </div>
            <div className="locations">
                <div className="location-list">
                    <div key={`location-all`} className="region-location location-all">
                        {!regionSelected && <Icon name="globe" />}
                        {selectedData?.flag ? (
                            <span className={`location-flag fi fi-${selectedData.flag}`} />
                        ):null}
                        <div className="location-data">
                            <div className="location-values">
                                <div className="location-item location-total">
                                    {formatNumber(
                                        (selectedData ? [selectedData] : allVisits)
                                            .filter((a) => a.current_total)
                                            .map((a) => a.current_total)
                                            .reduce((partialSum, a) => partialSum + a, 0)
                                    )}
                                </div>
                                <div className="location-item location-human">
                                    {formatNumber(
                                        (selectedData ? [selectedUniqueData] : uniqueVisits)
                                            .filter((a) => a.current_total)
                                            .map((a) => a.current_total)
                                            .reduce((partialSum, a) => partialSum + a, 0)
                                    )}
                                    <Icon name="person" />
                                </div>
                                <Sticker
                                    tooltip="Average increase rate compared to previous time range"
                                    className="location-percent"
                                    color={
                                        allIncreaseRate === 0
                                            ? ' gray '
                                            : allIncreaseRate > 0
                                            ? 'green'
                                            : 'red'
                                    }
                                    size="smallest"
                                >{`${allIncreaseRate}%`}</Sticker>
                            </div>
                            <div className="location-item location-name">
                                {selectedData ? selectedData.label : 'Worldwide'}
                            </div>
                        </div>
                    </div>
                    {getRegionList()}
                </div>
                {showMoreButton() && (
                    <Button
                        wrapperClassName="see-all-locations"
                        noBorder
                        label={
                            'Click to see all ' +
                            (regionSelected ? provinceVisits : allVisits).filter(
                                (visit) => visit?.current_total
                            ).length +
                            ' locations'
                        }
                        onClick={showAll}
                    />
                )}
            </div>
        </div>
    );
}

export default AnalyticsWorldMap;

import './ExportGridData.scss';
import React, { useState, forwardRef, useImperativeHandle } from 'react';

// import Dropdown from '../basic/Dropdown';
// import Input from '../basic/Input';
// import Button from './Button';
import FileUpload from '../basic/FileUpload';
import tmo from '../../tmo/tmo.lib';

const ExportGridData = forwardRef((props, ref) => {



  useImperativeHandle(ref, () => {
    return {

    }
  });


  let [filter, setFilter] = useState(null);


  const onValueChanged = () => {

  }

  const downloadFile = () => {

    function getValueForCSV(val) {
      if (val && val.toString) {
        return val.toString().replace(new RegExp(',', 'g'), ' | ')
      }
      else {
        return '?'
      }
    }


    const rows = [
      // ["name1", "city1", "some other info"],
      // ["name2", "city2", "more info"]
    ];
    let records = props.records || {};
    if (props.selectedRecords && props.selectedRecords.length) {
      records = props.selectedRecords;
    }
    //  let records = props.records || {};

    let csvColumns = [];
    props.columns.forEach(c => {
      if (c.type != 'system-check' && c.type != 'action') {
        // if (c.type == 'parent' || c.type == 'user') {
        //   c.children.forEach(cc => {
        //     csvColumns.push(getValueForCSV(cc.title);
        //   });
        // }
        // else {
        csvColumns.push(getValueForCSV(c.title));
        // }
      }
    });
    rows.push(csvColumns);

    // function getElementStyles(element) {  
    //       var targetDOMElement = element;
    //       var targetObjsStyles = window.getComputedStyle(targetDOMElement);

    //       var tempCopyOfTarget = document.createElement(targetDOMElement.tagName);
    //       document.body.insertAdjacentElement('afterBegin', tempCopyOfTarget);

    //       var basicElementsCSS = window.getComputedStyle(tempCopyOfTarget);

    //       var cleanSetOfStyles = {};
    //       Object.entries(targetObjsStyles).forEach(p => {
    //         if (basicElementsCSS[p[0]] !== p[1]) {
    //           cleanSetOfStyles[p[0]] = p[1];
    //         }

    //       });
    //       let styles = '';
    //       let cssClass = '';
    //       Object.keys(cleanSetOfStyles).forEach(k => {
    //         styles += k + ':' + cleanSetOfStyles[k] + '; ';
    //       })

    //       tempCopyOfTarget.remove()

    //       return { css: '.' + cssClass + '{ ' + styles + '}', cssClass }
    // }


    // selectedRowElements.forEach(r => {
    //   csvColumns = [];
    //   r.querySelectorAll('.datagrid-cell').forEach(c => {
    //     c.innerText
    //     csvColumns.push(getValueForCSV(r[c.field]);
    //   });
    //   rows.push(csvColumns);
    // })


    function getExportData(rowIndex, cellIndex) {
      let selectedRowElements = document.querySelectorAll('.datagrid[data-id="' + props.gridId + '"] .datagrid-row.checked');
      if (!selectedRowElements.length || !selectedRowElements[rowIndex]) {
        return '';
      }
      let cells = selectedRowElements[rowIndex].querySelectorAll('.datagrid-cell');

      if (cells[cellIndex]) {
        if (cells[cellIndex].getAttribute('data-export') && cells[cellIndex].getAttribute('data-export').trim()) {
          return cells[cellIndex].getAttribute('data-export').trim();
        }
      }
    }


    function getCellText(rowIndex, cellIndex) {
      let selectedRowElements = document.querySelectorAll('.datagrid[data-id="' + props.gridId + '"] .datagrid-row.checked');
      if (!selectedRowElements.length || !selectedRowElements[rowIndex]) {
        return '';
      }
      let cells = selectedRowElements[rowIndex].querySelectorAll('.datagrid-cell');

      if (cells[cellIndex]) {

        if (cells[cellIndex].querySelector('img') && !cells[cellIndex].innerText.trim()) {
          return cells[cellIndex].querySelector('img').src;
        }
        if (cells[cellIndex].getAttribute('data-export') && cells[cellIndex].getAttribute('data-export').trim()) {
          return cells[cellIndex].getAttribute('data-export').trim();
        }
        return cells[cellIndex].innerText.replace(new RegExp(',', 'g'), ' | ').replace(new RegExp('\n', 'g'), '     ').replace(new RegExp('check_box_outline_blank', 'g'), '𐄂').replace(new RegExp('check_box', 'g'), '☑');
      }
    }


    let rowIndex = -1;
    let cellIndex = -1;
    for (let record of records) {
      rowIndex++;
      csvColumns = [];
      cellIndex = -1;

      props.columns.forEach(c => {
        cellIndex++;
        if (c.type != 'system-check' && c.type != 'action') {
          // if (c.type == 'parent' || c.type == 'user') {
          //   c.children.forEach(cc => {
          //     if (cc.type == 'dropdown') {
          //       if(record[cc.field]){
          //         csvColumns.push(getValueForCSV(tmo.helpers.readOr(record[cc.field], 'label', '?'));
          //       }
          //       else{
          //         csvColumns.push(getValueForCSV(getCellText(rowIndex, cellIndex));
          //       }
          //     }
          //     else {
          //       csvColumns.push(getValueForCSV(record[cc.field]);
          //     }

          //   });
          // }
          // else {

          let exportData = getExportData(rowIndex, cellIndex);

            if (exportData) {
              csvColumns.push(exportData.replace(new RegExp(',', 'g'), '，').replace(new RegExp('\n', 'g'), '     ').replace(new RegExp('check_box_outline_blank', 'g'), '𐄂').replace(new RegExp('check_box', 'g'), '☑'));
            }
            else {

                if (c.type == 'dropdown') {
                  if (record[c.field] && record[c.field].label) {
                    csvColumns.push(getValueForCSV(tmo.helpers.readOr(record[c.field], 'label', ' ')));
                  }
                  else {
                    csvColumns.push(getValueForCSV(getCellText(rowIndex, cellIndex)));
                  }
                }
                else if (c.type === 'custom' || c.type == 'parent' || c.type == 'user') {

                  csvColumns.push(getValueForCSV(getCellText(rowIndex, cellIndex)));
                }
                else if (c.type == 'bool') {
                  csvColumns.push(getValueForCSV(record[c.field] ? 'YES' : 'NO'));
                }
                else if (c.type == 'tag') {
                  csvColumns.push(getValueForCSV((record[c.field] || []).map(r => tmo.helpers.readOr(r, 'name', '?')).join(' : ')));
                }
                else if (c.type == 'segment') {
                  csvColumns.push(getValueForCSV((record[c.field] || []).map(r => tmo.helpers.readOr(r, 'name', '?')).join(' : ')));
                }
                else {

                  csvColumns.push(getValueForCSV(record[c.field]));
                }
            } 
        }
      });
      rows.push(csvColumns);
    }

    let csvContent = "data:text/csv;charset=utf-8,"
      + rows.map(e => e.join(",")).join("\n");

    // var encodedUri = encodeURI(csvContent);
    // window.open(encodedUri);

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", (props.templateName || 'SHOPNOW_DATA_FILE' + (new Date().toISOString().replace(new RegExp(' ', 'g'), '_'))) + ".csv");
    //   document.body.appendChild(link);  
    window.open(encodedUri);
    //setTimeout(() => {
    //  link.click();  
    // }, 200);


  }



  return (
    <div className="export-data-panel">

      <div className="description">
        <b>Data export tool:</b>
        <ul>
          <li>You can only export visible records in current page as csv file</li>
          <li>Maximum number of records allowed to export is 100</li>
          <li>You will be getting CSV file. You can use excel to open it.</li>
          <li>We highly suggest system admins to limit export permissions with roles</li>
        </ul>
        <b>Click the link below to download exported data:</b>
        <p><a onClick={downloadFile} >Click here to download CSV file</a></p>

      </div>
    </div>
  );
});





export default ExportGridData;

import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { Globals } from '../../Globals';
import { isEmpty } from '../../tmo/tmo.utils';
import useInterval from '../../hooks/useInterval';
import { CARD_SIZE } from '../../ShopNow/Components/Overview/constants';
import { APPLICATION_TYPES } from '../../Auth/Application/Application.Api';
 import { BarChart } from '../../components/chart/BarChart';
import ActiveUsersPanel from '../../ECommerce/Overview/components/ActiveUsersPanel';
import { AnalyticsOverviewApi } from '../../Analytics/AnalyticsOverview.Api';
import MetricCard from '../../ShopNow/Components/Overview/MetricCard';
import AnalyticsActivePages from '../../ShopNow/Components/Overview/templates/AnalyticsActivePages';
import './OverviewCard.AnalyticsActiveUsers.scss';

const StyledTopVisualizationSelector = styled.div`
    display: grid;
    gap: 16px;
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
    grid-auto-rows: max-content;
    grid-auto-flow: row dense;
    margin-bottom: 16px;
`;

const Skeleton = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    min-height: ${(props) => props.$skeletonSize || 340}px;
`;

const FullWidthWrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;

    .visualizations-wrapper {
        flex: 1;

        > div {
            height: 100%;
        }
    }
`;

const API_TIMEOUT = 12000;

function OverviewCardAnalyticsActiveUsers({ config, cardIndex, onRemoveCard, onAddCard, applications }) {
    const [activeUsersData, setActiveUsersData] = useState(null);
    const [activePagesData, setActivePagesData] = useState(null);
    const [activeUsersDataTime, setActiveUsersDataTime] = useState(new Date());
    const [activePagesDataTime, setActivePagesDataTime] = useState(new Date());
    const [selected, setSelected] = useState(0);
    const [updateForce, setUpdateForce] = useState(false);
    const location = useLocation();

    const isAnalytics = useCallback(() => location.pathname.includes('analytics'), [location]);

    const getEcommerceAppIds = () => {
        let ecommerceApps = [];
        const apps = Globals.currentUser.applications.filter(
            (a) => a.account_id === Globals.currentAccount.account_id
        );

        apps.forEach((app) => {
            const isEcommerce = APPLICATION_TYPES.find(
                (a) => a.value === app.type && a?.ecommerce === true
            );

            if (isEcommerce) {
                ecommerceApps.push(app.id);
            }
        });

        return ecommerceApps;
    };

    const reqParams = isAnalytics()
        ? { analytics_application_id: applications }
        : { application_ids: isEmpty(applications) ? getEcommerceAppIds() : applications };

    const handleMetricSelection = (index) => {
        setSelected(index);

        setUpdateForce(true);
        setTimeout(() => {
            setUpdateForce(false);
        }, 500);
    };

    const metrics = [
        {
            title: 'Sessions online (Pinged)',
            tooltip:'Online sessions over time. People in web site right now. All tabs, visits etc from same devices are calculated as different sessions. Has a small delay for pings.' ,
            type: 'visitor',
            footerNote: 'Based on ping (Network signal)',
            measure: '{0} <small>Human</small> + ' + activeUsersData?.bots + ' <small>Bots</small>',
            value: {
                type: 'integer',
                current: activeUsersData?.humans,
            },
        },
        {
            title: 'Sessions created',
            tooltip:'Number of sessions by created time (First visit time). All tabs, visits etc from same devices are calculated as different sessions' ,
            type: 'created',
            measure:
                '{0} <small>Human</small> + ' +
                activeUsersData?.bots_in_30_min +
                ' <small>Bots</small>',
            footerNote: 'Witthin last 30 minutes',
            value: {
                type: 'integer',
                current: activeUsersData?.humans_in_30_min,
            },
        },
        {
            title: 'Active sessions',
            tooltip:'Current status of all the sessions was online within last 2 minutes. All tabs, visits etc from same devices are calculated as different sessions. Has a small delay for pings.' ,
            type: 'page',
            measure:
                '{0} <small>Human</small> + ' +
                (activePagesData || []).filter((p) => p.is_bot).length +
                ' <small>Bots</small>',
            footerNote: 'Winthin last 2 minutes',
            value: {
                type: 'integer',
                current: (activePagesData || []).filter((p) => !p.is_bot).length,
            },
        },
        {
            title: 'Login or register',
            type: 'registered',
            tooltip:'Number of register actions by sent time (Send to shopnow server). One use may send multiple login/register action. This is number of actions not users.' ,

            footerNote: 'Membership actions over time',
            value: {
                type: 'integer',
                current: activeUsersData?.registered,
            },
        },
        {
            title: 'Revenue',
            type: 'revenue', 
            measure: '{0} ' + (window.currentAccount?.currency || 'RMB'),
            footerNote: 'Website currency in last 30 minutes',
            value: {
                type: 'integer',
                current: activeUsersData?.revenue,
            },
        },
    ];

    const getStackedBarSum = (tooltipItems) => {
        let sum = 0;

        tooltipItems.forEach((tooltipItem) => (sum += tooltipItem.parsed.y));
        return 'Total: ' + sum;
    };

    const getBarChartData = () => {
        if (selected === 1) {
            return {
                ...activeUsersData,
                datasets: activeUsersData.datasets.filter(
                    (d) => d.type === 'created' || d.type === 'created_bot'
                ),
            };
        } else if (selected === 3) {
            return {
                ...activeUsersData,
                datasets: activeUsersData.datasets.filter((d) => d.type === 'registered'),
            };
        } else if (selected === 4) {
            // let registeredData = 
            
            // registeredData.data = registeredData.data.filter((d) => d!=null) || [];

            return { 
                ...activeUsersData, 
                datasets:activeUsersData.datasets.filter((d) => d.type === 'revenue' || d.type === 'basket')
            }
        } else {
            return {
                ...activeUsersData,
                datasets: activeUsersData.datasets.filter(
                    (d) =>
                        d.type === 'anonymous' ||
                        d.type === 'bot' ||
                        d.type === 'registered_online' ||
                        d.type === 'ordered_online'
                ),
            };
        }
    };



    useInterval(
        () => {
            getRealTimeData(reqParams)
        },
        API_TIMEOUT,
        [reqParams]
    );
 
 
    const getRealTimeData = (reqParams) => {
        AnalyticsOverviewApi.getRealtimeActivePages(reqParams).then((data) => {
            setActivePagesDataTime(new Date());
            setActivePagesData(data);
        });
        AnalyticsOverviewApi.getRealtimeActiveUsers(reqParams).then((data) => {
            const filteredData = {
                ...data,
                datasets: data.datasets.filter((dataset) => dataset.label !== 'Visitors'),
            };
            setActiveUsersDataTime(new Date());
            setActiveUsersData(filteredData);
        });
    };



   

    useEffect(() => {
        setActiveUsersData(null);
        setActivePagesData(null);
        getRealTimeData(reqParams);
    }, [applications]);

    return (
        <>
            {activeUsersData && activePagesData && ( 
                    <div className="top-panel-wrapper">
                        <div className="top-panel-left">
                            <FullWidthWrapper>
                                <StyledTopVisualizationSelector>
                                    {metrics.map((metric, index) => (
                                        <MetricCard
                                            onlyValue
                                            lastUpdate={
                                                metric.type === 'page'
                                                    ? activePagesDataTime
                                                    : activeUsersDataTime
                                            }
                                            key={`top-vis-metric-${index}`}
                                            {...metric}
                                            selected={selected === index}
                                            onClick={() => handleMetricSelection(index)}
                                        />
                                    ))}
                                </StyledTopVisualizationSelector>
                                <div className="visualizations-wrapper">
                                    {selected === 2 ? (
                                        <AnalyticsActivePages
                                            config={{
                                                title: 'Active Pages Over Time',
                                                skeletonSize: 660,
                                                size: CARD_SIZE.FULL_WIDTH,
                                            }}
                                            cardIndex={cardIndex}
                                            applications={applications}
                                            data={activePagesData}
                                        />
                                    ) : (
                                        !updateForce && (
                                            <BarChart
                                                forceUpdate={updateForce}
                                                borderRadius={4}
                                                data={getBarChartData()}
                                                height={400}
                                                stackedX
                                                stackedY
                                                options={{
                                                    borderRadius:8,
                                                    borderColor: 'white',
                                                    categoryPercentage: 1,
                                                    barPercentage: 0.8,
                                                    borderWidth: {
                                                        left: 1,
                                                        right: 1,
                                                    },
                                                    interaction: {
                                                        intersect: false,
                                                        mode: 'index',
                                                    },
                                                }}
                                                responsive
                                                plugins={{
                                                    tooltip: {
                                                        callbacks: {
                                                            footer: getStackedBarSum,
                                                        },
                                                    },
                                                }}
                                            />
                                        )
                                    )}
                                </div>
                            </FullWidthWrapper>
                        </div>
                        <div className="top-panel-right">
                            <ActiveUsersPanel data={activePagesData} />
                        </div>
                    </div>
                    
            )}
            {/* {(!activeUsersData || !activePagesData) && (
                <div className="full overview-card">
                    <Skeleton $skeletonSize={660} className={`skeleton-card`}>
                        <div className="skeleton-content" />
                    </Skeleton>
                </div>
            )} */}
        </>
    );
}

export default OverviewCardAnalyticsActiveUsers;

import './OverviewCardContainer.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import OverviewCard from './OverviewCard';
import HighChartWrappper from './HighChartWrappper';

import Highcharts from 'highcharts'

import { GridStack } from 'gridstack';
import 'gridstack/dist/gridstack.css';
import useInterval from '../../hooks/useInterval';
import tmoLib from '../../tmo/tmo.lib';
import { formatNumber } from '../../tmo/tmo.formatters';
import Card from './Card';
import IconButton from './IconButton';
import Sticker from './Sticker';
import OverviewCardBestRecord from './OverviewCardBestRecord';
import { FunnelChart } from '../chart/FunnelChart';
import RecordViewerGrid from '../../Customer/Segment/RecordViewerGrid';
import { CustomerListDataGridStore } from '../../Customer/List/CustomerList.GridStore';
import { CustomerProfileApi } from '../../Customer/Edit/CustomerProfile.Api';
import { CARD_TYPE_LIST_ITEMS, CARD_TYPE_MIN_SIZES, OVERVIEW_CARD_CHART_SETTINGS, OVERVIEW_CARD_CHART_TYPES, OVERVIEW_CARD_PRECALCULATED_CHART_TYPES, OVERVIEW_CARD_TYPES, OVERVIEW_PAGE_TYPE } from './OverviewCardUtil';
import { LineChart } from '../chart/LineChart';
import OverviewCardCard from './OverviewCardCard';
import { OverviewCardChartDataApi } from './OverviewCard.ChartData.Api';
import { OverviewCardManager } from './OverviewCardManager';
import { SegmentApi } from '../../Customer/Segment/Segment.Api';
import OverviewCardDynamicCard from './OverviewCardDynamicCard';
import { TIME_FRAME } from '../../ShopNow/Components/Report/Filters/utils';
import OverviewCardSelector from './OverviewCardSelector';
import { TagApi } from '../../Customer/Tags/Tag.Api';
import Button from './Button';
import { Globals } from '../../Globals';
import OverviewCardCardContainer from './OverviewCardCardContainer';
import OverviewCardAnalyticsActiveUsers from './OverviewCard.AnalyticsActiveUsers';
import OverviewCardWorldMap from './OverviewCard.WorldMap';
import { GlobalStore } from '../../GlobalStore';
import OverviewCardGridStackItem from './OverviewCardGridStackItem';



// const startOfDayDate = new Date(new Date().toISOString().split('T')[0]+'T00:00:00.000Z');
// const endOfDayDate = new Date(new Date().toISOString().split('T')[0]+'T23:59:59.000Z'); 

// console.log('startOfDayDate',startOfDayDate);

const MAX_COLUMNS = 3;

function OverviewCardContainer(props) {

  let containerId = (window.currentAccount).account_id + 'overview-data-' + MAX_COLUMNS + (props.id || 'no-id-11');

  const [data, setSortedData] = useState([]);
  let [grid, setGrid] = useState(null);

  const containerRef = useRef();

  const [allDone, setAllDone] = useState(false);
  const [chartData, setChartData] = useState(null);
  const [dynamicChartData, setDynamicChartData] = useState(null);
  const [showCardSelector, setShowCardSelector] = useState(false);
  const [enableEditMode, setEnableEditMode] = useState(false);
  const [deletedCards, setDeletedCards] = useState([]);
  const [cardSelectorContainer, setCardSelectorContainer] = useState({});
  const [cardsChanged, setCardsChanged] = useState(false);
 

  const setCardList = (cards) => {
    let index = 0;
    let cardLength = cards.filter((c, ind) => {
      if (c.type == OVERVIEW_CARD_TYPES.CARD_CONTAINER || c.type == OVERVIEW_CARD_TYPES.CARD || c.type == OVERVIEW_CARD_TYPES.DYNAMIC_CARD || c.type == OVERVIEW_CARD_TYPES.BEST) {
        c.delay = 0;
      }
      else {
        return true;
      }
    }).length;
    cards.forEach((c, ind) => {
      if (c.type == OVERVIEW_CARD_TYPES.CARD_CONTAINER || c.type == OVERVIEW_CARD_TYPES.CARD || c.type == OVERVIEW_CARD_TYPES.DYNAMIC_CARD || c.type == OVERVIEW_CARD_TYPES.BEST) {
        c.delay = 0;
      }
      else {
        index++;
        c.delay = 0;// index * ((cardLength) * 50);
      }
    });
    setSortedData(cards);

  }

  const loadCardsChartData = async (charts) => {
    if(!charts){
      return;
    }
    //  setChartData([...chartData.filter(c=>c.id!=chart.id)]);
    let result = await OverviewCardManager.getChartData(charts);

    let newData  = chartData.map(c => {
      result.forEach(r => {
        if (c.chart_key == r.chart_key) {
          c.data = r.data;
        }

      }); 
      return c;
 
    });
 
    setChartData(newData);
    console.log('setChartData', charts, result, newData);
    return result;
  }

  const loadChartData = async (chart) => {
    if(!chart){
      return;
    }
    //  setChartData([...chartData.filter(c=>c.id!=chart.id)]);
    let result = await OverviewCardManager.getChartData([chart]);

    let newData  = chartData.map(c => {
      result.forEach(r => {
        if (c.chart_key == r.chart_key) {
          c.data = r.data;
        }

      }); 
      return c;
 
    });
 
    setChartData(newData);
    console.log('setChartData', chart, result, newData);
    return result;
  }

 
 
  const loadChartsData = async (charts) => {
    

    console.log('loadChartsData');

      // if (sessionStorage.getItem(containerId)) {
      //   let dataText = sessionStorage.getItem(containerId);
      //   let filterSaved = sessionStorage.getItem(containerId+'filter');
      //   try {
      //     let dataJson = JSON.parse(dataText);
      //     if (dataJson && dataJson.length) {
      //       console.log('dataJson', dataJson);
      //       setChartData(dataJson);
      //     }
      //     if(filterSaved){
      //       setSelectedFilter(JSON.parse(filterSaved));
      //     }
      //     else{
      //       setSelectedFilter(
      //         GlobalStore.currentToolbarOptions.get()
      //       );
      //     }
          
      //   }
      //   catch (ex) {

      //   }
      // }

      let chartsToLoadData = [];
      charts.forEach(c => {
      
          chartsToLoadData.push(c); 
          if(c.children){
            c.children.forEach(ch=>{
              chartsToLoadData.push(ch);
            });
          }
          c.loading = true;
      });


      charts.forEach(d => { 
        if (d.children) {
          d.children.forEach(c => {
            if(c.date_range!=selectedFilter?.timeframe){
              c.date_range = selectedFilter?.timeframe || c.date_range;
            }
          });
        } 
        if(d.date_range!=selectedFilter?.timeframe){
          d.date_range = selectedFilter?.timeframe || d.date_range;
        }
      });

      if(!chartsToLoadData?.length){
        return;
      }
      
      let dynamicData = await OverviewCardManager.getDynamicChartData();
      setDynamicChartData(dynamicData);
      let result = await OverviewCardManager.getChartData(chartsToLoadData, selectedFilter);
      setChartData(result);
      setAllDone(true);

      // console.log('setChartData',result);
      ///setCardList([...data])
      
      
      charts.forEach(c => {
        
          c.loading = false;
      });

      console.log(charts);

   //   setSortedData(charts);




      sessionStorage.setItem(containerId, JSON.stringify(result));
      return result;
  }

  const getAllCharts = async () => {

    let allCharts = await OverviewCardManager.getAllCards();

    let randomCharts = [];
    allCharts.forEach((c, ind) => {
      if (Math.random() * 10 > 7 || ind == 0) {
        randomCharts.push(c);
      }
    });

    if(randomCharts.length>15){
      randomCharts = randomCharts.slice(0,15);
    }

    setCardList(randomCharts);
    return allCharts;

  }

  const loadAll = async (charts) => {
    let allCharts = await getAllCharts();

    await loadChartsData(allCharts);
  }

  const loadData = async (charts) => {
    await loadChartsData(charts);
  }


  let inited = false;
  const initPage = async (charts) => {
   

    if(inited){
      return;
    }
    inited = true;

    // if(selectedFilter){
    //   inited = true;
    // }
    // else{
    //   return;
    // }
 

    let loaded = false;
    let savedLocalStorageData = localStorage.getItem(containerId);
    if (savedLocalStorageData) {
      let objData = JSON.parse(savedLocalStorageData);
      if (objData) {
        objData = objData.filter(d => d && d.id && d.type && CARD_TYPE_MIN_SIZES[d.type]);
        if (objData.length) {
          console.log('objData', objData);
          if(objData.length>25){
            objData = objData.slice(0,25);
          }
          setCardList(objData);
          await loadData(objData)
          loaded = true;
        }
      }
    }
    if (!loaded) {
      // let appApplications = window.currentUser.applications.filter(a=>a.account_id=window.currentAccount.id).map(a=>a.id);
      // let systemFilters = [
      //   { field: 'registered_at', op: 'gte', value: props.start_date || startOfDayDate },
      //   { field: 'registered_at', op: 'lte', value: props.end_date || endOfDayDate },
      //   { field: 'application_ids', value: props.application_ids || appApplications }
      // ];


      // { 
      //   name: 'Customers By Segment', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 10 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.SEGMENT_CARD,
      // },

      await loadAll();

    }
  }


  useEffect(() => {

    initPage();

  }, []);


  let [selectedFilter, setSelectedFilter] = useState(null);


  let globalFilter = GlobalStore.currentToolbarOptions.follow();
 
  useEffect(() => {
 
    setSelectedFilter(globalFilter);
    selectedFilter = globalFilter;
    
    inited = false;
      loadChartsData(data);
    // initPage();
  }, [globalFilter]);


  let renderStackRunning = false;
  const renderStack = () => {
    if (renderStackRunning || grid) {
      return;
    }
    renderStackRunning = true;


    var gridInstance = GridStack.init({
      sizeToContent: false,
      cellHeight: '60px',
      disableResize: true,
      resizable: Globals.isAdmin ? null : { handles: 's' },
      disableDrag: !enableEditMode,
      column: MAX_COLUMNS,
      draggable: {
        handle: '.overview-card-wrapper-header-title',
      }
    });
    console.log('gridInstance', gridInstance)
    setGrid(gridInstance);
    grid = gridInstance;

    // grid.on('change', function(event, items) {
    // console.log('change', items);
    //console.log(grid.save());

    //  });
    // grid.on('resize', function (event, el) {
    //   let id = el.getAttribute('data-gs-id');
    //   let width = parseInt(el.getAttribute('gs-w')) || 0;
    //   let height = parseInt(el.getAttribute('gs-h')) || 0;
    //   let found = data.find(d => d.id == id);
    //   console.log('resized', id, width, height, found);
    //   if (found) {
    //     found.size =  found.size || {};
    //     found.size.width =  parseInt(el.getAttribute('gs-w'));
    //     found.size.height=  parseInt(el.getAttribute('gs-h'));
    //     found.size.x=  parseInt(el.getAttribute('gs-x'));
    //     found.size.y=  parseInt(el.getAttribute('gs-y'));

    //   }



    // });
    gridInstance.on('change', function (event, items) {
      // console.log('changed' , items, grid.getGridItems());
      // let dataToSave = [];
      // grid.getGridItems().forEach(item => {
      //   let itemId = item.getAttribute('data-gs-id');
      //   let itm = data.find(i=>i.id==itemId);
      //   if(itm){
      //   //  itm = { ...itm, size:{...itm.size} };
      //     itm.size =  itm.size || {};
      //     itm.size.w =  parseInt(item.getAttribute('gs-w'));
      //     itm.size.h=  parseInt(item.getAttribute('gs-h'));
      //     itm.size.x=  parseInt(item.getAttribute('gs-x'));
      //     itm.size.y=  parseInt(item.getAttribute('gs-y'));

      //     dataToSave.push(itm);
      //   }
      // });
      // dataToSave.sort((a, b) => {
      //   return (a.size.y - b.size.y) || (a.size.x - b.size.x);
      // });
      // data.sort((a, b) => {
      //   return (a.size.y - b.size.y) || (a.size.x - b.size.x);
      // });


      // saveStack();

    });

    if (enableEditMode) {
      startEditing();
    }


  }


  const saveStack = () => {
    let dataToSave = [];
    grid.getGridItems().forEach(item => {
      let itemId = item.getAttribute('data-gs-id');
      let itm = data.find(i => i.id == itemId);
      if (itm) {
        //  itm = { ...itm, size:{...itm.size} };
        itm.size = itm.size || {};
        itm.size.w = parseInt(item.getAttribute('gs-w'));
        itm.size.h = parseInt(item.getAttribute('gs-h'));
        itm.size.x = parseInt(item.getAttribute('gs-x'));
        itm.size.y = parseInt(item.getAttribute('gs-y'));

        dataToSave.push(itm);
      }
    });
    setCardList([...data]);

    dataToSave.sort((a, b) => {
      return (a.size.y - b.size.y) || (a.size.x - b.size.x);
    });
    localStorage.setItem(containerId, JSON.stringify(dataToSave));

  }


  const [renderingIndex, setRenderingIndex] = useState(100);

  // const renderNext = () => {
  //   if(chartData){
  //     if (data.length < renderingIndex) {
  //       return;
  //     }
  //     setRenderingIndex(renderingIndex + 1);
  //   }
  //   return;
  // }

  // useInterval(renderNext, 300, [renderingIndex]);

  const exitSaveCards = () => {
    if(!enableEditMode){
      return;
    }
    setEnableEditMode(false);
    grid.enableMove(false);
    grid.enableResize(false);

    
    if (!cardSelectorContainer || !cardSelectorContainer.container) {
      if(!cardsChanged){
        return;
      }
      window.document.location.reload();
    }
  }


  const onStopEdit = (event) => {
    if (event.key === 'Escape') {
      exitSaveCards();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', onStopEdit, false);
    return () => {
      document.removeEventListener('keyup', onStopEdit, false);
    };
  }, [onStopEdit]);


  if (!data.length) {
    return <></>
  }

  // const getChartData = (chart) => {
  //    return (chartData || []).find(c => c.chart_key == chart.chart_type)?.data;
  // }

  // const getFunnelChartData = (chart) => {
  //    let data = (chartData || []).find(c => c.chart_key == chart.chart_type)?.data;
  //   return {
  //     colors: data?.chart_model.colors || [],
  //     values: data?.chart_model.datasets || [],
  //     labels: data?.chart_model.labels || [],
  //   }
  // }

  // const getDynamicChartData = (chart) => {
  //   return (dynamicChartData || []).find(c => c.chart_key == chart.chart_type && c.id == chart.id)?.data;
  // }


  const onTimeRangeChanged = (chart, t) => {
    console.log('onTimeRangeChanged', chart, t);
    if(chart.type == OVERVIEW_CARD_TYPES.CARD_CONTAINER){
      let charts = chart.children.map(c=>{
        return {
          ...c,
          date_range: t.value,
          start_date: t.start_date,
          end_date: t.end_date,

        } 
      });
      loadCardsChartData(charts);
      saveStack();
      return;
    } 


    if (t.value !== TIME_FRAME.CUSTOM_RANGE) {
      chart.date_range = t.value;
      chart.start_date = t.start_date;
      chart.end_date = t.end_date;
      
      loadChartData(chart);
      saveStack();
    } else {

    }
  };



  const onMaxFieldsChanged = (chart, value) => {
    chart.max_field =  value; 
      loadChartData(chart);
      saveStack();
    
  };

  const onCardSelectionDone = (cards) => {


    if (cardSelectorContainer && cardSelectorContainer.container) {
      cardSelectorContainer.container.children = cardSelectorContainer.container.children || [];
      cardSelectorContainer.container.children.push({ type: OVERVIEW_CARD_TYPES.CARD, size: { w: 2, h: 2 } });
      let newData = cardSelectorContainer.container.children;

      cards.forEach(c => {
        c.id = c.id || c.chart_type;
        if (c.visible) {
          if (!newData.find(d => d.id == c.id)) {
            newData.push(c);
          }
        }
        else {
          if (newData.find(d => d.id == c.id)) {
            newData = cardSelectorContainer.container.children.filter(d => d.id != c.id);
          }
        }
      });

      let found = data.find(d => d.id == cardSelectorContainer.container.id);
      if (found) {
        found.children = newData; 
      }
      else {
        console.log('not found', cardSelectorContainer.container.id);
      }

    }
    else {
      let newData = data;
      cards.forEach(c => {
        console.log(c);
        c.id = c.id || c.chart_type || c.type;
        if (c.visible) {
          if (!newData.find(d => d.id == c.id)) {
            newData.push(c);
          }
        }
        else {
          if (newData.find(d => d.id == c.id)) {
            newData = data.filter(d => d.id != c.id);
          }
        }
      });

      setCardList([...newData]);
    }

    setShowCardSelector(false);
    setCardSelectorContainer(null); 
    setCardsChanged(true);
 
  }



  const onCardSelectionClosed = () => {
    if (cardSelectorContainer && cardSelectorContainer.container) {
      setShowCardSelector(false);
      setCardSelectorContainer(null);
      return;
    }
    setShowCardSelector(false);
    grid.destroy(false);

    setGrid(null);

    setTimeout(() => {
      document.body.scrollTop = document.body.scrollHeight;
    }, 150);
    setTimeout(() => {
      document.body.scrollTop = document.body.scrollHeight;
    }, 300);
    setTimeout(() => {
      document.body.scrollTop = document.body.scrollHeight;
    }, 500);
    setTimeout(() => {
      document.body.scrollTop = document.body.scrollHeight;
    }, 1000);

  }
  const removeCard = (card) => { 
    setDeletedCards(card);
    setCardsChanged(true);
    grid.removeWidget(document.querySelector(`[data-gs-id="${card.id}"]`));
 
  }


  const saveCards = () => {
    setEnableEditMode(false);
    grid.enableMove(false);
    grid.enableResize(false);

    saveStack();
    if(!cardsChanged){
      return;
    }
    window.document.location.reload();
  }

  const startEditing = () => {
    if (Globals.isAdmin) {
      grid.enableResize(true);
    } 
    grid.enableMove(true);
    if (!enableEditMode) { 
      setCardsChanged(false);
      setEnableEditMode(true); 
    }
  }


  const onContainerAddCard = (card) => {
    card = card || {};
    card.children = card.children || [];
    setCardSelectorContainer({ container: card, types: [OVERVIEW_CARD_TYPES.CARD, OVERVIEW_CARD_TYPES.DYNAMIC_CARD] });
    setShowCardSelector(true);

  }

  const onAddCard = () => {
    setCardSelectorContainer(null);
    setShowCardSelector(true);
  }
 


  console.log('====== OverviewCardContainer RENDERING')

  return <><div className="grid-stack" ref={renderStack}>


{data.filter(d => d.type != OVERVIEW_CARD_TYPES.CARD && d.type != OVERVIEW_CARD_TYPES.DYNAMIC_CARD).map((d, ind) => {


      return <OverviewCardGridStackItem
                onContainerAddCard={onContainerAddCard}
                settings={d}
                onStartEditing={startEditing}
                onRemoveCard={removeCard}
                chartData={chartData}
                onMaxFieldsChanged={onMaxFieldsChanged}
                onTimeRangeChanged={onTimeRangeChanged}
                onShowCardSelector={()=>setShowCardSelector(true)}
                containerRef={containerRef}
                allDone={allDone}
                editMode={enableEditMode}
                maxColumns={MAX_COLUMNS}
                />
        })}
{/* 
    {data.filter(d => d.type != OVERVIEW_CARD_TYPES.CARD && d.type != OVERVIEW_CARD_TYPES.DYNAMIC_CARD).map((d, ind) => {

      let cardClass = 'grid-stack-item-content ';
      let disableFooter = false;
      let disableHeader = false;
      let headerNoBorder = false;
      let noPadding = false;
      if (d.type == OVERVIEW_CARD_TYPES.CARD || d.type == OVERVIEW_CARD_TYPES.DYNAMIC_CARD) {
       
        headerNoBorder = true;
      }
      if (d.type == OVERVIEW_CARD_TYPES.CARD || d.type == OVERVIEW_CARD_TYPES.DYNAMIC_CARD || d.type == OVERVIEW_CARD_TYPES.BEST) {
        disableFooter = true;
      }
 

      let w = (d.size.w || d.size.minWidth);
      if (w < 1) {
        w = 1;
      }
      let mw = CARD_TYPE_MIN_SIZES[d.type].size.minWidth;
      if (mw < 1) {
        mw = 1;
      }
      if (mw > MAX_COLUMNS) {
        mw = MAX_COLUMNS;
      }

      if (w < mw) {
        w = mw;
      }
      if (w > MAX_COLUMNS) {
        w = MAX_COLUMNS;
      }
      if (!w) {
        w = mw;
      }



      return <div
        key={d.id || ind}
        className={"grid-stack-item border-dark "}
        data-gs-id={d.id}
        gs-id={d.id}
        gs-x={d.size.x}
        gs-y={d.size.y}
        gs-w={w}
        gs-h={d.size.h || d.size.minHeight}
        gs-min-w={mw}
        gs-min-h={CARD_TYPE_MIN_SIZES[d.type].size.minHeight}
      >

   
    { 
        d.type == OVERVIEW_CARD_TYPES.REAL_TIME_TRAFFIC &&
        <OverviewCard
            realtime
            editMode={enableEditMode}
            canRender={chartData ? true : false}
            renderTimeout={d.delay}
            className={cardClass}
            key={d.id}
            disableFooter={true}
            disableHeader={true}
            headerNoBorder={true}
            noPadding={true}
            title={d.name}
            tooltip={d.tooltip}
            parent={containerRef}
            noBackground
            onStartAdd={() => setShowCardSelector(true)}
            onStartEdit={() => startEditing(true)}
            onRemove={() => removeCard(d)}
            onTimeRangeChanged={(t) => onTimeRangeChanged(d, t)}
            onMaxFieldsChanged={(t) => onMaxFieldsChanged(d, t)}
            showMaxFieldSelect={d.type == OVERVIEW_CARD_TYPES.BAR || d.type == OVERVIEW_CARD_TYPES.DONUT || d.type == OVERVIEW_CARD_TYPES.PIE}
            maxField={d.max_field}
            >
          <OverviewCardAnalyticsActiveUsers />
          </OverviewCard>
    }
     { 
        d.type != OVERVIEW_CARD_TYPES.REAL_TIME_TRAFFIC  &&
        <OverviewCard
          editMode={enableEditMode}
          canRender={chartData ? true : false}
          hasData={getChartData(d)}
          renderTimeout={d.delay}
          className={cardClass}
          key={d.id}
          timeframe={d.date_range}
          disableFooter={disableFooter}
          disableHeader={disableHeader}
          headerNoBorder={headerNoBorder}
          noPadding={noPadding}
          title={d.name}
          tooltip={d.tooltip}
          parent={containerRef}
          onStartAdd={() => setShowCardSelector(true)}
          onStartEdit={() => startEditing(true)}
          onRemove={() => removeCard(d)}
          loading={d.loading}
          onTimeRangeChanged={(t) => onTimeRangeChanged(d, t)}
          onMaxFieldsChanged={(t) => onMaxFieldsChanged(d, t)}
          showMaxFieldSelect={d.type == OVERVIEW_CARD_TYPES.BAR || d.type == OVERVIEW_CARD_TYPES.DONUT || d.type == OVERVIEW_CARD_TYPES.PIE}
          maxField={d.max_field}
          allDone={allDone}
          >
          {chartData ? <>
            {renderingIndex > ind && (d.type == OVERVIEW_CARD_TYPES.FUNNEL) && <FunnelChart
              data={getFunnelChartData(d)}
              options={{ displayPercent: true }}
              hide
            />}

            {renderingIndex > ind && (d.type == OVERVIEW_CARD_TYPES.GRID) && <OverviewCardGrid
              {...OVERVIEW_CARD_CHART_SETTINGS[d.chart_type]()}
              gridId={d.grid_id} />}

            {renderingIndex > ind && (d.type == OVERVIEW_CARD_TYPES.CUSTOM) && props.children}
            {renderingIndex > ind && (d.type == OVERVIEW_CARD_TYPES.PIE  || d.type == OVERVIEW_CARD_TYPES.DONUT  || d.type == OVERVIEW_CARD_TYPES.BAR || d.type == OVERVIEW_CARD_TYPES.WORDCLOUD || d.type == OVERVIEW_CARD_TYPES.GAUGE || d.type == OVERVIEW_CARD_TYPES.COLUMN || d.type == OVERVIEW_CARD_TYPES.LINE || d.type == OVERVIEW_CARD_TYPES.VENN) && <HighChartWrappper yAxisType={getyAxisType(d)} settings={d} data={getChartData(d)} type={d.type.toLowerCase()} w={d.size?.w} h={d.size?.h} />}
            {renderingIndex > ind && d.type == OVERVIEW_CARD_TYPES.CARD_CONTAINER && <OverviewCardCardContainer editMode={enableEditMode} children={d.children} title={d.name} onGetDynamicChartData={(card) => getDynamicChartData(card)} onGetChartData={(card) => getChartData(card)} onAddCard={() => onContainerAddCard(d)} />}
              {renderingIndex > ind && d.type == OVERVIEW_CARD_TYPES.BEST && <OverviewCardBestRecord size={d.size} data={getChartData(d)}  {...OVERVIEW_CARD_CHART_SETTINGS[d.chart_type]()} />}
            {renderingIndex > ind && d.type == OVERVIEW_CARD_TYPES.WORLD_MAP && <OverviewCardWorldMap size={d.size} data={getChartData(d)} chart={d} timeframe={d.date_range} {...OVERVIEW_CARD_CHART_SETTINGS[d.chart_type]()} />}
             
          </> : null}
        </OverviewCard>
    }
      </div>

    })} */}





  </div>
    {showCardSelector && <OverviewCardSelector 
    overviewType={props.overviewType}
    types={cardSelectorContainer?.types || Object.values(OVERVIEW_CARD_TYPES).filter(v=>v!=OVERVIEW_CARD_TYPES.CARD)} cards={cardSelectorContainer?.container?.children || data} onSave={onCardSelectionDone} onClose={onCardSelectionClosed} />}
    {enableEditMode && <div className="card-edit-footer">
      <Button size="medium" icon="add" label="Add Cards" onClick={() => onAddCard()} />
      <Button size="medium" icon="save" label="Save" onClick={saveCards} />
      <Button size="medium" wrapperClassName="exit-edit" primary label="Exit Edit Mode" onClick={exitSaveCards} />
    </div>}
  </>

}


function OverviewCardGrid(props) {
  const recordViewerRef = useRef();

  useEffect(() => {
    recordViewerRef.current?.reload();
  }, [props.applications]);

  return <RecordViewerGrid
    ref={recordViewerRef}
    onSearch={(params) =>
      props.api.search({
        ...params,
        ...props.params
      })
    }
    store={props.store}
    gridId={props.gridId}
  />
}


// function OverviewCardChart(props) {

//   const [size, setSize] = useState();

//   const containerRef = useRef();


//   useEffect(() => {
//       var grid = GridStack.init({
//         draggable:{
//           handle: '.overview-card-wrapper-header-title',
//         }
//       });
//   });



//   return  <div className="grid-stack-item border-dark" data-gs-width="4" data-gs-height="4">

//             <OverviewCard className="grid-stack-item-content" key={d.id}  title={d.id} parent={containerRef}> 
//               <HighChartWrappper  size={size} />
//             </OverviewCard>

//       </div>  

// }






export default OverviewCardContainer;

import React from 'react';

import './Address.List.scss';

import { ListDataGridStore, Navigations } from './Address.GridStore';
import { AddressApi } from './Address.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import { GlobalStore } from '../../GlobalStore';
import AppToolbarHelper from '../../components/basic/AppToolbarHelper';

function AddressList(props) {



    let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let systemFilter = [
        ...AppToolbarHelper.getToolbarAppSelectionForEcommerceFilter(globalFilter, 'application_id'),
        // ...AppToolbarHelper.getToolbarTimeSelectionForEcommerceFilter(globalFilter, 'created_at'),
        ...AppToolbarHelper.getToolbarStoreSelectionForEcommerceFilter(globalFilter, 'store_id')
    ]


    return (
        <GridListPage
       
         tuneDownCondition={(item)=> !item.country}
        disableDelete={true}
            store={ListDataGridStore}
            systemFilter={systemFilter}
            onSearch={AddressApi.search}
            onHistory={AddressApi.history}
            onGotoEdit={Navigations.gotoEdit}
        />
    );
}

export default AddressList;

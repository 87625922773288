import './OverviewCard.scss';  
import React, { useEffect, useRef, useState } from 'react'; 
// import Icon from './Icon';
import IconButton from './IconButton';
import { Resizable } from 're-resizable';
import { ReactSortable } from 'react-sortablejs';
import Icon from './Icon';
import tmoLib from '../../tmo/tmo.lib';
import ListItem from './ListItem';
import { getTimeRangeDate, TIME_FRAME, timeFrameOptions } from '../../ShopNow/Components/Report/Filters/utils';
import { GlobalStore } from '../../GlobalStore';
import Button from './Button';
import DatePicker from './DatePicker';
import OverviewCardDatePicker from './OverviewCard.DatePicker';
import LoadMoreButton from './LoadMoreButton';


function OverviewCard(props) {
     
    const [maxFields, setMaxFields] = useState(props.maxField || 8);

  const [state,setState] = useState({ width:150, height:150 }); 

  const [canRender, setCanRender] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const canRenderRef= useRef(canRender);
    const setCanRenderRef = useRef(setCanRender);
    const renderingElRef = useRef(null);
 
    
  const checkIsVisible = (element) => {
    if(!element){
        return;
    }
    renderingElRef.current = element;
    const rect = element.getBoundingClientRect();
    
    if(rect.top-300 <= window.innerHeight){
        if(!isVisible){
            setIsVisible(true);
        }
        // console.log('setIsVisible', true);
    }
    else{
        if(isVisible){
            setIsVisible(false);
        }
        // conlsole.log('setIsVisible', false);
    }
};



const rendering = (element) => {
    setTimeout(()=>{
        checkIsVisible(element);
    },100);
};
 

  useEffect(()=>{ 
    if(isVisible){
        if(props.renderTimeout){
            if(props.canRender){
                setTimeout(()=>{
                    if(!canRenderRef.current){
                        setCanRenderRef.current(true);
                    }
                   
                }, props.renderTimeout  );
            }
        }
        else{
            if(!props.canRender){
                if(canRender){
                    setCanRender(false);
                }
                
            }
            else{
                if(!canRender){
                    setCanRender(true);
                }
              
            }
            

        }
    }
    else{
        
    }
  },[props.renderTimeout, props.canRender, isVisible])



  useEffect(()=>{ 
    tmoLib.ui.global.registerScrollListener(()=>{
        if(canRender && isVisible){
            return;
        }
        checkIsVisible(renderingElRef.current);
    });
  },[])
  // console.log('props.parent.current.offsetWidth',props.parent.current.ref.current.offsetWidth);
  // if(!props.parent?.current?.ref?.current?.offsetWidth ){
  //   return <></>;
  // }
// let pageWidth = props.parent.current.ref.current.offsetWidth || ((document.querySelector('.overview-card-container') || {}).offsetWidth || window.screen.availWidth-80);
 
// const getWidth = ()=>{
//   console.log('pageWidth',pageWidth );
//    return state.width - 16;

//   return 'calc('+((100 * state.width / pageWidth ) + '%')+' - 16px)'
// }


const [selectedCustomDate, setSelectedCustomDate] = useState({
  startDate: new Date(),
  endDate: new Date(),
});

const [selectedTimeRange, setSelectedTimeRange] = useState(timeFrameOptions[0]);

const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.follow();

const getCardPopoverContent = () => {
  return (
      <div className="overview-card-more-popover">
      {/* <ListItem
          label="Donut" 
          icon="donut_large"
          clickable 
      />
      <ListItem
          label="Pie Chart" 
          icon="pie_chart"
          clickable 
      />
      <ListItem
          label="Bar Chart" 
          icon="bar_chart"
          clickable 
      />
      <ListItem
          label="Line Chart" 
          icon="show_chart"
          clickable 
      /> 
      <ListItem
          label="Funnel Chart" 
          icon="change_history"
          clickable 
      /> 
      <ListItem
          label="Map" 
          icon="public"
          clickable 
      /> 
      <ListItem
          label="List" 
          icon="list"
          clickable 
      />
      <ListItem
          label="Card" 
          icon="pin"
          clickable 
      />
      
      <hr /> */}
      {/* <ListItem
      onClick={props.onStartAdd}
          label="Add card" 
          icon="add"
          clickable 
      /> */}
      <ListItem
      onClick={props.onStartEdit}
          label="Enter edit mode" 
          icon="edit"
          clickable 
      />
      <ListItem
      onClick={props.onRemove}
          label="Remove" 
          icon="delete"
          clickable 
      />
      {/* <ListItem
          label="More setings" 
          icon="more"
          clickable 
      /> */}
      </div>
  );
};


const showMore = (e) => {
  tmoLib.ui.popover.open({
      opener: e.target.closest('.overview-card-wrapper-header-icon'),
      content: getCardPopoverContent(),
      enableBgOverlay: true,
  });
};


const getTimeRangeSelectorPopover = () => (
  <div className="card-time-range-selector">
      {timeFrameOptions.map((t) => (
          <div
              className={`card-time-range-selector-line ${
                  selectedTimeRange.value === t.value ? 'selected' : ''
              }`}
              onClick={() => onTimeRangeChanged(t)}
          >
              <div className="time-text">
                  <div className="time-label">{t.label}</div>
                  <div className="time-range">
                      {getTimeRangeDate(t.value, dateCyclesLastFour, selectedCustomDate)}
                  </div>
              </div>
 
          </div>
      ))}
 
  </div>
); 



const onTimeRangeChanged = (t) => {
  if (t.value !== TIME_FRAME.CUSTOM_RANGE) {
      // setSelectedTimeRange(t);
        props.onTimeRangeChanged(t);
  } else {
    props.onTimeRangeChanged({value:TIME_FRAME.CUSTOM_RANGE,  ...selectedCustomDate});
    //  toggleModal('customDate');
  }
};

const onMaxFieldsChanged = (e) => {
    let value = e.target.value;
    setMaxFields(value);
    props.onMaxFieldsChanged(value);
};

const openTimeRangePopover = (e) =>
  tmoLib.ui.popover.open({
      opener: e.target,
      content: getTimeRangeSelectorPopover(),
      enableBgOverlay: false,
      closeOnScroll:false
  });


const getCardFooterLink = (icon, text, onClick, hideDateRange) => {
  return (
      <div className="card-footer-link"> 
{!hideDateRange && ( <OverviewCardDatePicker timeframe={props.timeframe} onTimeRangeChanged={onTimeRangeChanged} /> )}
 
<div className="card-footer-settings">
    {props.showMaxFieldSelect && <select  className="card-footer-max-fields" value={maxFields} onChange={onMaxFieldsChanged}>
        <option value={6}>Show: 6 items</option>
        <option value={8}>Show: 8 items</option>
        <option value={10}>Show: 10 items</option>
        <option value={12}>Show: 12 items</option>
        <option value={14}>Show: 14 items</option>
        <option value={16}>Show: 16 items</option>
        <option value={20}>Show: 20 items</option>
        <option value={30}>Show: 30 items</option>
        <option value={50}>Show: 50 items</option>
    </select>}
</div>
          {/* {!hideDateRange && (
              <div className="card-footer-time-selector" onClick={openTimeRangePopover}>
                  <Icon name={icon} />
                  <div className="time-text">
                      <div className="time-label">{selectedTimeRange.label}</div>
                      <div className="time-range">
                          {getTimeRangeDate(
                              selectedTimeRange.value,
                              dateCyclesLastFour,
                              selectedCustomDate
                          )}
                      </div>
                  </div>
              </div>
          )} */}
         
         
      </div>
  );
};

const getEmptyCardContent = (title, desc, onClick) => {
  return (
      <div className="card-empty">
          <div className="card-empty-title">{title}</div>
          <div className="card-empty-desc">{desc}</div>
          <div className="card-empty-button">
              <Button
                  wrapperClassName="card-empty-get-started"
                  label="GET STARTED"
                  primary
                  onClick={onClick}
              />
          </div>
      </div>
  );
};

const getPlaceholder=() =>{
    return <div className='skeleton-card'>
        
    </div>
}


console.log('----- ### OverviewCard ENDERINF')

  return (<div 
    ref={rendering}
    className={'overview-card-wrapper ' + (props.className || '') + (!props.disableFooter?' has-footer ':'') + ( props.headerNoBorder? ' no-border ' :' ' ) + ( props.noPadding? ' no-padding ' :' ' ) + (!props.disableHeader?' has-header ':'')+ (!props.noBackground?'  ':' no-background ') + (props.realtime ? ' realtime ': ' ') + (props.editMode?' edit-mode ' :'')}  >
   {!canRender || props.loading ?getPlaceholder():''}


         <div className={'overview-card-wrapper-header ' +( props.disableHeader? ' overview-card-wrapper-header-title hidden ' :' ' )  }>
              {!props.disableHeader && <div className='overview-card-wrapper-header-title' data-tooltip={props.tooltip}> 
                {props.title || 'CARD TTITLE'} 
                {props.loading ? <div  className='overview-card-wrapper-header-loading' ><Icon name="sync"/></div> : ''}
              
              </div>} 
                {/* <IconButton name="more_vert" className='overview-card-wrapper-header-icon' onClick={(e) => showMore(e)}></IconButton> */}
                
                {props.editMode ?
                    <IconButton name="delete" className='overview-card-wrapper-header-icon' onClick={props.onRemove}></IconButton>
                    :
                    <IconButton name="settings" className='overview-card-wrapper-header-icon' onClick={props.onStartEdit}></IconButton>
                }
         </div>
        



         <div className='overview-card-wrapper-body'>
           
          {canRender  && props.allDone ?props.children:''}
         </div> 
        {!props.disableFooter && <div className='overview-card-wrapper-footer'>
        {getCardFooterLink('calendar_today', 'View more', ()=>{}, false)}
         </div> }  
         </div>

  );
}
 


 

export default OverviewCard;

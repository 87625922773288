import React, { useState } from 'react';

import './DataGrid.pc.scss';
import './DataGrid.mobile.scss';

function DataGridFooter({ store }) {
    let gridState = store.gridState.follow();

    //const [loadingPage, setLoadingPage] = useState(gridState.paging.currentPage);
    // const [loadingStartTime, setLoadingStartTime] = useState(9999999999999999999);
    const [loadingTimeout, setLoadingTimeout] = useState(null);

    let pageNumberCount = 3;

    if (gridState.paging.currentPage == gridState.paging.requestedPage && gridState.loading) {
        if (loadingTimeout) {
            clearTimeout(loadingTimeout);
        }
        // gridState.loading = false;
        // store.gridStateChanged(gridState, true);
    }

    let pagesToShow = [];
    let showFirst = false;
    let showBack = false;
    let showNext = false;
    let showLast = false;
    let nextText = '';
    let backText = '';

    let totalPages = Math.ceil(gridState.paging.totalRecords / gridState.paging.pageSize);
    if (gridState.paging.currentPage >= pageNumberCount) {
        showFirst = true;
    }
    if (gridState.paging.currentPage >= pageNumberCount + 3) {
        showBack = true;
    }
    if (gridState.paging.currentPage < totalPages) {
        showLast = true;
    }
    if (gridState.paging.currentPage < totalPages) {
        showNext = true;
    }

    let start = Math.floor(gridState.paging.currentPage / pageNumberCount) * pageNumberCount - 2; //loadingPage-5;
    let end =
        Math.floor(gridState.paging.currentPage / pageNumberCount) * pageNumberCount +
        pageNumberCount +
        2; //loadingPage+5;
    if (start < 0) {
        start = 0;
        end = pageNumberCount + 4;
    }
    if (end >= totalPages) {
        end = totalPages;
    }
    if (gridState.paging.currentPage === totalPages - 1) {
        start = totalPages - pageNumberCount - 3;
        showNext = false;
        showLast = false;
    }

    if (end >= totalPages - pageNumberCount) {
        showNext = false;
    }

    for (let i = start; i < end; i++) {
        pagesToShow.push(i + 1);
    }
    let nextEnd = end + pageNumberCount + 3;
    if (nextEnd > totalPages) {
        nextEnd = totalPages;
    }
    nextText = end + '..' + nextEnd;

    let backStart = start - pageNumberCount - 2;
    if (backStart < 0) {
        backStart = 1;
    }
    backText = backStart + '..' + (start + 1);

    if (totalPages < 10) {
        showFirst = false;
        showLast = false;
        showBack = false;
        showNext = false;
        pagesToShow = [];
        for (let i = 0; i < totalPages; i++) {
            pagesToShow.push(i + 1);
        }
    }

    if (totalPages === 1) {
        pagesToShow = [];
    }

    const timedout = () => {
        if (gridState.paging.currentPage !== gridState.paging.requestedPage && gridState.loading) {
            gridState.loading = true;
            //  setLoadingPage(gridState.paging.currentPage);
            gridState.paging.requestedPage = gridState.paging.currentPage;
            store.gridStateChanged(gridState);
        }
    };

    const goto = (page) => {
        // setLoadingPage(page);
        setLoadingTimeout(setTimeout(() => timedout, 10000));
        gridState.paging.requestedPage = page;
        gridState.loading = true;
        store.gridStateChanged(gridState);
    };

    const gotoNext = () => goto(end + Math.ceil(pageNumberCount / 2));

    const gotoBack = () => goto(backStart + Math.ceil(pageNumberCount / 2));

    const gotoFirst = () => goto(0);

    const gotoLast = () => goto(totalPages - 1);

    const pageSizeChanged = (e) => {
        let val = parseInt(e.target.value);
        let newLoadingPage = gridState.paging.currentPage;
        if (gridState.paging.currentPage * val >= gridState.paging.totalRecords) {
            newLoadingPage = Math.ceil(gridState.paging.totalRecords / val) - 1;
            // setLoadingPage(newLoadingPage);
            setLoadingTimeout(setTimeout(() => timedout, 10000));

            gridState.loading = true;
            gridState.paging.requestedPage = newLoadingPage;
        }
        // setLoadingPageSize(val);

        gridState.paging.pageSize = val;
        store.gridStateChanged(gridState);
    };

    let showingRecordsStart = gridState.paging.currentPage * gridState.paging.pageSize;
    let showingRecordsEnd =
        gridState.paging.currentPage * gridState.paging.pageSize + gridState.paging.pageSize;
    if (showingRecordsEnd > gridState.paging.totalRecords) {
        showingRecordsEnd = gridState.paging.totalRecords;
    }

    return (
        <div className={'datagrid-footer '}>
            {!gridState.features.paging && <div className={'datagrid-paging '}>
            <div className={'page-info'}>Total {gridState.paging.totalRecords} Records</div>
                </div>}
            {gridState.features.paging && <div className={'datagrid-paging '}>
                <div className={'page-info'}>
                    {showingRecordsStart} - {showingRecordsEnd} of {gridState.paging.totalRecords}{' '}
                    Records. Total {totalPages} pages
                </div>
                {gridState.paging.disablePageSize || gridState.paging.totalRecords < 10 ? (
                    <></>
                ) : (
                    <div className={'page-size'}>
                        <div className={'page-size-label'}>Rows per page:</div>
                        <select
                            className={'page-size-select'}
                            value={gridState.paging.pageSize}
                            onChange={pageSizeChanged}
                        >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                            <option value="75">75</option>
                            <option value="100">100</option>
                            {/* <option value="10000">10000</option>  */}
                            {/* <option value="200">200</option>
                            <option value="250">250</option> */}
                            {/* <option value="500">500</option>
                            <option value="1000">1000</option> */}
                        </select>
                    </div>
                )}
                <div className={'pages'}>
                    {showFirst ? (
                        <div className={'page-number first'} onClick={gotoFirst}>
                            First
                        </div>
                    ) : null}
                    {showBack ? (
                        <div className={'page-number back'} onClick={gotoBack}>
                            {backText}
                        </div>
                    ) : null}

                    {pagesToShow.map((p) => (
                        <div
                            key={p}
                            className={
                                'page-number' +
                                (gridState.paging.requestedPage === p - 1 ? ' loading ' : '') +
                                (gridState.paging.currentPage === p - 1 ? ' selected ' : '')
                            }
                            onClick={() => goto(p - 1)}
                        >
                            {p}
                        </div>
                    ))}

                    {showNext ? (
                        <div className={'page-number next'} onClick={gotoNext}>
                            {nextText}
                        </div>
                    ) : null}
                    {showLast ? (
                        <div className={'page-number last'} onClick={gotoLast}>
                            Last
                        </div>
                    ) : null}
                </div>
            </div> }
        </div>
    );
}

export default DataGridFooter;

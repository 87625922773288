import React from 'react';
import tmo from '../../../tmo/tmo.lib';
import { DataGridState, DataGridStore } from '../../../components/grid/DataGrid.Store';
import Sticker from '../../../components/basic/Sticker';

export const Navigations = {
    gotoView: (type, id) => {
        tmo.ui.global.openPage(`/${type}/${id}`);
    },
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading history Report: ' + data.name,
        task_input: { automation_id: data.id },
        related_module_id: 'history',
        related_record_id: data.id,
    });
};

const PROCESS_LOG_STATUS = {
    APP_HEALTH_CHECK_PASSED: 'app_health_check_passed',
    APP_HEALTH_CHECK_FAILED: 'app_health_check_failed',
    APP_WARNING: 'app_warning',
    PROCESS_RUNNING: 'process_running',
    PROCESS_DONE: 'process_done',
    PROCESS_WARNING: 'process_warning',
    PROCESS_FAILED: 'process_failed',
};

const getStatusData = (status) => {
    switch (status) {
        case PROCESS_LOG_STATUS.APP_HEALTH_CHECK_PASSED:
            return {
                text: 'App Health Check Passed',
                color: 'green',
            };
        case PROCESS_LOG_STATUS.APP_HEALTH_CHECK_FAILED:
            return {
                text: 'App Health Check Failed',
                color: 'red',
            };
        case PROCESS_LOG_STATUS.APP_WARNING:
            return {
                text: 'App Warning',
                color: 'yellow',
            };
        case PROCESS_LOG_STATUS.PROCESS_RUNNING:
            return {
                text: 'Running',
                color: 'blue',
            };
        case PROCESS_LOG_STATUS.PROCESS_DONE:
            return {
                text: 'Done',
                color: 'green',
            };
        case PROCESS_LOG_STATUS.PROCESS_WARNING:
            return {
                text: 'Warning',
                color: 'yellow',
            };
        case PROCESS_LOG_STATUS.PROCESS_FAILED:
            return {
                text: 'Failed',
                color: 'red',
            };
        default:
            return {
                text: 'Unknown',
                color: 'gray',
            };
    }
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Issue',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'text',
        width: 130,
        key: 'col_name',
        field: 'name',
        db_field: 'name',
        disableGrouping: true,
    },
    {
        title: 'Description',
        type: 'text',
        key: 'col_description',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        width: 200,
        visible: true,
        mVisible: true,
        field: 'description',
        db_field: 'description',
    },
    {
        title: 'Time',
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        type: 'datetime',
        width: 130,
        key: 'col_time',
        field: 'created_at',
        disableGrouping: true,
        db_field: 'created_at',
    },
    {
        title: 'Status',
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        type: 'text',
        width: 130,
        key: 'col_status',
        field: 'failed',
        disableGrouping: true,
        db_field: 'failed',
        template: ({ item }) => {
            const { text, color } = getStatusData(item.status);
            return <Sticker color={color}>{text}</Sticker>;
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'process_log';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

export const ProcessLoggerDataGridStore = new DataGridStore(defaultGridState, null, null);

import React, { useRef, useState } from 'react';

import tmoLib from '../../../tmo/tmo.lib';
import Icon from '../../../components/basic/Icon';
import IconButton from '../../../components/basic/IconButton';
import Button from '../../../components/basic/Button';
import Dropdown from '../../../components/basic/Dropdown';
import Input from '../../../components/basic/Input';
import EditPropPopup from './EditPropPopup';
import './Material.scss';

const onCopyClicked = async (str) => {
    try {
        await tmoLib.ui.global.copyToClipboard(str);
        tmoLib.ui.global.showNotification({
            type: 'info',
            title: 'Copied!',
            text: 'You successfully copied to the clipboard.',
            image: null,
            time: 2,
            action: {},
        });
    } catch (err) {
        tmoLib.ui.global.showNotification({
            type: 'error',
            title: 'Can not copy!',
            text: 'There is an error occured while copying.',
            image: null,
            time: 2,
            action: {},
        });
    }
};

const openMaterialDetailPopover = ({
    e,
    item,
    tags,
    onDelete,
    onShareChanged,
    onDownload,
    onClone,
    onRename,
    onEditTag,
    onPublicChanged,
}) => {
    tmoLib.ui.popover.open({
        opener: e.target.parentElement.parentElement,
        content: (
            <div className="material-item-details">
                <div className="material-item-detail-buttons">
                    <Button
                        label="Download"
                        wrapperClassName="material-item-button"
                        primary={true}
                        size="small"
                        onClick={() => onDownload(item)}
                    />
                    <Button
                        label="Delete"
                        wrapperClassName="material-item-button"
                        primary={false}
                        size="small"
                        onClick={() => onDelete(item)}
                    />
                    <Button
                        label="Clone"
                        wrapperClassName="material-item-button"
                        primary={true}
                        size="small"
                        onClick={() => onClone(item)}
                    />
                    <Button
                        label="Share"
                        wrapperClassName="material-item-button"
                        primary={true}
                        size="small"
                        onClick={() => onShareChanged(item, true)}
                    />
                    <Button
                        label="Make public"
                        wrapperClassName="material-item-button"
                        primary={true}
                        size="small"
                        onClick={() => onPublicChanged(item, true)}
                    />
                    <Button
                        label="Edit Category"
                        wrapperClassName="material-item-button"
                        primary={true}
                        size="small"
                        onClick={() => onEditTag(item)}
                    />
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">
                        Name
                        <div
                            className="material-item-detail-line-button"
                            onClick={() => onRename(item, true)}
                        >
                            (Rename):{' '}
                        </div>
                    </div>{' '}
                    {item.custom_name || item.original_name || item.name || item.url}
                </div>
                {item.extension_detail && (
                    <div className="material-item-detail-line">
                        <div className="material-item-detail-line-label">Extension:</div>{' '}
                        {item.extension} {item.extension_detail.name}
                    </div>
                )}
                {item.extension_detail && (
                    <div className="material-item-detail-line">
                        <div className="material-item-detail-line-label">Can be opened with:</div>{' '}
                        {item.extension_detail.application}
                    </div>
                )}
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Category:</div>
                    {(item.tag || '-').toUpperCase()}
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Type:</div> {item.type}
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Original resolution:</div>{' '}
                    {item.original_resolution}
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Available resolutions:</div>{' '}
                    {item.resolutions}
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Size on disk:</div>{' '}
                    {item.size_byte > 1000000
                        ? (item.size_byte / (1024 * 1024)).toFixed(2) + ' Mb'
                        : (item.size_byte / 1024).toFixed(2) + ' Kb'}
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Uploaded at:</div>{' '}
                    {tmoLib.string.dateToString(item.created_at || new Date(), 'YYYY-MM-DD hh:mm')}
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Last modified at:</div>{' '}
                    {tmoLib.string.dateToString(item.updated_at || new Date(), 'YYYY-MM-DD hh:mm')}
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Uploaded from:</div>
                    <Icon name={item.channel} logo /> {item.channel?.toLocaleUpperCase()}
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Upload reason:</div>{' '}
                    {item.upload_reason?.toLocaleUpperCase()}
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Available at:</div>
                    <div className="material-item-tags">
                        <div className="material-item-tag">
                            <Icon name={'shopnow'} logo={true} />
                            SHOPNOW
                        </div>
                        {item.available_third_parties &&
                            item.available_third_parties
                                .filter((p) => p)
                                .map((c, index) => (
                                    <div
                                        key={`material-item-tag-${index}`}
                                        className="material-item-tag"
                                    >
                                        <Icon name={c.name} logo={true} />
                                        {c.name.toUpperCase()}
                                    </div>
                                ))}
                    </div>
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">
                        Share url:
                        <div
                            className="material-item-detail-line-link"
                            onClick={() => onCopyClicked(item.share_url)}
                        >
                            Copy
                        </div>
                    </div>
                    <a href={item.share_url} target="_blank">
                        {item.share_url}
                    </a>
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Publicly available:</div>{' '}
                    {item.is_public ? 'Yes' : 'No'}
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Shared</div>{' '}
                    {item.is_public ? 'Yes' : 'No'}
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Cropped from another file</div>
                </div>
                <div className="material-item-detail-line">
                    <div className="material-item-detail-line-label">Cloned from another file</div>
                </div>
                <br />
            </div>
        ),
        enableBgOverlay: true,
        closeOnScroll: false,
    });
    e.preventDefault();
    e.nativeEvent.preventDefault();
    e.stopPropagation();
};

const Material = (props) => {
    const editTagPopupRef = useRef();
    const editNamePopupRef = useRef();
    const { item, selected, horizontal, tags } = props;
    const [selectedName, setSelectedName] = useState(
        item.custom_name || item.original_name || item.name
    );
    const [selectedTag, setSelectedTag] = useState(
        tags?.find((tag) => tag.label === item.tag) ?? null
    );
    const onEditTag = () => editTagPopupRef.current.show();

    const onTagChanged = () => props.onTagChanged(item, selectedTag);

    const onRename = () => editNamePopupRef.current.show();

    const onRenamed = () => props.onNameChanged(item, selectedName);

    const onMaterialClicked = (item) => {
        if (props.onClick) {
            props.onClick(item);
        }
    };

    const handleTagSelection = (value) => setSelectedTag(value.items);

    const getTagsPopover = (item) => {
        return (
            <div className="material-tags-popover">
                <div className="material-item-tags multiline">
                    <div className="material-item-tag">{item.type}</div>
                    <div className="material-item-tag">{item.original_resolution}</div>
                    {item.tag && <div className="material-item-tag">{item.tag.toUpperCase()}</div>}

                    {item.available_third_parties &&
                        item.available_third_parties
                            .filter((p) => p)
                            .map((c, index) => (
                                <div
                                    key={`material-item-tag-icon-${index}`}
                                    className="material-item-tag"
                                >
                                    <Icon name={c.name} logo />
                                    {c.name.toUpperCase()}
                                </div>
                            ))}
                    {(!item.available_third_parties || !item.available_third_parties.length) && (
                        <div className="material-item-tag">
                            <Icon name={'shopnow'} logo /> ShopNow
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div
            onClick={() => onMaterialClicked(item)}
            className={
                'material-item ' +
                item.type +
                ' ' +
                (selected ? ' selected ' : ' ') +
                (horizontal ? ' horizontal ' : '')
            }
        >
            <div className="material-item-image">
                <img src={item.thumb || item.url} />
                <Icon name="play_circle" className="play-icon" />
                <img className="file-icon" src={'/fileicons/' + item.extension + '.png'} />
                {!props.hideActions && (
                    <div className="material-item-action-button">
                        <IconButton
                            name="more_vert"
                            onClick={(e) =>
                                openMaterialDetailPopover({
                                    e,
                                    item,
                                    tags: props.tags,
                                    onDelete: props.onDelete,
                                    onShareChanged: props.onShareChanged,
                                    onPublicChanged: props.onPublicChanged,
                                    onDownload: props.onDownload,
                                    onClone: props.onClone,
                                    onEditTag: onEditTag,
                                    onRename: onRename,
                                })
                            }
                        />
                    </div>
                )}
            </div>
            <div className="material-item-info">
                <div className="material-item-name">
                    {!item.is_public && <Icon name="encrypted" />}
                    {item.custom_name || item.original_name || item.name || item.url}
                </div>
                <div
                    className="material-item-tags"
                    data-popover={tmoLib.ui.popover.registerPopoverFunction(() =>
                        getTagsPopover(item)
                    )}
                >
                    <div className="material-item-tag">{item.type}</div>
                    <div className="material-item-tag">{item.original_resolution}</div>
                    {item.tag && (
                        <div className="material-item-tag">{(item.tag || '').toUpperCase()}</div>
                    )}

                    {item.available_third_parties &&
                        item.available_third_parties
                            .filter((p) => p)
                            .map((c, index) => (
                                <div
                                    key={`material-item-tag-icon-${index}`}
                                    className="material-item-tag "
                                >
                                    <Icon name={c.name} logo /> {c.name.toUpperCase()}
                                </div>
                            ))}
                    {(!item.available_third_parties || !item.available_third_parties.length) && (
                        <div className="material-item-tag">
                            <Icon name={'shopnow'} logo /> ShopNow
                        </div>
                    )}
                </div>
                <div className="material-item-date">
                    {tmoLib.string.dateToString(item.created_at || new Date(), 'YYYY-MM-DD hh:mm')}
                </div>
                {/* <div className='material-id'>ID: {item.id}</div>
                      <div className='material-crop-from'>{item.cropped_from ? ('Cropped from: '+item.cropped_from):'-'}</div> */}
            </div>
            <EditPropPopup title="Edit name" ref={editNamePopupRef} onSave={onRenamed}>
                <Input type="text" value={selectedName} onChange={(val) => setSelectedName(val)} />
            </EditPropPopup>
            <EditPropPopup
                title="Edit File Category"
                ref={editTagPopupRef}
                onSave={onTagChanged}
                disableFocusTrap
            >
                <Dropdown
                    className="material-item-edit-tag-dropdown"
                    disablePopupScroll={false}
                    placeholder="Select Category"
                    useLabel={false}
                    valueField="id"
                    labelField="label"
                    options={props.tags?.filter((t) => t && t.label)}
                    value={selectedTag?.id}
                    onChange={handleTagSelection}
                    selectOptions={{ closeOnSelect: true, isMultiSelect: false }}
                    searchOptions={{ enable: false, searchOnServer: false }}
                    showSelectedItemsFullWidth
                    showOnlySelectedItemCount={false}
                />
            </EditPropPopup>
        </div>
    );
};

export default Material;

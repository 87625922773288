import React from 'react';

import './Basket.List.scss';

import { ListDataGridStore, Navigations } from './Basket.GridStore';
import { BasketApi } from './Basket.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import { Tab, Tabs } from '../../components/basic/Tab';
import tmoLib from '../../tmo/tmo.lib';
import SimpleTable from '../../ShopNow/Components/TopLevel/SimpleTable';
import { GlobalStore } from '../../GlobalStore';
import AppToolbarHelper from '../../components/basic/AppToolbarHelper';

function BasketList(props) {



    const getChildTables = async ({ data, key, columns }) => {
        // let user = await OrderApi.detail({ id:data.id });

      
        return <div className="order-detail"><Tabs>
            {/* <Tab title="ADRESSES" forceRenderOnTabChange>
                <div class="addresses">

                     <div className='address'>
                        <div className='address-title'>
                             Shipping Address
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>Country</label>
                            <div className='address-row-value'>{tmoLib.helpers.readOr(data.shipping_address?.country, 'label', 'N/A')}</div>
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>Province</label>
                            <div className='address-row-value'>{tmoLib.helpers.readOr(data.shipping_address?.province, 'label', 'N/A')}</div>
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>City</label>
                            <div className='address-row-value'>{tmoLib.helpers.readOr(data.shipping_address?.city, 'label', 'N/A')}</div>
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>Address</label>
                            <div className='address-row-value'>{data.shipping_address?.address}</div>
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>Receiver</label>
                            <div className='address-row-value'>{data.shipping_address?.receiver_name}</div>
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>Mobile</label>
                            <div className='address-row-value'>{data.shipping_address?.mobile}</div>
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>E-Mail</label>
                            <div className='address-row-value'>{data.shipping_address?.email}</div>
                        </div>
                    </div>
                    <div className='address'>
                        <div className='address-title'>
                             Billing Address
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>Country</label>
                            <div className='address-row-value'>{tmoLib.helpers.readOr(data.billing_address?.country, 'label', 'N/A')}</div>
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>Province</label>
                            <div className='address-row-value'>{tmoLib.helpers.readOr(data.billing_address?.province, 'label', 'N/A')}</div>
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>City</label>
                            <div className='address-row-value'>{tmoLib.helpers.readOr(data.billing_address?.city, 'label', 'N/A')}</div>
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>Address</label>
                            <div className='address-row-value'>{data.billing_address?.address}</div>
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>Receiver</label>
                            <div className='address-row-value'>{data.billing_address?.receiver_name}</div>
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>Mobile</label>
                            <div className='address-row-value'>{data.billing_address?.mobile}</div>
                        </div>
                        <div className='address-row'>
                            <label className='address-row-label'>E-Mail</label>
                            <div className='address-row-value'>{data.billing_address?.email}</div>
                        </div>
                    </div>
                </div>
            </Tab> */}
            <Tab title="PRODUCTS" forceRenderOnTabChange>
                <div className="items">
                    {getItemList({ data: data, key, columns })}
                </div>
            </Tab>
        </Tabs></div>

    }




 





    const getItemList = ({ data, key, columns }) => {

        if (!data.items?.length) {
            return <></>
        }
        let items = data.items;
       
        console.log('items', items);

        let ItemColumns = [

           
            {
                name: 'key',
                title: 'Key',
                template: ({ item, column }) =>  <span className="price">{item.item.key}</span>  
            },
            {
                name: 'name',
                title: 'Name',
                template: ({ item, column }) =>  <span className="price">{item.item.name}</span>  
            },

            {
                name: 'price',
                title: 'Price',
                template: ({ item, column }) =><span className="price-new">
                                                    {tmoLib.string.readPrice(item, 'price', '0.00', item.currency)} 
                                                </span>
            },
            {
                name: 'quantity',
                title: 'Quantity'
            },


            {
                name: 'total',
                title: 'Total',
                template: ({ item, column }) => {
                    return   <span className="price">
                                    {item.total_old && item.total_old!=='0' && item.total!==item.total_old && item.total_old  ? 
                                    <span className="price-old">
                                         {tmoLib.string.readPrice(item, 'total_old', null, item.currency)}
                                    </span>:null
                                    }
                                    <span className="price-new">
                                        {tmoLib.string.readPrice(item, 'total', '0.00', item.currency)}
                                    </span>
                                </span>
    

                }
            }

        ];

        return <SimpleTable data={items} columns={ItemColumns} />


    }



    let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let systemFilter = [
        ...AppToolbarHelper.getToolbarAppSelectionForEcommerceFilter(globalFilter, 'application_id'),
        // ...AppToolbarHelper.getToolbarTimeSelectionForEcommerceFilter(globalFilter, 'created_at'),
        ...AppToolbarHelper.getToolbarStoreSelectionForEcommerceFilter(globalFilter, 'store_id')
    ]


    return ( 

        <GridListPage
        tuneDownCondition={(item)=> !item.total}
        disableDelete={true}
            store={ListDataGridStore}
            systemFilter={systemFilter}
            onSearch={BasketApi.search}
            onHistory={BasketApi.history}
            onGotoEdit={Navigations.gotoEdit}
            renderRowChild={getChildTables}
            hasChildren={true}
        />
    );
}

export default BasketList;

import React from 'react';

import './Visitor.List.scss';

import { ListDataGridStore, Navigations } from './Visitor.GridStore';
import { VisitorApi } from './Visitor.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

import tmoLib from '../../tmo/tmo.lib';
import { Tab, Tabs } from '../../components/basic/Tab';
import SimpleTable from '../../ShopNow/Components/TopLevel/SimpleTable';
import AppToolbarHelper from '../../components/basic/AppToolbarHelper';
import { GlobalStore } from '../../GlobalStore';


export const SYSTEM_ACTION_TYPES = {
    START: { name: 'START', system_type: 1, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: false, is_card_action: false, icon: 'view' },
    PAUSE: { name: 'PAUSE', system_type: 2, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: false, is_card_action: false, icon: 'view' },
    RESUME: { name: 'RESUME', system_type: 3, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: false, is_card_action: false, icon: 'view' },
    END: { name: 'END', system_type: 4, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: false, is_card_action: false, icon: 'view' },
    VISIT: { name: 'VISIT', system_type: 5, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: false, is_card_action: false, icon: 'view' },
    GO_BACK: { name: 'GO_BACK', system_type: 6, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: false, is_card_action: false, icon: 'view' },
    IMPRESSION: { name: 'IMPRESSION', system_type: 7, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    VIEW: { name: 'VIEW', system_type: 8, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    PROMOTION_OFFERED: { name: 'PROMOTION_OFFERED', system_type: 9, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: false, is_card_action: false, icon: 'view' },
    PROMOTION_CLICKED: { name: 'PROMOTION_CLICKED', system_type: 10, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    PROMOTION_ACCEPTED: { name: 'PROMOTION_ACCEPTED', system_type: 11, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    PROMOTION_REJECTED: { name: 'PROMOTION_REJECTED', system_type: 12, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    PERSONALIZED_CONTENT_SHOWN: { name: 'PERSONALIZED_CONTENT_SHOWN', system_type: 13, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: false, is_card_action: false, icon: 'view' },
    PERSONALIZED_CONTENT_IMPRESSED: { name: 'PERSONALIZED_CONTENT_IMPRESSED', system_type: 14, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    PERSONALIZED_CONTENT_CLOSED: { name: 'PERSONALIZED_CONTENT_CLOSED', system_type: 15, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },

    LOGIN: { name: 'LOGIN', system_type: 16, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    SOCIAL_LOGIN: { name: 'SOCIAL_LOGIN', system_type: 17, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },

    LOGOUT: { name: 'LOGOUT', system_type: 18, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    REGISTER: { name: 'REGISTER', system_type: 19, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    FORGOT_PASSWORD: { name: 'FORGOT_PASSWORD', system_type: 20, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    UPDATE_USER: { name: 'UPDATE_USER', system_type: 21, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    SUSPEND_USER: { name: 'SUSPEND_USER', system_type: 22, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    RESUME_USER: { name: 'RESUME_USER', system_type: 23, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },

    ADD_TO_BASKET: { name: 'ADD_TO_BASKET', system_type: 24, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    REMOVE_FROM_BASKET: { name: 'REMOVE_FROM_BASKET', system_type: 25, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    UPDATE_BASKET: { name: 'UPDATE_BASKET', system_type: 26, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    CHECKOUT: { name: 'CHECKOUT', system_type: 27, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    PAY: { name: 'PAY', system_type: 28, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    ORDER_PAID: { name: 'ORDER_PAID', system_type: 29, is_membership_action: false, is_conversion_action: true, is_purchase_action: true, is_interaction: true, is_card_action: false, icon: 'view' },
    CANCEL_ORDER: { name: 'CANCEL_ORDER', system_type: 30, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    REFUND_ORDER: { name: 'REFUND_ORDER', system_type: 31, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    CONVERSION: { name: 'CONVERSION', system_type: 32, is_membership_action: false, is_conversion_action: true, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    SHARE: { name: 'SHARE', system_type: 33, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    FAVOURITE: { name: 'FAVOURITE', system_type: 34, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    LIKE: { name: 'LIKE', system_type: 35, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    INVITE: { name: 'INVITE', system_type: 36, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    COMMENT: { name: 'COMMENT', system_type: 37, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    ADD_TO_WISHLIST: { name: 'ADD_TO_WISHLIST', system_type: 38, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    REMOVE_FROM_WISHLIST: { name: 'REMOVE_FROM_WISHLIST', system_type: 39, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },

    SEARCH: { name: 'SEARCH', system_type: 40, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    FILTER_SEARCH: { name: 'FILTER_SEARCH', system_type: 41, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    COMPARE: { name: 'COMPARE', system_type: 42, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    CUSTOMER_SERVICE: { name: 'CUSTOMER_SERVICE', system_type: 43, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    SENT_CUSTOMER_SERVICE_MESSAGE: { name: 'SENT_CUSTOMER_SERVICE_MESSAGE', system_type: 44, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    CONTACT_FORM: { name: 'CONTACT_FORM', system_type: 45, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    SUBSCRIBE_FORM: { name: 'SUBSCRIBE_FORM', system_type: 46, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    READ_MORE: { name: 'READ_MORE', system_type: 47, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },

    CLEAR_WISHLIST: { name: 'CLEAR_WISHLIST', system_type: 48, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    CLEAR_BASKET: { name: 'CLEAR_BASKET', system_type: 49, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    REQUEST_REFUND: { name: 'REQUEST_REFUND', system_type: 50, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },


    SOCIAL_LOGIN_CLICKED: { name: 'SOCIAL_LOGIN_CLICKED', system_type: 52, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },
    SOCIAL_LOGIN_DONE: { name: 'SOCIAL_LOGIN_DONE', system_type: 53, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: false, icon: 'view' },



    CARD_QR_CODE_SHOWN: { name: 'CARD_QR_CODE_SHOWN', system_type: 55, is_membership_action: false, is_conversion_action: false, is_purchase_action: false, is_interaction: false, is_card_action: true, icon: 'view' },
    CARD_QR_CODE_SCANNED: { name: 'CARD_QR_CODE_SCANNED', system_type: 56, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: true, icon: 'view' },
    CARD_CLAIMED: { name: 'CARD_CLAIMED', system_type: 57, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: true, icon: 'view' },
    CARD_ACTIVATED: { name: 'CARD_ACTIVATED', system_type: 58, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: true, icon: 'view' },
    CARD_REDEEMED: { name: 'CARD_REDEEMED', system_type: 59, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: true, icon: 'view' },
    CARD_CANCELLED: { name: 'CARD_CANCELLED', system_type: 60, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: true, icon: 'view' },
    CARD_INPUT_VERIFY_CLICKED: { name: 'CARD_INPUT_VERIFY_CLICKED', system_type: 61, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: true, icon: 'view' },
    CARD_INPUT_VERIFY_FAILED: { name: 'CARD_INPUT_VERIFY_FAILED', system_type: 62, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: true, icon: 'view' },
    CARD_INPUT_VERIFY_SUCCESSFUL: { name: 'CARD_INPUT_VERIFY_SUCCESSFUL', system_type: 63, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: true, icon: 'view' },
    CARD_CLAIM_MINI_PROGRAM_CLICKED: { name: 'CARD_CLAIM_MINI_PROGRAM_CLICKED', system_type: 64, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: true, icon: 'view' },
    CARD_REDEEM_MINI_PROGRAM_CLICKED: { name: 'CARD_REDEEM_MINI_PROGRAM_CLICKED', system_type: 65, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: true, icon: 'view' },
    CARD_CLAIM_LINK_CLICKED: { name: 'CARD_CLAIM_LINK_CLICKED', system_type: 66, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: true, icon: 'view' },
    CARD_REDEEM_LINK_CLICKED: { name: 'CARD_REDEEM_LINK_CLICKED', system_type: 67, is_membership_action: true, is_conversion_action: false, is_purchase_action: false, is_interaction: true, is_card_action: true, icon: 'view' },

}



function VisitorList(props) {

    const getChildTables = async ({ data, key, columns }) => {
        let visitor = await VisitorApi.detail({ id: data.id });

        return <div className="visitor-actions"><Tabs>
            <Tab title="SESSIONS">
                {getActionList({ data: visitor, key, columns })}
            </Tab>
            <Tab title="ACTIONS">
                {getActionList({ data: visitor, key, columns })}
            </Tab>
            <Tab title="ITEMS">
                {getItemList({ data: visitor, key, columns })}
            </Tab>
        </Tabs></div>

    }
    const getActionList = ({ data, key, columns }) => {

        console.log(data.actions);

        if (!data.actions?.length) {
            return <></>
        }
        let actions = [];
        for (const action of data.actions) {



            if (
                actions[actions.length - 1]?.system_type === SYSTEM_ACTION_TYPES.START.system_type &&
                action.system_type === SYSTEM_ACTION_TYPES.VISIT.system_type
            ) {
                actions[actions.length - 1] = action;
                actions[actions.length - 1].type = actions[actions.length - 1].type || {};
                actions[actions.length - 1].type.name = 'START/VISIT';
            }
            else if (
                actions[actions.length - 1]?.system_type === SYSTEM_ACTION_TYPES.VISIT.system_type &&
                action.system_type === SYSTEM_ACTION_TYPES.RESUME.system_type
            ) {
            }
            else if (
                actions[actions.length - 1]?.system_type === SYSTEM_ACTION_TYPES.PAUSE.system_type &&
                action.system_type === SYSTEM_ACTION_TYPES.END.system_type
            ) {
                actions[actions.length - 1] = action;
            }
            else if (
                actions[actions.length - 1]?.system_type === action.system_type &&
                actions[actions.length - 1]?.system_type === action.system_type &&
                JSON.stringify({ ...actions[actions.length - 1], id: null, created_at: null }) === JSON.stringify({ ...action, id: null, created_at: null })
            ) {
                //actions[actions.length-1] = action;
            }
            else {
                actions.push(action);
            }

        }


        actions.sort((a, b) => a.created_at > b.created_at ? -1 : 1);

        let ActionColumns = [
            {
                name: 'type',
                title: 'Type',
                template: ({ item, column }) => {
                    return item?.type?.name
                }
            },
            {
                name: 'group_name',
                title: 'Group Name',
                template: ({ item, column }) => {
                    return <>
                        {item?.type?.is_membership_action && <div>Membership Action</div>}
                        {item?.type?.is_conversion_action && <div>Conversion</div>}
                        {item?.type?.is_purchase_action && <div>Purchase Action</div>}
                        {item?.type?.system_type ? (
                            <div>System Defined Action</div>
                        ) : (
                            <div>User Defined Action</div>
                        )}
                    </>
                }
            },
            {
                name: 'group',
                title: 'Group'
            },
            {
                name: 'interest',
                title: 'Interest',
                template: ({ item, column }) => {
                    return item?.interest?.label
                }
            },
            {
                name: 'outcome',
                title: 'Outcome',
                template: ({ item, column }) => {
                    return item?.outcome?.label
                }
            },
            {
                name: 'cost',
                title: 'Cost'
            },
            {
                name: 'income',
                title: 'Income'
            },
            {
                name: 'tag1',
                title: 'Tag',
                template: ({ item, column }) => {
                    return item?.tag1?.label
                }
            },
            {
                name: 'created_at',
                title: 'Created At',
                template: ({ item, column }) => {
                    return tmoLib.string.printDateTime(item.created_at)
                }
            },
            {
                name: 'items',
                title: 'Items',
                template: ({ item, column }) => {
                    return (item?.items || []).map(i => {
                        if (i.url?.length) {
                            return <div className='action-item'><a href={i.url} target='_blank'>{tmoLib.helpers.readOr(i, 'name', 'N/A')}</a></div>
                        }
                        else {
                            return <div className='action-item'>{tmoLib.helpers.readOr(i, 'name', 'N/A')}</div>
                        }
                    })
                }
            },


        ];

        return <SimpleTable data={actions} columns={ActionColumns} />
 


    }

    const getItemList = ({ data, key, columns }) => {

        if (!data.actions?.length) {
            return <></>
        }
        let items = [];
        data.actions.forEach(a => {
            if (a.items?.length) {
                items.push(...a.items)
            }
        })

        return <table className="simple-table" cellPadding={0} cellSpacing={0}>
            <thead className="table-headers">
                <tr className="table-headers">
                    <td className="table-header ">Id</td>
                    <td className="table-header ">Type</td>
                    <td className="table-header ">Name</td>
                    <td className="table-header ">Key</td>
                    <td className="table-header ">Url</td>
                    <td className="table-header ">Description</td>
                    <td className="table-header ">Value</td>
                </tr>
            </thead>
            <tbody className="table-rows">
                {items.map(i => <tr className="table-row">


                    <td className="table-cell ">{i.id}</td>
                    <td className="table-cell ">{i.type}</td>
                    <td className="table-cell ">{i.name}</td>
                    <td className="table-cell ">{i.key}</td>
                    <td className="table-cell ">{<a href={i.url} target='_blank'>{i.url}</a>}</td>
                    <td className="table-cell ">{i.description}</td>
                    <td className="table-cell ">{i.value || 0}</td>
                </tr>

                )}

            </tbody>
        </table>



    }

 

    let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let systemFilter = [
        ...AppToolbarHelper.getToolbarAppSelectionForAnalyticsFilter(globalFilter, 'application_id'),
        ...AppToolbarHelper.getToolbarTimeSelectionForAnalyticsFilter(globalFilter, 'last_pinged_at')
    ]



    return (
        <GridListPage
            store={ListDataGridStore}
            onSearch={VisitorApi.search}
            onHistory={VisitorApi.history}
            onGotoEdit={Navigations.gotoEdit}
            renderRowChild={getChildTables}
            hasChildren={true}
            systemFilter={systemFilter}
        />
    );
}

export default VisitorList;

import React, { useEffect, useState } from 'react';

import Dropdown from '../../components/basic/Dropdown';
import { Globals } from '../../Globals';
import './CSRSelect.scss';

const CSRSelect = ({ placeholder, className, useLabel, isMultiSelect, onChange, value, items }) => {
    const userSelected = (items) => {
        if (onChange) {
            onChange(items);
        }
    };

    const loadData = async () =>
        Globals.callApi({
            url: 'chat/csr/list',
            params: {},
        });

    const [data, setData] = useState([]);

    useEffect(() => {
        loadData().then((d) => {
            setData(d);
        });
    }, []);

    if (!data || data.length === 0) {
        return <></>;
    }

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            valueField="id"
            labelField="name"
            options={data}
            value={value}
            items={items}
            selectedItemShape="square"
            onChange={({ items }) => userSelected(items)}
            selectOptions={{ closeOnSelect: !isMultiSelect, isMultiSelect: isMultiSelect }}
            searchOptions={{ enable: true, searchOnServer: false }}
            customTemplate={({ label, data }) => (
                <div className={'user-avatar '}>
                    <img src={data.member_avatar} />
                    <div className="info">
                        <div className="name">{label}</div>
                        <div className="city">
                            {data.group_name + ' : ' + data.default_session_role}
                        </div>
                    </div>
                </div>
            )}
            showSelectedItemsFullWidth={false}
            showOnlySelectedItemCount={false}
        />
    );
};

export default CSRSelect;

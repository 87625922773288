import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import IconButton from '../../components/basic/IconButton';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import Sticker from '../../components/basic/Sticker';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import { WECHAT_MEMBER_CARD_HEALTH_STATUS, WECHAT_MEMBER_CARD_PUBLISH_STATUS, WECHAT_MEMBER_CARD_STATUS } from './WechatMemberCard.Api';
import t from '../Localization/i18n';
import Icon from '../../components/basic/Icon';

export const Navigations = {
    goToUsage: (id) => tmo.ui.global.openPage('/wechat/membercard/usage/' + id),
    gotoEdit: (id) => tmo.ui.global.openPage('/wechat/membercard/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/wechat/membercards'),
    gotoView: (id) => tmo.ui.global.openPage('/wechat/membercard/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading WechatMemberCard Report: ' + data.name,
        task_input: { wechat_coupon_id: data.id },
        related_module_id: 'wechat_membercard',
        related_record_id: data.id,
    });
};

const qrCodePopoverFunc = (item) => (
    <div className="coupon-qr-popoover">
        {item.qr_code && <img src={item.qr_code.qrcode_url} />}
    </div>
);


const couponTypeProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const couponCodeTypeProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const colorDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const activeDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const statusOptions = [
    { label: 'Processing', value: WECHAT_MEMBER_CARD_STATUS.PROCESSING },
    { label: 'Failed', value: WECHAT_MEMBER_CARD_STATUS.FAILED },
    { label: 'Failed (Sync)', value: WECHAT_MEMBER_CARD_STATUS.FAILED_TO_SYNCRONIZE },
    { label: 'Rendering 1/4', value: WECHAT_MEMBER_CARD_STATUS.CONTENT_RENDERED },
    { label: 'Failed (Content)', value: WECHAT_MEMBER_CARD_STATUS.CONTENT_RENDER_FAILED },
    { label: 'Rendering 2/4', value: WECHAT_MEMBER_CARD_STATUS.READMORE_PREPARED },
    { label: 'Failed (Read More)', value: WECHAT_MEMBER_CARD_STATUS.READMORE_FAILED },
    { label: 'Syncing 3/4', value: WECHAT_MEMBER_CARD_STATUS.READY_TO_SEND },
    { label: 'Ready', value: WECHAT_MEMBER_CARD_STATUS.SYNCRONIZED },
    { label: 'New', value: WECHAT_MEMBER_CARD_STATUS.NEW },
];

const publishOptions = [
    { label: 'Processing', value: WECHAT_MEMBER_CARD_PUBLISH_STATUS.PROCESSING },
    { label: 'Not published', value: WECHAT_MEMBER_CARD_PUBLISH_STATUS.NOT_PUBLISHED },
    { label: 'Published', value: WECHAT_MEMBER_CARD_PUBLISH_STATUS.PUBLISHED },
    {
        label: 'Published (multiple)',
        value: WECHAT_MEMBER_CARD_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES,
    },
];


const statusDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: statusOptions,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};




export const getArticleStatusSticker = (item, size) => {
    size = size || 'small';

    if (item.is_new) {
        return (
            <Sticker color="lightsalmon" size={size}>
                NEW
            </Sticker>
        );
    }

    if (item.status === WECHAT_MEMBER_CARD_STATUS.CONTENT_RENDERED) {
        return (
            <Sticker color="yellow" size={size}>
                Rendering 1/4
            </Sticker>
        );
    } else if (item.status === WECHAT_MEMBER_CARD_STATUS.CONTENT_RENDER_FAILED) {
        return (
            <Sticker color="red" size={size}>
                Failed (Content)
            </Sticker>
        );
    } else if (item.status === WECHAT_MEMBER_CARD_STATUS.FAILED) {
        return (
            <Sticker color="red" size={size}>
                Failed
            </Sticker>
        );
    } else if (item.status === WECHAT_MEMBER_CARD_STATUS.FAILED_TO_SYNCRONIZE) {
        return (
            <Sticker color="red" size={size}>
                Failed (Sync)
            </Sticker>
        );
    } else if (item.status === WECHAT_MEMBER_CARD_STATUS.PROCESSING) {
        return (
            <Sticker color="gray" size={size}>
                Processing
            </Sticker>
        );
    } else if (item.status === WECHAT_MEMBER_CARD_STATUS.READMORE_FAILED) {
        return (
            <Sticker color="red" size={size}>
                Failed (Read More)
            </Sticker>
        );
    } else if (item.status === WECHAT_MEMBER_CARD_STATUS.READMORE_PREPARED) {
        return (
            <Sticker color="yellow" size={size}>
                Rendering 2/4
            </Sticker>
        );
    } else if (item.status === WECHAT_MEMBER_CARD_STATUS.READY_TO_SEND) {
        return (
            <Sticker color="yellow" size={size}>
                Syncing 3/4
            </Sticker>
        );
    } else if (item.status === WECHAT_MEMBER_CARD_STATUS.SYNCRONIZED) {
        return (
            <Sticker color="blue" size={size}>
                Ready
            </Sticker>
        );
    } else {
        return (
            <Sticker color="gray" size={size}>
                Processing
            </Sticker>
        );
    }
};

 
const publishStatusDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: publishOptions,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};


export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Application',
        index: 1,
        visible: true,
        mVisible: true,
        noSpan: true,
        type: 'dropdown',
        key: 'col_application_id',
        width: 220,
        field: 'application_id',
        db_field: 'application_id',
        dropdownProps: appDropdownProps,
        template: ({ item }) => <ApplicationTag application={item.application_id} />,
    },
    {
        title: 'Title',
        type: 'parent',
        width: 190,
        key: 'col_title',
        disableGrouping: true,
        index: 2,
        visible: true,
        mVisible: true,
        multiline: true,
        field: 'title',
        db_field: 'title',
        children: [
            {
                title: 'Title',
                type: 'text',
                width: 150,
                key: 'col_title',
                disableGrouping: true,
                index: 2,
                visible: true,
                mVisible: true,
                field: 'title',
                db_field: 'title',
            },
            {
                title: 'Description',
                type: 'text',
                width: 200,
                key: 'col_description',
                disableGrouping: true,
                multiline: true,
                index: 5,
                visible: true,
                mVisible: true,
                field: 'description',
                db_field: 'description',
            },
        ],
        template: ({ item }) => {
            return (
                <span>
                    <b>{item.title}</b>
                    <br />
                    {item.description}
                </span>
            );
        },
    },
    {
        title: 'Brand Name',
        type: 'text',
        width: 150,
        key: 'col_brand_name',
        index: 3,
        visible: true,
        mVisible: true,
        field: 'brand_name',
        db_field: 'brand_name',
    },
    {
        title: 'Image',
        type: 'parent',
        width: 190,
        key: 'col_image',
        disableGrouping: true,
        disableSort: true,
        disableFilter: true,
        index: 4,
        visible: true,
        mVisible: true,
        field: 'image',
        db_field: 'image',
        children: [
            {
                title: 'Background',
                type: 'image',
                key: 'col_2_1',
                field: 'bg_image',
                db_field: 'bg_image',
            },
            { title: t('Logo'), type: 'image', key: 'col_1_2', field: 'logo', db_field: 'logo' },
        ],
        template: ({ item }) => {
            return (
                <div className="member-card-image-preview">
                    <img src={item.bg_image} className="bg" alt="background" />
                    <img src={item.logo} className="logo" alt="logo" />
                    <div className="brand">{item.brand_name}</div>
                    <div className="title">{item.title}</div>
                    <div className="card-no">1111 2222 3333</div>
                </div>
            );
        },
    },
    {
        title: 'Valid For',
        type: 'parent',
        width: 200,
        key: 'col_valid_until_date',
        disableGrouping: true,
        index: 5,
        visible: true,
        mVisible: true,
        field: 'valid_until_date',
        db_field: 'valid_until_date',
        children: [
            {
                title: 'Valid For',
                type: 'number',
                key: 'col_8_1',
                field: 'valid_for_days',
                db_field: 'valid_for_days',
            },
            {
                title: 'Valid From',
                type: 'datetime',
                key: 'col_8_2',
                field: 'valid_from_date',
                db_field: 'valid_from_date',
            },
            {
                title: 'Valid Until',
                type: 'datetime',
                key: 'col_8_3',
                field: 'valid_until_date',
                db_field: 'valid_until_date',
            },
        ],
        template: ({ item }) => (
            <div className="member-card-validity">
                {item.valid_for_days ? ' for ' + item.valid_for_days + ' days ' : ''}
                {item.valid_for_days ? (
                    <>
                        <br />
                        {' start after ' + item.valid_for_days + ' days.'}
                    </>
                ) : (
                    ''
                )}
                {item.valid_from_date ? (
                    <>
                        <br />
                        {' from ' +
                            tmo.string.dateToString(item.valid_from_date, 'YYYY-MM-DD hh:mm')}
                    </>
                ) : (
                    ''
                )}
                <br />
                {item.valid_until_date
                    ? ' to ' + tmo.string.dateToString(item.valid_until_date, 'YYYY-MM-DD hh:mm')
                    : ''}
            </div>
        ),
    },
    {
        title: 'Publish on web',
        type: 'bool',
        width: 100,
        key: 'col_web_app',
        index: 6,
        visible: true,
        mVisible: true,
        field: 'publish_on_web',
        db_field: 'publish_on_web',
        dropdownProps: activeDropdownProps,
        template: ({ item }) =>
            !!item.publish_on_web ? (
                <Sticker color="green">Yes</Sticker>
            ) : (
                <Sticker color="red">No</Sticker>
            ),
    },



    {
        title: 'Status',
        type: 'parent',
        width: 120,
        key: 'col_status',
        disableGrouping: true,
        disableFilter: false,
        disableSort: true,
        index: 7, 
        visible: true,
        mVisible: true,
        field: 'status',
        db_field: 'status',
        dropdownProps: statusDropdownProps,
        template: ({ item }) => {
            let statusEl = getArticleStatusSticker(item, 'small');
            let publishStatusEl;
            let healthStatusEl;

            // if (item.publish_status === WECHAT_MEMBER_CARD_PUBLISH_STATUS.NOT_PUBLISHED) {
            //     publishStatusEl = (
            //         <Sticker color="yellow" size="small">
            //             Not Published
            //         </Sticker>
            //     );
            // } else if (item.publish_status === WECHAT_MEMBER_CARD_PUBLISH_STATUS.PROCESSING) {
            //     publishStatusEl = (
            //         <Sticker color="gray" size="small">
            //             Processing
            //         </Sticker>
            //     );
            // } else
            
            if (item.publish_status === WECHAT_MEMBER_CARD_PUBLISH_STATUS.PUBLISHED) {
                publishStatusEl = (
                    <Sticker color="blue" size="small">
                        Published
                    </Sticker>
                );
            } else if (
                item.publish_status === WECHAT_MEMBER_CARD_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES
            ) {
                publishStatusEl = (
                    <Sticker color="blue" size="small">
                        Published (MULT)
                    </Sticker>
                );
            } 
            
            // else {
            //     publishStatusEl = (
            //         <Sticker color="yellow" size="small">
            //             Not Published
            //         </Sticker>
            //     );
            // }

            if (item.health === WECHAT_MEMBER_CARD_HEALTH_STATUS.HAS_ISSUES) {
                healthStatusEl = <Icon name="warning" />;
            }

            return (
                <span className="statuses">
                    {healthStatusEl}
                    <br />
                    {statusEl}
                    <br />
                    {publishStatusEl}
                    <br />
                </span>
            );
        },
        templateChildren: () => <></>,
        children: [
            {
                title: 'Status',
                type: 'dropdown',
                width: 100,
                key: 'col_11_1',
                disableGrouping: true,
                disableFilter: false,
                index: 1,
                mIndex: 1,
                visible: true,
                mVisible: true,
                field: 'status',
                db_field: 'status',
                dropdownProps: statusDropdownProps,
            },
            {
                title: 'Publish status',
                type: 'dropdown',
                width: 100,
                key: 'col_11_2',
                disableGrouping: true,
                disableFilter: false,
                index: 2,
                mIndex: 2,
                visible: true,
                mVisible: true,
                field: 'publish_status',
                db_field: 'publish_status',
                dropdownProps: publishStatusDropdownProps,
            },
            {
                title: 'Health',
                type: 'dropdown',
                width: 100,
                key: 'col_11_3',
                disableGrouping: true,
                disableFilter: true,
                index: 1,
                mIndex: 1,
                visible: true,
                mVisible: true,
                field: 'health',
                db_field: 'health',
            },
        ],
    },


    // {
    //     title: 'Status',
    //     type: 'dropdown',
    //     width: 100,
    //     key: 'col_status',
    //     index: 7,
    //     visible: true,
    //     mVisible: true,
    //     field: 'status',
    //     db_field: 'status',
    //     dropdownProps: statusDropdownProps,
    //     template: ({ item }) => {
    //         switch (item.status) {
    //             case WECHAT_MEMBER_CARD_STATUS.ACTIVE:
    //                 return (
    //                     <Sticker color="blue" size="small">
    //                         {item.status}
    //                     </Sticker>
    //                 );

    //             case WECHAT_MEMBER_CARD_STATUS.INACTIVE:
    //                 return (
    //                     <Sticker color="red" size="small">
    //                         {item.status}
    //                     </Sticker>
    //                 );

    //             default:
    //                 return null;
    //         }
    //     },
    // },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_action',
        width: 150,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>

 
                        
                    {(  item.publish_status === WECHAT_MEMBER_CARD_PUBLISH_STATUS.PUBLISHED ||
                        item.publish_status ===  WECHAT_MEMBER_CARD_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES) && (
                        <>
                        <IconButton
                            className="grid-open-button"
                            tooltip={'Member card owners and transactions'}
                            name="groups"
                            onClick={() => Navigations.goToUsage(item.id)}
                        />
                        <IconButton
                            className="grid-open-button"
                            name="open_in_new"
                            tooltip={'Go to member card report'}
                            onClick={() => Navigations.gotoView(item.id)}
                        />
                        </>
                        
                    )}
                    
                        
               
                <IconButton
                    className="grid-open-button"
                    tooltip={'Member card public QR Code (Not tracable)'}
                    name="qr_code"
                    popover={tmo.ui.popover.registerPopoverFunction(() => qrCodePopoverFunc(item))}
                />
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    tooltip={'Edit Member Card'}
                    onClick={() => Navigations.gotoEdit(item.id)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'wechat_membercards_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow WechatMemberCard',
        simpleLabel: 'ShopNow WechatMemberCard',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat WechatMemberCard',
        simpleLabel: 'Wechat WechatMemberCard',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo WechatMemberCard',
        simpleLabel: 'Weibo WechatMemberCard',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok WechatMemberCard',
        simpleLabel: 'TikTok WechatMemberCard',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn WechatMemberCard',
        simpleLabel: 'LinkedIn WechatMemberCard',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ WechatMemberCard',
        simpleLabel: 'QQ WechatMemberCard',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App WechatMemberCard',
        simpleLabel: 'App WechatMemberCard',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null, t);

ListDataGridStore.beforeLoad = () => {
    appDropdownProps.options = Globals.currentUser.applications.filter((item) =>
        item.type.includes('wx')
    ) || {
        id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9326',
        name: 'Wechat Official Account',
    };
    colorDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'wechat_couponcolor'
    )[0].items;
    couponTypeProps.options = Globals.constants.filter(
        (c) => c.type === 'wechat_coupontype'
    )[0].items;
    couponCodeTypeProps.options = Globals.constants.filter(
        (c) => c.type === 'wechat_couponcodetype'
    )[0].items;
};

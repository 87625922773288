import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import IconButton from '../../components/basic/IconButton';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import Sticker from '../../components/basic/Sticker';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import { WECHAT_GIFT_CARD_STATUS } from './WechatGiftCard.Api';

export const Navigations = {
    goToUsage: (id) => tmo.ui.global.openPage('/wechat/giftcard/usage/' + id),
    gotoEdit: (id) => tmo.ui.global.openPage('/wechat/giftcard/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/wechat/giftcards'),
    gotoView: (id) => tmo.ui.global.openPage('/wechat/giftcard/' + id),
};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading WechatGiftCard Report: ' + data.name,
        task_input: { wechat_coupon_id: data.id },
        related_module_id: 'wechat_membercard',
        related_record_id: data.id,
    });

const qrCodePopoverFunc = (item) => (
    <div className="coupon-qr-popoover">
        {item.qr_code && <img src={item.qr_code.qrcode_url} />}
    </div>
);

const couponTypeProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const couponCodeTypeProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const colorDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Title',
        type: 'text',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'title',
        db_field: 'title',
    },
    {
        title: 'Image',
        type: 'parent',
        width: 190,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'image',
        db_field: 'image',
        children: [
            {
                title: 'Background',
                type: 'image',
                key: 'col_2_1',
                field: 'bg_image',
                db_field: 'bg_image',
            },
            { title: 'Logo', type: 'image', key: 'col_1_2', field: 'logo', db_field: 'logo' },
        ],
        template: ({ item }) => (
            <div className="member-card-image-preview">
                <img src={item.bg_image} className="bg" alt="background" />
                <img src={item.logo} className="logo" alt="logo" />
                <div className="brand">{item.brand_name}</div>
                <div className="title">{item.title}</div>
                <div className="card-no">1111 2222 3333</div>
            </div>
        ),
    },
    {
        title: 'Description',
        type: 'text',
        width: 200,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        multiline: true,
        field: 'description',
        db_field: 'description',
    },
    {
        title: 'Status',
        type: 'text',
        width: 150,
        key: 'col_4',
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'status',
        db_field: 'status',
        template: ({ item }) => {
            switch (item.status) {
                case WECHAT_GIFT_CARD_STATUS.ACTIVE:
                    return (
                        <Sticker color="blue" size="small">
                            {item.status}
                        </Sticker>
                    );

                case WECHAT_GIFT_CARD_STATUS.INACTIVE:
                    return (
                        <Sticker color="red" size="small">
                            {item.status}
                        </Sticker>
                    );

                default:
                    return null;
            }
        },
    },
    {
        title: 'Active',
        type: 'text',
        width: 150,
        key: 'col_5',
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'active',
        db_field: 'active',
        template: ({ item }) =>
            item.coupon_data.active ? (
                <Sticker color="green">Enabled</Sticker>
            ) : (
                <Sticker color="red">Disabled</Sticker>
            ),
    },
    {
        title: 'Application',
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        key: 'col_6',
        width: 220,
        field: 'application_id',
        db_field: 'application_id',
        dropdownProps: appDropdownProps,
        template: ({ item }) => <ApplicationTag application={item.application_id} />,
    },
    {
        title: 'Brand Name',
        type: 'text',
        width: 150,
        key: 'col_7',
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'brand_name',
        db_field: 'brand_name',
    },
    {
        title: 'Valid For',
        type: 'parent',
        width: 200,
        key: 'col_8',
        disableGrouping: true,
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        field: 'valid_until_date',
        db_field: 'valid_until_date',
        children: [
            {
                title: 'Valid For',
                type: 'number',
                key: 'col_8_1',
                field: 'valid_for_days',
                db_field: 'valid_for_days',
            },
            {
                title: 'Valid From',
                type: 'datetime',
                key: 'col_8_2',
                field: 'valid_from_date',
                db_field: 'valid_from_date',
            },
            {
                title: 'Valid Until',
                type: 'datetime',
                key: 'col_8_3',
                field: 'valid_until_date',
                db_field: 'valid_until_date',
            },
        ],
        template: ({ item }) => (
            <div className="member-card-validity">
                {item.valid_for_days ? ' for ' + item.valid_for_days + ' days ' : ''}
                {item.valid_for_days ? (
                    <>
                        <br />
                        {' start after ' + item.valid_for_days + ' days.'}
                    </>
                ) : (
                    ''
                )}
                {item.valid_from_date ? (
                    <>
                        <br />
                        {' from ' +
                            tmo.string.dateToString(item.valid_from_date, 'YYYY-MM-DD hh:mm')}
                    </>
                ) : (
                    ''
                )}
                <br />
                {item.valid_until_date
                    ? ' to ' + tmo.string.dateToString(item.valid_until_date, 'YYYY-MM-DD hh:mm')
                    : ''}
            </div>
        ),
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_action',
        width: 150,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                <IconButton
                    className="grid-open-button"
                    name="groups"
                    onClick={() => Navigations.goToUsage(item.id)}
                />
                <IconButton
                    className="grid-open-button"
                    name="qr_code"
                    popover={tmo.ui.popover.registerPopoverFunction(() => qrCodePopoverFunc(item))}
                />
                <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                />
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'wechat_giftcard_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow WechatGiftCard',
        simpleLabel: 'ShopNow WechatGiftCard',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat WechatGiftCard',
        simpleLabel: 'Wechat WechatGiftCard',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo WechatGiftCard',
        simpleLabel: 'Weibo WechatGiftCard',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok WechatGiftCard',
        simpleLabel: 'TikTok WechatGiftCard',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn WechatGiftCard',
        simpleLabel: 'LinkedIn WechatGiftCard',
        key: 'linkedin_followers',
        value: false,
    },
    {
        label: 'Display QQ WechatGiftCard',
        simpleLabel: 'QQ WechatGiftCard',
        key: 'qq_followers',
        value: false,
    },
    {
        label: 'Display App WechatGiftCard',
        simpleLabel: 'App WechatGiftCard',
        key: 'app_users',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    colorDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'wechat_couponcolor'
    )[0].items;
    couponTypeProps.options = Globals.constants.filter(
        (c) => c.type === 'wechat_coupontype'
    )[0].items;
    couponCodeTypeProps.options = Globals.constants.filter(
        (c) => c.type === 'wechat_couponcodetype'
    )[0].items;
};

import { Globals } from '../../Globals';

export class ActionTypeApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
        selection,
    }) => {
        console.log(system_filters);
        // const appIdSystemFilters = (system_filters || []).filter(
        //     (filter) => filter.field === 'application_id'
        // );

        return await Globals.callApi({
            url: 'analytics/action_type/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: system_filters, //appIdSystemFilters,
                selection,
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/action_type/get',
            params: { action_type_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'analytics/action_type/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/action_type/remove',
            params: { action_type_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/action_type/rollback',
            params: { action_type_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'action_type' },
        });
    };
}

import './OverviewCardContainer.scss';
import React, { useEffect, useRef, useState } from 'react'; 
import 'gridstack/dist/gridstack.css'; 
import tmoLib from '../../tmo/tmo.lib';
import { formatNumber } from '../../tmo/tmo.formatters'; 
import IconButton from './IconButton';
import Sticker from './Sticker'; 
import { LineChart } from '../chart/LineChart';
import { BarLineChart } from '../chart/BarLineChart';
import { getCampaignEngagementData } from '../../Marketing/Campaign/mocks';


 

function OverviewCardDynamicCard(props) {

   const [chartData, setChartData] = useState({});
 
  useEffect(()=>{
    setChartData(props.data || {});
    console.log('setChartData', props.data);
  },[props.data])

   
  const getMorePopover = (data, fields) => {

    if (!fields) {
      return null;
    }
    return (
      <div className="more-data-popover">

        {fields.filter(f => data[f.field]).map((f, index) => (
          <div className="more-data-item">
            <div className="more-data-item-label">{f.title}</div>
            <div className="more-data-item-value">{formatNumber(data[f.field].current.total)}</div>
          </div>
        ))}

      </div>
    );
  };  
  return <div className='data-card'>

    <label className="sub-title overview-card-wrapper-header-title" data-tooltip={props.tooltip}>
      {/* {props.title}   */}
      {props.more && <IconButton
        popover={tmoLib.ui.popover.registerPopoverFunction(() =>
          getMorePopover(chartData, props.more)
        )}
        className="more-data-button" name="keyboard_double_arrow_right" />}
    </label>
  
    <div
      className="value"
      data-tooltip="Total value for choosen time range"
    >
      {formatNumber(chartData.value)}{' '}
      <span className="value-label">{props.measure || props.title}</span>
    </div> 
    <Sticker
              color={'blue'}
              tooltip="Rate in total"
          >
            {(chartData.rate || 0).toFixed(2) || '0.0'} %
          </Sticker>

    </div>
}
 


export default OverviewCardDynamicCard;

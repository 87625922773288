import { SegmentApi } from "../../Customer/Segment/Segment.Api";
import { TagApi } from "../../Customer/Tags/Tag.Api";
 import { CARD_TYPE_MIN_SIZES, OVERVIEW_CARD_CATEGORY, OVERVIEW_CARD_CHART_TYPES,  OVERVIEW_CARD_FILTER,  OVERVIEW_CARD_TYPES, OVERVIEW_PAGE_TYPE } from "./OverviewCardUtil";


export class OverviewCardList { 
   
 
  static getDynamicCards = async ()=>{
    let allSegments = await SegmentApi.getReport({});
    allSegments = (allSegments || []).filter(s=>s && s.id);
    let allTags = await TagApi.getReport({});
     
    let allSegmentCards =  allSegments.map(s=>{
      return {
        id:s.id,
        name: s.name,
        segment:true,
        color:s.color,
        icon:s.icon,
        type: OVERVIEW_CARD_TYPES.DYNAMIC_CARD, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.SEGMENT_CARD,
        size: { w: 2, h: 2 }
      }
    }); 
     
    let allTagCards =  allTags.map(s=>{
      return {
        id:s.id,
        name: s.name,
        tag:true,
        color:s.color,
        icon:s.icon,
        logo:s.logo,
        type: OVERVIEW_CARD_TYPES.DYNAMIC_CARD, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.TAG_CARD,
        size: { w: 2, h: 2 }
      }
    });

    return [...allSegmentCards, ...allTagCards]
 
  }


  static async getAllCards (){
    
 
    let allCharts = [
      
  



  // ANALYTICS_VISIT_BY_TIME:'ANALYTICS_VISIT_BY_TIME',
  // ANALYTICS_VISIT_BY_REFERRER:'ANALYTICS_VISIT_BY_REFERRER',
  // ANALYTICS_VISIT_BY_DEVICE_TYPE:'ANALYTICS_VISIT_BY_DEVICE_TYPE',
  // ANALYTICS_VISIT_BY_SCREEN_SIZE:'ANALYTICS_VISIT_BY_SCREEN_SIZE',
  // ANALYTICS_VISIT_BY_COUNTRY:'ANALYTICS_VISIT_BY_COUNTRY',
  // ANALYTICS_VISIT_BY_BROWSER:'ANALYTICS_VISIT_BY_BROWSER',
  // ANALYTICS_VISIT_BY_OS:'ANALYTICS_VISIT_BY_OS',
  // ANALYTICS_VISIT_BY_MAIN_LANGUAGE:'ANALYTICS_VISIT_BY_MAIN_LANGUAGE',
  // ANALYTICS_VISIT_BY_MAJOR_LANGUAGE:'ANALYTICS_VISIT_BY_MAJOR_LANGUAGE',
  // ANALYTICS_VISIT_BY_LANGUAGES:'ANALYTICS_VISIT_BY_LANGUAGES'
  { 
  

    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    filter:[],
    name: 'Num. of Visits by Time Visitors Spent on App', 
    type: OVERVIEW_CARD_TYPES.DONUT, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_TIME 
  },
  {
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Num. of Visits by First Visit Source',
    type: OVERVIEW_CARD_TYPES.PIE,
    size: { w: 6, h: 4 },
    chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_REFERRER
  },
  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Num. of Visits by Device Category', 
    type: OVERVIEW_CARD_TYPES.PIE, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_DEVICE_TYPE 
  },
  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Num. of Visits by Screen Resolution', 
    type: OVERVIEW_CARD_TYPES.PIE, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_SCREEN_SIZE 
  },
  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Num. of Visits by Country', 
    type: OVERVIEW_CARD_TYPES.PIE, 
    size: { w: 6, h: 4 },
    tooltip:'The chart displays the number of visits from various countries, as determined primarily by IP address location. If the IP location data is unavailable or unreliable, the system will use the visitor\'s timezone to infer their country. In cases where neither IP nor timezone data is accessible, the visitor\'s language settings are used as a fallback for location estimation. This multi-layered approach helps ensure more accurate geographic insights into your audience.',
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_COUNTRY 
  },
  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Num. of Visits by Browser', 
    type: OVERVIEW_CARD_TYPES.PIE, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_BROWSER 
  },
  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Num. of Visits by Operating System', 
    type: OVERVIEW_CARD_TYPES.PIE, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_OS 
  },
  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Num. of Visits by Browser Language (Inluding sub-languages)', 
    type: OVERVIEW_CARD_TYPES.PIE, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_MAIN_LANGUAGE 
  },
  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Num. of Visits by Browser Language (Major language)', 
    type: OVERVIEW_CARD_TYPES.PIE, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_MAJOR_LANGUAGE 
  },
  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Visitor linguistic profile', 
    type: OVERVIEW_CARD_TYPES.PIE, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_LANGUAGES 
  },










  // { 
  //   category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
  //   name: 'Visitor linguistic profile', 
  //   type: OVERVIEW_CARD_TYPES.PIE, 
  //   size: { w: 6, h: 4 },
  //   chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_UTM_SOURCE 
  // },



  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Visitors by UTM Source', 
    type: OVERVIEW_CARD_TYPES.PIE, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_UTM_SOURCE_UNIQUE 
  },
 

  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Visitors by UTM Campaign', 
    type: OVERVIEW_CARD_TYPES.BAR, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_UTM_CAMPAIGN_UNIQUE 
  },



  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Visitors by UTM Medium', 
    type: OVERVIEW_CARD_TYPES.PIE, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_UTM_MEDIUM_UNIQUE 
  },

  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Visitors by Source', 
    type: OVERVIEW_CARD_TYPES.PIE, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_BY_REFERRER_CATEGORY_UNIQUE 
  },













  { 
    category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Visitor Interaction Over Time', 
    type: OVERVIEW_CARD_TYPES.FUNNEL, 
    size: { w: 6, h: 4 },
    chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_INTERACTION 
  }, 





  {
        category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Interacted sessions',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_INTERACTED,
        size: { w: 4, h: 4 }
    },
     {
        category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Non-Direct Visitors on Desktop',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_DESKTOP_REFERRED_VISIT,
        size: { w: 4, h: 4 }
    },
    {
        category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Non-Direct Visitors on Mobile',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_MOBILE_REFERRED_VISIT,
        size: { w: 4, h: 4 }
    },
     {
      category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
      suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Direct Visitors on Desktop',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_DESKTOP_DIRECT_VISIT,
        size: { w: 4, h: 4 }
    },
    {
      category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
      suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Direct Visitors on Mobile',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_MOBILE_DIRECT_VISIT,
        size: { w: 4, h: 4 }
    },
     {
      category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
      suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Direct Visitors on All Devices',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_DIRECT_VISIT,
        size: { w: 4, h: 4 }
    },
     {
      category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
      suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Non-Direct Visitors on All Devices',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_REFERRED_VISIT,
        size: { w: 4, h: 4 }
    },
     {
      category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
      suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Num. of Direct Visits',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_DIRECT_VISIT,
        size: { w: 4, h: 4 }
    },
    
    {
      category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
      suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Num. of Non-Direct Visits',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_REFERRED_VISIT,
        size: { w: 4, h: 4 }
    },
     {
      category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
      suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Registered users',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_REGISTER,
        size: { w: 4, h: 4 }
    },
     {
      category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
      suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Registered and Logged in users',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_MEMBERSHIP,
        size: { w: 4, h: 4 }
    },
     {
      category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
      suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Users with conversion actions',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_CONVERTED,
        size: { w: 4, h: 4 }
    },
     {
      category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
      suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Total time users spend on app',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_TIME,
        size: { w: 4, h: 4 }
    },
     {
      category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
      suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
        name: 'Total Num of Interactions',
        type: OVERVIEW_CARD_TYPES.CARD,
        chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_INTERACTIONS,
        size: { w: 4, h: 4 }
    }, 



{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Num. of Visits', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISITS,
	size: { w: 4, h: 4 }
},
{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Visitors', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_VISIT,
	size: { w: 4, h: 4 }
},

{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Num. of Visits by Humans', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_HUMAN_VISIT,
	size: { w: 4, h: 4 }
},

{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Num. of Visits by Bots', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_BOT_VISIT,
	size: { w: 4, h: 4 }
},

{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Visitors on Mobile', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_MOBILE_VISIT,
	size: { w: 4, h: 4 }
},

{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Visitors on Desktop', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_DESKTOP_VISIT,
	size: { w: 4, h: 4 }
},

{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Num. of Visits by Humans on Desktop', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_HUMAN_DESKTOP_VISIT,
	size: { w: 4, h: 4 }
},

{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Human Visitors by Humans on Mobile', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_HUMAN_MOBILE_VISIT,
	size: { w: 4, h: 4 }
},

{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Human Visitors on Desktop', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_HUMAN_DESKTOP_VISIT,
	size: { w: 4, h: 4 }
},

{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Num. of Visits by Bots on Mobile', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_BOT_MOBILE_VISIT,
	size: { w: 4, h: 4 }
},

{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Num. of Visits by Bots on Desktop', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_BOT_DESKTOP_VISIT,
	size: { w: 4, h: 4 }
},

{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Bot Visitors on Mobile', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_BOT_MOBILE_VISIT,
	size: { w: 4, h: 4 }
},

{  
  category:OVERVIEW_CARD_CATEGORY.ANALYTICS, 
  suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
	name: 'Bot Visitors on Desktop', 
	type: OVERVIEW_CARD_TYPES.CARD, 
	chart_type: OVERVIEW_CARD_CHART_TYPES.ANALYTICS_UNIQUE_BOT_DESKTOP_VISIT,
	size: { w: 4, h: 4 }
},






















  

       

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'E-Commerce Revenue', 
        type: OVERVIEW_CARD_TYPES.COLUMN, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_BY_DAY 
      },

      
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'E-Commerce Successful Orders', 
        type: OVERVIEW_CARD_TYPES.COLUMN, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS_BY_DAY 
      }, 
      

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'E-Commerce Orders', 
        type: OVERVIEW_CARD_TYPES.COLUMN, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ALL_ORDERS_BY_DAY 
      }, 
      

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],

        name: 'E-Commerce Users', 
        type: OVERVIEW_CARD_TYPES.COLUMN, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REGISTERED_USERS_BY_DAY 
      },  
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_BASKETS],
        name: 'E-Commerce Users (Abandoned Basket)', 
        type: OVERVIEW_CARD_TYPES.COLUMN, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REGISTERED_ABANDONED_USERS_BY_DAY 
      },  
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'E-Commerce Users (Not ordered)', 
        type: OVERVIEW_CARD_TYPES.COLUMN, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REGISTERED_NOT_ORDERED_USERS_BY_DAY 
      },   






      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'E-Commerce Revenue', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE 
      },  

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'E-Commerce Orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS 
      }, 

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
 
        name: 'E-Commerce Registered Users', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REGISTERED_USERS 
      }, 
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'E-Commerce Registered & Ordered Users', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REGISTERED_ORDERED_USERS 
      }, 
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_BASKETS],
        name: 'E-Commerce Registered & Abandoned Users', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REGISTERED_ABANDONED_USERS 
      }, 
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'E-Commerce Registered & Paid Users', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REGISTERED_PAID_USERS 
      }, 
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'E-Commerce Failed Orders', 
        type: OVERVIEW_CARD_TYPES.COLUMN, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_FAILED_ORDERS 
      }, 
      
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],

        name: 'E-Commerce Users', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_USERS 
      }, 
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'E-Commerce Failed Order Funnel', 
        type: OVERVIEW_CARD_TYPES.FUNNEL, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDER_FUNNEL 
      }, 
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'E-Commerce User Funnel', 
        type: OVERVIEW_CARD_TYPES.FUNNEL, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_USER_FUNNEL 
      }, 
      


















	
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Num. of Pending Payment', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS_PENDING_PAYMENT 
      }, 
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Num. of Pending Shipment', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS_PENDING_SHIPMENT 
      },  
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Refunded orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS_REFUNDED 
      }, 

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Cancelled orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS_CANCELLED 
      }, 

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Completed orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS_DELIVERED 
      }, 

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Orders delivered min. 15 days ago', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS_DELIVERED_OLD 
      },  



      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'All orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS_ALL
      },  

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Net orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS_NET
      },  


      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Successful orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS_SUCCESSFUL
      },  

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Failed orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS_FAILED
      },   

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Orders on the way', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ORDERS_IN_TRANSIT
      },  






















	
      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Possible income from pending payment.', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_PENDING_PAYMENT 
      // }, 

      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Revenue from pending shipment', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_PENDING_SHIPMENT 
      // },  
      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Total refunded', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_REFUNDED 
      // }, 

      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Cancelled cost', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_CANCELLED 
      // }, 

      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Revenue from completed orders', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_DELIVERED 
      // }, 

      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Refenue from orders delivered min. 15 days ago', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_DELIVERED_OLD 
      // },  



      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Total amount of all orders', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_ALL
      // },  

      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Net Revenue', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_NET
      // },  


      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Revenue from successful orders', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_SUCCESSFUL
      // },  

      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Total cost of failed orders', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_FAILED
      // },   

      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Total revenue from orders on the way', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_IN_TRANSIT
      // },  
















	
      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Possible income from pending payment.', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ITEMS_PENDING_PAYMENT 
      // }, 

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Total items pending shipment', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ITEMS_PENDING_SHIPMENT 
      },  
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Num. of items refunded', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ITEMS_REFUNDED 
      }, 

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Num. of items cancelled', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ITEMS_CANCELLED 
      }, 

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Num. items from completed orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ITEMS_DELIVERED 
      }, 

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Num. items from orders delivered min. 15 days ago', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ITEMS_DELIVERED_OLD 
      },  



      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Num. items from all orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ITEMS_ALL
      },  

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Num. items from net orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ITEMS_NET
      },  


      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Num. items successful', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ITEMS_SUCCESSFUL
      },  

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Num. items failed', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ITEMS_FAILED
      },   

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Num. items on the way', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ITEMS_IN_TRANSIT
      },  















	
      // { 
      //   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   name: 'Possible income from pending payment.', 
      //   type: OVERVIEW_CARD_TYPES.CARD, 
      //   size: { w: 6, h: 4 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_PENDING_PAYMENT 
      // }, 

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Revenue from pending shipment', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_PENDING_SHIPMENT 
      },  
      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Total refunded', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_REFUNDED 
      }, 

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Cancelled cost', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_CANCELLED 
      }, 

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Revenue from completed orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_DELIVERED 
      }, 

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Refenue from orders delivered min. 15 days ago', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_DELIVERED_OLD 
      },  



      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Total amount of all orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_ALL
      },  

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Net Revenue', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_NET
      },  


      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Revenue from successful orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_SUCCESSFUL
      },  

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Total cost of failed orders', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_FAILED
      },   

      { 
        category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Total revenue from orders on the way', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_IN_TRANSIT
      },  



















      






	
      // # { 
      //   #   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   #   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   #   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   #   name: 'Possible daily income from pending payment.', 
      //   #   type: OVERVIEW_CARD_TYPES.CARD, 
      //   #   size: { w: 6, h: 4 },
      //   #   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_AVRG_REVENUE_PENDING_PAYMENT 
      //   # }, 
      //   # { 
      //   #   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   #   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   #   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   #   name: 'Avg. daily revenue from pending shipment', 
      //   #   type: OVERVIEW_CARD_TYPES.CARD, 
      //   #   size: { w: 6, h: 4 },
      //   #   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_AVRG_REVENUE_PENDING_SHIPMENT 
      //   # },  
  
      //   # { 
      //   #   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   #   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   #   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   #   name: 'Total refunded', 
      //   #   type: OVERVIEW_CARD_TYPES.CARD, 
      //   #   size: { w: 6, h: 4 },
      //   #   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_AVRG_REVENUE_REFUNDED 
      //   # }, 
  
      //   # { 
      //   #   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
      //   #   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
      //   #   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
      //   #   name: 'Cancelled cost', 
      //   #   type: OVERVIEW_CARD_TYPES.CARD, 
      //   #   size: { w: 6, h: 4 },
      //   #   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_AVRG_REVENUE_CANCELLED 
      //   # }, 
  
        { 
          category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
          suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
          filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
          name: 'Avg. daily revenue from completed orders', 
          type: OVERVIEW_CARD_TYPES.CARD, 
          size: { w: 6, h: 4 },
          chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_AVRG_REVENUE_DELIVERED 
        }, 
  
        { 
          category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
          suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
          filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
          name: 'Avg. daily revenue from orders delivered min. 15 days ago', 
          type: OVERVIEW_CARD_TYPES.CARD, 
          size: { w: 6, h: 4 },
          chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_AVRG_REVENUE_DELIVERED_OLD 
        },  
  
  
  
        { 
          category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
          suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
          filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
          name: 'Avg. daily amount of all orders', 
          type: OVERVIEW_CARD_TYPES.CARD, 
          size: { w: 6, h: 4 },
          chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_AVRG_REVENUE_ALL
        },  
  
        { 
          category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
          suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
          filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
          name: 'Avg. daily net revenue', 
          type: OVERVIEW_CARD_TYPES.CARD, 
          size: { w: 6, h: 4 },
          chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_AVRG_REVENUE_NET
        },  
  
  
        { 
          category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
          suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
          filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
          name: 'Avg. daily revenue from successful orders', 
          type: OVERVIEW_CARD_TYPES.CARD, 
          size: { w: 6, h: 4 },
          chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_AVRG_REVENUE_SUCCESSFUL
        },  
  
        { 
          category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
          suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
          filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
          name: 'Avg. daily cost of failed orders', 
          type: OVERVIEW_CARD_TYPES.CARD, 
          size: { w: 6, h: 4 },
          chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_AVRG_REVENUE_FAILED
        },   
  
        // # { 
        // #   category:OVERVIEW_CARD_CATEGORY.ECOMMERCE, 
        // #   suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ECOMMERCE, OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        // #   filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        // #   name: 'Avg. daily crevenue from orders on the way', 
        // #   type: OVERVIEW_CARD_TYPES.CARD, 
        // #   size: { w: 6, h: 4 },
        // #   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_AVRG_REVENUE_IN_TRANSIT
        // # },  




  // ECOMMERCE_ORDER:'ECOMMERCE_ORDER',
  // ECOMMERCE_TOTAL_REVENUE:'ECOMMERCE_TOTAL_REVENUE',
  // ECOMMERCE_TOTAL_ORDER:'ECOMMERCE_TOTAL_ORDER',
  // ECOMMERCE_NEW_BASKET:'ECOMMERCE_NEW_BASKET',
  // ECOMMERCE_ABANDONED_BASKET:'ECOMMERCE_ABANDONED_BASKET',



  // { 
  //   name: 'Total E-Commerce Revenue', 
  //   type: OVERVIEW_CARD_TYPES.CARD, 
  //   size: { w: 6, h: 4 },
  //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_TOTAL_REVENUE 
    
  // },

  // { 
  //   name: 'Total E-Commerce Orders', 
  //   type: OVERVIEW_CARD_TYPES.CARD, 
  //   size: { w: 6, h: 4 },
  //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_TOTAL_ORDER 
    
  // },

  // { 
  //   name: 'Total E-Commerce New Baskets', 
  //   type: OVERVIEW_CARD_TYPES.CARD, 
  //   size: { w: 6, h: 4 },
  //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_NEW_BASKET 
    
  // },

  // { 
  //   name: 'E-Commerce Abandoned Baskets', 
  //   type: OVERVIEW_CARD_TYPES.CARD, 
  //   size: { w: 6, h: 4 },
  //   chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_ABANDONED_BASKET 
    
  // },




  //     { 
  //       name: 'E-Commerce Registered', 
  //       type: OVERVIEW_CARD_TYPES.COLUMN, 
  //       size: { w: 6, h: 4 },
  //       chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REGISTER_BY_DAY 
  //     },
  //     { 
  //       name: 'E-Commerce Registered > Paid', 
  //       type: OVERVIEW_CARD_TYPES.COLUMN, 
  //       size: { w: 6, h: 4 },
  //       chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REGISTER_PAY_BY_DAY 
  //     },

  //     { 
  //       name: 'E-Commerce Registered > Abandoned Baskets', 
  //       type: OVERVIEW_CARD_TYPES.COLUMN, 
  //       size: { w: 6, h: 4 },
  //       chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REGISTER_ABANDONED_BY_DAY 
  //     },
 
       


  { 
    category:OVERVIEW_CARD_CATEGORY.GENERIC, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Card Container', 
    type: OVERVIEW_CARD_TYPES.CARD_CONTAINER, 
    id:OVERVIEW_CARD_TYPES.CARD_CONTAINER,
    size: { w: 6, h: 4 }
  },

  { 
    category:OVERVIEW_CARD_CATEGORY.GENERIC, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Card Container', 
    type: OVERVIEW_CARD_TYPES.CARD_CONTAINER, 
    id:OVERVIEW_CARD_TYPES.CARD_CONTAINER + '1',
    size: { w: 6, h: 4 }
  },
  

  { 
    category:OVERVIEW_CARD_CATEGORY.GENERIC, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Card Container', 
    type: OVERVIEW_CARD_TYPES.CARD_CONTAINER, 
    id:OVERVIEW_CARD_TYPES.CARD_CONTAINER + '2',
    size: { w: 6, h: 4 }
  },
  

  { 
    category:OVERVIEW_CARD_CATEGORY.GENERIC, 
    suitable_with_overviews:[OVERVIEW_PAGE_TYPE.ANY],
    name: 'Card Container', 
    type: OVERVIEW_CARD_TYPES.CARD_CONTAINER, 
    id:OVERVIEW_CARD_TYPES.CARD_CONTAINER + '3',
    size: { w: 6, h: 4 }
  },
  

      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers Best Life Time Value', 
        type: OVERVIEW_CARD_TYPES.BEST, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_MOST_SPENT 
      },
      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Returned best customers', 
        type: OVERVIEW_CARD_TYPES.BEST, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_RETURNED 
      },

      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_PRODUCTS],
        name: 'Most selling products', 
        type: OVERVIEW_CARD_TYPES.BEST, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_PRODUCT_BEST 
      },
  
      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By RFM Group', 
        type: OVERVIEW_CARD_TYPES.BAR, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_RFM ,
        
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By Segments', 
        type: OVERVIEW_CARD_TYPES.BAR, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_SEGMENT 
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By RFM Score', 
        type: OVERVIEW_CARD_TYPES.BAR, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_RFM_SCORE
      },

 
      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By Recency', 
        type: OVERVIEW_CARD_TYPES.PIE, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_RECENCY
      },
      

      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By Frequency', 
        type: OVERVIEW_CARD_TYPES.PIE, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_FREQUENCY
      }, 


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By Monetary', 
        type: OVERVIEW_CARD_TYPES.PIE, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_MONETARY
      },
      

      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By Language', 
        type: OVERVIEW_CARD_TYPES.PIE, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_LANGUAGE 
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By Device', 
        type: OVERVIEW_CARD_TYPES.PIE, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_DEVICE
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By Browser', 
        type: OVERVIEW_CARD_TYPES.PIE, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_BROWSER
      },
      

      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By Country', 
        type: OVERVIEW_CARD_TYPES.BAR, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_COUNTRY
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By Province', 
        type: OVERVIEW_CARD_TYPES.BAR, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_PROVINCE
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By Location', 
        type: OVERVIEW_CARD_TYPES.BAR, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_LOCATION
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Customers By Order Status', 
        type: OVERVIEW_CARD_TYPES.PIE, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_ORDER_STATUS
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Customers By Ordered Items', 
        type: OVERVIEW_CARD_TYPES.BAR, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_ORDERED_ITEM
      },

      
      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customers By Num. Of Sessions', 
        type: OVERVIEW_CARD_TYPES.PIE, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_SESSION
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Customers By Orders', 
        type: OVERVIEW_CARD_TYPES.PIE, 
        size: { w: 6, h: 4 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMERS_BY_ORDER
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Active Customers', 
        type: OVERVIEW_CARD_TYPES.GRID, 
        size: { w: 12, h: 12 }, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.ACTIVE_CUSTOMERS 
      },

      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Active users over time', 
        type: OVERVIEW_CARD_TYPES.REAL_TIME_TRAFFIC, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_ACTIVE_VISITS_OVER_TIME 
      },
      

      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Visitors By Region (Not including bots)', 
        type: OVERVIEW_CARD_TYPES.WORLD_MAP, 
        size: { w: 6, h: 4 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.ANALYTICS_VISIT_MAP 
      },


      {  
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Total Customers', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.TOTAL_CUSTOMERS,
        size: { w: 4, h: 4 }
      },


      {  
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Registered Customers', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.REGISTERED_CUSTOMERS,
        size: { w: 4, h: 4 }
      },


      {
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],  
        name: 'Logged In Customers', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.LOGGEDIN_CUSTOMERS,
        size: { w: 4, h: 4 }
      },


      {  
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Left Customers', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.LEFT_CUSTOMERS,
        size: { w: 4, h: 4 }
      },


      {  
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Converted Customers', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.CONVERTED_CUSTOMERS,
        size: { w: 4, h: 4 }
      },


      {  
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Ordered Customers', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.ORDERED_CUSTOMERS,
        size: { w: 4, h: 4 }
      },


      {  
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'New Customers (First Order)', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.NEW_CUSTOMERS,
        size: { w: 4, h: 4 }
      },


      {  
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Returning Customers (Multiple sessions)', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.RETURNING_CUSTOMERS,
        size: { w: 4, h: 4 }
      },


      {  
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Two Times Buyers (Multiple sessions)', 
        type: OVERVIEW_CARD_TYPES.CARD, 
        chart_type:OVERVIEW_CARD_CHART_TYPES.TWO_TIMES_BUYERS,
        size: { w: 4, h: 4 }
      },
      

      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
          name: 'Best Segments', 
          type: OVERVIEW_CARD_TYPES.BEST, 
          size: { w: 6, h: 10 },
          chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_SEGMENT_BEST,
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Best Tags', 
        type: OVERVIEW_CARD_TYPES.BEST, 
        size: { w: 6, h: 10 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_TAG_BEST,
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Best Customers', 
        type: OVERVIEW_CARD_TYPES.BEST, 
        size: { w: 6, h: 10 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_TOP,
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customer Interaction (Registered)', 
        type: OVERVIEW_CARD_TYPES.FUNNEL, 
        size: { w: 6, h: 6 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_INTERACTION_REGISTER,
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customer Interaction (Membership)', 
        type: OVERVIEW_CARD_TYPES.FUNNEL, 
        size: { w: 6, h: 6 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_INTERACTION_MEMBERSHIP,
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customer Interaction (Interacted)', 
        type: OVERVIEW_CARD_TYPES.FUNNEL, 
        size: { w: 6, h: 6 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_INTERACTION_INTERACTED,
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS],
        name: 'Customer Interaction (Ordered)', 
        type: OVERVIEW_CARD_TYPES.FUNNEL, 
        size: { w: 6, h: 6 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_INTERACTION_REGISTER_ORDER,
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customer Interaction (Commented)', 
        type: OVERVIEW_CARD_TYPES.FUNNEL, 
        size: { w: 6, h: 10 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_INTERACTION_COMMENT,
      },


      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        filter:[OVERVIEW_CARD_FILTER.IF_HAS_ORDERS, OVERVIEW_CARD_FILTER.IF_HAS_BASKETS],
        name: 'Customer Interaction (Basket > Order)', 
        type: OVERVIEW_CARD_TYPES.FUNNEL, 
        size: { w: 1, h: 6 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_INTERACTION_ADD_BASKET_ORDER,
      },
      
 
      { 
        category:OVERVIEW_CARD_CATEGORY.CUSTOMER, 
        suitable_with_overviews:[OVERVIEW_PAGE_TYPE.CUSTOMER, OVERVIEW_PAGE_TYPE.DASHBOARD],
        name: 'Customer Source', 
        type: OVERVIEW_CARD_TYPES.PIE, 
        size: { w: 6, h: 6 },
        chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_SOURCE,
      },


      // { 
      //   name: 'Customer Referrals', 
      //   type: OVERVIEW_CARD_TYPES.PIE, 
      //   size: { w: 6, h: 6 },
      //   chart_type:OVERVIEW_CARD_CHART_TYPES.CUSTOMER_REFERRAL,
      // },

      
 
    ];


    // let dynamicCharts = await this.getDynamicCards();
    // allCharts.push(...dynamicCharts);
    allCharts.forEach(a=>{
       a.id=  a.id || a.chart_type;
       a.size.w = CARD_TYPE_MIN_SIZES[a.type]?.size?.minWidth;
       a.size.h = CARD_TYPE_MIN_SIZES[a.type]?.size?.minHeight;
    });

    return allCharts;
 
  }
  

}
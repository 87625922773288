import './OverviewCardContainer.scss';
import React, {  useEffect, useRef } from 'react';
import OverviewCard from './OverviewCard';
import HighChartWrappper from './HighChartWrappper';
 
import 'gridstack/dist/gridstack.css'; 
import OverviewCardBestRecord from './OverviewCardBestRecord';
import { FunnelChart } from '../chart/FunnelChart';
import RecordViewerGrid from '../../Customer/Segment/RecordViewerGrid'; 
import {  CARD_TYPE_MIN_SIZES, OVERVIEW_CARD_CHART_SETTINGS, OVERVIEW_CARD_CHART_TYPES, OVERVIEW_CARD_TYPES } from './OverviewCardUtil';
 
import OverviewCardCardContainer from './OverviewCardCardContainer';
import OverviewCardAnalyticsActiveUsers from './OverviewCard.AnalyticsActiveUsers';
import OverviewCardWorldMap from './OverviewCard.WorldMap'; 
 

function OverviewCardGridStackItem(props) {
 
  
  const getChartData = (chart) => {
     return (props.chartData || []).find(c => c.chart_key == chart.chart_type)?.data;
  }

  const getFunnelChartData = (chart) => {
    // console.log('getChartData', chart, chartData, (chartData || []).find(c=>c.chart_key==chart.chart_type)?.data);
    let data = (props.chartData || []).find(c => c.chart_key == chart.chart_type)?.data;
    return {
      colors: data?.chart_model.colors || [],
      values: data?.chart_model.datasets || [],
      labels: data?.chart_model.labels || [],
    }
  }

  const getDynamicChartData = (chart) => {
    return (props.chartData || []).find(c => c.chart_key == chart.chart_type && c.id == chart.id)?.data;
  }

  const onTimeRangeChanged = (chart, t) => {
    props.onTimeRangeChanged(chart, t); 
  };



  const onMaxFieldsChanged = (chart, value) => {
    props.onMaxFieldsChanged(chart, value);
    
  };
 
  const removeCard = (card) => {
    props.onRemoveCard(card);
  }

 
  const startEditing = () => {
   props.onStartEditing();
  }


  const onContainerAddCard = (card) => {
    props.onContainerAddCard(card); 

  } 
  const getyAxisType = (chart) =>{ 

    if(chart.chart_type==OVERVIEW_CARD_CHART_TYPES.ECOMMERCE_REVENUE_BY_DAY){
      return 'currency';
    }
    return 'number';
  }


  console.log('====== OverviewCardContainer RENDERING')

  let settings =  props.settings;

  let cardClass = 'grid-stack-item-content ';
  let disableFooter = false;
  let disableHeader = false;
  let headerNoBorder = false;
  let noPadding = false;
  
  if ( settings.type == OVERVIEW_CARD_TYPES.CARD ||  settings.type == OVERVIEW_CARD_TYPES.DYNAMIC_CARD) {
    headerNoBorder = true;
  }
  if ( settings.type == OVERVIEW_CARD_TYPES.CARD ||  settings.type == OVERVIEW_CARD_TYPES.DYNAMIC_CARD ||  settings.type == OVERVIEW_CARD_TYPES.BEST) {
    disableFooter = true;
  } 

  let w = ( settings.size.w ||  settings.size.minWidth);
  if (w < 1) {
    w = 1;
  }
  let mw = CARD_TYPE_MIN_SIZES[ settings.type].size.minWidth;
  if (mw < 1) {
    mw = 1;
  }
  if (mw > props.maxColumns) {
    mw = props.maxColumns;
  }

  if (w < mw) {
    w = mw;
  }
  if (w > props.maxColumns) {
    w = props.maxColumns;
  }
  if (!w) {
    w = mw;
  }
 
      return <div
        key={settings.id}
        className={"grid-stack-item border-dark "}
        data-gs-id={settings.id}
        gs-id={settings.id}
        gs-x={settings.size.x}
        gs-y={settings.size.y}
        gs-w={w}
        gs-h={settings.size.h || settings.size.minHeight}
        gs-min-w={mw}
        gs-min-h={CARD_TYPE_MIN_SIZES[settings.type].size.minHeight}
      >
 
    { 
        settings.type == OVERVIEW_CARD_TYPES.REAL_TIME_TRAFFIC &&
        <OverviewCard
            realtime
            editMode={props.editMode}
            canRender={props.chartData ? true : false}
            renderTimeout={settings.delay}
            className={cardClass}
            key={settings.id}
            disableFooter={true}
            disableHeader={true}
            headerNoBorder={true}
            noPadding={true}
            title={settings.name}
            tooltip={settings.tooltip}
            parent={props.containerRef}
            noBackground
            onStartAdd={() => props.onShowCardSelector()}
            onStartEdit={() => startEditing(true)}
            onRemove={() => removeCard(settings)}
            onTimeRangeChanged={(t) => onTimeRangeChanged(settings, t)}
            onMaxFieldsChanged={(t) => onMaxFieldsChanged(settings, t)}
            showMaxFieldSelect={settings.type == OVERVIEW_CARD_TYPES.BAR || settings.type == OVERVIEW_CARD_TYPES.DONUT || settings.type == OVERVIEW_CARD_TYPES.PIE}
            maxField={settings.max_field}
            allDone={props.allDone}
            >
          <OverviewCardAnalyticsActiveUsers />
          </OverviewCard>
    }
     { 
        settings.type != OVERVIEW_CARD_TYPES.REAL_TIME_TRAFFIC  &&
        <OverviewCard
          editMode={props.editMode}
          canRender={props.chartData ? true : false}
          hasData={getChartData(settings)}
          renderTimeout={settings.delay}
          className={cardClass}
          key={settings.id}
          timeframe={settings.date_range}
          disableFooter={disableFooter}
          disableHeader={disableHeader}
          headerNoBorder={headerNoBorder}
          noPadding={noPadding}
          title={settings.name}
          tooltip={settings.tooltip}
          parent={props.containerRef}
          onStartAdd={() => props.onShowCardSelector(true)}
          onStartEdit={() => startEditing(true)}
          onRemove={() => removeCard(settings)}
          loading={settings.loading}
          onTimeRangeChanged={(t) => onTimeRangeChanged(settings, t)}
          onMaxFieldsChanged={(t) => onMaxFieldsChanged(settings, t)}
          showMaxFieldSelect={settings.type == OVERVIEW_CARD_TYPES.BAR || settings.type == OVERVIEW_CARD_TYPES.DONUT || settings.type == OVERVIEW_CARD_TYPES.PIE}
          maxField={settings.max_field}
          allDone={props.allDone}
          >
          {props.chartData  && props.allDone? <>

            { (settings.type == OVERVIEW_CARD_TYPES.FUNNEL) && 
                <FunnelChart data={getFunnelChartData(settings)} options={{ displayPercent: true }} hide />}

            { (settings.type == OVERVIEW_CARD_TYPES.GRID) && 
                <OverviewCardGrid {...OVERVIEW_CARD_CHART_SETTINGS[settings.chart_type]()} gridId={settings.grid_id} />}

            { (settings.type == OVERVIEW_CARD_TYPES.CUSTOM) && 
                props.children}

            { (settings.type == OVERVIEW_CARD_TYPES.PIE  || settings.type == OVERVIEW_CARD_TYPES.DONUT  || settings.type == OVERVIEW_CARD_TYPES.BAR || settings.type == OVERVIEW_CARD_TYPES.WORDCLOUD || settings.type == OVERVIEW_CARD_TYPES.GAUGE || settings.type == OVERVIEW_CARD_TYPES.COLUMN || settings.type == OVERVIEW_CARD_TYPES.LINE || settings.type == OVERVIEW_CARD_TYPES.VENN) && 
                <HighChartWrappper yAxisType={getyAxisType(settings)} settings={settings} data={getChartData(settings)} type={settings.type.toLowerCase()} w={settings.size?.w} h={settings.size?.h} />}

            { settings.type == OVERVIEW_CARD_TYPES.CARD_CONTAINER && 
                <OverviewCardCardContainer editMode={props.editMode} children={settings.children} title={settings.name} onGetDynamicChartData={(card) => getDynamicChartData(card)} onGetChartData={(card) => getChartData(card)} onAddCard={() => onContainerAddCard(settings)} />}

            {/* { settings.type == OVERVIEW_CARD_TYPES.CARD && <OverviewCardCard data={getChartData(settings)} title={settings.name}    {...OVERVIEW_CARD_CHART_SETTINGS[settings.chart_type]()}  /> } */}
            {/* { settings.type == OVERVIEW_CARD_TYPES.DYNAMIC_CARD && <OverviewCardDynamicCard data={getDynamicChartData(settings)} title={settings.name} {...OVERVIEW_CARD_CHART_SETTINGS[settings.chart_type]()}  /> } */}

            { settings.type == OVERVIEW_CARD_TYPES.BEST && 
                <OverviewCardBestRecord size={settings.size} data={getChartData(settings)}  {...OVERVIEW_CARD_CHART_SETTINGS[settings.chart_type]()} />}

            { settings.type == OVERVIEW_CARD_TYPES.WORLD_MAP && 
                <OverviewCardWorldMap size={settings.size} data={getChartData(settings)} chart={settings} timeframe={settings.date_range} {...OVERVIEW_CARD_CHART_SETTINGS[settings.chart_type]()} />}
             
          </> : null}
        </OverviewCard>
    }
      </div>
   

}


function OverviewCardGrid(props) {
  const recordViewerRef = useRef();

  useEffect(() => {
    recordViewerRef.current?.reload();
  }, [props.applications]);

  return <RecordViewerGrid
    ref={recordViewerRef}
    onSearch={(params) =>
      props.api.search({
        ...params,
        ...props.params
      })
    }
    store={props.store}
    gridId={props.gridId}
  />
}
 




export default OverviewCardGridStackItem;


import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import tmo from '../../../tmo/tmo.lib';
import { Globals } from '../../../Globals';
import Button from '../../../components/basic/Button';
import Card from '../../../components/basic/Card';
import Icon from '../../../components/basic/Icon';
import IconButton from '../../../components/basic/IconButton';
import Input from '../../../components/basic/Input';
import {
    AttributeManagerPopup,
    DataGridToolTypes,
    FovouritePopup,
    HistoryPopup,
    PermissionPopup,
    RestoreDataFromSession,
    SharePopup,
} from '../../../components/grid/DataGridToolsPopups';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { areArraysEqual } from '../../../Marketing/Automation/utils';
import TagSelect from '../TagSelect';
import SocketManager from '../../../SocketManager';
import { usePrevious } from '../../../hooks/usePrevious';
import './EditPage.scss';

function EditPage(props) {
    const api = props.api;
    const store = props.store;
    const onLoad = props.onLoad;
    const onDefaultData = props.onDefaultData;
    const onSave = props.onSave;
    const onSaveDuplicate = props.onSaveDuplicate;
    const pageTitle = props.pageTitle;
    const pageSubSelect = props.pageSubSelect;
    const enableTagging = props.enableTagging;
    const enableAttributes = props.enableAttributes;
    const setData = props.setData;
    const data = props.data;
    const rightBarContent = props.rightBarContent;
    const leftBarContent = props.leftBarContent;
    const leftBarTitle = props.leftBarTitle;
    const rightBarTitle = props.rightBarTitle;
    const cardHeader = props.cardHeader;
    const onExport = props.onExport;
    const navigations = props.navigations;
    const hideReport = props.hideReport;
    const enableDublicate = props.enableDublicate;
    const enableSaveAsDraft = props.enableSaveAsDraft;
    const enableSaveAsTemplate = props.enableSaveAsTemplate;
    const recordType = props.recordType;
    const navigate = useNavigate();
 

    const gridState = store.gridState.follow();

    const params = useParams();

    const { restoreConfig, updateOrDuplicate, onGoToView } = props;

    const [channels, setChannels] = useState([]);
    const [refresher, setRefresher] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const isRefresh = useRef(false);
    const [rightPanelOpened, setRightPanelOpened] = useState(false);
    const [leftPanelOpened, setLeftPanelOpened] = useState(false);
    const location = useLocation();
    const previousLocation = usePrevious(location);

    const className =
        'edit-page ' +
        (props.className || '') +
        ' ' +
        (leftBarContent ? ' has-leftbar ' : ' ') +
        (leftPanelOpened ? ' leftbar-open ' : ' ') +
        (rightPanelOpened ? ' rightbar-open ' : ' ');

    const [restoreData, setRestoreData] = useSessionStorage(
        restoreConfig?.key ?? location.pathname,
        restoreConfig?.defaultValue ?? {}
    );

    const handleRefresh = () => {
        isRefresh.current = true;
    };

    useEffect(() => {
        if (recordType && props.listenRecordIds && props.listenRecordIds.length) {
            props.listenRecordIds.forEach((rid) => {
                SocketManager.subscribeRecord(recordType, rid, () => loadRecordFromApi(true));
                return () => SocketManager.unSubscribeRecord(recordType, rid);
            });
        } else if (recordType && data.id && data.id.length && data.id !== 'new') {
            if (data.id) {
                SocketManager.subscribeRecord(recordType, data.id, () => loadRecordFromApi(true));
                return () => SocketManager.unSubscribeRecord(recordType, data.id);
            }
        }
    }, [data.id, props.listenRecordIds]);

    const loadRecordFromApi = (forceUpdate = false) => {
        if (params.id === 'new') {
            let defaultData = onDefaultData();

            if (onLoad) {
                let { data } = onLoad({ data: defaultData });
                if (data) {

                    console.log('loadRecordFromApi', data);
                    setData(data);
                    return;
                }
            }
            console.log('load default data');
            setData(defaultData);

            return;
        }

        api.get({ id: params.id }).then((d) => {
            onLoad && onLoad({ data: d });
            console.log('data from api');
            setData({ ...d });
            setLoaded(true);

            // local data and DB are different, let the user choose
            if (restoreData[params.id] && !forceUpdate) {
                let equalityCondition = data.updated_at !== restoreData[params.id].updated_at;

                if (restoreConfig?.comparator) {
                    const dataField = data[restoreConfig.comparator];
                    const localField = restoreData[params.id][restoreConfig.comparator];

                    if (Array.isArray(dataField)) {
                        const apiUpdates = dataField.map((item) => item.updated_at);
                        const localUpdates = localField.map((item) => item.updated_at);
                        equalityCondition = areArraysEqual(apiUpdates, localUpdates);
                    } else {
                        equalityCondition = dataField.updated_at !== localField.updated_at;
                    }
                }

                const modal = equalityCondition
                    ? DataGridToolTypes.RESTORE_SERVER_CHANGES
                    : DataGridToolTypes.RESTORE;
                showTool(modal, true);
            } else {
                clearBrowserData();
            }
        });
    };

    useEffect(() => {
        loadRecordFromApi();
    }, [refresher]);

    useEffect(() => {
        const dataNotEmpty = Object.keys(data).length === 0;
        // track changes from parent components data, but not on a refresh
        if (
            !dataNotEmpty &&
            toolsVisibility.RESTORE === false &&
            toolsVisibility.RESTORE_SERVER_CHANGES === false &&
            loaded
        ) {
            saveChangesToBrowser();
        }
    }, [data]);

    useEffect(() => {
        if (
            location.pathname !== previousLocation?.pathname &&
            previousLocation?.pathname !== undefined
        ) {
            loadRecordFromApi(true);
        }
    }, [location.pathname, previousLocation?.pathname]);

    useEffect(() => {
        // on a refresh, let's keep the stored data
        window.addEventListener('beforeunload', handleRefresh);
        return () => {
            window.removeEventListener('beforeunload', handleRefresh);
            if (!isRefresh.current) {
                clearBrowserData();
            }
        };
    }, []);

    const setRecord = (field, value) => {
        data[field] = value;
        console.log('Set Record');
        setData({ ...data });
    };

    const setRecordProps = (field, value) => {
        data.props = data.props || {};
        data.props[field] = value;
        console.log('Set Record props');
        setData({ ...data });
    };

    const rollbackRecordClicked = async () => {
        await api.rollback({ id: params.id });
        setRefresher(refresher + 1);
    };

    const deleteRecord = async () => {
        let isDeleted = await api.remove({ id: params.id });
        clearBrowserData();
        setRefresher(refresher + 1);

        if (isDeleted) {
            tmo.ui.global.showConfirmPopup({
                popupType: 'deleteSucceeded',
                onDone: navigations.gotoList,
                onCancel: navigations.gotoList,
            });
        } else {
            tmo.ui.global.showConfirmPopup({ popupType: 'deleteFailed' });
        }
    };

    const cancelClicked = () => {
        //call api
        tmo.ui.global.showConfirmPopup({ popupType: 'cancel', onDone: navigations.gotoList });
        clearBrowserData();
    };

    const showSaveModal = (result) => {
        if (result) {
            tmo.ui.global.showConfirmPopup({
                popupType: 'saveSucceeded',
                onDone: navigations.gotoList,
                onCancel: navigations.gotoList,
                onStay: () => {
                    clearBrowserData();
                    navigations.gotoEdit(result.id || data.id);
                },
            });
            setChannels(Globals.constants.filter((c) => c.type === 'channel')[0].items);

            console.log('Set showSaveModal');
            setData(result);
        } else {
            console.log('Set saveFailed ', result);
            tmo.ui.global.showConfirmPopup({ popupType: 'saveFailed' });
        }
    };

    const save = async () => {
        console.log('save');
        tmo.ui.global.showConfirmPopup({ popupType: 'saving' });

        if (onSave) {
            let result = await onSave(data);
            tmo.ui.global.hideConfirmPopup();
            showSaveModal(result);
        }
    };

    const saveDuplicate = async () => {
        const replaceRecord = async () => {
            if (onSave) {
                const result = await onSave(data);
                showSaveModal(result);
            }
        };

        const duplicateRecord = async () => {
            if (onSaveDuplicate) {
                const result = await onSaveDuplicate(data);
                showSaveModal(result);
            }
        };

        tmo.ui.global.showConfirmPopup({
            popupType: 'updateOrDuplicate',
            onDone: replaceRecord,
            onStay: duplicateRecord,
        });
    };

    const deleteRecordClicked = () => {
        tmo.ui.global.showConfirmPopup({
            popupType: 'delete',
            onDone: deleteRecord,
            contentTitle: 'You are deleting this record',
            contentDetail: (
                <div>
                    <div className="paragraph">This record will be deleted from system. </div>
                    <div className="paragraph">
                        <label>Outcome:</label> You won't be able to acceess any data belongs to
                        this record.
                    </div>
                </div>
            ),
        });
    };

    const customRightButtonClicked = () => {
        if (props.customRightButton && props.customRightButton.onClick) {
            props.customRightButton.onClick();
        }
    };

    const viewReportClicked = () => {
        if (!onGoToView) {
            navigations.gotoView(data.id);
        } else {
            onGoToView();
        }
    };

    const [toolsVisibility, setToolsVisibility] = useState({
        COLUMN_MANAGER: false,
        ATTRIBUTE_MANAGER: false,
        FILTER_PANEL: false,
        DISPLAY_SETTINGS: false,
        COLOR_MANAGER: false,
        EXPORT: false,
        IMPORT: false,
        TAG_MANAGER: false,
        FAVOURITES: false,
        SHARES: false,
        DESIGN: false,
        ADD_FAVOURITES: false,
        ADD_SHARE: false,
        PERMISSION: false,
        HISTORY: false,
        RESTORE: false,
        RESTORE_SERVER_CHANGES: false,
    });

    const showTool = (toolType, visible) => {
        toolsVisibility[toolType] = visible;
        setToolsVisibility({ ...toolsVisibility });
    };

    const exportData = () => {
        if (onExport) {
            onExport();
        }
    };

    const onPermissionsSave = (permissions) => {
        gridState.permissions = {
            enabled: true,
            hasValue: !!permissions,
            options: permissions ? permissions : {},
        };
        store.gridStateChanged(gridState);
        showTool(DataGridToolTypes.PERMISSION, false);
    };

    const onHistoryClose = () => {
        showTool(DataGridToolTypes.HISTORY, false);
    };

    const onRestoreDataClose = () => {
        showTool(DataGridToolTypes.RESTORE, false);
    };

    const onRestoreServerDataClose = () => {
        showTool(DataGridToolTypes.RESTORE_SERVER_CHANGES, false);
    };

    const onAttributeManagerSave = (attributes) => {
        gridState.columns = [...gridState.columns.filter((c) => !c.isAttribute), ...attributes];
        store.gridStateChanged(gridState);
        showTool(DataGridToolTypes.ATTRIBUTE_MANAGER, false);
    };

    const loadDataOnReload = () => {
        showTool(DataGridToolTypes.RESTORE, false);
        showTool(DataGridToolTypes.RESTORE_SERVER_CHANGES, false);
        console.log('loadDataOnReload');
        setData(restoreData[data.id]);
        onLoad && onLoad({ data: restoreData[data.id] });
    };

    const saveChangesToBrowser = () => {
        let newSessionData = restoreData;
        newSessionData[params.id] = data;
        setRestoreData(newSessionData);
    };

    const clearBrowserData = () => {
        let newSessionData = restoreData;
        delete newSessionData[params.id];
        setRestoreData(newSessionData);
    };

    const clearDataOnReload = () => {
        showTool(DataGridToolTypes.RESTORE, false);
        showTool(DataGridToolTypes.RESTORE_SERVER_CHANGES, false);
        clearBrowserData();
    };

    const getPopups = () => {
        return (
            <>
                {toolsVisibility.ATTRIBUTE_MANAGER && (
                    <AttributeManagerPopup
                        columns={gridState.columns}
                        onSave={onAttributeManagerSave}
                        onClose={() => showTool(DataGridToolTypes.ATTRIBUTE_MANAGER, false)}
                    />
                )}
                {toolsVisibility.FAVOURITES && (
                    <FovouritePopup
                        columns={gridState.columns}
                        onClose={() => showTool(DataGridToolTypes.FAVOURITES, false)}
                    />
                )}
                {toolsVisibility.SHARES && (
                    <SharePopup
                        columns={gridState.columns}
                        onClose={() => showTool(DataGridToolTypes.SHARES, false)}
                    />
                )}
                {toolsVisibility.PERMISSION && (
                    <PermissionPopup
                        columns={gridState.columns}
                        value={gridState.permissions.options}
                        onSave={onPermissionsSave}
                        onClose={() => showTool(DataGridToolTypes.PERMISSION, false)}
                    />
                )}
                {toolsVisibility.HISTORY && (
                    <HistoryPopup
                        columns={gridState.columns}
                        value={gridState.permissions.options}
                        onClose={onHistoryClose}
                    />
                )}
                {toolsVisibility.RESTORE && (
                    <RestoreDataFromSession
                        onClose={onRestoreDataClose}
                        customFooter={
                            <>
                                <Button primary label="YES" onClick={loadDataOnReload} />
                                <Button label="NO" onClick={clearDataOnReload} />
                            </>
                        }
                    />
                )}
                {toolsVisibility.RESTORE_SERVER_CHANGES && (
                    <RestoreDataFromSession
                        onClose={onRestoreServerDataClose}
                        customFooter={
                            <>
                                <Button primary label="YES" onClick={loadDataOnReload} />
                                <Button label="NO" onClick={clearDataOnReload} />
                            </>
                        }
                    />
                )}
            </>
        );
    };

    const getMorePopoverContent = () => {
        return (
            <div className="edit-page-more-popover-content">
                {enableDublicate && (
                    <div className="edit-page-more-popover-line-item">
                        <IconButton
                            name="content_copy"
                            tooltip={'Manage Attributes'}
                            className="action attribute-management"
                        />
                        Duplicate Record
                    </div>
                )}
                {enableSaveAsDraft && (
                    <div className="edit-page-more-popover-line-item">
                        <IconButton
                            name="variables"
                            tooltip={'Manage Attributes'}
                            className="action attribute-management"
                        />
                        Save As Module
                    </div>
                )}
                {enableSaveAsTemplate && (
                    <div className="edit-page-more-popover-line-item">
                        <IconButton
                            name="database"
                            tooltip={'Saves as template for future usage'}
                            className="action attribute-management"
                        />
                        Save As Template
                    </div>
                )}
                {enableAttributes && (
                    <div className="edit-page-more-popover-line-item">
                        <IconButton
                            name="edit_attributes"
                            tooltip={'Manage Attributes'}
                            className="action attribute-management"
                        />
                        Manage Attributes
                    </div>
                )}
                <div className="edit-page-more-popover-line-item" onClick={() => exportData()}>
                    <IconButton
                        name="download"
                        tooltip={'Export Excel Data'}
                        className="action export"
                    />
                    Export Excel Data
                </div>
                <div
                    className="edit-page-more-popover-line-item"
                    onClick={() => showTool(DataGridToolTypes.PERMISSION, true)}
                >
                    <IconButton name="key" tooltip={'Permissions'} className="action permission" />
                    Manage Permissions
                </div>
            </div>
        );
    };

    let canLoad = false;
    if (params.id !== 'new' && loaded) {
        canLoad = true;
    }
    if (params.id === 'new') {
        canLoad = true;
    }

    const middleContent = () => {
        return (
            <>
                <div className="main-content">{props.children}</div>
                {enableAttributes && <div className="hr" />}
                {enableAttributes &&
                    gridState.columns
                        .filter((c) => c.isAttribute)
                        .map((c) => (
                            <Input
                                key={c.field}
                                type="text"
                                className="attribute-component"
                                placeholder={c.title}
                                value={(data.props || {})[c.field]}
                                onChange={(value) => setRecordProps(c.field, value)}
                            />
                        ))}
            </>
        );
    };

    const leftContent = () => {
        return <div className="leftbar sidebar">{leftBarContent}</div>;
    };

    const rightContent = () => {
        return (
            <div className="rightbar sidebar">
                {enableTagging && (
                    <Card header={rightBarTitle} noPadding>
                        <TagSelect
                            placeholder="Add customer tag"
                            className="tag-select"
                            useLabel={false}
                            onChange={(value) => {
                                setRecord('tags', value);
                            }}
                            tags={data.tags}
                        />
                    </Card>
                )}
                {rightBarContent}
            </div>
        );
    };

    const recordSelectorContent = () => {
        return (
            <Card enableScroll={true} header={props.recordSelectorHeader} className="record-select">
                {props.recordSelector}
            </Card>
        );
    };

    const getContent = () => {
        return (
            <>
                {props.disableCard ? (
                    <div className="info">{middleContent()}</div>
                ) : (
                    <Card enableScroll={true} header={cardHeader} className="info">
                        {middleContent()}
                    </Card>
                )}
                {props.recordSelector && recordSelectorContent()}
                {leftBarContent && leftContent()}
                {(rightBarContent || enableTagging) && rightContent()}
            </>
        );
    };

    const openMore = (e) => {
        tmo.ui.popover.open({
            opener: e.target,
            content: getMorePopoverContent(),
            enableBgOverlay: true,
        });
    };

    const leftOpenerClicked = (e) => {
        setRightPanelOpened(false);
        setLeftPanelOpened(true);
        tmo.ui.global.disablePageScroll();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const rightOpenerClicked = (e) => {
        setRightPanelOpened(true);
        setLeftPanelOpened(false);
        tmo.ui.global.disablePageScroll();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const closeAllPanels = (e) => {
        setRightPanelOpened(false);
        setLeftPanelOpened(false);
        tmo.ui.global.enablePageScroll();
    };


    const goBack = (e) => {
        if (props.onGoBack) {
            props.onGoBack();
        } else {
            
           if(previousLocation && (previousLocation.pathname == location.pathname || previousLocation.pathname.endsWith('new'))){
            navigate(-1);
            setTimeout(()=>{
                if(previousLocation && (previousLocation.pathname == location.pathname || previousLocation.pathname.endsWith('new'))){
                    navigate(-1);
                }
            },300);
           }
           else{
            navigate(-1);
           }
           
        }
    };

    return (
        <div className={className}>
            {getPopups()}
            {data.deleted && (
                <div className="deleted-message">
                    Record has been deleted on {data.deleted_at}. You can rollback this record by
                    clicking:{' '}
                    <Button
                        label="ROLLBACK"
                        icon="settings_backup_restore"
                        size="small"
                        noBorder
                        onClick={rollbackRecordClicked}
                    />
                </div>
            )}
            <div className="edit-page-header">
                <div className="title">
                    <IconButton
                        name="arrow_back"
                        tooltip={'Go back'}
                        className="go-back"
                        onClick={goBack }
                    />
                    {pageTitle || 'Edit record'}
                </div>
                {pageSubSelect && <div className="sub-select">{pageSubSelect}</div>}
                {canLoad && params.id !== 'new' && (
                    <div className="right-side">
                        {!hideReport && (
                            <Button
                                label="VIEW REPORT"
                                icon="bar_chart"
                                size="small"
                                noBorder
                                onClick={viewReportClicked}
                            />
                        )}
                        {!data.deleted && (
                            <Button
                                label="DELETE RECORD"
                                icon="delete"
                                size="small"
                                noBorder
                                onClick={deleteRecordClicked}
                            />
                        )}
                        {props.customRightButton && (
                            <Button
                                label={props.customRightButton.text}
                                icon={props.customRightButton.icon}
                                size="small"
                                noBorder
                                onClick={customRightButtonClicked}
                            />
                        )}
                    </div>
                )}
                <div className="actions">
                    {/* <IconButton
                        name="more_vert"
                        tooltip={'Options'}
                        className="action export"
                        onClick={(e) => openMore(e)}
                    /> */}
                </div>
            </div>

            <div className="edit-page-panel-openers-bg" onClick={closeAllPanels} />
            <div className="edit-page-panel-openers">
                <div className="edit-page-panel-opener-left" onClick={leftOpenerClicked}>
                    <IconButton name="left_panel_open" /> {props.leftBarTitle}
                </div>
                <div className="edit-page-panel-opener-placeholder" />
                <div className="edit-page-panel-opener-right" onClick={rightOpenerClicked}>
                    {props.rightBarTitle} <IconButton name="right_panel_open" />
                </div>
            </div>
            {canLoad && getContent()}
            {!canLoad && (
                <div className="info">
                    <div className="loading-edit-content">
                        <Icon name="cached" />
                        <div className="loading-edit-content-title"> Loading ... </div>
                        <div className="loading-edit-content-desc">
                            Please wait while we are loading application data.
                        </div>
                    </div>
                </div>
            )}
            {canLoad && (
                <div className="edit-page-footer">
                    <div className="edit-page-footer-main">
                        {!props.hideSave && (
                            <Button
                                disabled={props?.disableSave}
                                label="FINISH AND SAVE"
                                icon="save"
                                primary
                                onClick={updateOrDuplicate ? saveDuplicate : save}
                            />
                        )}
                        {!props.hideCancel && (
                            <Button label="CANCEL" noBorder onClick={cancelClicked} />
                        )}
                        {props.buttons}
                    </div>
                    <div className="edit-page-footer-right" />
                </div>
            )}
        </div>
    );
}

export default EditPage;

 
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './CsrAssignPopup.scss'; 
 
 
import tmo from '../../../tmo/tmo.lib';   
import Popup from '../../../components/basic/Popup'; 
import Dropdown from '../../../components/basic/Dropdown';
import CSRSelect from '../../../ShopNow/Components/CSRSelect';

 
function CsrAssignPopup({ onClose, onDone, buttonText}) { 

  // const gotoEdit = (id)=>{
  //   tmo.ui.global.openPage('/customer/edit/'+id);
  // }
   
  const [ selectedUser, setSelectedUser ] = useState(null);
 
  // const emailMediaRef = useRef();

  // const [ data, setData ] = useState([]); 
  const textboxRef = useRef();

  

  // const  loadData =  ()=>{
  //   return new Promise( async (resolve, reject)=>{
  //     var response = await fetch('/data/email_messages.json'); 
  //         var data = await response.json();  
  //         resolve(data); 
  //   });

  // }
    
  // useEffect(() => {   
  //   loadData().then((d)=>{ 
  //     setData(d);
  //   });
 
  // },[]);
 
  
  
  const assignCompleted = ()=>{
    console.log('assignCompleted'); 
    if(onDone){
      onDone({user:selectedUser, note:textboxRef.current.value});
    }
  }

  
   
   
  
  return (
    <Popup   
            blurBackground={true} 
            open={true} 
            disableScroll={true} 
            draggable={true}
            showCloseOnTop={true}
            showNotes={true}
            showButtons={true}
            title={'Select CSR'}
            note={''} 
            showCloseButton={true}
            closeButtonText={buttonText}
            enableCloseOnBackgoundClick={false}  
             onClose={()=>onClose()}     
             onButtonClick={()=>assignCompleted()} 
            // leftFooter={<Button label="Assign To Me" onClick={()=>assignToMe}/>} 


           > 
           <div className='assign-popup-content'>

              <div className='assign-popup-buttons'>

                 
                  <CSRSelect placeholder={'Shopnow User To Assign This Conversation'}  className="csr-select" useLabel={true} isMultiSelect={false} value={1} onChange={(items)=>setSelectedUser(items)} />
 
{/*  
                  <Dropdown placeholder={'Shopnow User To Assign This Conversation'} 
                    
                    useLabel={true} 
                      valueField="id" 
                      labelField="name"  
                      options={[]} 
                      onChange={({items, value})=>{setSelectedUser(items)}}
                      selectOptions={{closeOnSelect:true, isMultiSelect:false }} 
                      searchOptions={{ enable:true,  searchOnServer:true, onSearch:loadUserDropdownData }}
                      customTemplate={({label, value, data})=>{
                        return <div className='user-avatar'>
                          <img src={data.image} />
                          <div className="info">
                            <div className="name">{label}</div>
                            <div className="city">{data.department}</div>
                          </div>
                        </div>
                      }}
                      showSelectedItemsFullWidth={true}
                      showOnlySelectedItemCount={true}
                      > 
                  </Dropdown> */}
                  
                  {/* <Button label="Select User To Assign" primary={true}></Button> */}
                  {/* <Button label="Assign To Me"></Button> */}
               </div>
              <textarea className='assign-note' placeholder='Write a note' ref={textboxRef} />
            </div>
        </Popup> ); 
  


 
}

export default CsrAssignPopup;

import Icon from './Icon';
import './ListItemSwitchGroup.scss';



import React, { Component, useEffect, useRef, useState } from 'react';
import Switch from './Switch';
import Check from './Check';
import ListItem from './ListItem';
 
     

export   function ListItemSwitchGroup(props) { 
   
  let {label, propName, renderParentSwitch, onlyOneChildActive, className } = props;
  
  const [updater, setUpdater]  = useState(0);

  const [data, setData] = useState(props.data);


  useEffect(() => {  
    setData(props.data); 
  }, [props.data]);

  const onChange=(switchValue)=>{
    data[propName]=switchValue;  

    console.log('ListItemSwitchGroup onChange', propName,  data[propName]);
    // if(!switchValue){ 
    //   children.forEach(c=>{
    //     c.value=false;
    //   });
    // }

    if(props.onChange){
      props.onChange(data, data[propName]);
    }

    // setUpdater(updater+1);
    setData({...data});
  }


  const onChildChange=( childPropName, childValue)=>{
    console.log('onChildChange', data, childPropName);
    let children = React.Children.toArray(props.children);
     

    if(!childValue && onlyOneChildActive && !children.filter(c=>c.props.propName!=childPropName && data[c.props.propName]).length){
        childValue = true;
        console.log('all false',childPropName, data[childPropName], childValue);
    }

    data[childPropName] = childValue;

    if(renderParentSwitch && !data[propName]){
      data[childPropName] = false;
      // setUpdater(updater+1);
      
      setData({...data});
      return; 
    }

   
    if(onlyOneChildActive && childValue){
      children.forEach(c=>{
        if(c.props.propName!=childPropName && data[c.props.propName]){
          data[c.props.propName] = false;
        }
      }); 
    }
   

    console.log('onChildChange',childValue, data[childPropName]);
    //childData[childPropName]=childValue; 
    if(props.onChange){
      props.onChange(data, data[childPropName]);
    } 

    setData({...data});
    // setUpdater(updater+1);
  }

  const updateChildrenWithProps = React.Children.toArray(props.children).map( 
    (child, i) => { 
      // let cdata =  data;
      if(propName){
        data[child.props.propName] = data[propName]?data[child.props.propName]:false;
      }
      // console.log(' child.props.data[child.props.propName]', cdata[child.props.propName]);
      return React.cloneElement(child, {...child.props, data:data, propName:child.props.propName,  onChange:(childData, childValue)=>onChildChange( child.props.propName, childValue) } );
    }
  );

  return ( 
      <div  className={'list-item-switch-group '+(className || '')} >
         {renderParentSwitch && 
          <ListItem label={label}
            switch={true}   
            value={data[propName]} 
            onChange={(switchValue) => onChange(switchValue) } />
         }
         {props.children && renderParentSwitch ? 
              <div className={'list-item-switch-group-children' + (data[propName] ?' enabled ':' disabled ')}>
                {updateChildrenWithProps}
              </div>
              :
              updateChildrenWithProps
         }
      </div>
  );
}


export   function ListItemSwitchGroupItem(props) { 
   
  let {data, label, propName, className } = props;
   
  // console.log('ListItemSwitchGroupItem', data[propName]);

  const onChange=(switchValue)=>{
     console.log('ListItemSwitchGroupItem onChange', switchValue);
  
    data[propName]=switchValue; 
    if(props.onChange){
      props.onChange(data, data[propName]);
    } 
  }


  return ( <ListItem label={label}
            switch 
            value={data[propName]} 
            onChange={(switchValue) => onChange(switchValue) } />
            
  );
}



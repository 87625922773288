import React, { useCallback, useEffect, useState } from 'react';

import tmoLib from '../../tmo/tmo.lib';    
import './OverviewCard.WorldMap.scss'; 


 import { isEmpty } from '../../tmo/tmo.utils';  
import Icon from './Icon';
import AnalyticsWorldMap from '../../ShopNow/Components/Map/AnalyticsWorldMap';
import Wizard from '../../ShopNow/Components/Overview/templates/shared/Wizard';
import { TIME_FRAME } from '../../ShopNow/Components/Report/Filters/utils';



function OverviewCardWorldMap({
    config,
    data,
    onTimeRangeChanged,
    timeframe,
    customTimeframe,
    cardIndex,
    onRemoveCard,
    onAddCard,
    applications,
    chart
}) {
    const [location, setLocation] = useState(null);
    const [mapData, setMapData] = useState(data);
    const [isMock, setIsMock] = useState(false);

    const handleLocationSelection = async (val) => {
        setLocation(val);
    };

    const gotoUrl = (url) => tmoLib.ui.global.openPage(url);

    const renderEmptyState = () => {
        if (config?.mock && !isMock) {
            setIsMock(true);
            setMapData(config?.mock);
            return null;
        }

        return (
            <div className="empty-state">
                <Icon name="bar_chart" className="empty-state-icon" />
                <div className="empty-state-text">
                    {config?.emptyStateText || 'No data available'}
                </div>
            </div>
        );
    };

    const isDataEmpty = useCallback(
        () => data?.chart_model.every((model) => isEmpty(model?.datasets)),
        [data?.chart_model]
    );

    useEffect(() => {
        if (!isDataEmpty()) {
            setMapData(data);
            if (isMock) {
                setIsMock(false);
            }
        } else {
            if (config.mock && !isMock) {
                setIsMock(true);
                setMapData(config.mock);
            }
        }
    }, [data]);

   
    return ( <>
            {isMock && <Wizard config={config} />}
            
            {!isDataEmpty() || isMock ? (
                <AnalyticsWorldMap
                    data={mapData?.chart_model}
                    location={location}
                    onLocationSelected={handleLocationSelection}
                    params={{
                        application_ids: applications,
                        timeframe: timeframe || TIME_FRAME.ALL,
                        customTimeframe,
                    }}
                />
            ) : (
                renderEmptyState()
            )}
        </>
    );
}

export default OverviewCardWorldMap;

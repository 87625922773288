 
 import React, { Component, useRef, useState, useEffect, forwardRef, useImperativeHandle }  from 'react';
    
 import  './EmailMedias.scss'; 
 
 
import tmo from '../../../tmo/tmo.lib';  
import EmailMessageContent from './EmailMessageContent';
import IconButton from '../../../components/basic/IconButton';
import MediaGallery from '../../../components/basic/MediaGallery';
import { EmailStore } from './Email.Store';
import { EmailData } from './Email.Data';


const EmailMedias=forwardRef(({}, ref) => { 

  useImperativeHandle(ref, () => ({
    show:show
  }));

  let currentSession = EmailStore.currentSession.get();

  const close = ()=>{
    document.body.className = document.body.className.replace('disable-scroll',' ');
    setVisible(false);
  }
   
  
   
  const show = async (message)=>{
    if(tmo.helpers.isMobile()){
      if(document.body.className.indexOf('disable-scroll')<=-1){ 
          document.body.className+=' disable-scroll ';
      }
    }
    let defaultSelectedMediaIndex = await EmailData.loadMedias({selectedMediaId:message.id});
    
    setVisible(true);
    setSelectedMediaIndex(defaultSelectedMediaIndex);
    // setMedias(allMedias);
  }
  const medias =  EmailStore.currentMedias.get();

  const [ visible, setVisible ] = useState(false);
  // const [ medias, setMedias ] = useState([]);
  const [ selectedMediaIndex, setSelectedMediaIndex ] = useState([]);
    
  if(!visible){
    return <></>
  }
  return (
    <div className='email-medias'>
      <div className='email-medias-header'>
        <div className='email-medias-title'>
            Medias from : {currentSession.conversation_title}
        </div>
        <IconButton name="download" onClick={()=>window.open('https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/3yNuRud8uFBm5LV9EEWQuWs98aEdoIIDcNZIEkjz0zTzFnC5_HAtBMddSqe-2TcyT_1024.jpeg','_blank')} />
        <IconButton name="close" onClick={()=>close()} />
      </div>
      <div className='email-medias-content'>
      <MediaGallery medias={medias} selectedMediaIndex={selectedMediaIndex} />
      </div>
    </div>
  ) 
 



});

export default EmailMedias;

import React from 'react';

import './Product.List.scss';

import { ListDataGridStore, Navigations } from './Product.GridStore';
import { ProductApi } from './Product.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import { Tab, Tabs } from '../../components/basic/Tab';
import tmoLib from '../../tmo/tmo.lib';
import SimpleTable from '../../ShopNow/Components/TopLevel/SimpleTable';
import { GlobalStore } from '../../GlobalStore';
import AppToolbarHelper from '../../components/basic/AppToolbarHelper';

function ProductList(props) {


    const getChildTables = async ({ data, key, columns }) => {
       
        if (!data.skus?.length) {
            return <></>
        }
        let items = data.skus;
       
        console.log('skus', items);

        let ItemColumns = [
 
            {
                name: 'key',
                title: 'Key',
                template: ({ item, column }) =>  <span className="price">{item.item.key}</span>  
            },
            {
                name: 'name',
                title: 'Name',
                template: ({ item, column }) =>  <span className="price">{item.item.name}</span>  
            },

            {
                name: 'price',
                title: 'Price',
                template: ({ item, column }) => <span className="price-new">
                    {tmoLib.string.readPrice(item, 'price', '0.00', item.currency)} 
                </span>
            },

            {
                name: 'quantity',
                title: 'Quantity'
            },


            {
                name: 'total',
                title: 'Total',
                template: ({ item, column }) => {
                    return   <span className="price">
                        
                                    {item.total!==item.total_old && item.total_old  ?
                                    <span className="price-old">
                                        {tmoLib.string.readPrice(item, 'total_old', '0.00', item.currency)} 
                                    </span>:null}
                                    <span className="price-new">
                                        {tmoLib.string.readPrice(item, 'total', '0.00', item.currency)} 
                                    </span>
                                </span>
    

                }
            }

        ];

        return <div className="order-detail">
            <SimpleTable data={items} columns={ItemColumns} />
            </div> 

    }



    let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let systemFilter = [
        ...AppToolbarHelper.getToolbarAppSelectionForEcommerceFilter(globalFilter, 'application_id'),
        // ...AppToolbarHelper.getToolbarTimeSelectionForEcommerceFilter(globalFilter, 'created_at'),
        ...AppToolbarHelper.getToolbarStoreSelectionForEcommerceFilter(globalFilter, 'store_id')
    ]

  

    return (
        <GridListPage   

        
        renderRowChild={getChildTables}
        hasChildren={true}
        enabledField={'is_active'}
        // tuneDownCondition={(item)=>!item.is_active}
        disableDelete={true}
            store={ListDataGridStore}
            systemFilter={systemFilter}
            onSearch={ProductApi.search}
            onHistory={ProductApi.history}
            onGotoEdit={Navigations.gotoEdit}
        />
    );
}

export default ProductList;

import React from 'react';
import { Link } from 'react-router-dom';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import Icon from '../../components/basic/Icon';
import IconButton from '../../components/basic/IconButton';
import StatusBar from '../../components/basic/StatusBar';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import Sticker from '../../components/basic/Sticker';
import {
    WECHAT_ARTICLE_HEALTH_STATUS,
    WECHAT_ARTICLE_PUBLISH_STATUS,
    WECHAT_ARTICLE_STATUS,
} from './WechatArticle.Api';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import t from '../Localization/i18n';

export const Navigations = {
    gotoEdit: (groupId) => tmo.ui.global.openPage('/wechat/article/edit/' + groupId),
    gotoList: () => tmo.ui.global.openPage('/wechat/articles'),
    gotoView: (id) => tmo.ui.global.openPage('/wechat/article/' + id),
    gotoComments: (id) => tmo.ui.global.openPage(`/wechat/article/${id}/comments`),
    startSend: (id) => tmo.ui.global.openPage('/wechat/article/send/' + id),
};

const createdByOptions = [
    { label: 'ShopNow', value: true },
    { label: 'Third-party', value: false },
];

const statusOptions = [
    { label: 'Processing', value: WECHAT_ARTICLE_STATUS.PROCESSING },
    { label: 'Failed', value: WECHAT_ARTICLE_STATUS.FAILED },
    { label: 'Failed (Sync)', value: WECHAT_ARTICLE_STATUS.FAILED_TO_SYNCRONIZE },
    { label: 'Rendering 1/4', value: WECHAT_ARTICLE_STATUS.CONTENT_RENDERED },
    { label: 'Failed (Content)', value: WECHAT_ARTICLE_STATUS.CONTENT_RENDER_FAILED },
    { label: 'Rendering 2/4', value: WECHAT_ARTICLE_STATUS.READMORE_PREPARED },
    { label: 'Failed (Read More)', value: WECHAT_ARTICLE_STATUS.READMORE_FAILED },
    { label: 'Syncing 3/4', value: WECHAT_ARTICLE_STATUS.READY_TO_SEND },
    { label: 'Ready', value: WECHAT_ARTICLE_STATUS.SYNCRONIZED },
    { label: 'New', value: WECHAT_ARTICLE_STATUS.NEW },
];

const publishOptions = [
    { label: 'Processing', value: WECHAT_ARTICLE_PUBLISH_STATUS.PROCESSING },
    { label: 'Not published', value: WECHAT_ARTICLE_PUBLISH_STATUS.NOT_PUBLISHED },
    { label: 'Published', value: WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED },
    {
        label: 'Published (multiple)',
        value: WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES,
    },
];

export const getArticleStatusSticker = (item, size) => {
    size = size || 'small';

    if (item.is_new) {
        return (
            <Sticker color="lightsalmon" size={size}>
                NEW
            </Sticker>
        );
    }

    if (item.status === WECHAT_ARTICLE_STATUS.CONTENT_RENDERED) {
        return (
            <Sticker color="yellow" size={size}>
                Rendering 1/4
            </Sticker>
        );
    } else if (item.status === WECHAT_ARTICLE_STATUS.CONTENT_RENDER_FAILED) {
        return (
            <Sticker color="red" size={size}>
                Failed (Content)
            </Sticker>
        );
    } else if (item.status === WECHAT_ARTICLE_STATUS.FAILED) {
        return (
            <Sticker color="red" size={size}>
                Failed
            </Sticker>
        );
    } else if (item.status === WECHAT_ARTICLE_STATUS.FAILED_TO_SYNCRONIZE) {
        return (
            <Sticker color="red" size={size}>
                Failed (Sync)
            </Sticker>
        );
    } else if (item.status === WECHAT_ARTICLE_STATUS.PROCESSING) {
        return (
            <Sticker color="gray" size={size}>
                Processing
            </Sticker>
        );
    } else if (item.status === WECHAT_ARTICLE_STATUS.READMORE_FAILED) {
        return (
            <Sticker color="red" size={size}>
                Failed (Read More)
            </Sticker>
        );
    } else if (item.status === WECHAT_ARTICLE_STATUS.READMORE_PREPARED) {
        return (
            <Sticker color="yellow" size={size}>
                Rendering 2/4
            </Sticker>
        );
    } else if (item.status === WECHAT_ARTICLE_STATUS.READY_TO_SEND) {
        return (
            <Sticker color="yellow" size={size}>
                Syncing 3/4
            </Sticker>
        );
    } else if (item.status === WECHAT_ARTICLE_STATUS.SYNCRONIZED) {
        return (
            <Sticker color="blue" size={size}>
                Ready to send
            </Sticker>
        );
    } else {
        return (
            <Sticker color="gray" size={size}>
                Processing
            </Sticker>
        );
    }
};

export const getArticleStatusBar = (item, size, value) => {
    size = size || 'small';

    if (item.is_new) {
        return <StatusBar color="lightsalmon" value={value || 0} size={size} text="NEW" />;
    }

    if (item.status === WECHAT_ARTICLE_STATUS.CONTENT_RENDERED) {
        return <StatusBar color="yellow" value={value || 25} size={size} text="Rendering 1/4" />;
    } else if (item.status === WECHAT_ARTICLE_STATUS.CONTENT_RENDER_FAILED) {
        return <StatusBar color="red" value={0} size={size} text="Failed (Content)" />;
    } else if (item.status === WECHAT_ARTICLE_STATUS.FAILED) {
        return <StatusBar color="red" value={0} size={size} text="Failed" />;
    } else if (item.status === WECHAT_ARTICLE_STATUS.FAILED_TO_SYNCRONIZE) {
        return <StatusBar color="red" value={0} size={size} text="Failed (Sync)" />;
    } else if (item.status === WECHAT_ARTICLE_STATUS.PROCESSING) {
        return <StatusBar color="gray" value={value || 0} size={size} text="Processing" />;
    } else if (item.status === WECHAT_ARTICLE_STATUS.READMORE_FAILED) {
        return <StatusBar color="red" value={0} size={size} text="Failed (Read More)" />;
    } else if (item.status === WECHAT_ARTICLE_STATUS.READMORE_PREPARED) {
        return <StatusBar color="yellow" value={value || 50} size={size} text="Rendering 2/4" />;
    } else if (item.status === WECHAT_ARTICLE_STATUS.READY_TO_SEND) {
        return <StatusBar color="yellow" value={value || 70} size={size} text="Syncing 3/4" />;
    } else if (item.status === WECHAT_ARTICLE_STATUS.SYNCRONIZED) {
        return <StatusBar color="blue" value={value || 100} size={size} text="Ready" />;
    } else {
        return <StatusBar color="gray" value={value || 0} size={size} text="Processing" />;
    }
};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading WechatArticle Report: ' + data.id,
        task_input: { wechat_article_id: data.id },
        related_module_id: 'wechat_article',
        related_record_id: data.id,
    });

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const statusDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: statusOptions,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const createdByDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: createdByOptions,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: false, searchOnServer: false },
};

const publishStatusDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: publishOptions,
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_selected',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Application',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'dropdown',
        noSpan: true,
        disableSort: true,
        key: 'col_application_id',
        width: 220,
        field: 'application_id',
        db_field: 'application_id',
        dropdownProps: appDropdownProps,
        template: ({ item }) => <ApplicationTag application={item.application} />,
        templateChildren: () => <></>,
    },
    {
        title: 'Article Group',
        type: 'text',
        width: 120,
        key: 'col_group_id',
        multiline: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        disableSort: true,
        field: 'group_id',
        db_field: 'group_id',
        template: ({ item }) => <span>{item.group_id}</span>,
        templateChildren: () => <></>,
    },
    {
        title: 'Index',
        type: 'text',
        width: 60,
        key: 'col_group_index',
        disableGrouping: true,
        disableSort: true,
        disableFilter: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'group_index',
        db_field: 'group_index',
        template: ({ item }) => <span>{item.group_index || 1}</span>,
    },
    {
        title: 'Thumb Media',
        type: 'custom',
        width: 150,
        key: 'col_thumb_media',
        disableGrouping: true,
        disableFilter: true,
        disableResize: true,
        disableSort: true,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'thumb_media',
        db_field: 'thumb_media',
        template: ({ item }) => (
            <div className="article-thumb-image-wrapper main-image">
                <img className="article-thumb-image" src={(item.thumb_media || {}).url} />
            </div>
        ),
        templateChildren: ({ item }) => (
            <div className="article-thumb-image-wrapper sub-image">
                <img
                    className="article-thumb-image sub-article"
                    src={(item.thumb_media || {}).url}
                />
            </div>
        ),
    },
    {
        title: 'Options',
        type: 'parent',
        width: 240,
        key: 'col_options',
        disableGrouping: true,
        disableFilter: true,
        disableSort: true,
        index: 5,
        mIndex: 5,
        visible: true,
        mVisible: true,
        field: 'options',
        db_field: 'options',
        children: [
            {
                title: 'Cover Pic',
                type: 'check',
                width: 100,
                key: 'col_4_1',
                disableGrouping: true,
                disableFilter: true,
                centered: true,
                index: 3,
                mIndex: 3,
                visible: true,
                mVisible: true,
                field: 'show_cover_pic',
                db_field: 'show_cover_pic',
            },
            {
                title: 'Comments',
                type: 'check',
                width: 100,
                key: 'col_4_2',
                disableGrouping: true,
                centered: true,
                disableFilter: true,
                index: 4,
                mIndex: 4,
                visible: true,
                mVisible: true,
                field: 'need_open_comment',
                db_field: 'need_open_comment',
            },
            {
                title: 'Only Fans Comment',
                type: 'check',
                width: 100,
                key: 'col_4_3',
                disableGrouping: true,
                index: 5,
                mIndex: 5,
                visible: true,
                mVisible: true,
                centered: true,
                field: 'only_fans_can_comment',
                db_field: 'only_fans_can_comment',
            },
        ],
        template: ({ item }) => (
            <div className="article-options">
                {item.show_cover_pic ? (
                    <div className="option-line">
                        <Icon name="check_box" />
                        Showing Cover Picture
                    </div>
                ) : (
                    <div className="option-line">
                        <Icon name="check_box_outline_blank" />
                        Not Showing Cover Picture
                    </div>
                )}

                {item.need_open_comment ? (
                    <div className="option-line">
                        <Icon name="check_box" />
                        Comments Are Enabled
                    </div>
                ) : (
                    <div className="option-line">
                        <Icon name="check_box_outline_blank" />
                        Comments Are Disabled
                    </div>
                )}

                {item.only_fans_can_comment ? (
                    <div className="option-line">
                        <Icon name="check_box" />
                        Only Followers Can Comment
                    </div>
                ) : (
                    <div className="option-line">
                        <Icon name="check_box_outline_blank" />
                        Everyone Can Comment
                    </div>
                )}

                {item.show_custom_read_more ? (
                    <div className="option-line">
                        <Icon logo name="shopnow" />
                        ShopNow Managed Read More Page
                    </div>
                ) : (
                    <div className="option-line">
                        <Icon name="person" />
                        User defined Read More Page
                    </div>
                )}
            </div>
        ),
    },
    {
        title: 'Title',
        type: 'parent',
        width: 200,
        key: 'col_title',
        disableGrouping: true,
        disableFilter: true,
        multiline: true,
        disableSort: true,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'title',
        db_field: 'title',
        template: ({ item }) => (
            <span>
                <b>{item.title}</b>
                <br />
                {item.digest}
            </span>
        ),
        children: [
            {
                title: 'Title',
                type: 'text',
                width: 100,
                key: 'col_7_1',
                disableGrouping: true,
                disableFilter: false,
                index: 1,
                mIndex: 1,
                visible: true,
                mVisible: true,
                field: 'title',
                db_field: 'title',
            },
            {
                title: 'Digest',
                type: 'text',
                width: 100,
                key: 'col_7_2',
                disableGrouping: true,
                disableFilter: false,
                index: 2,
                mIndex: 2,
                visible: true,
                mVisible: true,
                field: 'digest',
                db_field: 'digest',
            },
        ],
    },
    {
        title: 'Author',
        type: 'text',
        width: 100,
        key: 'col_author',
        disableGrouping: true,
        multiline: true,
        disableSort: true,
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        field: 'author',
        db_field: 'author',
    },
    {
        title: 'Source',
        type: 'dropdown',
        width: 100,
        key: 'col_10',
        disableGrouping: true,
        index: 10,
        mIndex: 10,
        visible: true,
        mVisible: true,
        disableSort: false,
        field: 'created_by_shopnow',
        db_field: 'created_by_shopnow',
        dropdownProps: createdByDropdownProps,
        template: ({ item }) => {
            if (item.created_by_shopnow) {
                return <span>ShopNow</span>;
            } else {
                return <span>Third-party</span>;
            }
        },
    },
    {
        title: 'Status',
        type: 'parent',
        width: 120,
        key: 'col_11',
        disableGrouping: true,
        disableFilter: false,
        disableSort: true,
        index: 11,
        mIndex: 11,
        visible: true,
        mVisible: true,
        field: 'status',
        db_field: 'status',
        dropdownProps: statusDropdownProps,
        template: ({ item }) => {
            let statusEl = getArticleStatusSticker(item, 'small');
            let publishStatusEl;
            let healthStatusEl;

            // if (item.publish_status === WECHAT_ARTICLE_PUBLISH_STATUS.NOT_PUBLISHED) {
            //     publishStatusEl = (
            //         <Sticker color="yellow" size="small">
            //             Not Published
            //         </Sticker>
            //     );
            // } else if (item.publish_status === WECHAT_ARTICLE_PUBLISH_STATUS.PROCESSING) {
            //     publishStatusEl = (
            //         <Sticker color="gray" size="small">
            //             Processing
            //         </Sticker>
            //     );
            // } else 
            
            if (item.publish_status === WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED) {
                publishStatusEl = (
                    <Sticker color="blue" size="small">
                        Published
                    </Sticker>
                );
            } else if (
                item.publish_status === WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES
            ) {
                publishStatusEl = (
                    <Sticker color="blue" size="small">
                        Published (MULT)
                    </Sticker>
                );
            }
            
            // else {
            //     publishStatusEl = (
            //         <Sticker color="yellow" size="small">
            //             Not Published
            //         </Sticker>
            //     );
            // }

            if (item.health === WECHAT_ARTICLE_HEALTH_STATUS.HAS_ISSUES) {
                healthStatusEl = <Icon name="warning" />;
            }

            return (
                <span className="statuses">
                    {healthStatusEl}
                    <br />
                    {statusEl}
                    <br />
                    {publishStatusEl}
                    <br />
                </span>
            );
        },
        templateChildren: () => <></>,
        children: [
            {
                title: 'Status',
                type: 'dropdown',
                width: 100,
                key: 'col_11_1',
                disableGrouping: true,
                disableFilter: false,
                index: 1,
                mIndex: 1,
                visible: true,
                mVisible: true,
                field: 'status',
                db_field: 'status',
                dropdownProps: statusDropdownProps,
            },
            {
                title: 'Publish status',
                type: 'dropdown',
                width: 100,
                key: 'col_11_2',
                disableGrouping: true,
                disableFilter: false,
                index: 2,
                mIndex: 2,
                visible: true,
                mVisible: true,
                field: 'publish_status',
                db_field: 'publish_status',
                dropdownProps: publishStatusDropdownProps,
            },
            {
                title: 'Health',
                type: 'dropdown',
                width: 100,
                key: 'col_11_3',
                disableGrouping: true,
                disableFilter: true,
                index: 1,
                mIndex: 1,
                visible: true,
                mVisible: true,
                field: 'health',
                db_field: 'health',
            },
        ],
    },
    {
        title: 'Read More Url',
        type: 'link',
        width: 100,
        key: 'col_15',
        disableGrouping: true,
        index: 15,
        mIndex: 15,
        visible: false,
        mVisible: false,
        disableSort: true,
        multiline: true,
        field: 'content_source_url',
        db_field: 'content_source_url',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 100,
        key: 'col_updated_at',
        disableGrouping: true,
        disableSort: true,
        index: 18,
        mIndex: 18,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: 'Created At',
        type: 'datetime',
        width: 100,
        key: 'col_19',
        disableGrouping: true,
        disableSort: true,
        index: 19,
        mIndex: 19,
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },
    {
        title: '#',
        index: 20,
        mIndex: 20,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_20',
        width: 120,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        hideOnSelect: true,
        multiline: true,
        template: ({ item, fireEvent, isChildRow }) => {
            if (isChildRow) {
                return (
                    <>
                        {/* <IconButton className="grid-open-button" name="content_copy"  onClick={()=>Navigations.gotoEdit(item.id)}   />   */}
                        {/* {item.sync_failed==false && item.published==false && <IconButton className="grid-edit-button" name="send" onClick={()=>fireEvent('send', item)}  />} */}
                        {(item.publish_status === WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED ||
                            item.publish_status ===
                                WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES) &&
                            item.need_open_comment && (
                                <IconButton
                                    className="grid-open-button"
                                    name="comment"
                                    onClick={() => Navigations.gotoComments(item.id)}
                                />
                            )}
                        {(item.publish_status === WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED ||
                            item.publish_status ===
                                WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES) && (
                            <IconButton
                                className="grid-open-button"
                                name="open_in_new"
                                onClick={() => Navigations.gotoView(item.id)}
                            />
                        )}
                    </>
                );
            }
            return (
                <>
                    {item.sync_failed == null && item.published === false && (
                        <IconButton
                            className="grid-edit-button"
                            name="edit"
                            onClick={() => Navigations.gotoEdit(item.group_id)}
                        />
                    )}
                    <IconButton
                        className="grid-open-button"
                        name="edit"
                        onClick={() => Navigations.gotoEdit(item.group_id)}
                    />
                    {item.status === WECHAT_ARTICLE_STATUS.SYNCRONIZED && (
                        <Link
                            title="Create campaign"
                            alt="Create campaign"
                            to="/marketing/campaign/edit/new"
                        >
                            <IconButton className="grid-edit-button" name="send" />
                        </Link>
                    )}
                    {(item.publish_status === WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED ||
                        item.publish_status ===
                            WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES) && (
                        <IconButton
                            className="grid-edit-button"
                            name="search_check"
                            onClick={() => Navigations.gotoView(item.id)}
                        />
                    )}
                    {(item.publish_status === WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED ||
                        item.publish_status ===
                            WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES) && (
                        <IconButton
                            className="grid-open-button"
                            name="open_in_new"
                            onClick={() => Navigations.gotoView(item.id)}
                        />
                    )}
                    {(item.publish_status === WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED ||
                        item.publish_status ===
                            WECHAT_ARTICLE_PUBLISH_STATUS.PUBLISHED_MULTIPLE_TIMES) &&
                        !!item.need_open_comment && (
                            <IconButton
                                className="grid-open-button"
                                name="comment"
                                notify={item.has_new_comments}
                                onClick={() => Navigations.gotoComments(item.group_id)}
                            />
                        )}
                </>
            );
        },
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'wechat_article_grid_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display Converted',
        simpleLabel: 'ShopNow WechatArticle',
        key: 'converted',
        value: false,
    },
    {
        label: 'Display Unconverted',
        simpleLabel: 'Wechat WechatArticle',
        key: 'unconverted',
        value: false,
    },
    {
        label: 'Display Best Performing',
        simpleLabel: 'Weibo WechatArticle',
        key: 'best_perform',
        value: false,
    },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null, t);

ListDataGridStore.beforeLoad = () => {
    appDropdownProps.options = Globals.currentUser.applications.filter((item) =>
        item.type.includes('wx')
    ) || {
        id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9326',
        name: 'Wechat Official Account',
    };
};

 
import { GlobalStore } from '../../GlobalStore';
import { getTimeRangeDates, TIME_FRAME } from '../../ShopNow/Components/Report/Filters/utils';
 
export default class AppToolbarHelper  { 
         

    static getToolbarAppSelectionForAnalyticsFilter  (filter, fieldName) {
       
        let systemFilter  = [];
        let applicationIds =  filter.application_ids;

        if(applicationIds?.length){
            let accId = window.currentAccount.id
            applicationIds =  window.currentUser.analytics_apps.filter(a=>a.account_id==accId && applicationIds.indexOf(a.shopnow_application_id)>-1).map(a=>a.id);
        }
        else{
            let accId = window.currentAccount.id
            applicationIds =  window.currentUser.analytics_apps.filter(a=>a.account_id==accId).map(a=>a.id);
            
        } 
        
        systemFilter.push({
            field: fieldName,
            value: applicationIds
        }); 

        return systemFilter;


    }


    static getToolbarAppSelectionForEcommerceFilter  (filter, fieldName) {
       
        let systemFilter  = [];
        let applicationIds =  filter.application_ids;

        if(applicationIds?.length){
            let accId = window.currentAccount.id
            applicationIds =  window.currentUser.applications.filter(a=>a.account_id==accId && applicationIds.indexOf(a.id)>-1).map(a=>a.id);
        }
        else{
            let accId = window.currentAccount.id
            applicationIds =  window.currentUser.applications.filter(a=>a.account_id==accId).map(a=>a.id);
            
        } 
        
        systemFilter.push({
            field: fieldName,
            value: applicationIds
        }); 

        return systemFilter;


    }



    static getToolbarStoreSelectionForEcommerceFilter  (filter, fieldName) {
       
        let systemFilter  = [];
        let storeIds =  filter.store_ids;

        if(storeIds?.length){ 
            systemFilter.push({
                field: fieldName,
                value: storeIds
            }); 
        }

        return systemFilter;


    }





    static getToolbarTimeSelectionForEcommerceFilter  (filter, fieldName)  {
       
        console.log('filter', filter);
        let systemFilter  = [];
      
      
        const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.get();

        console.log('filter.timeframe', filter.timeframe);

        if(filter.timeframe!=TIME_FRAME.ALL){

            let  { current_start, current_end } =  getTimeRangeDates(filter.timeframe, dateCyclesLastFour);
            systemFilter.push({
                field: fieldName,
                op: 'gte',
                value: (new Date(current_start)),
            });   

        console.log('systemFilter', systemFilter);

            systemFilter.push({
                field: fieldName,
                op: 'lte',
                value: (new Date(current_end)),
            });   

        } 

        console.log('systemFilter re', systemFilter);
        return systemFilter;


    }


    static getToolbarTimeSelectionForAnalyticsFilter  (filter, fieldName)  {
       
        console.log('filter', filter);
        let systemFilter  = [];
        const convertDateToAnalyticsTime = (time) => {
            return Math.floor((time - 1640966400000) / 1000);
        }; 

      
        const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.get();

        console.log('filter.timeframe', filter.timeframe);

        if(filter.timeframe!=TIME_FRAME.ALL){

            let  { current_start, current_end } =  getTimeRangeDates(filter.timeframe, dateCyclesLastFour);
            systemFilter.push({
                field: fieldName,
                op: 'gte',
                value: convertDateToAnalyticsTime(Date.parse(current_start)),
            });   

        console.log('systemFilter', systemFilter);

            systemFilter.push({
                field: fieldName,
                op: 'lte',
                value: convertDateToAnalyticsTime(Date.parse(current_end)),
            });   

        } 

        console.log('systemFilter re', systemFilter);
        return systemFilter;


    }
    


    static getToolbarBotSelectionForAnalyticsFilter  (filter, botFieldName, suspiciousFieldName)  {
       
        console.log('filter', filter);
        let systemFilter  = []; 
      
        if(filter.exclude_bots){
            systemFilter.push({
                field: botFieldName, 
                value: false
            });   
        }
        

        if(filter.exclude_suspicious){
            systemFilter.push({
                field: suspiciousFieldName, 
                value: false
            });   
        }
        
        console.log('systemFilter re', systemFilter);
        return systemFilter;


    }
    

 
} 
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import styled from 'styled-components';

import tmoLib from '../../tmo/tmo.lib';
import BoundInput from './BoundInput';
import IconButton from './IconButton';
import './Input.scss';

const StyledHideValueButton = styled(IconButton)`
    margin-left: -30px;
    font-size: 120%;
    opacity: ${(props) => (props.$hiddenValue ? 1 : 0.5)};
`;

const Input = forwardRef((props, ref) => {
    const { clearable = true, hideValue = false } = props;
    const calculateSizeInByte = (val) => {
        let byteSize = 0;
        for (let c of (val || '').toString())
            if (c.charCodeAt(0) > 255) byteSize += 2;
            else byteSize++;

        return byteSize;
    };
    const [value, setValue] = useState(props.value !== undefined ? props.value : '');
    const [sizeInBytes, setSizeInBytes] = useState(calculateSizeInByte(props.value));
    const [hiddenValue, setHiddenValue] = useState(false);

    useImperativeHandle(ref, () => ({
        value,
    }));

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }


    const onBlur = (e) => {
        if (props.onBlur) {
            props.onBlur(e.target.value);
        }
    }
    const onChange = (e) => {
        if (props.type === 'number') {
            let val = 0,
                maxVal = 0,
                minVal = 0;
            try {
                val = parseInt(e.target.value);
            } catch (ex) {}
            try {
                maxVal = props.maxValue
                    ? parseInt(props.maxValue)
                    : props.long
                    ? 999999999999999999
                    : 2147483640;
            } catch (ex) {}
            try {
                minVal = props.minValue
                    ? parseInt(props.minValue)
                    : props.long
                    ? -999999999999999999
                    : -2147483640;
            } catch (ex) {}
            if (val < minVal || val > maxVal) {
                e.target.value = 0;
            }
        }

        if (props.type === 'text' && props.maxByte) {
            let val = e.target.value;
            let byteSize = 0;
            for (let c of val) {
                if (c.charCodeAt(0) > 255) {
                    byteSize += 2;
                } else {
                    byteSize++;
                }
            }
            if (byteSize > props.maxByte) {
                e.target.value = value;
            } else {
                setSizeInBytes(byteSize);
                setValue(e.target.value);
            }
        }

        onValueChanged(e.target.value);
    };

    const onValueChanged = (val) => {
        if (props.onChange) {
            props.onChange(val);
        } else {
            setValue(val);
        }
    };

    const clear = () => {
        let val = '';
        if (props.type === 'text') {
            val = '';
        }
        if (props.type === 'number') {
            if (props.minValue) {
                val = props.minValue;
            } else {
                val = 0;
            }
        }
        props.onChange && props.onChange(val);
        setValue(val);
    };

    const toggleHiddenValue = () => setHiddenValue(!hiddenValue);

    const bindValue = () => {
        tmoLib.variable.open({
            type: 'text',
            value: inputValue,
            onChange: (val) => onValueChanged(val),
        });
    };

    let isVariable = false;

    let inputValue = '';
    if (props.value === undefined || props.value === null || !props.onChange) {
        inputValue = value || '';
    } else {
        inputValue = props.value;
    }
    if (inputValue && inputValue.indexOf && inputValue.indexOf('{{') > -1) {
        isVariable = true;
    }

    if (isVariable) {
        return (
            <BoundInput
                value={inputValue}
                onChange={onValueChanged}
                type="text"
                placeholder={props.placeholder}
            />
        );

        // (
        //       <div className={(props.className || '') + ' input bindable has-value '+(props.error?' error ':'') + (props.disabled?' disabled ':'')} data-error={props.error}>
        //               <div  dangerouslySetInnerHTML={{__html: inputValue }} className='inline-variables' ></div>
        //               <label className='with-value' >
        //                 {props.placeholder}
        //                 {props.maxByte && <span>{sizeInBytes+' / '+props.maxByte}</span>}
        //               </label>
        //               <IconButton name="close"  className='clear-button' onClick={clear} />
        //               <IconButton name="commit" tooltip="Bind a dynamic value"  className='variable-button'  onClick={bindValue}/>
        //       </div>
        //   );
    }

    return (
        <div
            className={
                (props.className || '') +
                ' input ' +
                (props.error ? ' error ' : '') +
                (props.disabled ? ' disabled ' : '') +
                (props.bindable ? ' bindable ' : '') +
                (inputValue ? ' has-value ' : '')
            }
            data-error={props.error}
        >
            <input
                placeholder=" "
                maxLength={props.maxLength || 1000000}
                disabled={props.disabled}
                type={hiddenValue && hideValue ? 'password' : props.type || 'number'}
                min={props.minValue}
                max={props.maxValue}
                value={inputValue}
                name={props.name}
                onChange={onChange}
                onBlur={onBlur}
                autoFocus={props.autoFocus}
            />
            {props.placeholder && (
                <label htmlFor={props.name}>
                    {props.placeholder}
                    {props.maxByte && <span>{sizeInBytes + ' / ' + props.maxByte}</span>}
                </label>
            )}
            {clearable && <IconButton name="close" className="clear-button" onClick={clear} />}
            {hideValue && (
                <StyledHideValueButton
                    $hiddenValue={hiddenValue}
                    name="visibility"
                    onClick={toggleHiddenValue}
                />
            )}
            {props.bindable && (
                <IconButton
                    name="commit"
                    tooltip="Bind a dynamic value"
                    className="variable-button"
                    onClick={bindValue}
                />
            )}
        </div>
    );
});

export default Input;

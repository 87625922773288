import React from 'react';

import { ListDataGridStore, Navigations } from './User.GridStore';
import { UserApi } from './User.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import { Tabs, Tab } from '../../components/basic/Tab';
import { SYSTEM_ACTION_TYPES } from '../Session/Session.List';
import tmoLib from '../../tmo/tmo.lib';
import SimpleTable from '../../ShopNow/Components/TopLevel/SimpleTable';
import { GlobalStore } from '../../GlobalStore';
import AppToolbarHelper from '../../components/basic/AppToolbarHelper';

function UserList(props) {



    const getChildTables = async ({ data, key, columns }) => {
        let user = await UserApi.detail({ id: data.id });

        return <div className="session-actions"><Tabs>
            <Tab title="SESSIONS" forceRenderOnTabChange>
                <div className="sessions">
                    {getSessionList({ data: user, key, columns })}
                </div>
            </Tab>
            <Tab title="ACTIONS" forceRenderOnTabChange>
                <div className="actions">
                    {getActionList({ data: user, key, columns })}
                </div>
            </Tab>
            <Tab title="ITEMS" forceRenderOnTabChange>
                <div className="items">
                    {getItemList({ data: user, key, columns })}
                </div>
            </Tab>
        </Tabs></div>

    }

    const getSessionList = ({ data, key, columns }) => {

        console.log(data.sessions);

        if (!data.sessions?.length) {
            return <></>
        }

        let SessionColumns = [
            {
                name: 'id',
                title: 'Id'
            },
            {
                name: 'visitor_id',
                title: 'visitor_id'
            },
            {
                name: 'referral_code',
                title: 'referral code'
            },

            {
                name: 'created_at',
                title: 'Created At',
                template: ({ item, column }) => {
                    return tmoLib.string.printDateTime(item.created_at)
                }
            },
            {
                name: 'ended_at',
                title: 'Ended At',
                template: ({ item, column }) => {
                    return item.ended_at < new Date('2000-01-01') ? 'N/A' : tmoLib.string.printDateTime(item.ended_at)
                }
            },
            {
                name: 'referrer',
                title: 'referrer'
            },
            {
                name: 'entry_url',
                title: 'entry url'
            }


        ];

        return <SimpleTable data={data.sessions} columns={SessionColumns} />


    }





    const getActionList = ({ data, key, columns }) => {

        if (!data.actions?.length) {
            return <>No Actions</>
        }
        let actions = [];
        for (const action of data.actions) {



            if (
                actions[actions.length - 1]?.system_type === SYSTEM_ACTION_TYPES.START.system_type &&
                action.system_type === SYSTEM_ACTION_TYPES.VISIT.system_type
            ) {
                actions[actions.length - 1] = action;
                actions[actions.length - 1].type = actions[actions.length - 1].type || {};
                actions[actions.length - 1].type.name = 'START/VISIT';
            }
            else if (
                actions[actions.length - 1]?.system_type === SYSTEM_ACTION_TYPES.VISIT.system_type &&
                action.system_type === SYSTEM_ACTION_TYPES.RESUME.system_type
            ) {
            }
            else if (
                actions[actions.length - 1]?.system_type === SYSTEM_ACTION_TYPES.PAUSE.system_type &&
                action.system_type === SYSTEM_ACTION_TYPES.END.system_type
            ) {
                actions[actions.length - 1] = action;
            }
            else if (
                actions[actions.length - 1]?.system_type === action.system_type &&
                actions[actions.length - 1]?.system_type === action.system_type &&
                JSON.stringify({ ...actions[actions.length - 1], id: null, created_at: null }) === JSON.stringify({ ...action, id: null, created_at: null })
            ) {
                //actions[actions.length-1] = action;
            }
            else {
                actions.push(action);
            }

        }

        actions.sort((a, b) => a.id > b.id ? -1 : 1);

        let ActionColumns = [
            {
                name: 'type',
                title: 'Type',
                template: ({ item, column }) => {
                    return item?.type?.name
                }
            },
            {
                name: 'group_name',
                title: 'Group Name',
                template: ({ item, column }) => {
                    return item.type && <>
                        {item?.type?.is_membership_action && <div>Membership Action</div>}
                        {item?.type?.is_conversion_action && <div>Conversion</div>}
                        {item?.type?.is_purchase_action && <div>Purchase Action</div>}
                        {item?.type?.system_type ? (
                            <div>System Defined Action</div>
                        ) : (
                            <div>User Defined Action</div>
                        )}
                    </>
                }
            },
            {
                name: 'group',
                title: 'Group'
            },
            {
                name: 'interest',
                title: 'Interest',
                template: ({ item, column }) => {
                    return item?.interest?.label
                }
            },
            {
                name: 'outcome',
                title: 'Outcome',
                template: ({ item, column }) => {
                    return item?.outcome?.label
                }
            },
            {
                name: 'cost',
                title: 'Cost'
            },
            {
                name: 'income',
                title: 'Income'
            },
            {
                name: 'tag1',
                title: 'Tag',
                template: ({ item, column }) => {
                    return item?.tag1?.label
                }
            },
            {
                name: 'created_at',
                title: 'Created At',
                template: ({ item, column }) => {
                    return tmoLib.string.printDateTime(item.created_at)
                }
            },
            {
                name: 'items',
                title: 'Items',
                template: ({ item, column }) => {
                    return (item?.items || []).map(i => {
                        if (i.url?.length) {
                            return <div className='action-item'>
                                <a href={i.url} target='_blank'>{tmoLib.helpers.readOr(i, 'name', 'N/A')}</a>
                            </div>
                        }
                        else {
                            return <div className='action-item'>{tmoLib.helpers.readOr(i, 'name', 'N/A')}</div>
                        }
                    })

                }
            },


        ];


        return <SimpleTable data={actions} columns={ActionColumns} />


    }






    const getItemList = ({ data, key, columns }) => {

        if (!data.actions?.length) {
            return <></>
        }
        let items = [];
        data.actions.forEach(a => {
            if (a.items?.length && a.items[0].url) {
                a.items.forEach(i => {
                    items.push({ ...i, id: (a.id + '_' + i.id) })
                });
            }
        })

        console.log('items', items);

        let ItemColumns = [
            // {
            //     name:'id',
            //     title:'Id'
            // },
            {
                name: 'types',
                title: 'Type',
                template: ({ item, column }) => {
                    return (item?.type || '').toString().toUpperCase()
                }
            },
            {
                name: 'name',
                title: 'Name'
            },
            {
                name: 'key',
                title: 'Key'
            },
            {
                name: 'url',
                title: 'Url',
                template: ({ item, column }) => {
                    return <a href={item.url} target='_blank'>{item.url}</a>
                }
            },
            {
                name: 'description',
                title: 'Description'
            },
            {
                name: 'value',
                title: 'value'
            }


        ];

        return <SimpleTable data={items} columns={ItemColumns} />


    }


    let globalFilter = GlobalStore.currentToolbarOptions.follow();
     
    let systemFilter = [
        ...AppToolbarHelper.getToolbarAppSelectionForAnalyticsFilter(globalFilter, 'application_id'),
        ...AppToolbarHelper.getToolbarTimeSelectionForAnalyticsFilter(globalFilter, 'created_at')
    ]


    return (
        <GridListPage
            store={ListDataGridStore}
            systemFilter={systemFilter}
            onSearch={UserApi.search}
            onHistory={UserApi.history}
            renderRowChild={getChildTables}
            hasChildren={true}
            onGotoEdit={Navigations.gotoEdit}
        />
    );
}

export default UserList;

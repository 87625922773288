import { SegmentApi } from "../../Customer/Segment/Segment.Api";
import { TagApi } from "../../Customer/Tags/Tag.Api";
import { OverviewCardChartDataApi } from "./OverviewCard.ChartData.Api";
import { OverviewCardList } from "./OverviewCardList";
import { OVERVIEW_CARD_CHART_TYPES, OVERVIEW_CARD_PRECALCULATED_CHART_TYPES, OVERVIEW_CARD_TYPES } from "./OverviewCardUtil";


export class OverviewCardManager { 
  static selectedStartTime =  new Date(new Date().toISOString().split('T')[0]+'T00:00:00.000Z');
  static selectedEndTime = new Date(new Date().toISOString().split('T')[0]+'T23:59:59.000Z'); 
  static selectedApplicationIds =  null;
  static selectedAdditionalFilters = null;
  


// static startOfDayDate = new Date(new Date().toISOString().split('T')[0]+'T00:00:00.000Z');
// static endOfDayDate = new Date(new Date().toISOString().split('T')[0]+'T23:59:59.000Z'); 
 

  static getSystemFiltersForCustomer (){
    let appApplications = window.currentUser.applications.filter(a=>a.account_id==window.currentAccount.id).map(a=>a.id);
    return [
      { field: 'registered_at', op: 'gte', value: this.selectedStartTime   },
      { field: 'registered_at', op: 'lte', value: this.selectedEndTime  },
      { field: 'application_ids', value: this.selectedApplicationIds || appApplications }
    ];
  }


  static async getDynamicChartData (){
 
    let allTags = await TagApi.getReport({});
    let allSegments = await SegmentApi.getReport({});
    allSegments = (allSegments || []).filter(s=>s && s.id);

    return [

      ...allSegments.map(s=>{
                return {
                    data_start_time:new Date(),
                    data_end_time:new Date(), 
                    chart_key:OVERVIEW_CARD_CHART_TYPES.SEGMENT_CARD,
                    id:s.id,
                    data:{ 
                        value:s.customer_count,
                        rate:s.customer_rate
                    }  
                }
            }),

      ...allTags.map(s=>{
                return {
                    data_start_time:new Date(),
                    data_end_time:new Date(), 
                    chart_key:OVERVIEW_CARD_CHART_TYPES.TAG_CARD,
                    id:s.id,
                    data:{ 
                        value:s.customer_count,
                        rate:s.customer_rate
                    }  
                }
            })

  ];
 
  }

  static async getChartData (charts, filter){
    let appApplications = window.currentUser.applications.filter(a=>a.account_id==window.currentAccount.id).map(a=>a.id);
    
    console.log('getChartData',charts, filter);
    if(filter){
      charts.forEach(c=>{
          c.date_range =  filter.timeframe ||  c.date_range ;
      });
      this.selectedApplicationIds =  filter.application_ids ;
      this.selectedStoreIds =  filter.store_ids   ;
      this.selectedAdditionalFilters =  filter.customFilter  ;
      
      console.log(' this.selectedAdditionalFilters ',  this.selectedAdditionalFilters );
    }
    let params = [];
    charts.forEach(c=>{
      if(OVERVIEW_CARD_PRECALCULATED_CHART_TYPES[c.chart_type]){
          params.push({
            "max_field": c.max_field,
            "chart_key": c.chart_type,
            "date_range": c.date_range || "ALL",
            "start_date": c.start_date || null,
            "end_date": c.end_date || null
          }) 
      }
      
    });

      return  OverviewCardChartDataApi.get({
                  application_ids:this.selectedApplicationIds , //|| appApplications ,
                  custom_filter:this.selectedAdditionalFilters,
                  charts:params,
                  store_ids:this.selectedStoreIds
                });
  }
  

  static async getAllCards (){
    return OverviewCardList.getAllCards();
  }
  

}
import { config } from "./config";

export const APP_SETTINGS = {
    HINTS: 'ui_hints',
};


export const ENVIRONMENTS = {
    PRODUCTION: 'production',
    DEVELOPMENT: 'development',
    ALPHA: 'alpha', 
    TEST: 'test'

};

let configData = config.prod || config;
if(document.location.hostname.indexOf('localhost')>-1){
    configData = config.local || config;
}
else if(document.location.hostname.indexOf('alpha')>-1){
    configData = config.alpha || config;
}
else{ 
    configData = config.prod || config;
}

export const Globals = {
    appReady: false,
    currentUser: {},
    urlWhiteList: [],
    currentWechatUser: {
        id: '',
        external_userid: '',
        wecom_servicer_userid: '',
    },
    currentWecomUser: {
        id: '11',
        external_userid: '11',
        wecom_servicer_userid: '11',
    },
    currentAccount: {
        account_name: 'TMO TEST ACCOUNT',
        account_id: '8f36fd64-b98c-4f92-ad0b-949b7bc5a0e8',
    },
    currentApplication: {
        account_name: 'TMO TEST ACCOUNT',
        account_id: '8f36fd64-b98c-4f92-ad0b-949b7bc5a0e8',
        application_id: 'c7f158e6-a7b3-4386-83b1-8ac4d07c9324',
    },
    environment: ENVIRONMENTS.PRODUCTION,
    isAdmin: false,
    noCache: false,
    isProduction: true,
    constants: [],
    members: [],
    roles: [],

    checkPermission: (allowedRoles, disallowedRoles) => {
        if (
            disallowedRoles &&
            disallowedRoles.length &&
            window.currentUser &&
            window.currentUser.roles &&
            window.currentUser.roles.length &&
            window.currentUser.roles.filter((ur) => disallowedRoles.indexOf(ur) > -1).length
        ) {
            return false;
        }
        if (
            allowedRoles &&
            allowedRoles.length &&
            window.currentUser &&
            window.currentUser.roles &&
            window.currentUser.roles.length &&
            window.currentUser.roles.filter((ur) => allowedRoles.indexOf(ur) > -1).length == 0
        ) {
            return false;
        }
        return true;
    },

    fireOnAppReady: () => {
        Globals.onAppReadyListeners.forEach((l) => {
            try {
                l();
            } catch (ex) {}
        });
        // Globals.onAfterAppReadyListeners.forEach((l) => {
        //     try {
        //         l();
        //     } catch (ex) {}
        // });
    },
    onAppReadyListeners: [],
    // onAfterAppReadyListeners: [],
    onAppReady: (cb) => {
        Globals.onAppReadyListeners.push(cb);
    },
      // onAfterAppReady: (cb) => {
    //     Globals.onAfterAppReadyListeners.push(cb);
    // },

    
    chatPageUrl: configData.chatPageUrl, 
    fileUploadApi:  configData.fileUploadApi, 
    editorUrl:  configData.editorUrl, 
    shortUrlApiAddress:  configData.shortUrlApiAddress, 
    shortUrlServiceAddress:  configData.shortUrlServiceAddress, 
    apiAddress: configData.apiAddress, 
    socketAddress: configData.socketAddress, 
   
    callApi: async ({ url, params }) => {
        return new Promise(async (resolve, reject) => {
            try {
                if (!Globals.currentUser) {
                    return;
                }
                params['account_id'] = Globals.currentAccount.account_id;
                params['application_id'] =
                params['application_id'] || Globals.currentApplication.application_id;
                params['member_id'] = Globals.currentUser.id;
                params['timezone'] =  new Date().getTimezoneOffset();

                let addr = '';
                if (url) {
                    addr = Globals.apiAddress + url;
                }
                // if (url.indexOf('/realtime/active_users')>-1 || url.indexOf('/realtime/active_pages')>-1)  {
                //     let cacheFor =  'cache_for=30';
                //   if(addr.indexOf('?')>-1){
                //       addr+='&' + cacheFor;
                //     }
                //      else {
                //           addr+='?' + cacheFor;
                //     }
                // }

                var response = await fetch(addr, {
                    method: 'POST',
                    cache: 'no-cache',
                    headers: {
                        'Content-Type': 'application/json',
                        ak: '123123',
                        token: Globals.currentUser.token,
                        nonce: new Date().getTime(),
                    },
                    redirect: 'follow',
                    body: JSON.stringify(params),
                });
                if (response && response.ok) {
                    var data = await response.json();
                    resolve(data);
                } else {
                    reject(response);
                }
            } catch (ex) {
                reject(ex);
                console.log(ex);
            }
        });
    },
};

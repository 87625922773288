

import React, { Component, useRef, useState, useEffect } from 'react';

import './Main.scss';
import Input from '../../components/basic/Input';

import { SettingApi } from './Setting.Api'; 
  
import Button from '../../components/basic/Button';
import ListItem from '../../components/basic/ListItem';
import Card from '../../components/basic/Card'; 
import { Globals } from '../../Globals'; 
import WecomChatSettings from '../../Chat/ChatSetting/WecomChatSettings';
import CurrencySetting from './CurrencySetting/CurrencySetting'
import tmoLib from '../../tmo/tmo.lib';


function Settings(props) {


  const gridSelectorRef = useRef();

  const [data, setData] = useState({});
  const token_valid_untilRef = useRef();
  const updated_atRef = useRef();
  const short_nameRef = useRef();
  const long_nameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const pwdRef = useRef();
  const avatarRef = useRef();
  const departmentRef = useRef();
  const tokenRef = useRef();

  const currencySettingRef = useRef();

  const saveRecord = async (data) => {
    return SettingApi.save({
      params: {
        id: data.id,
        channel: data.channel,
        name: data.name,
        description: data.description,
        color: data.color.value,
      }
    });
  }


  const getMemberDetails = () => {
    return <></>;
  }



  const loadRecord = ({ data }) => {
    return { data: setRequiredFields(data) };
  }

  const setRecord = (field, value) => {
    data[field] = value;
    setData({ ...data });
  }

 

  const getDefaultData = () => {

    return setRequiredFields();
  }



  const setRequiredFields = (data) => {

    data = data || { name: 'Role name' };


    console.log('loadRecord', data);
    return data;
  }


  const changeItem = (type, cb) => {
     
  }

  const changePassword = () => {
    
  }


  const linkSocialLogin = () => {
     
  }


  const gotoSetting = (path) => { 
    tmoLib.ui.global.openPage('/system/settings/'+path);
  } 

  const changeMobile = () => { 
    
  }

  let urlPaths  = window.location.pathname.split('/');
  let urlPath = urlPaths[urlPaths.length-1];
  
  if(!urlPath || urlPath=='settings'){
    urlPath = 'currency'
  }
 
  const saveSettings = () =>{
    if(urlPath=='currency'){
        currencySettingRef.current.save();
    }
  }

  const loadData = async () => {
    
    // let apiData = await SettingApi.get({id:Globals.currentUser.id});
    setData(Globals.currentUser);
  }


  //moount
  useEffect(() => {  
    loadData();  
  },[]);

  

  return (

      <div className='settings-page-content'>
   
        <Card className="settings-buttons-content" noPadding={true}>  
        <ListItem 
              label="Currency settings" 
              desc="Customer support chat settings" 
              icon="payments" 
              selected={urlPath=='currency'}
              arrow={urlPath=='currency'}
              clickable={true}
               onClick={()=>gotoSetting('currency')} /> 
            
{/* 
            <ListItem 
              label="Conversation settings" 
              desc="Customer support chat settings" 
              icon="forum" 
              selected={urlPath=='conversation'}
              arrow={urlPath=='conversation'}
              clickable={true}
               onClick={()=>gotoSetting('conversation')} /> 
            
            <ListItem 
              label="Notification settings" 
              desc="Shopnow notification settings" 
              icon="notifications" 
              selected={urlPath=='notification'}
              arrow={urlPath=='notification'}
              clickable={true} 
              onClick={()=>gotoSetting('notification')} /> 

            <ListItem 
              label="UI settings" 
              desc="Shopnow app UI settings" 
              icon="web" 
              selected={urlPath=='ui'}
              arrow={urlPath=='ui'}
              clickable={true} 
              onClick={()=>gotoSetting('ui')} /> 
  */}
            <div className='profile-button-top-margin'></div>
                
        </Card>

        <Card className="settings-sub-content">
           {urlPath=='currency' ? <CurrencySetting ref={currencySettingRef} /> :null}
           {urlPath=='chat' ? <WecomChatSettings />:null}
        </Card>

        <div className="settings-buttons"> 
            <Button label="SAVE" wrapperClassName="save-button" onClick={saveSettings} primary={true}  />
          
        </div>
      </div>
    
  );
}

export default Settings;


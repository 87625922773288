 
 import React, { Component, useRef, useState, useEffect, forwardRef, useImperativeHandle }  from 'react';
    
 import  './RightPanel.scss'; 
  
import ListItem from '../../../components/basic/ListItem'; 
import CustomerProfileSummary from '../../../Customer/CustomerProfileSummary';
import IconButton from '../../../components/basic/IconButton'; 
import Icon from '../../../components/basic/Icon';
import Button from '../../../components/basic/Button';
import InvitePopup from './InvitePopup';
import CsrAssignPopup from './CsrAssignPopup';
import EmailMessageContent from './EmailMessageContent';
import LoadMoreButton from '../../../components/basic/LoadMoreButton';
import FileUpload from '../../../components/basic/FileUpload';
import Input from '../../../components/basic/Input';
import Popup from '../../../components/basic/Popup';
import { EmailStore } from './Email.Store';
import { SESSION_STATUS, SESSION_USER_ROLES } from './Email.Static';
import { EmailData } from './Email.Data';
import { Tag } from '../../../components/basic/Tag';
import Dropdown from '../../../components/basic/Dropdown';
import UserAddPopup from './UserAddPopup';
 

import t from './Localization/i18n';

const RightPanel = forwardRef(({  },ref)=>  { 

  // const gotoEdit = (id)=>{
  //   tmo.ui.global.openPage('/customer/edit/'+id);
  // }
   
  const currentSession = EmailStore.currentSession.follow() || { users:[] , tags:[], subjects:[] };
  const currentSessionUser = EmailStore.currentSessionUser.follow();
  const currentTags = EmailStore.currentTags.follow();
   
 
  useImperativeHandle(ref, () => ({
    show:show
  }));

  const [ visible, setVisible ] = useState(false);   

  // const [ currentSession, setCurrentSession ] = useState({});    
  const [ currentUser, setCurrentUser ] = useState({});    

  const [ selectedPage, setSelectedPage ] = useState('detail'); 
  const [ editConversationInfoVisible, setEditConversationInfoVisible ] = useState(false); 

  const [ showAllUsers, setShowAllUsers ] = useState(false); 
  const [ showInvitePoopup, setShowInvitePoopup ] = useState(false); 
  const [ showAddUserPopup, setShowAddUserPopup ] = useState(false); 
  

  const [ showAssignPopup, setShowAssignPopup ] = useState(false); 
  const [ emailNotes, setEmailNotes ] = useState([]); 
  const [ emailHistoryItems, setEmailHistoryItems ] = useState([]);  
  const loadButtonRef = useRef();
  const addNoteTextRef = useRef();

  const show=({ session, currentUser})=>{
    setVisible(true);
    // setCurrentSession(session);
    // setCurrentUser(currentUser);
  }
 

//   const  loadMessages =  async ({session, offset, limit, keyword, message_type})=>{
  
//     return new Promise( async (resolve, reject)=>{
//       var response = await fetch('http://localhost:3134/wecom/message/list',{
//         method: 'POST',   
//         cache: 'no-cache',  
//         headers: {
//           'Content-Type': 'application/json' 
//         },
//         redirect: 'follow',  
//         body: JSON.stringify({account_id:'8f36fd64-b98c-4f92-ad0b-949b7bc5a0e8', application_id:'c7f158e6-a7b3-4386-83b1-8ac4d07c9324', member_id:'11', wecom_servicer_userid:'11', email_session_id:session.id , offset:offset, limit:limit, keyword:keyword, message_type})
//     }); 
//           var data = await response.json();   
//           data.forEach(d=>{ 
//             d.sender= session.users.filter(s=>s.id==d.sender_id)[0];
            
//           });
//           resolve(data);   
//     });

// }

 

  const openMessageHistory= async (type) =>{
    setSelectedPage('history');
    setEmailHistoryItems(await EmailData.getMessageHistory({session:currentSession, offset:0, limit:50, message_type:type}));
   return;
  }
 
  const openNotes= async () =>{
    setSelectedPage('notes');
    setEmailNotes(await EmailData.getSessionNotes({session:currentSession}));
  }

  const addNote = async ()=>{
    if(addNoteTextRef.current.value.trim().length<3){
      return;
    }
    let note = await EmailData.addSessionNote({session:currentSession, note:addNoteTextRef.current.value});
    setEmailNotes([note,...emailNotes]);
    addNoteTextRef.current.value='';
  }
 
  
  // const [ data, setData ] = useState(null);

  
  // const  loadData =  ()=>{
  //   return new Promise( async (resolve, reject)=>{
  //     var response = await fetch('/data/customerprofile.json'); 
  //         var data = await response.json();  
  //         resolve(data.slice(0,25)); 
  //   });

  // }
    
  // useEffect(() => {   
  //   loadData().then((d)=>{
  //     // console.log(d);
  //     setData(d);
  //   })
  // },[]);
 
  // const openChannel=(name)=>{
  //   setSelectedChannel(name);
  // }

  // const filterSessions=(type)=>{
  //   setSelectedFilter(type);
  // }

  // const labelClicked=(id)=>{
  //   setSelectedLabel(id);
  // }

    let sessionUsersToShow = currentSession.users.slice(0,4); 

    const addLabelClicked=()=>{

    }
    
    const inviteUserClicked=()=>{
      setShowAddUserPopup(true);
    } 
    // const usersInvited=()=>{
    //   setShowInvitePoopup(false);
    // }

    const assignToOthers=()=>{
      setShowAssignPopup(true);
    }

    const assignedToOthers=({user, note})=>{
      setShowAssignPopup(false);
      EmailData.assignSession({note:note, selectedUser:user});
    }

    const assignToMe=()=>{
      EmailData.assignSession({note:''});
    }


    const joinConversation=()=>{
      EmailData.joinConversation({note:''});
    }


    const addUser=({user, role, note})=>{
      console.log({user, role, note});
      setShowAddUserPopup(false);
      EmailData.addUserToConversation({note:note, role, selectedUser:user});
       
    } 

    

    const closeSession=()=>{
      EmailData.closeSession({});
    }

    const resumeSession=()=>{ 
      EmailData.resumeSession({});
    }
    // const blockUser=()=>{
    //   EmailData.blockUser({});
    // }
    const leaveConversation=()=>{
      
    }
    const pintToTop=(value)=>{
        EmailData.pinSession({pin_top:value});
    }
    const mute=(value)=>{
      EmailData.muteSession({muted:value});
    }




    // const historyFilterChanged=(value)=>{
    //     loadMessages({session:currentSession, offset:0, limit:50, message_type:value}).then((data)=>{ 
    //       setEmailHistoryItems(data.reverse());
    //     }); 
    // }
 
    const startLoadingMessages = () =>{
        console.log('loading...');
        setTimeout(()=>loadButtonRef.current.loadingDone(false,'Error during looading messages'),3000);
    }
    const closePanel = (e) =>{
      if(e.target.className.indexOf('email-right-panel-wrapper')<=-1){
        return;
      }
      setVisible(false);
      setEditConversationInfoVisible(false);
      setSelectedPage('detail');
        // if(onClose){
        //   onClose();
        // }
    }


    const showEditDetail=()=>{
        setEditConversationInfoVisible(true);
    }


  // const  updateSessionProfile =  async ({member_id, wecom_email_session_id,image,conversation_title,description})=>{
      
  //       let url = 'http://localhost:3134/wecom/session/update_profile';
        
  //       return new Promise( async (resolve, reject)=>{
  //         var response = await fetch(url,{
  //           method: 'POST',   
  //           cache: 'no-cache',  
  //           headers: {
  //             'Content-Type': 'application/json' 
  //           },
  //           redirect: 'follow',  
  //           body: JSON.stringify({ 
  //             account_id:'8f36fd64-b98c-4f92-ad0b-949b7bc5a0e8',
  //             application_id:'c7f158e6-a7b3-4386-83b1-8ac4d07c9324',
  //              member_id, wecom_email_session_id,image,conversation_title,description
              
  //           }) // body data type must match "Content-Type" header
  //       }); 
  //           var data = await response.json();   
  //           resolve(data);   
  //     });

  //   }

    const saveConversationInfo=({conversation_title, description, image})=>{
      //save
      // state.updateSessionProfile({
      //   image:image,
      //   conversation_title,
      //   description
      // }); 
      EmailData.saveConversationProfile({conversation_title, description, image});
      setEditConversationInfoVisible(false);
    }

    const addTagToSession = ({items, value}) =>{
      console.log({items, value});
      EmailData.tagSession({tags:items});
      currentSession.tags = items;

    }
    
    let canAssignToMe = false; 
    let canAssign = false; 
    let canReAssign = false; 
    let canClose = false; 
    let canResume = false; 
    let canBlock = false; 
    let canLeave = false; 
    let canPin = false; 
    let canMute = false; 
    let canJoin = false; 
    let canExport = false; 
    let canSeeNotes = false; 
    let canSeeHistory = false; 
    let canAddUser = false; 
    let canEditProfile = false; 
    let canAddTag = false;

    let customer = null; 

    let isUserAlreeadyIn = (currentSessionUser && currentSessionUser.id);

    if(currentSession.status==SESSION_STATUS.PENDING){
      canAssignToMe = true;
      canAssign = true; 
    }

    if(isUserAlreeadyIn && 
      ( currentSessionUser.session_role==SESSION_USER_ROLES.USER || 
        currentSessionUser.session_role==SESSION_USER_ROLES.WATCHER ||
        currentSessionUser.session_role==SESSION_USER_ROLES.CUSTOMER ) 
      ){
          canLeave = true; 
    }
    
    if(isUserAlreeadyIn){ 
        canBlock = true;
        canPin = true;
        canMute = true;

    } 

    if(isUserAlreeadyIn && 
      (
        (currentSessionUser.session_role==SESSION_USER_ROLES.ADMIN || currentSessionUser.session_role==SESSION_USER_ROLES.USER)
          || 
        (currentSession.assignedTo && currentSessionUser && currentSession.assignedTo.id==currentSessionUser.id)
      )
      ){
         
        if(currentSession.status==SESSION_STATUS.IN_PROGRESS){
          canReAssign = true;
          canClose = true;
        }
    } 
    else{
      if(currentSession.assignedTo && currentSessionUser && currentSession.assignedTo.id==currentSessionUser.id){
        canAssignToMe = false; 
      }
      else{
        canAssignToMe = true; 
      }
      
      canAssign = true;
    }
    if(!isUserAlreeadyIn  && currentSession.users.length>1 && currentSession.users.filter(u=>u.session_role==SESSION_USER_ROLES.USER || u.session_role==SESSION_USER_ROLES.ADMIN || u.session_role==SESSION_USER_ROLES.WATCHER).length>0){
      canJoin = true;
    }
    // console.log('currentSession.users.filter(u=>u.session_role==SESSION_USER_ROLES.USER || u.session_role==SESSION_USER_ROLES.ADMIN || u.session_role==SESSION_USER_ROLES.WATCHER)', currentSessionUser, currentSession.users.filter(u=>u.session_role==SESSION_USER_ROLES.USER || u.session_role==SESSION_USER_ROLES.ADMIN || u.session_role==SESSION_USER_ROLES.WATCHER),  currentSession.users);
    if(isUserAlreeadyIn &&  currentSessionUser.session_role==SESSION_USER_ROLES.ADMIN){
      canExport = true;
    }

    if(
      (
        isUserAlreeadyIn &&  
        (currentSessionUser.session_role==SESSION_USER_ROLES.ADMIN || currentSessionUser.session_role==SESSION_USER_ROLES.USER) 
      )
      ||
      (  currentSession.assignedTo && currentSessionUser && currentSession.assignedTo.id==currentSessionUser.id  )
    ){
      canSeeNotes = true;
      canSeeHistory = true;
      canAddUser = true;
      canEditProfile = true;
      canAddTag = true;
    }
    
    if(currentSession.status==SESSION_STATUS.CLOSED){
      canResume = true;
    }
    
    let customesrInSession = currentSession.users.filter(u=>u.session_role==SESSION_USER_ROLES.CUSTOMER);
     if(customesrInSession.length==1){
       customer = customesrInSession[0];
     }

     
     
    console.log('customesrInSession', customer, customesrInSession, currentSession.users);
    
    return(<div className={'email-right-panel-wrapper '+ (visible?' right-panel-visible ':' right-panel-hidden ') + (currentSession.users.length>0?'  ':' right-panel-disabled ')} onClick={(e)=>closePanel(e)} >
            {showAddUserPopup && <UserAddPopup onClose={()=>setShowAddUserPopup(false)} onDone={({user, role, note})=>addUser({user, role, note})} buttonText={'Add selected user'} />}
            {showAssignPopup && <CsrAssignPopup onClose={()=>setShowAssignPopup(false)} onDone={({user, note})=>assignedToOthers({user, note})} buttonText={'Assign to selected user'} />}
            
            <div className='email-right-panel'>
              
                {selectedPage=='detail' && (
                    <div className="email-detail">
                    <div className="email-name">{t("Conversation Details")}</div>
                    <div className="email-detail-content">
                      <div className="user-avatar" onClick={() => setSelectedPage("customer")}>
                        <img src={currentSession.image} />
                        <div className="info">
                          <div className="name">{currentSession.conversation_title}</div>
                          <div className="city">
                            {currentSession.description || t("Last activity: {0}", currentSession.last_message_time_text)}
                          </div>
                        </div>
                        {customer && customer.customer_id && <Icon name="chevron_right" />}
                      </div>
                      {editConversationInfoVisible && (
                        <EmailDetailEditPopup
                          currentSession={currentSession}
                          onSave={saveConversationInfo}
                          onClose={() => setEditConversationInfoVisible(false)}
                        />
                      )}
                      {canEditProfile && (
                        <Button lineButton={true} noBorder={true} wrapperClassName="edit-email" onClick={showEditDetail} label={t("Edit Conversation Profile")} />
                      )}
                      {currentSession.assignedTo && currentSession.assignedTo.id && 
                        <div className={"email-assigned-member "+ (canEditProfile?'':' show-top-border ')}>
                              
                            <div className="email-assigned-member-head">{t("Assigned to:", currentSession.users.length)}</div>
                            <div className="user-avatar" onClick={() => setSelectedPage("customer")}>
                              <img src={currentSession.assignedTo.avatar} />
                              <div className="info">
                                <div className="name">{currentSession.assignedTo.name}</div>
                                <div className="city">
                                  {currentSession.assignedTo.description}
                                </div>
                              </div> 
                            </div>
                        </div>
                      }

                      <div className="email-members">
                        <div className="email-members-head">{t("Users in email ({0})", currentSession.users.length)}</div>
                        <div className="email-members-list">
                          {(showAllUsers ? currentSession.users : sessionUsersToShow).map((u) => (
                            <img key={u.id} title={u.name + " : " + u.session_role} className={"email-member-avatar " + u.session_role} src={u.avatar} />
                          ))}
                          {!showAllUsers && currentSession.users.length > 4 && (
                            <div className="email-members-count" onClick={() => setShowAllUsers(true)}>
                              {t("+{0}", currentSession.users.length - 4)}
                            </div>
                          )}
                          {showAllUsers && <IconButton name="first_page" onClick={() => setShowAllUsers(false)} />}
                          {canAddUser && <IconButton name="add" onClick={inviteUserClicked} />}
                        </div>
                      </div>
                      <div className="email-labels">
                        <div className="email-labels-head">{t("Tags ({0})", currentSession.tags.length)}</div>
                        <div className="email-labels-list">
                          <Dropdown
                            placeholder={t("Add customer tag")}
                            className="tag-select"
                            valueField="id"
                            labelField="name"
                            items={currentSession.tags.sort((a, b) => (a.name > b.name ? 1 : -1))}
                            value={currentSession.tags.sort((a, b) => (a.name > b.name ? 1 : -1)).map((t) => t.id)}
                            options={currentTags}
                            selectOptions={{ closeOnSelect: false, isMultiSelect: true, selectMode: "check" }}
                            searchOptions={{ enable: true }}
                            useLabel={false}
                            noBorder={true}
                            useColors={true}
                            useListColors={false}
                            onChange={addTagToSession}
                            customTemplate={({ label, value, data }) => {
                              return <Tag value={value} label={label} color={data.color.value} logo={data.channel.value} />;
                            }}
                          ></Dropdown>
                        </div>
                      </div>

                      <div className='email-labels'>
                        <div className='email-labels-head'>{t('Subjects ({0})', currentSession.subjects.length)}</div>
                        <div className='email-labels-list'>
                          {!currentSession.subjects || currentSession.subjects.length === 0 ? (
                            <div className='no-subjects-note' data-tooltip={t('Use # character eg: "#SUBJECT NAME" to start a subject. Subject start message will not be delivered to customers.')}>
                              {t('No subjects have been started in this session yet.')}
                            </div>
                          ) : (
                            (currentSession.subjects || []).map(u => (
                              <div key={u.email_subject_id} className='email-label-text'>#{u.title} ({u.total_messages})</div>
                            ))
                          )}
                        </div>
                      </div>

                          
                          
                            {canAssignToMe && (
                              <ListItem icon="flag_circle" label={t('Assign to me')} clickable={true} onClick={assignToMe}></ListItem>
                            )}
                            {canAssign && (
                              <ListItem icon="flag" label={t('Assign to others')} clickable={true} onClick={assignToOthers}></ListItem>
                            )}
                            {canReAssign && (
                              <ListItem icon="flag" label={t('Re-Assign to others')} clickable={true} onClick={assignToOthers}></ListItem>
                            )}
                            {canClose && (
                              <ListItem icon="stop_circle" label={t('Close session')} clickable={true} onClick={closeSession}></ListItem>
                            )}
                            {canJoin && (
                              <ListItem icon="group_add" label={t('Join email')} clickable={true} onClick={joinConversation}></ListItem>
                            )}
                            {canLeave && (
                              <ListItem icon="directions_run" label={t('Leave group')} clickable={true} onClick={leaveConversation}></ListItem>
                            )}
                            {canSeeNotes && (
                              <ListItem icon="history" arrow={true} clickable={true} label={t('Conversation History')} onClick={() => openMessageHistory()} />
                            )}
                            {canSeeHistory && (
                              <ListItem icon="sticky_note_2" arrow={true} clickable={true} label={t('Conversation notes')} onClick={() => openNotes()} />
                            )}
                            {canPin && (
                              <ListItem label={t('Pin to top')} switch={true} value={currentSession.pin_top} onChange={pintToTop} />
                            )}
                            {canMute && (
                              <ListItem switch={true} value={currentSession.muted} onChange={mute} label={t('Mute')} />
                            )}
                          </div>
                          
                    </div>
                )} 

                {selectedPage=='customer' && (
                    <div className='customer-detail'> 
                          <div className='customer-detail-header' onClick={()=>setSelectedPage('detail')}>
                            <Icon name="arrow_back_ios" />
                            {t('Go Back')}
                          </div>
                          <div className='customer-detail-content'>
                            
                            {customer && <CustomerProfileSummary customerId={customer.customer_id}  />}
                          </div>
                    </div>
                  )} 


                {selectedPage=='history' && (
                    <div className='email-history'> 
                          <div className='email-history-header' onClick={()=>setSelectedPage('detail')}>
                            <Icon name="arrow_back_ios" />
                            {t('Go Back')}
                          </div>
                          <select className='email-history-type' onChange={(e)=>openMessageHistory(e.target.value)}>
                              <option value="text">{t('Text Messages')}</option>
                              <option value="link">{t('Link Messages')}</option>
                              <option value="image">{t('Image Messages')}</option>
                              <option value="video">{t('Video Messages')}</option>
                              <option value="audio">{t('Audio Messages')}</option>
                              <option value="file">{t('File Messages')}</option> 
                          </select> 

                          <div className='email-history-list'>
                            {emailHistoryItems.map(m=>(
                              <div key={m.id} className='email-history-item' >
                                <EmailMessageContent message={m} onMessageMediaClick={()=>{}} />
                              </div>)
                            )}
                             <LoadMoreButton
                                  onLoad={startLoadingMessages}
                                  loadingText={t('Loading More Messages...')}
                                  loadText={t('Load More Messages')}
                                  nothingToLoadText={t('No More Messages To Show')}
                                  ref={loadButtonRef}
                                />
                          </div>
                    </div>
                  )} 

                {selectedPage=='notes' && (
                      <div className='email-notes'> 
                            <div className='email-notes-header' onClick={() => setSelectedPage('detail')}>
                              <Icon name="arrow_back_ios" />
                              {t('Go Back')}
                            </div>
                            <div className='email-note-list'>
                              {emailNotes.map(n => (
                                <div key={n.id} className='email-note-item'>
                                  <div className='email-note-title'>{n.sender.name + ' added on ' + n.time}</div>
                                  {n.note}
                                </div>
                              ))}
                            </div>
                            <textarea placeholder={t('Your note')} className='email-note-text' ref={addNoteTextRef}></textarea>
                            <Button label={t('ADD NOTE')} primary={true} onClick={() => addNote()}></Button>
                    </div>
                  )} 

            </div>
            </div>
    )
});



const EmailDetailEditPopup =({currentSession, onSave, onClose}) =>{

  const editNameRef = useRef();
  const editDescriptionRef = useRef();
  const fileUploadRef = useRef();


  const [ uploadedFile, setUploadedFile ] = useState({});

  const fileUploaded =(result) =>{
    if(result && !result.error){ 
      setUploadedFile(result);
    }  
  }



  const uploadAvatar = ()=>{
    fileUploadRef.current.chooseFile();
  }
  const save = ()=>{
    if(onSave){ 
      onSave({conversation_title:editNameRef.current.value || currentSession.conversation_title, description:editDescriptionRef.current.value || currentSession.description, image:uploadedFile.url || currentSession.image });
    }
  }
  return (
    <Popup
      blurBackground={false}
      open={true}
      disableScroll={true}
      draggable={false}
      showCloseOnTop={true}
      showNotes={true}
      showButtons={true}
      title={t('Edit Conversation Info')}
      note={''}
      showCloseButton={true}
      closeButtonText={t('Save')}
      enableCloseOnBackgoundClick={false}
      onButtonClick={() => save()}
      onClose={() => onClose()}
    >
      <div className="edit-email-detail-popup">
        <div className="avatar" style={{ backgroundImage: 'url(' + currentSession.image + ')' }} onClick={uploadAvatar}>
          <Icon name="edit" />
        </div>
        <FileUpload ref={fileUploadRef} hidden={true} allowMultiple={false} onFileUploaded={fileUploaded} />
        <Input ref={editNameRef} type="text" placeholder={t('Conversation title')} value={currentSession.conversation_title} autoFocus={true} />
        <Input ref={editDescriptionRef} type="text" placeholder={t('Conversation description')} value={currentSession.description} />
      </div>
    </Popup>

); 
  
  
  }



export default RightPanel;

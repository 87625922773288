import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import IconButton from '../../components/basic/IconButton';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import CustomerInfo from '../../ShopNow/Components/TopLevel/CustomerInfo';
import { GlobalStore } from '../../GlobalStore';
import Constants from '../../System/StaticVar/Constants';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/address/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/address/list'),
    gotoView: (id) => tmo.ui.global.openPage('/address/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading Address Report: ' + data.name,
        task_input: { address_id: data.id },
        related_module_id: 'address',
        related_record_id: data.id,
    });
};

const defaultCities = [
    { label: 'Beijing', value: 'Beijing' },
    { label: 'Shanghai', value: 'Shanghai' },
    { label: 'Guangzhou', value: 'Guangzhou' },
    { label: 'Shenzhen', value: 'Shenzhen' },
    { label: 'Qingdao', value: 'Qingdao' },
    { label: 'Nanjing', value: 'Nanjing' },
    { label: "Xi'an", value: 'xian' },
    { label: 'Chengdu', value: 'Chengdu' },
    { label: 'Chongqing', value: 'Chongqing' },
    { label: 'Tianjin', value: 'Tianjin' },
    { label: 'Wuhan', value: 'Wuhan' },
    { label: 'Hangzhou', value: 'Hangzhou' },
    { label: 'Shenyang', value: 'Shenyang' },
    { label: 'Dongguan', value: 'Dongguan' },
    { label: 'Foshan', value: 'Foshan' },
    { label: 'Harbin', value: 'Harbin' },
    { label: 'Dalian', value: 'Dalian' },
    { label: 'Zhengzhou', value: 'Zhengzhou' },
    { label: 'Jinan', value: 'Jinan' },
    { label: 'Changsha', value: 'Changsha' },
    { label: 'Kunming', value: 'Kunming' },
    { label: 'Changchun', value: 'Changchun' },
    { label: 'Ürümqi', value: 'urunqi' },
    { label: 'Shantou', value: 'Shantou' },
    { label: 'Suzhou', value: 'Suzhou' },
];

const getCities = (keyword) => {
    return new Promise(async (resolve) => {
        const response = await fetch('/data/cities.json');
        const data = await response.json();

        resolve(
            tmo.helpers.searchSimple({
                keyword,
                data,
                valueFieldName: 'value',
                labelFieldName: 'label',
            })
        );
    });
};

const cityDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: defaultCities,
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getCities,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getCities },
};

const countryDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const provinceDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};
 

const storeDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0, 
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },

    {
        title: 'Receiver Name',
        type: 'parent',
        width: 220,
        key: 'receiver_name',
        disableGrouping: true,
        index: 1, 
        visible: true,
        mVisible: true,
        field: 'receiver_name',
        db_field: 'receiver_name',
        template: ({ item }) => {
            const userName = tmo.helpers.readOr(item, 'receiver_name', 'Guest User');
            return (
                <span>
                    <CustomerInfo
                        id={item.id}
                        avatar={item.customer?.avatar ?? '/noavatar.png'}
                        name={userName}
                        description={item.customer?.desc}
                        customer={item.customer}
                        active={item.customer?.active}
                    />
                </span>
            );
        },
        children: [
            {
                title: 'Name',
                type: 'text',
                key: 'col_3_1',
                field: 'receiver_name',
                db_field: 'receiver_name',
            },
        ],
    },

    {
        title: 'Address',
        type: 'text',
        width: 220,
        key: 'address',
        disableGrouping: true,
        index: 2, 
        visible: true,
        mVisible: true,
        field: 'address',
        db_field: 'address',
    },

    
    {
        title: 'Location',
        index: 3, 
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 160,
        key: 'location',
        db_field: 'location',
        template: ({ item }) =>{
            // return <>{item.location || item.country?.label || item.province?.label || item.city?.label || item.location}</>;
            if(item.country?.label && item.province?.label && item.city?.label){
                return  <span className="location">
                            <span>
                                {tmo.helpers.readOr(item.country, 'label', ' ') + ' '+
                                tmo.helpers.readOr(item.province, 'label', ' ')} 
                            </span>
                            {tmo.helpers.readOr(
                                item.city,
                                'label',
                                '-'
                            )}
                        </span>
            }
            else if(item.location?.length){
                return <span className="location">{item.location}</span>;
            }
            else if(item.country?.label || item.province?.label || item.city?.label){
                return  <span className="location">
                                <span>
                                    {tmo.helpers.readOr(item.country, 'label', ' ') + ' '+
                                    tmo.helpers.readOr(item.province, 'label', ' ')} 
                                </span>
                                {tmo.helpers.readOr(
                                    item.city,
                                    'label',
                                    '-'
                                )}
                            </span>
            }
            else{
                return  <span className="location">N/A</span>
            }
             

        },
           
        children: [
            {
                title: 'Country/Region',
                type: 'dropdown',
                key: 'col_2_1',
                field: 'country',
                dropdownProps: countryDropdownProps,
                db_field: 'country',
            },
            {
                title: 'Province',
                type: 'dropdown',
                key: 'col_2_2',
                field: 'province',
                dropdownProps: provinceDropdownProps,
                db_field: 'province',
            },
            {
                title: 'City',
                type: 'dropdown',
                key: 'col_2_3',
                field: 'city',
                dropdownProps: cityDropdownProps,
                db_field: 'city',
            },
        ],
    },
    {
        title: 'Contact',
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 160,
        key: 'col_4',
        db_field: 'email',
        template: ({ item }) => (
            !item.mobile && !item.email ? <span>N/A</span>:
            <span className="translated-text">
                 {tmo.helpers.readOr(item, 'mobile', '')}
                <br />
                {tmo.helpers.readOr(item, 'email', '')}
            </span>
        ),
        children: [
            {
                title: 'Mobile phone',
                type: 'text',
                key: 'col_4_1',
                field: 'mobile',
                db_field: 'mobile',
            },
            { title: 'Email', type: 'text', key: 'col_4_2', field: 'email', db_field: 'email' },
        ],
    },
    {
        title: 'Channel',
        type: 'dropdown',
        width: 100,
        key: 'col_channel',
        index: 5, 
        visible: true,
        field: 'channel',
        db_field: 'channel',
        dropdownProps: Constants.CHANNEL_DROWNDOWN_PROPS,
        template: ({ item }) => <span>{Constants.CHANNELS[item.channel]}</span>,
    },
    {
        title: 'Application',
        type: 'dropdown',
        width: 120,
        key: 'col_6',
        dropdownProps: appDropdownProps,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        noSpan:true,
        field: 'application_id',
        db_field: 'application_id',
        template: ({ item }) => <ApplicationTag application={item.application} />,
    },
    {
        title: 'Store',
        type: 'dropdown',
        width: 100,
        key: 'col_7',
        dropdownProps: storeDropdownProps,
        index: 7,
        mIndex: 7,
        visible: true,
        mVisible: true,
        field: 'store_id',
        db_field: 'store_id',
        template: ({ item }) => {
            return (
                <span className="translated-text">
                    <span className="translated-text-main">
                        {tmo.helpers.readOr(item.store, 'name', 'N/A')}
                    </span>
                    <span className="translated-text-sub">
                        {tmo.helpers.readOr(item.store, 'name_sub', 'N/A')}
                    </span>
                </span>
            );
        },
    },
    {
        title: 'Created At',
        type: 'datetime',
        width: 110,
        key: 'col_created_at',
        disableGrouping: true,
        index: 8, 
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 110,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 9, 
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'address_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Address',
        simpleLabel: 'ShopNow Address',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Address',
        simpleLabel: 'Wechat Address',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Address',
        simpleLabel: 'Weibo Address',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Address',
        simpleLabel: 'TikTok Address',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Address',
        simpleLabel: 'LinkedIn Address',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Address', simpleLabel: 'QQ Address', key: 'qq_followers', value: false },
    { label: 'Display App Address', simpleLabel: 'App Address', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = async (store) => {
    const ecommerceApps = GlobalStore.toolbarEcommerceOptions.get().applications; // TODO: later when ecommerce stores have application data, filter stores from toolbar selection (dont show all)
    const storeTypesRecords = await Globals.callApi({
        url: 'shop/store/search',
        params: {
            offset: 0,
            page_size: 1000,
        },
    });
    countryDropdownProps.options = Globals.constants.find((c) => c.type === 'country').items;
    provinceDropdownProps.options = Globals.constants.find((c) => c.type === 'province').items;
    appDropdownProps.options = Globals.currentUser.applications;
    storeDropdownProps.options = storeTypesRecords.records;
};

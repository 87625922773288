import React, { useCallback, useEffect, useRef, useState } from 'react';

import tmo from '../../tmo/tmo.lib';
import { debounce } from '../../tmo/tmo.utils';
import EditPage from '../../ShopNow/Components/TopLevel/EditPage';
import ColorSelect from '../../ShopNow/Components/ColorSelect';
import IconSelect from '../../ShopNow/Components/IconSelect';
import Button from '../../components/basic/Button';
import Input from '../../components/basic/Input';
import Switch from '../../components/basic/Switch';
import SegmentFilter from './Components/SegmentFilter';
import { CustomerListDataGridStore } from '../List/CustomerList.GridStore';
import { DownloadReport, SegmentListDataGridStore, Navigations } from './Segment.GridStore';
import RecordViewerGrid from './RecordViewerGrid';
import { isSegmentValid } from './validations';
import { SegmentApi } from './Segment.Api';
import './Segment.Edit.scss';

function SegmentEdit() {
    const searchGrid = useRef();

    const getDefaultData = () => ({
        icon: {
            value: 'person',
            label: 'person',
        },
        color: { value: 'blue' },
        condition: {},
        content: [
            {
                id: new Date().getTime(),
                type: 'text',
                need_ai: false,
                content: { content: 'Message' },
            },
        ],
    });

    const [data, setData] = useState(getDefaultData());
    const [totalCustomer, setTotalCustomer] = useState(0);
    const [errors, setErrors] = useState(null);
    const [isValidating, setIsValidating] = useState(false);

    const saveRecord = async (data) => {
        let sum = 'Add';

        sum += ' if ' + data.condition?.label;

        if (data?.condition?.filter) {
            data.condition.filter.forEach((item) => {
                if (item.column.need_ai) {
                    data.need_ai = true;
                }
            });
        }

        return SegmentApi.save({
            params: {
                condition: data.condition,
                name: data.name,
                is_active: data.is_active || false,
                summary: sum,
                id: data.id,
                need_ai: data.need_ai || false,
                icon: data.icon.value,
                color: data.color.value,
                score:data.score,
                category:data.category
            },
        });
    };

    const loadRecord = ({ data }) => {
        return { data: getDefaultData(data) };
    };

    const setRecord = (field, value) => {
        data[field] = value;
        setData({ ...data });
    };

    const reloadCustomerSearch = useCallback(
        debounce(() => searchGrid.current.reload()),
        []
    );

    const filterChanged = (value) => {
        setRecord('condition', value);
        if (searchGrid.current) {
            reloadCustomerSearch();
        }
    };

    const onCustomerSearch = async (params) => {
        if (!data.condition?.filter) {
            return;
        }

        params.condition = data.condition.filter;

        try {
            let datas = await SegmentApi.getCustomers(params);
            if (!datas.records.length) {
                setTotalCustomer(0);
            } else {
                setTotalCustomer(datas.total);
            }
            return datas;
        } catch (ex) {
            setTotalCustomer(0);
            return { total: 0, records: [] };
        }
    };

    const hasError = (id) => {
        if (!errors) return null;

        const foundError = errors?.find((error) => error === id);

        if (foundError && isValidating) {
            if (id === 'condition') {
                return '* This filter has missing values, please provide valid conditions';
            }

            return '* This field is required';
        }

        return null;
    };

    const getButtons = () => {
        return (
            <>
                {(errors === null || Object.keys(errors)?.length > 0) && (
                    <>
                        <Button
                            label="FINISH AND SAVE"
                            icon="save"
                            primary
                            onClick={() => {
                                if (errors?.length > 0) {
                                    tmo.ui.global.showNotification({
                                        text: 'Segment has missing fields',
                                        title: 'Save failed!',
                                        time: 6,
                                    });
                                }

                                setIsValidating(true);
                            }}
                        />
                        <Button
                            label="CANCEL"
                            noBorder
                            onClick={() =>
                                tmo.ui.global.showConfirmPopup({
                                    popupType: 'cancel',
                                    onDone: Navigations.gotoList,
                                })
                            }
                        />
                    </>
                )}
            </>
        );
    };

    useEffect(() => {
        if (data) {
            const segmentValidations = isSegmentValid(data);

            if (!segmentValidations.isValid) {
                setErrors(segmentValidations.errors);
                return;
            } else {
                setErrors([]);
            }

            if (isValidating) {
                setIsValidating(false);
            }
        }
    }, [data]);

    return (
        <EditPage
            pageTitle="Edit Segment"
            data={data}
            setData={setData}
            cardHeader="Segment Details"
            sideBarTitle="Preview"
            enableTagging={false}
            enableAttributes={false}
            api={SegmentApi}
            store={SegmentListDataGridStore}
            navigations={Navigations}
            onLoad={loadRecord}
            onSave={saveRecord}
            onExport={() => DownloadReport(data)}
            onDefaultData={getDefaultData}
            hideReport
            className="segment-edit"
            buttons={getButtons()}
            hideSave={errors === null || Object.keys(errors)?.length > 0}
            hideCancel={errors === null || Object.keys(errors)?.length > 0}
        >
            <Input
                error={hasError('name')}
                placeholder="Name of the segment"
                type="text"
                className="name"
                value={data.name}
                onChange={(value) => setRecord('name', value)}
            />
            <Input
               error={hasError('score')}
               placeholder="Score for the segment (0 - 100)"
               type="number"
               className="score"
               maxValue={100}
               minValue={0}
               value={data.score}
               onChange={(value) => setRecord('score', value)}
           />
           <Input
              error={hasError('category')}
              placeholder="Category for the segment"
              type="text"
              className="category"
              value={data.category}
              onChange={(value) => setRecord('category', value)}
          />
            <Switch
                placeholder="Active"
                label="Activate this item"
                className="active"
                value={data.is_active}
                onChange={(value) => setRecord('is_active', value)}
            />
            <ColorSelect
                useLabel
                placeholder="Segment color"
                className="color"
                onChange={(value) => setRecord('color', value)}
                value={data.color?.value}
            />
            <IconSelect
                useLabel
                placeholder="Segment icon"
                className="icon"
                logoFieldName="logo"
                onChange={(value) => setRecord('icon', value)}
                value={data.icon}
            />
            <SegmentFilter
                error={hasError('condition')}
                placeholder="Filter"
                value={data.condition}
                onChange={(value) => filterChanged(value)}
                className={'segment-filter'}
            />
            <div className="segment-notes">
                <br />
                <b>Current possible number of customers: {totalCustomer}</b>
                <br />
                <i>Numbers may change over time. Segments are working dynamic and real-time</i>
            </div>
            <div className="segment-grid">
                <RecordViewerGrid
                    ref={searchGrid}
                    gridId={'segment-customer-search'}
                    onSearch={onCustomerSearch}
                    store={CustomerListDataGridStore}
                />
                {!totalCustomer && (
                    <div className="segment-grid-nodata">No Suitable Customer Data Found</div>
                )}
            </div>
        </EditPage>
    );
}

export default SegmentEdit;

import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals';
import IconButton from '../../components/basic/IconButton';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/auth/csr/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/auth/csr/list'),
    gotoView: (id) => tmo.ui.global.openPage('/auth/csr/' + id),
};

export const DownloadReport = (data) => {
    tmo.task.startPrintTask({
        name: 'Downloading ChatCsr Report: ' + data.name,
        task_input: { chat_csr_id: data.id },
        related_module_id: 'chat_csr',
        related_record_id: data.id,
    });
};

const is_availableDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const is_onlineDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Name',
        type: 'text',
        width: 150,
        key: 'col_1',
        disableGrouping: true,
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        field: 'name',
        db_field: 'name',
    },
    {
        title: 'Group Name',
        type: 'text',
        width: 150,
        key: 'col_2',
        disableGrouping: true,
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        field: 'group_name',
        db_field: 'group_name',
    },
    {
        title: 'Is Available',
        type: 'dropdown',
        width: 150,
        key: 'col_3',
        dropdownProps: is_availableDropdownProps,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'is_available',
        db_field: 'is_available',
        template: ({ item }) => (
            <span>{tmo.helpers.readOr(item.is_available, 'label', 'N/A')}</span>
        ),
    },
    {
        title: 'Is Online',
        type: 'dropdown',
        width: 150,
        key: 'col_4',
        dropdownProps: is_onlineDropdownProps,
        index: 4,
        mIndex: 4,
        visible: true,
        mVisible: true,
        field: 'is_online',
        db_field: 'is_online',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.is_online, 'label', 'N/A')}</span>,
    },
    {
        title: 'Csr Member Id',
        type: 'text',
        width: 150,
        key: 'col_6',
        disableGrouping: true,
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        field: 'csr_member_id',
        db_field: 'csr_member_id',
    },
    {
        title: 'Default Session Role',
        type: 'text',
        width: 150,
        key: 'col_8',
        disableGrouping: true,
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        field: 'default_session_role',
        db_field: 'default_session_role',
    },
    {
        title: 'Parent Wecom Chat Csr Id',
        type: 'text',
        width: 150,
        key: 'col_9',
        disableGrouping: true,
        index: 9,
        mIndex: 9,
        visible: true,
        mVisible: true,
        field: 'parent_wecom_chat_csr_id',
        db_field: 'parent_wecom_chat_csr_id',
    },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 150,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 16,
        mIndex: 16,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
    {
        title: '#',
        index: 17,
        mIndex: 17,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_action',
        width: 110,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                />
                <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                />
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'chat_csr_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: true,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow ChatCsr',
        simpleLabel: 'ShopNow ChatCsr',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat ChatCsr',
        simpleLabel: 'Wechat ChatCsr',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo ChatCsr',
        simpleLabel: 'Weibo ChatCsr',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok ChatCsr',
        simpleLabel: 'TikTok ChatCsr',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn ChatCsr',
        simpleLabel: 'LinkedIn ChatCsr',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ ChatCsr', simpleLabel: 'QQ ChatCsr', key: 'qq_followers', value: false },
    { label: 'Display App ChatCsr', simpleLabel: 'App ChatCsr', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = () => {
    // TODO: add when we have constants for this
    /*
    is_availableDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'undefined'
    )[0].items;

    is_onlineDropdownProps.options = Globals.constants.filter(
        (c) => c.type === 'undefined'
    )[0].items;
    */
};

import { Globals } from '../../Globals';
import { EXCLUDE_TYPES } from '../../ShopNow/Components/TopLevel/Toolbar';

export class VisitorApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
        selection,
    }) => {
        // const appIdSystemFilters = system_filters.filter(
        //     (filter) => filter.field === 'application_id'
        // );
   
     

        return await Globals.callApi({
            url: 'analytics/visitor/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: system_filters, //[...appIdSystemFilters],
                selection,
            },
        });
    };

    static detail = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/visitor/detail',
            params: { id: id },
        });
    };
    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/visitor/get',
            params: { visitor_id: id },
        });
    };
 

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/visitor/remove',
            params: { visitor_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'analytics/visitor/rollback',
            params: { visitor_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'visitor' },
        });
    };
}

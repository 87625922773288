


import React, { } from 'react';

import './SocialLogin.List.scss';


import { ListDataGridStore, Navigations } from './SocialLogin.GridStore';
import { SocialLoginApi } from './SocialLogin.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

function SocialLoginList() {

  return <div className='social-login-list'>
    <GridListPage store={ListDataGridStore} onSearch={SocialLoginApi.search} onHistory={SocialLoginApi.history} onGotoEdit={Navigations.gotoEdit} />
  </div>

}

export default SocialLoginList;




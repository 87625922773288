 import React, { useEffect, useRef, useState } from 'react'; 
import { CARD_TYPE_LIST_ITEMS,  OVERVIEW_CARD_CATEGORY,  OVERVIEW_CARD_CATEGORY_LABELS,  OVERVIEW_CARD_CHART_TYPES,  OVERVIEW_CARD_FILTER,  OVERVIEW_CARD_TYPES } from './OverviewCardUtil';
 
import ListItem from './ListItem';
import Popup from './Popup';
import Input from './Input';
import { SegmentApi } from '../../Customer/Segment/Segment.Api';
import { Tag } from './Tag';
import { TagApi } from '../../Customer/Tags/Tag.Api';
import { OverviewCardManager } from './OverviewCardManager';
import tmoLib from '../../tmo/tmo.lib';
import './OverviewCardSelector.scss'
import { ECommerceGenericApi } from '../../ECommerce/ECommerceGeneric.Api';


// const startOfDayDate = new Date(new Date().toISOString().split('T')[0]+'T00:00:00.000Z');
// const endOfDayDate = new Date(new Date().toISOString().split('T')[0]+'T23:59:59.000Z'); 
 
// console.log('startOfDayDate',startOfDayDate);



function OverviewCardSelector(props) {

  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [accountProps, setAccountProps] = useState({});
   

  console.log('props.types', props.types);

  const getAllCards = async () =>{
     
    let allCards = await OverviewCardManager.getAllCards();
    if(props.types?.length){
      allCards = allCards.filter(c=>props.types.indexOf(c.type)>-1);
    }

    (props.cards || []).forEach(c=>{
      let found =allCards.find(card=>card.id==c.id);
        if(found){
          found.visible = true;
        }
    });
    setData(allCards.filter(c=>c.suitable_with_overviews.indexOf(props.overviewType)>-1));
    return allCards;

  } 


  const initPage = async ()=>{
    let summary = await ECommerceGenericApi.summary({ application_ids:[] });
    setAccountProps({ 
      hasOrders:summary.order>0, 
      hasBasket:summary.basket>0, 
      hasEcommerceCustomers:summary.user>0,
      hasProducts:summary.product>0, 
    });

    setData(await getAllCards());

  }


  useEffect(() => {
    initPage();

  }, []);

 

  const onPopupClose = () => {
    if (props.onClose) {
        props.onClose();
    }
};

const saveRecord = async () => {
  if (props.onSave) {
    props.onSave(data);
}
};
 
const setVisible = async (item, value) => {
  console.log(item.id);
  setData([...data.filter(dd=>dd.id!=item.id), {...item, visible:value}])
};
 

const getListData = () => {
  if(!keyword.trim().length){
    return (data || []).sort((a,b)=> (  a.name )>(  b.name ) ? -1:1);
  }
  else{
    return tmoLib.helpers.searchSimple({ keyword:keyword, data:data, valueFieldName:'id', labelFieldName:'name', showAll:true  });
    // return (data || []).sort((d,b)=>  (d.name.toLowerCase().indexOf(keyword)>-1 ? -1:1));
  }
  
}
 
const getFilteredCards = (category) => {
   let cards = getListData();
   if(category && category.value){
      cards = cards.filter(a=>a.category==category.value);
   }
   if(!accountProps.hasBasket){
      cards = cards.filter(c=>(c.filter || []).indexOf(OVERVIEW_CARD_FILTER.IF_HAS_BASKETS)==-1)
    }
    if(!accountProps.hasOrders){
       cards = cards.filter(c=>(c.filter || []).indexOf(OVERVIEW_CARD_FILTER.IF_HAS_ORDERS)==-1)
     }
     if(!accountProps.hasProducts){
        cards = cards.filter(c=>(c.filter || []).indexOf(OVERVIEW_CARD_FILTER.IF_HAS_PRODUCTS)==-1)
      }
    return cards;
  
}

  

let allCategories = [
  { value: OVERVIEW_CARD_CATEGORY.ANALYTICS, label:OVERVIEW_CARD_CATEGORY_LABELS.analytics },
  { value: OVERVIEW_CARD_CATEGORY.CUSTOMER, label:OVERVIEW_CARD_CATEGORY_LABELS.customer },
  { value: OVERVIEW_CARD_CATEGORY.ECOMMERCE, label:OVERVIEW_CARD_CATEGORY_LABELS.ecommerce },
  { value: OVERVIEW_CARD_CATEGORY.GENERIC, label:OVERVIEW_CARD_CATEGORY_LABELS.generic },
];

  return <Popup
  blurBackground={false}
  open
  disableScroll 
  showCloseOnTop
  showNotes
  showButtons
  title={<div className='card-selector-header'>
            <div className='card-selector-header-label'>Add / Remove Cards to Dashboard</div>  
            <input  className='card-selector-header-input' type='text' placeholder='Search keyword' value={keyword} onChange={(e)=>setKeyword(e.target.value.toLowerCase())} />
            {/* <Input placeholder="Search chart" type="text" value={keyword} onChange={(val)=>setKeyword(val.toLowerCase().trim())} /> */}
        </div>}
  note={''}
  showCloseButton
  showOkButton
  okButtonText={'OK'}
  closeButtonText={'SAVE'}
  enableCloseOnBackgoundClick={false}
  onClose={onPopupClose}
  onButtonClick={saveRecord} 
  noPadding
  className="card-selector-popup"
> 
<div style={{width:'600px', minHeight:'500px'}}>
     
      {!keyword.length &&
allCategories.map(c=>{
  return   <div className='card-group'>
    <div className='card-category'>{c.label}</div>
    <div className='card-category-inner'>
      {getFilteredCards(c).map(d=>  <ListItem 
                  key={d.id || d.chart_type} 
                  label={d.name} 
                  desc={CARD_TYPE_LIST_ITEMS[d.type]?.description} 
                  icon={CARD_TYPE_LIST_ITEMS[d.type]?.icon} switch 
                  value={d.visible} 
                  onChange={(val)=>setVisible(d, val)} ></ListItem>
              )}
              </div>
              </div>
})
      }
      {
        keyword.length && <div className='card-group'>
        <div className='card-category'>Search result for: "{keyword}"</div>
        <div className='card-category-inner'>
          {getFilteredCards(null).map(d=>  <ListItem 
                      key={d.id || d.chart_type} 
                      label={OVERVIEW_CARD_CATEGORY_LABELS[d.category] + ' : ' + d.name} 
                      desc={CARD_TYPE_LIST_ITEMS[d.type]?.description} 
                      icon={CARD_TYPE_LIST_ITEMS[d.type]?.icon} switch 
                      value={d.visible} 
                      onChange={(val)=>setVisible(d, val)} ></ListItem>
                  )}
                  </div>
                  </div>

      }
   
      {/* getListData().map(d=> (d.segment || d.tag)?<ListItem
        key={d.id} desc={CARD_TYPE_LIST_ITEMS[d.type]?.description} icon={CARD_TYPE_LIST_ITEMS[d.type]?.icon} switch 
        value={d.visible} onChange={(val)=>setVisible(d, val)} >
         <div style={{width:'100%', marginLeft:'-32px'}} ><Tag color={d.color} icon={d.icon} logo={d.logo} size={'medium'} square={d.segment} label={d.name}/></div>
        </ListItem>
        :
        <ListItem 
          key={d.id} label={d.name} desc={CARD_TYPE_LIST_ITEMS[d.type]?.description} icon={CARD_TYPE_LIST_ITEMS[d.type]?.icon} switch 
          value={d.visible} onChange={(val)=>setVisible(d, val)} ></ListItem>
      )} */}
      
      </div>
  </Popup>;

}

 





export default OverviewCardSelector;

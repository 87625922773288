import React from 'react'; 
import tmo from '../../tmo/tmo.lib';
import { Globals } from '../../Globals'; 
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import CustomerInfo from '../../ShopNow/Components/TopLevel/CustomerInfo';
import Sticker from '../../components/basic/Sticker';
import Icon from '../../components/basic/Icon';
import { ANALYTICS_STATIC_TYPES as STATIC_TYPES } from '../Main';
import Constants from '../../System/StaticVar/Constants';
 
export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/session/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/session/list'),
    gotoView: (id) => tmo.ui.global.openPage('/session/' + id),
    gotoUser: (id) => tmo.ui.global.openPage('/users/' + id),

};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading Session Report: ' + data.name,
        task_input: { session_id: data.id },
        related_module_id: 'session',
        related_record_id: data.id,
    });

const defaultCities = [
    { label: 'Beijing', value: 'Beijing' },
    { label: 'Shanghai', value: 'Shanghai' },
    { label: 'Guangzhou', value: 'Guangzhou' },
    { label: 'Shenzhen', value: 'Shenzhen' },
    { label: 'Qingdao', value: 'Qingdao' },
    { label: 'Nanjing', value: 'Nanjing' },
    { label: "Xi'an", value: 'xian' },
    { label: 'Chengdu', value: 'Chengdu' },
    { label: 'Chongqing', value: 'Chongqing' },
    { label: 'Tianjin', value: 'Tianjin' },
    { label: 'Wuhan', value: 'Wuhan' },
    { label: 'Hangzhou', value: 'Hangzhou' },
    { label: 'Shenyang', value: 'Shenyang' },
    { label: 'Dongguan', value: 'Dongguan' },
    { label: 'Foshan', value: 'Foshan' },
    { label: 'Harbin', value: 'Harbin' },
    { label: 'Dalian', value: 'Dalian' },
    { label: 'Zhengzhou', value: 'Zhengzhou' },
    { label: 'Jinan', value: 'Jinan' },
    { label: 'Changsha', value: 'Changsha' },
    { label: 'Kunming', value: 'Kunming' },
    { label: 'Changchun', value: 'Changchun' },
    { label: 'Ürümqi', value: 'urunqi' },
    { label: 'Shantou', value: 'Shantou' },
    { label: 'Suzhou', value: 'Suzhou' },
];

const getAnalyticsCities = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params: { type: STATIC_TYPES.CITY, keyword },
    });

const getAnalyticsCountries = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params: { type: STATIC_TYPES.COUNTRY, keyword },
    });

const getAnalyticsProvinces = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params: { type: STATIC_TYPES.PROVINCE, keyword },
    });

const getAnalyticsChannels = async (params, keyword) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/channels',
        params: { keyword },
    });

const applicationDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const CATEGORIZATION_REASONS = [
    { label: '0. N/A', tooltip: 'N/A (Unknown)', value: 0 },
    { label: '1. HUMAN', tooltip: 'HUMAN (Not detected as bot)', value: 1 },
    { label: '2. Device type is crawler', tooltip: 'Device type detected as crawler', value: 2 },
    {
        label: '3. Using Electron',
        tooltip: 'Using electron wrapper (Potential automation/crawler tool)',
        value: 3,
    },
    { label: '4. OS value', tooltip: 'Operating System value is not common', value: 4 },
    {
        label: '5. Using Selenium',
        tooltip: 'Using selenium test automation/crawler tool',
        value: 5,
    },
    {
        label: '6. WebDriver available',
        tooltip: 'WebDriver available on browser (Potential crawler)',
        value: 6,
    },
    { label: '7. Using Cordova', tooltip: 'Using cordova to open', value: 7 },
    {
        label: '8. User Agent',
        tooltip: 'Browser user agent including suspicious values or a Search Engine Spider',
        value: 8,
    },
    {
        label: '9. Third Party User Agent ',
        tooltip: 'Third Party User Agent (Not using a browser)',
        value: 9,
    },
    {
        label: '10. Google translate',
        tooltip: 'Using google translate as proxy or translating page',
        value: 10,
    },
    {
        label: '11. Url is not same',
        tooltip: 'Url is not same as website url (Potential iframe or proxy)',
        value: 11,
    },
    { label: '12. Screen size', tooltip: 'Screen size', value: 12 },
    {
        label: '13. Short session time',
        tooltip: 'Session time is short and no action.  (No second ping winthin 5 minutes)',
        value: 13,
    },
    {
        label: '14. Short session time from desktop',
        tooltip: 'Session time is short for desktop device. (No second ping winthin 60 seconds)',
        value: 14,
    },
    {
        label: '15. No action',
        tooltip: 'User did not create any action other than first visit',
        value: 15,
    },
];

const SESSION_STATUS = [
    { label: '1. ACTIVE', tooltip: 'User is actively watchin website', value: 1 },
    { label: '2. PAUSED', tooltip: 'User switched another tab / app', value: 2 },
    { label: '3. ENDED', tooltip: 'User left the app', value: 3 },
    {
        label: '4. UNKNOWN',
        tooltip:
            'Session status unknown. Usually happens with bad network connection / suspicious user activity.',
        value: 4,
    },
    {
        label: '4. WILL END',
        tooltip:
            'Session will end soon.',
        value: 5,
    },
];

const boolDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const categorizationDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: CATEGORIZATION_REASONS,
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const sessionstatusDropdownProps = {
    valueField: 'value',
    labelField: 'label',
    options: SESSION_STATUS,
    enableSearchInput: true,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};
const cityDropdownProps = {
    valueField: 'id',
    labelField: 'label',
    options: defaultCities,
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsCities,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getAnalyticsCities },
};

const osDropdown = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const browserDropdown = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const countryDropdownProps = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsCountries,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getAnalyticsCountries },
};

const provinceDropdownProps = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsProvinces,
    searchOptions: { enable: true, searchOnServer: true, onSearch: getAnalyticsProvinces },
};

const channelDropdownProps = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: true,
    searchOnServer: true,
    onSearch: getAnalyticsChannels,
    searchOptions: { enable: true, searchOnServer: false },
};

const languageDropdown = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const getAnalyticsTypesOptions = async (params) =>
    await Globals.callApi({
        url: 'analytics/analytics_type/list',
        params,
    });

const deviceTypeDropdown = {
    valueField: 'id',
    labelField: 'label',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },

    // {
    //     title: 'Customer',
    //     index: 1,
    //     visible: true,
    //     type: 'parent',
    //     width: 150,
    //     key: 'customer',
    //     field: 'customer',
    //     db_field: 'customer_id',
    //     stickLeft: true,
    //     stickLeftPosition: '50px',
    //     disableGrouping: true,
    //     disableSort: true,
    //     disableFilter: true,
    //     template: ({ item }) => {

    //         if (item?.customer) {
    //             return (
    //                 <span>
    //                     <CustomerInfo
    //                         id={item?.customer?.id}
    //                         avatar={item?.customer?.avatar ?? '/noavatar.png'}
    //                         name={item?.customer?.name || 'Guest'}
    //                         description={item?.customer?.desc}
    //                         customer={item?.customer}
    //                         active={item?.customer?.active}
    //                         />
                       
    //                 </span>
    //             );
    //         }
    //         else {
    //             return <span>N/A</span>
    //         }
    //     },
    //     children: [
    //         {
    //             title: 'Customer',
    //             type: 'text',
    //             key: 'col_1_1',
    //             field: 'related_customer_id',
    //             db_field: 'related_customer_id',
    //         },
    //     ],
    // },

    {
        title: 'User',
        index: 2,
        visible: true,
        type: 'parent',
        width: 150,
        key: 'user_id',
        field: 'user_id',
        db_field: 'user_id',
        disableGrouping: true,
        disableSort: true,
        disableFilter: true,
        template: ({ item }) => {

            if (item.is_human && item?.users?.length) {
                return (
                    <span>
                        <div className='session-user'>
                            <div className='session-user-info'>
                                <b>{item?.users[0]?.name || 'N/A'}</b><br />
                                {item?.users[0]?.description}
                            </div>
                            {/* <IconButton
                                className="grid-goto-button"
                                name="open_in_new"
                                onClick={() => Navigations.gotoUser(item.id)}
                            /> */}
                        </div>
                    </span>
                );
            }
            else {
                return (
                    <span>
                        Guest User
                    </span>
                );
            }
        }
    },

    {
        title: 'Session Time (sec.)',
        type: 'text',
        width: 100,
        key: 'session_time',
        disableGrouping: true,
        disableFilter: true,
        disableSort: true,
        index: 3,
        visible: true,
        mVisible: true,
        field: 'session_time',
        db_field: 'session_time',
        template: ({ item }) => <span>{tmo.string.msToTime(item.session_time * 1000, '(hh):mm:ss')} </span>,

    },
    {
        title: 'Session Id',
        type: 'text',
        width: 100,
        key: 'id',
        disableGrouping: true,
        index: 4,
        visible: true,
        mVisible: true,
        field: 'id',
        db_field: 'id',
    },

    {
        title: 'Created At',
        type: 'datetime',
        width: 110,
        key: 'created_at',
        disableGrouping: true,
        index: 5,
        visible: true,
        mVisible: true,
        field: 'created_at',
        db_field: 'created_at',
    },


    {
        title: 'Session key',
        type: 'text',
        width: 100,
        key: 'key',
        disableGrouping: true,
        index: 7,
        visible: true,
        mVisible: true,
        field: 'key',
        db_field: 'key',
    },
    {
        title: 'Visitor Id',
        type: 'text',
        width: 100,
        key: 'col_2',
        disableGrouping: true,
        index: 9,
        visible: true,
        mVisible: true,
        field: 'visitor_id',
        db_field: 'visitor_id',
    },

    {
        title: 'Third Party',
        type: 'text',
        width: 100,
        key: 'col_third_party_id',
        disableGrouping: true,
        disableFilter: true,
        index: 10,
        visible: true,
        mVisible: true,
        field: 'third_party_id',
        db_field: 'third_party_id',
        template: ({ item }) => <span>{item.third_party?.label}</span>,

    },
    {
        title: 'Channel',
        type: 'dropdown',
        width: 100,
        key: 'col_3',
        disableGrouping: true,
        index: 12,
        visible: true,
        mVisible: true,
        field: 'channel_id',
        db_field: 'channel_id',
        dropdownProps: channelDropdownProps,
        template: ({ item }) => <span>{Constants.CHANNELS[item.channel]}</span>,
    },
    {
        title: 'Referrer',
        type: 'text',
        width: 150,
        key: 'col_4',
        disableGrouping: true,
        index: 14,
        visible: true,
        mVisible: true,
        multiline: true,
        field: 'referrer',
        db_field: 'referrer',
        template: ({ item }) => (
            <span>
                <a href={item.referrer} target="_blank">
                    {tmo.helpers
                        .readOr(item, 'referrer', 'N/A')
                        .replace('http://www.', '')
                        .replace('https://www.', '')}
                </a>
            </span>
        ),
    },
    {
        title: 'Referral Code',
        type: 'text',
        width: 100,
        key: 'referral_code',
        disableGrouping: true,
        index: 16,
        visible: true,
        mVisible: true,
        field: 'referral_code',
        db_field: 'referral_code',
    },

    {
        title: 'Entry Url',
        type: 'text',
        width: 200,
        key: 'entry_url',
        disableGrouping: true,
        multiline: true,
        index: 18,
        visible: true,
        mVisible: true,
        field: 'entry_url',
        db_field: 'entry_url',
        template: ({ item }) => (
            <span>
                <a href={item.entry_url} target="_blank">
                    {tmo.helpers
                        .readOr(item, 'entry_url', 'N/A')
                        .replace('http://www.', '')
                        .replace('https://www.', '')}
                </a>
            </span>
        ),
    },

    // {
    //     title: 'Time spent',
    //     type: 'text',
    //     width: 80,
    //     key: 'time_spent',
    //     disableGrouping: true,
    //     index: 13,
    //     visible: true,
    //     mVisible: true,
    //     field: 'last_pinged_at',
    //     db_field: 'last_pinged_at',
    //     template: ({ item }) => <span>
    //         {tmo.string.printTime(new Date('01.01.2000 00:00:00').getTime() + new Date(item.last_pinged_at).getTime()-new Date(item.created_at).getTime())}
    //      </span>,
    // },

    {
        title: 'Bot',
        type: 'parent',
        width: 60,
        key: 'bot',
        disableGrouping: true,
        index: 20,
        visible: true,
        mVisible: true,
        db_field: 'bot',
        template: ({ item }) => (
            <span>
                {item.bot && (
                    <Icon
                        tooltip={
                            'Robot: ' +
                            (CATEGORIZATION_REASONS.find(
                                (r) => r.value === item?.categorization_reason
                            )?.tooltip || '') +
                            '. <br/><br/> User Agent: ' +
                            item.parameters.user_agent
                        }
                        style={{ fontSize: '150%', opacity: 0.8 }}
                        name="robot_2"
                    />
                )}
                {!item.bot && !item.crawler && (
                    <Icon
                        tooltip="Human"
                        style={{
                            fontSize: '130%',
                            backgroundColor: '#3d54b5',
                            color: '#fff',
                            borderRadius: '4px',
                            padding: '3px',
                            marginRight: '2px',
                        }}
                        name="person"
                    />
                )}
                {item.crawler && !item.bot && (
                    <Icon
                        tooltip={
                            'Potential crawler: ' +
                            (CATEGORIZATION_REASONS.find(
                                (r) => r.value === item?.categorization_reason
                            )?.tooltip || '') +
                            '. <br/><br/> User Agent: ' +
                            item.parameters.user_agent
                        }
                        style={{
                            fontSize: '130%',
                            backgroundColor: '#ff9900',
                            color: '#fff',
                            borderRadius: '4px',
                            padding: '3px',
                            marginRight: '2px',
                        }}
                        name="detection_and_zone"
                    />
                )}
                {item.suspicious && !item.bot && (
                    <Icon
                        tooltip={
                            'Suspicious: ' +
                            (CATEGORIZATION_REASONS.find(
                                (r) => r.value == item?.categorization_reason
                            )?.tooltip || '') +
                            '. <br/><br/> User Agent: ' +
                            item.parameters.user_agent
                        }
                        style={{ fontSize: '130%', color: '#ff0000' }}
                        name="report"
                    />
                )}
            </span>
        ),
        children: [
            {
                title: 'Is Bot',
                dropdownProps: boolDropdownProps,
                type: 'bool',
                key: 'col_bot',
                field: 'bot',
                db_field: 'bot',
            },
            {
                title: 'Is Crawler',
                dropdownProps: boolDropdownProps,
                type: 'bool',
                key: 'col_crawler',
                field: 'crawler',
                db_field: 'crawler',
            },
            {
                title: 'Is Suspicious',
                dropdownProps: boolDropdownProps,
                type: 'bool',
                key: 'col_suspicious',
                field: 'suspicious',
                db_field: 'suspicious',
            },
        ],
    },

    {
        title: 'UTM',
        type: 'text',
        width: 160,
        key: 'utm',
        disableGrouping: true,
        disableSort: true,
        disableFilter: true,
        index: 21,
        visible: true,
        mVisible: true,
        field: 'entry_url',
        db_field: 'entry_url',
        template: ({ item }) => {
            if (item.utm && item.utm.source) {
                return (
                    <span className="utm-column">
                        {item.utm.source ? (
                            <div data-tooltip={'Source: ' + item.utm.source}>
                                Source: {item.utm.source}
                            </div>
                        ) : (
                            ''
                        )}
                        {item.utm.campaign ? (
                            <div data-tooltip={'Campaign: ' + item.utm.campaign}>
                                Campaign: {item.utm.campaign}
                            </div>
                        ) : (
                            ''
                        )}
                        {item.utm.medium ? (
                            <div data-tooltip={'Medium: ' + item.utm.medium}>
                                Medium: {item.utm.medium}
                            </div>
                        ) : (
                            ''
                        )}
                        {item.utm.term ? (
                            <div data-tooltip={'Term: ' + item.utm.term}>Term: {item.utm.term}</div>
                        ) : (
                            ''
                        )}
                        {item.utm.content ? (
                            <div data-tooltip={'Content: ' + item.utm.content}>
                                Content: {item.utm.content}
                            </div>
                        ) : (
                            ''
                        )}
                    </span>
                );
            } else {
                return <span>N/A</span>;
            }
        },
    },

    {
        title: 'Flagged by',
        type: 'dropdown',
        width: 100,
        key: 'categorization_reason',
        disableGrouping: true,
        index: 22,
        visible: false,
        mVisible: false,
        field: 'categorization_reason',
        db_field: 'categorization_reason',
        dropdownProps: categorizationDropdownProps,
        template: ({ item }) => (
            <span>
                {CATEGORIZATION_REASONS.find((r) => r.value === item?.categorization_reason)
                    ?.label || ''}
            </span>
        ),
    },
    {
        title: 'Application',
        type: 'dropdown',
        width: 220,
        key: 'application_id',
        dropdownProps: applicationDropdownProps,
        index: 23,
        visible: false,
        mVisible: false,
        disableFilter: true,
        field: 'application_id',
        db_field: 'application_id',
        template: ({ item }) => <ApplicationTag application={item.application} />,
    },
    {
        title: 'Mobile',
        type: 'bool',
        width: 60,
        key: 'is_mobile',
        dropdownProps: boolDropdownProps,
        index: 24,
        visible: true,
        mVisible: true,
        field: 'is_mobile',
        db_field: 'is_mobile',
        template: ({ item }) => (
            <span>
                {item.is_mobile ? (
                    <Icon name="phone_iphone" tooltip="Mobile Device" />
                ) : (
                    <Icon name="desktop_windows" tooltip="Desktop Device" />
                )}
            </span>
        ),
    },
    {
        title: 'Os',
        type: 'dropdown',
        width: 120,
        key: 'os_id',
        dropdownProps: osDropdown,
        index: 27,
        visible: true,
        mVisible: true,
        field: 'os_id',
        db_field: 'os_id',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.os, 'label', 'N/A')}</span>,
    },
    {
        title: 'Browser',
        type: 'dropdown',
        width: 120,
        key: 'browser_id',
        dropdownProps: browserDropdown,
        index: 29,
        visible: true,
        mVisible: true,
        field: 'browser_id',
        db_field: 'browser_id',
        template: ({ item }) => (
            <span data-tooltip={item.parameters.user_agent}>
                {tmo.helpers.readOr(item.browser, 'label', 'N/A')}
            </span>
        ),
    }, 

    {
        title: 'Device Type',
        type: 'dropdown',
        width: 120,
        key: 'device_type_id',
        dropdownProps: deviceTypeDropdown,
        index: 32,
        visible: true,
        mVisible: true,
        field: 'device_type_id',
        db_field: 'device_type_id',
        template: ({ item }) => <span>{tmo.helpers.readOr(item.device_type, 'label', 'N/A')}</span>,
    },
    {
        title: 'Language',
        type: 'dropdown',
        width: 120,
        key: 'main_language_id',
        dropdownProps: languageDropdown,
        index: 34,
        visible: true,
        mVisible: true,
        field: 'main_language_id',
        db_field: 'main_language_id',
        template: ({ item }) => (
            <span>{tmo.helpers.readOr(item.main_language, 'label', 'N/A')}</span>
        ),
    },
    {
        title: 'Ip',
        type: 'text',
        width: 100,
        key: 'ip',
        disableGrouping: true,
        index: 36,
        visible: true,
        mVisible: true,
        field: 'ip',
        db_field: 'ip',
        template: ({ item }) =>
            window.currentUser &&
                window.currentUser.connection &&
                window.currentUser.connection.ip &&
                window.currentUser.connection.ip === item?.parameters?.ip ? (
                <Sticker color="red">{item?.parameters?.ip ?? ''}</Sticker>
            ) : (
                <span>{item?.parameters?.ip ?? ''}</span>
            ),
    },
    {
        title: 'Location',
        index: 40,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 100,
        key: 'country_id',
        field: 'country_id',
        db_field: 'country_id',
        template: ({ item }) =>
            item.country || item.province || item.city ? (
                <span className="location">
                    <span>
                        {' '}
                        {tmo.helpers.readOr(item.country, 'label', ' ')} -{' '}
                        {tmo.helpers.readOr(item.province, 'label', ' ')}
                    </span>
                    {tmo.helpers.readOr(
                        item.city,
                        'label',
                        tmo.helpers.readOr(item.country, 'label', ' ')
                    )}
                </span>
            ) : item.parameters.tznstr ? (
                <span
                    data-tooltip="User location is not accurately detectable so this is what we found for now."
                    className="location-alt"
                    dangerouslySetInnerHTML={{
                        __html: item.parameters.tznstr.split('/').join('<br/>'),
                    }}
                />
            ) : (
                <span className="location">N/A</span>
            ),
        children: [
            {
                title: 'Country/Region',
                type: 'dropdown',
                key: 'col_13_1',
                field: 'country_id',
                dropdownProps: countryDropdownProps,
                db_field: 'country_id',
            },
            {
                title: 'Province',
                type: 'dropdown',
                key: 'col_13_2',
                field: 'province_id',
                dropdownProps: provinceDropdownProps,
                db_field: 'province_id',
            },
            {
                title: 'City',
                type: 'dropdown',
                key: 'col_13_3',
                field: 'city_id',
                dropdownProps: cityDropdownProps,
                db_field: 'city_id',
            },
        ],
    },

    {
        title: 'Screen size',
        type: 'json',
        width: 150,
        key: 'screen_size',
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableGrouping: true,
        index: 42,
        visible: true,
        field: 'parameters',
        db_field: 'parameters',
        children: [
            {
                title: 'screen_width',
                type: 'text',
                key: 'screen_width',
                field: 'screen_width',
                db_field: 'screen_width',
            },
            {
                title: 'screen_height',
                type: 'text',
                key: 'screen_height',
                field: 'screen_height',
                db_field: 'screen_height',
            },
        ],
        template: ({ item }) => (
            <>
                {item.parameters &&
                    item.parameters.screen_width &&
                    item.parameters.screen_height && (
                        <div>
                            {item.parameters.screen_width + ' x ' + item.parameters.screen_height}
                        </div>
                    )}
            </>
        ),
    },

    {
        title: 'Parameters',
        type: 'json',
        width: 150,
        key: 'parameters',
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        index: 44,
        visible: false,
        mVisible: false,
        multiline: true,
        field: 'parameters',
        db_field: 'parameters',
        children: [
            {
                title: 'browser',
                type: 'text',
                key: 'col_14_1',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'browser_version',
                type: 'text',
                key: 'col_14_2',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'user_agent',
                type: 'text',
                key: 'col_14_3',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'device_type',
                type: 'text',
                key: 'col_14_4',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'device_model',
                type: 'text',
                key: 'col_14_5',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'device_brand',
                type: 'text',
                key: 'col_14_6',
                field: 'keywords',
                db_field: 'keywords',
            },
            { title: 'os', type: 'text', key: 'col_14_7', field: 'keywords', db_field: 'keywords' },
            {
                title: 'main_language',
                type: 'text',
                key: 'col_14_8',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'languages',
                type: 'text',
                key: 'col_14_9',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'ip',
                type: 'text',
                key: 'col_14_10',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'screen_width',
                type: 'text',
                key: 'col_14_11',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'screen_height',
                type: 'text',
                key: 'col_14_12',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'screen_height',
                type: 'text',
                key: 'col_14_13',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'screen_pixel_depth',
                type: 'text',
                key: 'col_14_14',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'screen_color_depth',
                type: 'text',
                key: 'col_14_15',
                field: 'keywords',
                db_field: 'keywords',
            },
        ],
        template: ({ item }) => (
            <>
                {item.parameters &&
                    item.parameters.browser &&
                    item.parameters.browser.length > 0 && (
                        <div>Browser: {item.parameters.browser}</div>
                    )}
                {item.parameters &&
                    item.parameters.device_type &&
                    item.parameters.device_type.length > 0 && (
                        <div>Device: {item.parameters.device_type}</div>
                    )}
                {item.parameters &&
                    item.parameters.device_brand &&
                    item.parameters.device_brand.length > 0 && (
                        <div>Brand: {item.parameters.device_brand}</div>
                    )}
                {item.parameters &&
                    item.parameters.device_model &&
                    item.parameters.device_model.length > 0 && (
                        <div>Model: {item.parameters.device_model}</div>
                    )}
                {item.parameters &&
                    item.parameters.main_language &&
                    item.parameters.main_language.length > 0 && (
                        <div>Language: {item.parameters.main_language}</div>
                    )}
                {item.parameters && item.parameters.ip && item.parameters.ip.length > 0 && (
                    <div>IP: {item.parameters.ip}</div>
                )}
            </>
        ),
    },
    {
        title: 'Traits',
        type: 'json',
        width: 150,
        key: 'traits',
        disableGrouping: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableMore: true,
        disablePermission: true,
        multiline: true,
        index: 46,
        visible: false,
        mVisible: false,
        field: 'traits',
        db_field: 'traits',
        children: [
            {
                title: 'email',
                type: 'text',
                key: 'col_15_1',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'mobile',
                type: 'text',
                key: 'col_15_2',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'userid',
                type: 'text',
                key: 'col_15_3',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'username',
                type: 'text',
                key: 'col_15_4',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'wechat_unionid',
                type: 'text',
                key: 'col_15_5',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'wechat_openid',
                type: 'text',
                key: 'col_15_6',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'chinese_id',
                type: 'text',
                key: 'col_15_7',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'taobao_openid',
                type: 'text',
                key: 'col_15_8',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'matomo_idvisitor',
                type: 'text',
                key: 'col_15_9',
                field: 'keywords',
                db_field: 'keywords',
            },
            {
                title: 'jd_openid',
                type: 'text',
                key: 'col_15_10',
                field: 'keywords',
                db_field: 'keywords',
            },
        ],
        template: ({ item }) => (
            <>
                {item.traits && item.traits.email && item.traits.email.length > 0 && (
                    <div>E-mail: {item.traits.email}</div>
                )}
                {item.traits && item.traits.mobile && item.traits.mobile.length > 0 && (
                    <div>Mobile: {item.traits.mobile}</div>
                )}
                {item.traits && item.traits.userid && item.traits.userid.length > 0 && (
                    <div>UserId: {item.traits.userid}</div>
                )}
                {item.traits && item.traits.username && item.traits.username.length > 0 && (
                    <div>Username: {item.traits.username}</div>
                )}
                {item.traits &&
                    item.traits.matomo_idvisitor &&
                    item.traits.matomo_idvisitor.length > 0 && (
                        <div>Matomo ID: {item.traits.matomo_idvisitor}</div>
                    )}
                {item.traits &&
                    item.traits.wechat_unionid &&
                    item.traits.wechat_unionid.length > 0 && (
                        <div>WeChat UnionId: {item.traits.wechat_unionid}</div>
                    )}
                {item.traits &&
                    item.traits.taobao_openid &&
                    item.traits.taobao_openid.length > 0 && (
                        <div>WeChat OpenId: {item.traits.taobao_openid}</div>
                    )}
            </>
        ),
    },
    {
        title: 'Last Ping',
        type: 'datetime',
        width: 110,
        key: 'last_pinged_at',
        disableGrouping: true,
        index: 48,
        visible: true,
        mVisible: true,
        field: 'last_pinged_at',
        db_field: 'last_pinged_at',
    },

    {
        title: 'Ended at',
        type: 'datetime',
        width: 110,
        key: 'ended_at',
        disableGrouping: true,
        index: 50,
        visible: true,
        mVisible: true,
        field: 'ended_at',
        db_field: 'ended_at',
        defaultText: 'N/A',
    },
    {
        title: 'Status',
        type: 'dropdown',
        width: 110,
        key: 'status',
        disableGrouping: true,
        index: 52,
        visible: true,
        mVisible: true,
        field: 'status',
        db_field: 'status',
        dropdownProps: sessionstatusDropdownProps,
        template: ({ item }) => (
            <span>{SESSION_STATUS.find(s => s.value.toString() === item.status)?.label}</span>
        ),
    },
    {
        title: '#',
        index: 140,
        visible: true,
        mVisible: true,
        type: 'action',
        key: 'col_actions',
        width: 60,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableMore: true,
        disableGrouping: true,
        disablePermission: true,
        stickRight: true,
        template: ({ item }) => (
            <>
                {/* <IconButton
                    className="grid-open-button"
                    name="open_in_new"
                    onClick={() => Navigations.gotoView(item.id)}
                /> */}
                {/* <IconButton
                    className="grid-edit-button"
                    name="edit"
                    onClick={() => Navigations.gotoEdit(item.id)}
                /> */}
            </>
        ),
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'session_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: false,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: false,
    export: true,
    tagging: false,
    bookmark: false,
    share: false,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: false,
    search: true,
    paging: true,
    inlineEditing: false,
};

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Session',
        simpleLabel: 'ShopNow Session',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Session',
        simpleLabel: 'Wechat Session',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Session',
        simpleLabel: 'Weibo Session',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Session',
        simpleLabel: 'TikTok Session',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Session',
        simpleLabel: 'LinkedIn Session',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Session', simpleLabel: 'QQ Session', key: 'qq_followers', value: false },
    { label: 'Display App Session', simpleLabel: 'App Session', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = async (store) => {
    countryDropdownProps.options = []; // TODO: maybe set some defaults
    provinceDropdownProps.options = []; // TODO: maybe set some defaults
    cityDropdownProps.options = []; // TODO: maybe set some defaults
    osDropdown.options = await getAnalyticsTypesOptions({
        type: STATIC_TYPES.OS,
    });
    browserDropdown.options = await getAnalyticsTypesOptions({
        type: STATIC_TYPES.BROWSER,
    });
    languageDropdown.options = await getAnalyticsTypesOptions({
        type: STATIC_TYPES.LANGUAGE,
    });
    console.log('deviceTypeDropdown', deviceTypeDropdown.options);
    deviceTypeDropdown.options = await getAnalyticsTypesOptions({
        type: STATIC_TYPES.DEVICE_TYPE,
    });
    channelDropdownProps.options = getAnalyticsChannels({});

    applicationDropdownProps.options = Globals.currentUser.analytics_apps;
};

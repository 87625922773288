import React from 'react';

import './CustomerList.scss';
import './CustomerList.print.scss';

import tmo from '../../tmo/tmo.lib';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';
import { CustomerProfileApi } from '../Edit/CustomerProfile.Api';
import { CustomerListDataGridStore } from './CustomerList.GridStore';

function CustomerList(props) {
    const gotoEdit = (id) => tmo.ui.global.openPage('/customer/edit/' + id);

    let store =  CustomerListDataGridStore.duplicate('grid_registered_customers');
    return (
        <GridListPage
            searchParameters={{registered_users:true, guest_users:false}}
            store={store}
            onSearch={CustomerProfileApi.search}
            onDelete={CustomerProfileApi.remove}
            onHistory={CustomerProfileApi.history}
            onGotoEdit={gotoEdit}
            recordType="customer"
        />
    );
}

export default CustomerList;

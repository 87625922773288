import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import tmo from '../../../tmo/tmo.lib';
import CustomerInfo from '../../../ShopNow/Components/TopLevel/CustomerInfo';
import Card from '../../../components/basic/Card';
import Sticker, { getRateColor } from '../../../components/basic/Sticker';
import ListItem from '../../../components/basic/ListItem';

const StyledOnlineStatus = styled.div`
    margin-left: 8px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: #4caf50;
`;

const TopPanel = styled.div`
    display: flex;
    padding: 16px;
    flex-direction: column;
    color: #3f51b5;
    border-bottom: 1px solid #eeeeee;

    strong {
        font-size: 110%;
    }
`;

const StyledWrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: ${(props) => props.$justify || 'flex-start'};
    flex-direction: ${(props) => props.$direction || 'row'};
`;

const BottomPanel = styled.div`
    display: flex;
    flex-direction: column;

    ${StyledWrapper} {
        border-bottom: 1px solid #eeeeee;
        padding: 16px;

        strong {
            font-size: 110%;
        }
    }

    .list-container {
        display: flex;
        flex-direction: column;
        max-height:330px;
        overflow: auto;

        .active-customer-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 8px 16px;
            border-bottom: 1px solid #eeeeee;
        }
    }
`;

function ActiveUsersPanel(props) {
    const { data } = props;
    const location = useLocation();

    const isAnalytics = useCallback(() => location.pathname.includes('analytics'), [location]);

    const realCustomerData = data.filter(
        (item) =>
            item.is_human &&
            new Date(item.last_pinged_at).getTime() - new Date(item.started_at).getTime() > 8000
    );

    const getTimeSpent = (item) =>
        new Date(item.last_pinged_at).getTime() - new Date(item.started_at).getTime();


    let ips = {};
    let visitors = {};
    data.forEach(d => {
        ips[d.ip] = ips[d.ip] || 0;
        ips[d.ip]++;
        visitors[d.visitor_id] = true;
    });
    let sorted = Object.keys(ips).map(i=>({ip:i, count:ips[i]})).sort((a, b) => b.count - a.count).filter(s=>s.count>1);
    

    const timeMsToString = (timeMs)=>{ 

                    let time = tmo.string.msToTime(timeMs,'(hh):mm:ss');

                    if (timeMs >= 1000 * 60 * 60 * 24) {
                        time = '1d+';
                    }
                    
                    return  time;

                    
    }

    const calculateActiveUserTime = (data)=>{
        const timeMs =
                        new Date(data.last_pinged_at).getTime() -
                        new Date(data.started_at).getTime();

                    let time = timeMsToString(timeMs);  
                    
                    return {time, timeMs};

                    
    }
    const getActiveUsersNow = (data)=>{
        let visitors = {};
        for(const d of data){ 
            let {time, timeMs} = calculateActiveUserTime(d);
            if(timeMs<5){
                continue;
            }
            let  { customer, started_at, last_pinged_at, current_page, session_id } = d;

            visitors[d.visitor_id] = visitors[d.visitor_id] || {
                sessions:[],
                time:'',
                timeMs:0,
                refCode:'',
                customer:null,
                id:'',
                id:''
            };
           
           let session = { 
                    customer, 
                    started_at, 
                    last_pinged_at, 
                    current_page, 
                    session_id,
                    time : time
            };
            visitors[d.visitor_id].ip = d.ip;
            visitors[d.visitor_id].sessions.push(session);

            visitors[d.visitor_id].id =  visitors[d.visitor_id].sessions[0].session_id;
            visitors[d.visitor_id].customer = visitors[d.visitor_id].customer || session.customer;
            visitors[d.visitor_id].refCode = visitors[d.visitor_id].refCode || session.current_page?.ref_code;
      
            visitors[d.visitor_id].timeMs =  visitors[d.visitor_id].timeMs<timeMs? timeMs : visitors[d.visitor_id].timeMs; 
            visitors[d.visitor_id].time =  timeMsToString(visitors[d.visitor_id].timeMs);
        }
       return  Object.keys(visitors).map(v=>visitors[v])
            .sort((a, b) => b.timeMs - a.timeMs)
            .map(v => { 
                    let desc = '';

                    if (v.ref_code) {
                        desc = 'Referral: ' + v.ref_code;
                    } else {
                        desc = `Sessions(${v.sessions.length}): ${v.sessions.map(s=>s.session_id).join(', ')}` ;
                    }

                    return (
                        <div
                            key={`active-customer-${v.id}`}
                            className="active-customer-item"
                        >
                            <CustomerInfo
                                id={v.customer?.id}
                                customer={v.customer}
                                name={v.customer?.name}
                                description={desc}
                                avatar={v.customer?.avatar}
                                active
                            />
                            <Sticker color={getRateColor(v.timeMs)}>
                                
                                {!isNaN(v.timeMs) ? v.time : '00:00:00'}
                            </Sticker>
                        </div>
                    );
                }
            )
    }

    let customers = getActiveUsersNow(data.filter(d=>d.customer && d.customer.name));
    let nonCustomers = getActiveUsersNow(data.filter(d=>(!d.customer || !d.customer.name) &&  d.last_pinged_at ));
    return (
        <>
        <Card noPadding>
            <TopPanel $direction="column">
                <StyledWrapper $direction="column">
                    <StyledWrapper $justify="space-between">
                        <StyledWrapper>
                            <strong>Active Users Now</strong>
                            <StyledOnlineStatus />
                        </StyledWrapper>
                        <strong>{customers.length?customers.length+' + ':''}{nonCustomers.length}</strong>
                    </StyledWrapper>
                    {!isAnalytics() && (
                        <StyledWrapper $justify="space-between">
                            <span>During Checkout</span>
                            <span>{data?.checkout || 0}</span>
                        </StyledWrapper>
                    )}
                </StyledWrapper>
            </TopPanel>
            <BottomPanel>
                <StyledWrapper $justify="space-between">
                    <strong>Latest Users</strong>
                    <span>Time spent</span>
                </StyledWrapper>
                <div className="list-container" style={sorted.length>0?{ maxHeight:300}: {maxHeight:460}}>
                     {customers?.length>0 && <>{customers}<hr/></>}
                     {nonCustomers}
                    
                </div>
            </BottomPanel>
         
            
        </Card>
        {sorted.length>0&& 
            <>
            <br/>
            <Card noPadding>
            <BottomPanel>
                <StyledWrapper $justify="space-between">
                        <strong>IPs with multiple sessions</strong>
                        <span>Sessions</span>
                    </StyledWrapper>
                <div className="list-container" style={{maxHeight:200, minHeight:100}}>
                {sorted.map(s=>(
                    <ListItem key={s.ip} label={s.ip} >{s.count} sessions</ListItem>
                ))}
                </div>
                </BottomPanel>
            </Card>
            </>
        }
        </>
    );
}

export default ActiveUsersPanel;

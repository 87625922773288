import './HighChartWrappper.scss';
import React, { useEffect, useRef, useState } from 'react';
// import Icon from './Icon';
import IconButton from './IconButton';
import { Resizable } from 're-resizable';
import { ReactSortable } from 'react-sortablejs';
import Icon from './Icon';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'



export const LineChartMockData = {
    chart: {
        // height:  props.height || '100%',

        type: 'line',
        custom: {},
        events: {

        }
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    title: {
        text: null
    },
    // subtitle: {
    //     text: 'Source: <a href="https://www.ssb.no/transport-og-reiseliv/faktaside/bil-og-transport">SSB</a>'
    // },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        series: {
            animation: false,
            allowPointSelect: true,
            cursor: 'pointer',
            borderRadius: 4,
            dataLabels: [{
                enabled: true,
                distance: 20,
                format: '{point.name}'
            }, {
                enabled: true,
                distance: -15,
                format: '{point.percentage:.0f}%',
                style: {
                    fontSize: '0.9em'
                }
            }],
            showInLegend: true
        }
    },
    xAxis: {
        title: {
            text: null
        },
        //   labels: {
        //       enabled: false
        //   }
    },
    yAxis: {
        title: {
            text: null
        },
        //   labels: {
        //       enabled: false
        //   }
    },
    series: []
    
    // [{
    //     name: 'Registrations',
    //     colorByPoint: true,
    //     innerSize: '75%',
    //     data: [{
    //         name: 'EV',
    //         y: 23.9
    //     }, {
    //         name: 'Hybrids',
    //         y: 12.6
    //     }, {
    //         name: 'Diesel',
    //         y: 37.0
    //     }, {
    //         name: 'Petrol',
    //         y: 26.4
    //     }]
    // }]
};




export const BarChartMockData = {
    chart: {
        // height:  props.height || '100%',

        type: 'bar',
        // custom: {},
        // events: {

        // }
    },
    // accessibility: {
    //     point: {
    //         valueSuffix: '%'
    //     }
    // },
    title: {
        text: null
    },
    // subtitle: {
    //     text: 'Source: <a href="https://www.ssb.no/transport-og-reiseliv/faktaside/bil-og-transport">SSB</a>'
    // },
    // tooltip: {
    //     // formatter: function(){ 
    //     //     console.log(this);
    //     //     return (this.y || 0).toFixed(2)+'%';
    //     // },
    //    pointFormat: (this?.y || 0).toFixed(2)+'%'
    // },
    tooltip: {
        formatter: function(){ 
            return (this.y || 0).toFixed(2)+'%';
        }
    },
    legend: {
        enabled: false
    },
    plotOptions: {
        series: {
            animation: false,
            allowPointSelect: false,
            cursor: 'pointer',
            borderRadius: 4,
            dataLabels: [{
                enabled: true,
                 distance: 40,
               // format: '{point.name} : {point.y}',,
                formatter: function(){ 
                    // console.log(this);
                                return this?.point.name+ ' : '+(this?.y || 0).toFixed(2)+'%';
                           
                        }
            }
            
            // {
            //     enabled: true,
            //     distance: -100,
            //     format: '11%',
            //     // style: {
            //     //     fontSize: '1em'
            //     // }
            // }
        
        ],
            showInLegend: true
        }
    },
    xAxis: {  
        visible:false,
        title: {
            text: null
        },
        minorGridLineWidth: 0,
        minorTickLength: 0,
        tickLength: 0,
        enabled:false,
        labels: {
            enabled: false
        }, 
        //   labels: {
        //       enabled: false
        //   }
    },
    yAxis: { 
        
        title: {
            text: null
        },
        //   labels: {
        //       enabled: false
        //   }
    },
    series: []
    // [{
    //     groupPadding:0.01,
    //     name: 'Registrations',
    //     colorByPoint: true,
    //     innerSize: '90%',
    //     data: [{
    //         name: 'EV',
    //         y: 23.9
    //     }, {
    //         name: 'Hybrids',
    //         y: 12.6
    //     }, {
    //         name: 'Diesel',
    //         y: 37.0
    //     }, {
    //         name: 'Petrol',
    //         y: 26.4
    //     }]
    // }]
};



const   MONTH_NAMES_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];



export const ColumnChartMockData = {
    chart: {
        // height:  props.height || '100%',

        type: 'column',
        custom: {},
        events: {

        }
    },
    accessibility: {
        // point: {
        //     valueSuffix: '%'
        // }
    },

     

    navigator: {
        outlineColor:'#fff',
        outlineWidth:0,
        enabled: true,
        liveRedraw: true,
        backgroundColor: '#dddddd',
        
        series: {
            
            type:'column',
            pointPlacement: 0.5,
            pointPadding: 0.25,
            accessibility: {
                enabled: false
            }
        },

       
        xAxis: { 
            labels:{
                formatter: function() { 
                    let numOfPoints = this?.chart?.series[0]?.yData?.length || 365;
                    if(numOfPoints<180){
                        let d = new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.value-1)));
                        return MONTH_NAMES_SHORT[d.getMonth()] + ' ' + d.getDate();
                    }
                    else{
                        return  Highcharts.dateFormat('%d.%m.%Y', new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.value-1))).getTime() );
                    }
                }
            }
        },
       
        yAxis: {
            min: 0, 
            reversed: false
        }
    },

    title: {
        text: null
    },
    // subtitle: {
    //     text: 'Source: <a href="https://www.ssb.no/transport-og-reiseliv/faktaside/bil-og-transport">SSB</a>'
    // },
    tooltip: {
        formatter: function( ) { 
            let numOfPoints = this?.series?.yData?.length || 365;
            console.log('numOfPoints',numOfPoints, this);
            let d = new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.x-1)));
            return MONTH_NAMES_SHORT[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear() + ': <b>' + this.y + '</b>';
            // return  Highcharts.dateFormat('%d.%m.%Y', new Date( new Date().getTime() - (1000 * 60 * 60 * 24 *  (365-this.x))).getTime() ) + ' <br/> <b>' + this.y+'</b>'
         // return Highcharts.dateFormat('%H:%M:%S %Y', this.value);
        }
        
    },
    legend: {
        enabled: false
    },
    rangeSelector: {
        selected: 4
    },


    plotOptions: {
        series: {
            showInNavigator:true, 

            animation: false,
            allowPointSelect: true,
            cursor: 'pointer',
            borderRadius: 4,
            // dataLabels: [{
            //     enabled: true,
            //     distance: 20,
            //     format: '{point.name}'
            // }, {
            //     enabled: true,
            //     distance: -15,
            //     format: '{point.percentage:.0f}%',
            //     style: {
            //         fontSize: '0.9em'
            //     }
            // }],
            showInLegend: true
        }
    },
    xAxis: {
        title: {
            text: null
        },

        
            range: 30,
        

        lineColor:'#dddddd',
        tickColor:'#dddddd',
        labels: {
            formatter: function() { 
                let numOfPoints = this?.chart?.series[0]?.yData?.length || 365;
                if(numOfPoints<180){
                    let d = new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.value-1)));
                    return MONTH_NAMES_SHORT[d.getMonth()] + ' ' + d.getDate();
                }
                else{
                    return  Highcharts.dateFormat('%d.%m.%Y', new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.value-1))).getTime() );
                }
            }
          },
       

        //   labels: {
        //       enabled: false
        //   } 
    },
    yAxis: {
        type: 'datetime',
        title: {
            text: null
        },
        borderColor:'#ffffff',
        borderWidth:0, 
        lineColor:'#ffffff',
        labels: {
            enabled:true,
            formatter: function() {
                
                return  this.value;
             // return Highcharts.dateFormat('%H:%M:%S %Y', this.value);
            }
        },
    },
    plotOptions: {
        column: {
            pointPadding: 0,
            borderWidth: 0,
            colors:['#3d54b5'],
        }
    },
    series: [ 
    ]
};






export const ColumnChartMockDataForCurrency = {
    chart: {
        // height:  props.height || '100%',

        type: 'column',
        custom: {},
        events: {

        }
    },
    accessibility: {
        // point: {
        //     valueSuffix: '%'
        // }
    },

     

    navigator: {
        outlineColor:'#fff',
        outlineWidth:0,
        enabled: true,
        liveRedraw: true,
        backgroundColor: '#dddddd',
        
        series: {
            
            type:'column',
            pointPlacement: 0.5,
            pointPadding: 0.25,
            accessibility: {
                enabled: false
            }
        },

       
        xAxis: { 
            labels:{
                formatter: function() { 
                    let numOfPoints = this?.chart?.series[0]?.yData?.length || 365;
                    if(numOfPoints<180){
                        let d = new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.value-1)));
                        return MONTH_NAMES_SHORT[d.getMonth()] + ' ' + d.getDate();
                    }
                    else{
                        return  Highcharts.dateFormat('%d.%m.%Y', new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.value-1))).getTime() );
                    }
                }
            }
        },
       
        yAxis: {
            min: 0, 
            reversed: false
        }
    },

    title: {
        text: null
    },
    // subtitle: {
    //     text: 'Source: <a href="https://www.ssb.no/transport-og-reiseliv/faktaside/bil-og-transport">SSB</a>'
    // },
    tooltip: {
        formatter: function( ) { 
            let numOfPoints = this?.series?.yData?.length || 365;
            console.log('numOfPoints',numOfPoints, this);
            let d = new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.x-1)));
            return MONTH_NAMES_SHORT[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear() + ': <b>' +   window.currentAccount.currency +' '+ this.y + '</b>';
            // return  Highcharts.dateFormat('%d.%m.%Y', new Date( new Date().getTime() - (1000 * 60 * 60 * 24 *  (365-this.x))).getTime() ) + ' <br/> <b>' + this.y+'</b>'
         // return Highcharts.dateFormat('%H:%M:%S %Y', this.value);
        }
        
    },
    legend: {
        enabled: false
    },
    rangeSelector: {
        selected: 4
    },


    plotOptions: {
        series: {
            showInNavigator:true, 

            animation: false,
            allowPointSelect: true,
            cursor: 'pointer',
            borderRadius: 4,
            // dataLabels: [{
            //     enabled: true,
            //     distance: 20,
            //     format: '{point.name}'
            // }, {
            //     enabled: true,
            //     distance: -15,
            //     format: '{point.percentage:.0f}%',
            //     style: {
            //         fontSize: '0.9em'
            //     }
            // }],
            showInLegend: true
        }
    },
    xAxis: {
        title: {
            text: null
        },

        
            range: 30,
        

        lineColor:'#dddddd',
        tickColor:'#dddddd',
        labels: {
            formatter: function() { 
                let numOfPoints = this?.chart?.series[0]?.yData?.length || 365;
                if(numOfPoints<180){
                    let d = new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.value-1) ) );
                    return MONTH_NAMES_SHORT[d.getMonth()] + ' ' + d.getDate();
                }
                else{
                    return  Highcharts.dateFormat('%d.%m.%Y', new Date( new Date().getTime() - (1000 * 60 * 60 * 24 * (numOfPoints-this.value-1))).getTime() );
                }
            }
          },
       

        //   labels: {
        //       enabled: false
        //   } 
    },
    yAxis: {
        type: 'datetime',
        title: {
            text: null
        },
        borderColor:'#ffffff',
        borderWidth:0, 
        lineColor:'#ffffff',
        labels: {
            enabled:true,
            formatter: function() {
                
                return  window.currentAccount.currency +' '+this.value;
             // return Highcharts.dateFormat('%H:%M:%S %Y', this.value);
            }
        },
    },
    plotOptions: {
        column: {
            pointPadding: 0,
            borderWidth: 0,
            colors:['#3d54b5'],
        }
    },
    series: [
        // {
        //     name: 'Registrations',
        //     colorByPoint: true,
        //     innerSize: '75%',
        //     name: '...',
        //     data: [45321, 140000, 10000, 140500, 19500, 113500]
        // },
        // {
        //     name: 'Registrations',
        //     colorByPoint: true,
        //     innerSize: '75%',
        //     name: '...',
        //     data: [45321, 140000, 10000, 140500, 19500, 113500]
        // }
    ]
};





export const PieChartMockData = {
    chart: {
        // height:  props.height || '100%',

        type: 'pie',
        custom: {},
        // events: {
        //     render() {
        //         const chart = this,
        //             series = chart.series[0];
        //         let customLabel = chart.options.chart.custom.label;

        //         if (!customLabel) {
        //             customLabel = chart.options.chart.custom.label =
        //                 chart.renderer.label(
        //                     'Total<br/>' +
        //                     '<strong>2 877 820</strong>'
        //                 )
        //                     .css({
        //                         color: '#000',
        //                         textAnchor: 'middle'
        //                     })
        //                     .add();
        //         }

        //         const x = series.center[0] + chart.plotLeft,
        //             y = series.center[1] + chart.plotTop -
        //                 (customLabel.attr('height') / 2);

        //         customLabel.attr({
        //             x,
        //             y
        //         });
        //         // Set font size based on chart diameter
        //         customLabel.css({
        //             fontSize: `${series.center[2] / 12}px`
        //         });
        //     }
        // }
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    title: {
        text: null
    },
    // subtitle: {
    //     text: 'Source: <a href="https://www.ssb.no/transport-og-reiseliv/faktaside/bil-og-transport">SSB</a>'
    // },
    tooltip: {
        pointFormat: '<b>{point.percentage:.0f}%</b>'
    },
  

    legend: {
        enabled: false
    },
    plotOptions: {
        series: {
            animation: false,
            allowPointSelect: true,
            cursor: 'pointer',
            borderRadius: 4,
            dataLabels: [{
                enabled: true,
                distance: 20,
                format: '{point.name}'
            }, {
                enabled: true,
                distance: -15,
                format: '{point.percentage:.0f}%',
                style: {
                    fontSize: '0.9em'
                }
            }],
            showInLegend: true
        }
    },
    xAxis: {
        labels: {
            enabled: false
        }
    },
    yAxis: {
        labels: {
            enabled: false
        }
    },

    series: []
    // [{
    //     name: 'Registrations',
    //     colorByPoint: true,
    //     innerSize: '75%',
    //     data: [{
    //         name: 'EV',
    //         y: 23.9
    //     }, {
    //         name: 'Hybrids',
    //         y: 12.6
    //     }, {
    //         name: 'Diesel',
    //         y: 37.0
    //     }, {
    //         name: 'Petrol',
    //         y: 26.4
    //     }]
    // }]
};




export const DonutChartMockData = {
    chart: {
        // height:  props.height || '100%',

        type: 'pie',
        custom: {},
        // events: {
        //     render() {
        //         const chart = this,
        //             series = chart.series[0];
        //         let customLabel = chart.options.chart.custom.label;

        //         if (!customLabel) {
        //             customLabel = chart.options.chart.custom.label =
        //                 chart.renderer.label(
        //                     'Total<br/>' +
        //                     '<strong>2 877 820</strong>'
        //                 )
        //                     .css({
        //                         color: '#000',
        //                         textAnchor: 'middle'
        //                     })
        //                     .add();
        //         }

        //         const x = series.center[0] + chart.plotLeft,
        //             y = series.center[1] + chart.plotTop -
        //                 (customLabel.attr('height') / 2);

        //         customLabel.attr({
        //             x,
        //             y
        //         });
        //         // Set font size based on chart diameter
        //         customLabel.css({
        //             fontSize: `${series.center[2] / 12}px`
        //         });
        //     }
        // }
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    title: {
        text: null
    },
    // subtitle: {
    //     text: 'Source: <a href="https://www.ssb.no/transport-og-reiseliv/faktaside/bil-og-transport">SSB</a>'
    // },
    tooltip: {
        pointFormat: '<b>{point.percentage:.0f}%</b>'
    },
  

    legend: {
        enabled: false
    },
    plotOptions: {
        series: {
            innerSize: '75%',
            animation: false,
            allowPointSelect: true,
            cursor: 'pointer',
            borderRadius: 4,
            dataLabels: [{
                enabled: true,
                distance: 20,
                format: '{point.name}'
            }, {
                enabled: true,
                distance: -15,
                format: '{point.percentage:.0f}%',
                style: {
                    fontSize: '0.9em'
                }
            }],
            showInLegend: true
        }
    },
    xAxis: {
        labels: {
            enabled: false
        }
    },
    yAxis: {
        labels: {
            enabled: false
        }
    },

    series:[]
    
    // [{
    //     name: 'Registrations',
    //     colorByPoint: true,
        
    //     data: [{
    //         name: 'EV',
    //         y: 23.9
    //     }, {
    //         name: 'Hybrids',
    //         y: 12.6
    //     }, {
    //         name: 'Diesel',
    //         y: 37.0
    //     }, {
    //         name: 'Petrol',
    //         y: 26.4
    //     }]
    // }]
};




const text =
    'Chapter 1. Down the Rabbit-Hole ' +
    'Alice was beginning to get very tired of sitting by her sister on ' +
    'the bank, and of having nothing to do: ' +
    'once or twice she had peeped into the book her sister was reading, ' +
    'but it had no pictures or conversations ' +
    'in it, \'and what is the use of a book,\' thought Alice ' +
    '\'without pictures or conversation?\'' +
    'So she was considering in her own mind (as well as she could, for ' +
    'the hot day made her feel very sleepy ' +
    'and stupid), whether the pleasure of making a daisy-chain would be ' +
    'worth the trouble of getting up and picking ' +
    'the daisies, when suddenly a White Rabbit with pink eyes ran close ' +
    'by her.',
    lines = text.replace(/[():'?0-9]+/g, '').split(/[,\. ]+/g),
    wcdata = lines.reduce((arr, word) => {
        let obj = Highcharts.find(arr, obj => obj.name === word);
        if (obj) {
            obj.weight += 1;
        } else {
            obj = {
                name: word,
                weight: 1
            };
            arr.push(obj);
        }
        return arr;
    }, []);

export const WordCloudMockData = {
    type: 'wordcloud',
    chart: {
        type: 'wordcloud',
    },
    series: [{
        type: 'wordcloud',
        data: wcdata,
        name: 'Occurrences'
    }],

    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    title: {
        text: null
    },
    // subtitle: {
    //     text: 'Source: <a href="https://www.ssb.no/transport-og-reiseliv/faktaside/bil-og-transport">SSB</a>'
    // },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.0f}%</b>'
    },
}


export const GaugeMockData = {
    chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        height: '80%'
    },

    title: {
        text: null
    },

    pane: {
        startAngle: -90,
        endAngle: 89.9,
        background: null,
        center: ['50%', '75%'],
        size: '110%'
    },

    // the value axis
    yAxis: {
        min: 0,
        max: 200,
        tickPixelInterval: 72,
        tickPosition: 'inside',
        tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
        tickLength: 20,
        tickWidth: 2,
        minorTickInterval: null,
        labels: {
            distance: 20,
            style: {
                fontSize: '14px'
            }
        },
        lineWidth: 0,
        plotBands: [{
            from: 0,
            to: 130,
            color: '#55BF3B', // green
            thickness: 20,
            borderRadius: '50%'
        }, {
            from: 150,
            to: 200,
            color: '#DF5353', // red
            thickness: 20,
            borderRadius: '50%'
        }, {
            from: 120,
            to: 160,
            color: '#DDDF0D', // yellow
            thickness: 20
        }]
    },

    series:[]
    
    // [{
    //     name: 'Speed',
    //     data: [80],
    //     tooltip: {
    //         valueSuffix: ' km/h'
    //     },
    //     dataLabels: {
    //         format: '{y} km/h',
    //         borderWidth: 0,
    //         color: (
    //             Highcharts.defaultOptions.title &&
    //             Highcharts.defaultOptions.title.style &&
    //             Highcharts.defaultOptions.title.style.color
    //         ) || '#333333',
    //         style: {
    //             fontSize: '16px'
    //         }
    //     },
    //     dial: {
    //         radius: '80%',
    //         backgroundColor: 'gray',
    //         baseWidth: 12,
    //         baseLength: '0%',
    //         rearLength: '0%'
    //     },
    //     pivot: {
    //         backgroundColor: 'gray',
    //         radius: 6
    //     }

    // }]

}














export const VennMockData = {
    chart:{},
    tooltip: {
        headerFormat: '',
        pointFormat: '' +
            '{#if (eq 1 point.sets.length)}' +
            'Product:<br><b>Highcharts {point.sets.0}</b>' +
            '{else}' +
            'Products:<br>' +
            '{#each point.sets}' +
            'Highcharts <b>{this}</b>{#unless @last} and {/unless}' +
            '{/each}<br><br>' +
            'Shared components:<br>' +
            '<b>{point.name}</b><br>' +
            '{/if}'
    },
    series: [],
    
    // [{
    //     type: 'venn',
    //     colors: [
    //         'rgb(180, 210, 255)',
    //         'rgb(180, 255, 210)',
    //         'rgb(180, 235, 235)',
    //         'rgb(200, 200, 200)',
    //         'rgb(170, 230, 250)',
    //         'rgb(170, 250, 230)',
    //         'rgb(170, 240, 240)',
    //         'rgb(190, 190, 190)',
    //         'rgb(160, 220, 245)',
    //         'rgb(160, 245, 220)'
    //     ],
    //     data: [{
    //         sets: ['Core'],
    //         value: 10,
    //         name: 'Highcharts Core',
    //         dataLabels: {
    //             style: {
    //                 fontSize: 15
    //             }
    //         }
    //     }, {
    //         sets: ['Stock'],
    //         value: 3,
    //         dataLabels: {
    //             style: {
    //                 fontSize: 13
    //             }
    //         }
    //     }, {
    //         sets: ['Dashboards'],
    //         value: 3,
    //         dataLabels: {
    //             style: {
    //                 fontSize: 13
    //             }
    //         }
    //     }, {
    //         sets: ['Gantt'],
    //         value: 2.5,
    //         dataLabels: {
    //             style: {
    //                 fontSize: 13
    //             }
    //         }
    //     }, {
    //         sets: ['Maps'],
    //         value: 3,
    //         dataLabels: {
    //             style: {
    //                 fontSize: 13
    //             }
    //         }
    //     }, {
    //         sets: ['Gantt', 'Maps', 'Stock'],
    //         value: 1,
    //         name: 'Core'
    //     }, {
    //         sets: ['Stock', 'Core'],
    //         value: 1,
    //         name: 'DateTime Series and Axis'
    //     }, {
    //         sets: ['Gantt', 'Core'],
    //         value: 1,
    //         name: 'X-range Series and DateTime Axis'
    //     }, {
    //         sets: ['Maps', 'Core'],
    //         value: 1,
    //         name: 'Heatmap and ColorAxis'
    //     }, {
    //         sets: ['Stock', 'Gantt'],
    //         value: 0.25,
    //         name: 'Navigator & RangeSelector'
    //     }, {
    //         sets: ['Dashboards', 'Core'],
    //         value: 1,
    //         name: 'Data Layer'
    //     }, {
    //         sets: ['Dashboards', 'DataGrid'],
    //         value: 0.5,
    //         name: 'DataGrid'
    //     }, {
    //         sets: ['Dashboards', 'KPI'],
    //         value: 0.5,
    //         name: 'KPI'
    //     }, {
    //         sets: ['DataGrid'],
    //         value: 0.5,
    //         name: ''
    //     }, {
    //         sets: ['KPI'],
    //         value: 0.2,
    //         name: 'KPI'
    //     }, {
    //         sets: ['Custom'],
    //         value: 2,
    //         name: 'Custom component'
    //     }, {
    //         sets: ['Custom', 'Dashboards'],
    //         value: 0.3,
    //         name: 'Sync API'
    //     }],
    //     dataLabels: {
    //         style: {
    //             textOutline: 'none'
    //         }
    //     }
    // }],
    title: {
        text: 'Highsoft products relationships'
    },
    subtitle: {
        text: 'Highcharts Core, Stock, Maps, Gantt, and Dashboards'
    },
    accessibility: {
        point: {
            valueDescriptionFormat: '' +
                '{#if (eq 1 point.sets.length)}' +
                'Product: Highcharts {point.sets.0}' +
                '{else}' +
                'Products: ' +
                '{#each point.sets}' +
                'Highcharts {this}{#unless @last} and {/unless}' +
                '{/each}' +
                ', Shared components: ' +
                '{point.name}' +
                '{/if}'
        },
        series: {
            describeSingleSeries: true,
            descriptionFormat: 'Venn diagram with ' +
                '{series.points.length} relations.'
        }
    }
}
import './FilterPanel.scss';  
import React, { useState,forwardRef, useImperativeHandle  } from 'react'; 

import Dropdown from '../basic/Dropdown'; 
import Switch from '../basic/Switch';
import Check from '../basic/Check';
import { ListItemSwitchGroup, ListItemSwitchGroupItem } from '../../components/basic/ListItemSwitchGroup';

const VisualSettings = forwardRef((props, ref) => {
 

 
    useImperativeHandle(ref, () => {
      return { 
        save:save
      }
    }); 
   
 
  const save = ()=> {  
    return data;
  };
 

 
  let [data, setData] = useState(props.value && props.value.visibleColumnsMobile ? props.value : {
    selectRowOnClickWeb:false,
    showCellBordersWeb:false,
    highlightBookmarkedWeb:false,
    highlightSharedWeb:false,
    showColumnHeadersMobile:false,
    highlightBookmarkedMobile:false,
    highlightSharedMobile:false,
    visibleColumnsMobile:'mobile-show-top-3-columns',
    cardViewMobile:false,
    tableViewMobile:false 
  });
 


  const defaultVisibleColumnValues = [
    { label:'Top 3 Columns', value:'mobile-show-top-3-columns' },
    { label:'Top 4 Columns', value:'mobile-show-top-4-columns' },
    { label:'Top 5 Columns', value:'mobile-show-top-5-columns' },
    { label:'Top 6 Columns', value:'mobile-show-top-6-columns' },
    { label:'Top 7 Columns', value:'mobile-show-top-7-columns' },
    { label:'Top 8 Columns', value:'mobile-show-top-8-columns' }
  ];
 
  return ( 
    <div style={{display:'flex', flexWrap:'wrap', padding:'8px'}}>
      <div style={{minWidth:'250px', padding:'8px'}}>
          <b>Web:</b>
          <br/>
          <Switch label="Select Rows On Click" value={data.selectRowOnClickWeb} onChange={(value)=>{ data.selectRowOnClickWeb=value; setData({...data}); }}  />
          <br/>
          <Switch label="Show Cell Borders " value={data.showCellBordersWeb} onChange={(value)=>{ data.showCellBordersWeb=value; setData({...data}); }}  />
          <br/>
          <Switch label="Highlight Bookmarked Records "  value={data.highlightBookmarkedWeb} onChange={(value)=>{ data.highlightBookmarkedWeb=value; setData({...data}); }}  />
          <br/>
          <Switch label="Highlight Shared Records" value={data.highlightSharedWeb} onChange={(value)=>{ data.highlightSharedWeb=value; setData({...data}); }}  />
          <br/>
          <br/>
      </div>
      <div style={{minWidth:'250px', padding:'8px'}}>
          


          <b>Mobile:</b>
          <br/>
          <Switch label="Show Column Headers" value={data.showColumnHeadersMobile} onChange={(value)=>{ data.showColumnHeadersMobile=value; setData({...data}); }}  />
          <br/>
          <Switch label="Highlight Bookmarked Records " value={data.highlightBookmarkedMobile} onChange={(value)=>{ data.highlightBookmarkedMobile=value; setData({...data}); }}  />
          <br/>
          <Switch label="Highlight Shared Records" value={data.highlightSharedMobile} onChange={(value)=>{ data.highlightSharedMobile=value; setData({...data}); }}  />
          <br/>
          <br/>
            <Dropdown placeholder={'Default Visible Columns'}  
                  valueField="value" labelField="label"  
                  options={defaultVisibleColumnValues} 
                  selectOptions={{closeOnSelect:true, isMultiSelect:false }} 
                  searchOptions={{ enable:false }}
                  value={data.visibleColumnsMobile}
                  onChange={({items, value})=>{ data.visibleColumnsMobile=value; setData({...data}); } } 
                  useLabel={true} > 
              </Dropdown>
          <br/>

                <ListItemSwitchGroup data={data}  onlyOneChildActive={true}  renderParentSwitch={false} >
                    <ListItemSwitchGroupItem data={data} label="Card View" propName="cardViewMobile" />
                    <ListItemSwitchGroupItem data={data} label="Table View" propName="tableViewMobile" />
                </ListItemSwitchGroup>
{/* 
              <Check type="radio" name="view" label="Card View" value={data.cardViewMobile} onChange={(value)=>{ if(value) { data.cardViewMobile=true; data.tableViewMobile=false; } else{ data.cardViewMobile=false; data.tableViewMobile=true;  } console.log('cardViewMobile', data); setData({...data}); }} /> 
          <br/>
              <Check type="radio" name="view" label="Table View" value={data.tableViewMobile} onChange={(value)=>{ if(value) { data.cardViewMobile=false; data.tableViewMobile=true; } else{ data.cardViewMobile=true; data.tableViewMobile=false;  }  console.log('tableViewMobile', data); setData({...data}); }} />  */}
      </div>
    </div> 
  );
});
 


 

export default VisualSettings;

import { useEffect, useState } from 'react';

export class DataStore {
    id = 0;
    data = {};
    constructor(defaultValue) {
        this.data = {
            value: defaultValue,
            lastUpdate: 0,
        };
    }

    updaters = {};

    set = (newData) => {
        
        this.data.value = newData;
        this.data.lastUpdate = new Date();
        for (let p in this.updaters) {
            this.updaters[p][1]({
                id: this.updaters[p][0].id,
                updated: this.updaters[p][0].updated + 1,
            });
        }
        // console.log('set....' , updaters);
        // updaters.forEach(u=>{
        //     u.set(u.value+1);
        // });
      //  console.log('set fireChanged ')
        this.fireChanged(newData);
    };

    fire = () => {
         
        this.data.lastUpdate = new Date();
        for (let p in this.updaters) {
            this.updaters[p][1]({
                id: this.updaters[p][0].id,
                updated: this.updaters[p][0].updated + 1,
            });
        } 
   //     console.log('fire fireChanged ')
        this.fireChanged();
    };


    add = (newItem) => {
        
        this.data.value.push(newItem);
        this.data.lastUpdate = new Date();
        for (let p in this.updaters) {
            this.updaters[p][1]({
                id: this.updaters[p][0].id,
                updated: this.updaters[p][0].updated + 1,
            });
        }
     //   console.log('add fireChanged ')
        this.fireChanged(this.data.value);
    };

    update = (oldItem, newItem, key) => {
        
        for (let i = 0; i < this.data.value.length; i++) {
            if (this.data.value[i][key] === oldItem[key]) {
                this.data.value[i] = newItem;
            }
        }
        this.data.lastUpdate = new Date();
        for (let p in this.updaters) {
            this.updaters[p][1]({
                id: this.updaters[p][0].id,
                updated: this.updaters[p][0].updated + 1,
            });
        }
       
     //   console.log('update fireChanged ')
        this.fireChanged();
    };

    upsert = (newItem, key) => {
        let found = false;
        for (let i = 0; i < this.data.value.length; i++) {
            if (this.data.value[i][key] === newItem[key]) {
                this.data.value[i] = newItem;
                found = true;
            }
        }
        if (!found) {
            this.data.value.push(newItem);
        }
        this.data.lastUpdate = new Date();
        for (let p in this.updaters) {
            this.updaters[p][1]({
                id: this.updaters[p][0].id,
                updated: this.updaters[p][0].updated + 1,
            });
        }
        //console.log('upsert fireChanged ')
        this.fireChanged();
    };

    get = () => {
        return this.data.value;
    };

    follow = () => {
        // const [value, setValue] = ;
        //  let id = '';
        let state = useState(() => {
            return { id: this.id++, updated: 0 };
        }); //{value, setValue};
        //  console.log('id', id);
        this.updaters[state[0].id] = state;
        return this.data.value;
    };

    changeListeners = []; 

    fireChanged = () => {
        this.changeListeners.forEach((u) => {
            u.callback(this.data.value);
        });
    };
    
    onChanged = (callback) => {
        useEffect(() => {
            callback.id = this.changeListeners.length; 
            this.changeListeners.push({ callback: callback, id: callback.id });
           
        }, []);

        //unmount
        useEffect(
            () => () => {
                 this.changeListeners = this.changeListeners.filter((u) => u.id !== callback.id);
            },
            []
        );

        return callback;
    };
}

export class DataEvent {
    updaters = [];

    fire = (data) => {
        this.updaters.forEach((u) => {
            u.callback(data);
        });
        // console.log('eevent fire ', this.updaters.length);
    };

    follow = (callback, arg) => {
       // console.log('follow callback ', arg);
        useEffect(() => {
            callback.id = this.updaters.length;
            // console.log('updaters for event ', callback.id, this.updaters.length);
            // console.log('MOUNTEDDDDDDDDD');
            this.updaters.push({ callback: callback, id: callback.id });

         //   console.log('follow callback this.updaters.push ', arg);
        }, []);
 
        let callbackStr = callback.toString();
        let found  = this.updaters.find(c=>c.callback.toString()===callbackStr);
        if(found){
            found.callback = callback;
        } 
        
        //unmount
        useEffect(
            () => () => {
                // console.log('UN---MOUNTEDDDDDDDDD',callback.id,  this.updaters.filter(u=>u.id===callback.id).length );
                this.updaters = this.updaters.filter((u) => u.id !== callback.id);
            //    console.log('follow callback this.updaters.filter ', arg);
            },
            []
        );

        return callback;
    };
}

import React, { useEffect, useRef, useState } from 'react';

import tmo from '../../tmo/tmo.lib';
import { isEmpty } from '../../tmo/tmo.utils';
import { Globals } from '../../Globals';
import Icon from '../basic/Icon';
import Skeleton from '../basic/Skeleton';
import DataGridFooter from './DataGridFooter';
import {
    useDataGridItemChangeEvent,
    useDataGridRowDataChangeEvent,
    useDataGridColumnResizeEvent,
} from './DataGridEvents';
import {
    DataGridCellTag,
    DataGridCellUser,
    DataGridCellText,
    DataGridCell,
    DataGridCellDatetime,
    DataGridCellUrl,
    DataGridCellCheck,
    DataGridCellImage,
    DataGridCellHtml,
    DataGridCellJson,
    DataGridCellSegment,
    DataGridCellCurrency,
} from './DataGridCell';
import DataGridHeader from './DataGridHeader';
import DataGridTools from './DataGridTools';
import DataGridRow from './DataGridRow';
import DataGridManager from './DataGridManager';
import { DataGridStore } from './DataGrid.Store';
import DataGridEmptyState from './DataGridEmptyState';
import './DataGrid.pc.scss';
import './DataGrid.mobile.scss';

function DataGrid(props) {
    useEffect(() => {
        console.log('store rendering', props, props.store?.defaultState?.gridId);
        props.store.render();
    }, []);

    let gridStore = props.store || new DataGridStore();
    const gridState = props.store.gridState.follow();
    let gridData = props.store.gridData.follow();

    const toolsRef = useRef();
    const managerRef = useRef();

    // let gridId = 'users';
    let keyField = gridState.keyField || props.keyField || 'id';

    const [paging, setPaging] = useState({ currentPage: 0, pageSize: 10, totalRecords: 100000 });
    const [visualsRefreshed, refreshVisuals] = useState(0);

    // const [columns, setColumns] = useState(props.columns);

    // let data = props.data;
    let dummyData = Array.from(Array(25).keys()).map((a) => {
        let val = { loading: true };
        val[keyField] = a;
        return val;
    });
    //  useEffect(() => {
    //      if(props.data){
    //      // setData([...props.data]);
    //      }

    // },[props.data]);

    let onGridDataItemChange = useDataGridItemChangeEvent('items');

    let onGridRowDataChange = useDataGridRowDataChangeEvent(gridState.gridId, keyField);
    let onGridColumnResized = useDataGridColumnResizeEvent(gridState.gridId);
    // let onGridColumnOrderChanged = useDataGridColumnOrderChangeEvent(gridId);


    let isMobile = tmo.helpers.isMobile();

    const [visibleColumns, setVisibleColumns] = useState([]);
    const [recalculatedColumnWidths, setRecalculatedColumnWidths] = useState({});
    const [totalWidth, setTotalWidth] = useState(0);

    const [forceUpdate, setForceUpdate] = useState(false);

    const calculateVisibleColumns = ()=>{
        let visibleColumnList = gridState.columns;
        if (
            gridState.permissions &&
            gridState.permissions.enabled &&
            gridState.permissions.options &&
            gridState.permissions.options.length
        ) {
            if (Globals.isAdmin) {
                visibleColumnList = visibleColumnList.filter((v) => {
                    let hasPermission = gridState.permissions.options.filter(
                        (p) => p.column == v.key
                    )[0];
    
                    if (hasPermission && hasPermission.roles && hasPermission.roles.length) {
                        return Globals.checkPermission(null, hasPermission.roles);
                        // if(window.currentUser && window.currentUser.roles && window.currentUser.roles.length &&  window.currentUser.roles.filter(ur=>hasPermission.roles.indexOf(ur)>-1).length){
                        //     return false;
                        // }
                    }
                    return true;
                });
            }
        }
    
        visibleColumnList = isMobile
            ? visibleColumnList
                  .filter((c) => c.mVisible)
                  .sort((a, b) => ((a.mIndex || a.index) > (b.mIndex || b.index) ? 1 : -1))
            : visibleColumnList.filter((c) => c.visible).sort((a, b) => (a.index > b.index ? 1 : -1));
    
        if (props.isSelectMode) {
            visibleColumnList = visibleColumnList.filter((c) => !c.hideOnSelect);
        }
    
        if (props.viewOnly) {
            visibleColumnList = visibleColumnList.filter((c) => c.type !== 'action');
        }
    
        let totalWidthCalculated = 0;
        visibleColumnList.forEach((c) =>{
            if(recalculatedColumnWidths[c.key]){
                c.width = recalculatedColumnWidths[c.key] || c.width;
            }
            totalWidthCalculated += c.width;
            
        });
        setTotalWidth(totalWidthCalculated);
    
        setVisibleColumns(visibleColumnList);
    }

    useEffect(() => {
        calculateVisibleColumns();
    }, [gridState.columns, isMobile, props.isSelectMode, props.viewOnly, forceUpdate, visualsRefreshed]);
    

    
    gridStore.onForceUpdate.follow((state) => {
        setForceUpdate(true);
        setTimeout(() => {
            setForceUpdate(false);
        }, 300);
    });
    if (forceUpdate) {
        return <></>;
    }

    const itemChanged = (item, field, oldValue, newValue) => {
        if (props.onChange) {
            props.onChange(gridData, item, field, oldValue, newValue);
        }

        if (field === 'selected') {
            managerRef.current.itemSelectionChanged({ record: item, isSelected: newValue });
            // toolsRef.current.onItemSelectionChanged({record:item,  isSelected:newValue});
        }

        onGridRowDataChange({ rowData: item, field, newValue });
    };

    const columnResized = ({ column , ended}) => {
        props.onColumnResized && props.onColumnResized({ column });
        onGridColumnResized({ column }); 
        onGridSizeChanged();
    };
    const columnOrderChanged = ({ columns }) => {
        props.onColumnOrderChanged && props.onColumnOrderChanged({ columns });
        // setColumns(columns);
        // onGridColumnOrderChanged({columns});
    };
    const columnGrouped = ({ columns, group }) => {
        props.onColumnGroupChanged && props.onColumnGroupChanged({ columns });
        // setColumns([...columns]);
        // onGridColumnOrderChanged({columns});
    };

    // const dataSortChanged = ({column})=>{
    //   props.onDataSortChanged && props.onDataSortChanged({column});
    // //  onGridSortChanged({column});
    // }
    // const dataFilterChanged = ({filter})=>{
    //   props.onDataFilterChanged && props.onDataFilterChanged({filter});
    //   //onGridFilterChanged({column});
    // }

    // let conditions = [
    //     { field: 'published_date', op: 'equal', not: false, values: ['2022-12-13 06:25:59'] },
    //     { condition: 'and' },
    //     { field: 'published_date', op: 'contains', not: false, value: '2022-12-13 06:25:59' },
    //     { condition: 'and' },
    //     {
    //         field: 'published_date',
    //         op: 'between',
    //         not: false,
    //         value1: '2022-12-13 06:25:59',
    //         value2: '2022-12-13 06:25:59',
    //     },
    //     { condition: 'and' },
    //     { field: 'published_date', op: 'gte', not: false, value: '2022-12-13 06:25:59' },
    //     { condition: 'and' },
    //     { field: 'published_date', op: 'lte', not: false, value: '2022-12-13 06:25:59' },
    // ];

    // let conditionFunction = eval("(item)=>{ return  ([['en']].filter(v=>v==item['language']).length>0)  &&  (item['title'].indexOf('202')>-1) }");
    // let conditionFunction2 = eval("(item)=>{ return  ([['en']].filter(v=>v==item['language']).length>0)  &&  (item['title'].toString().toLowerCase().indexOf('china')>-1) }");

    let colorConditions = null;
    //  [
    //   {  className:'red',  condition:conditionFunction2},
    //   {  className:'green',  condition:conditionFunction}
    // ];

    // const buildCondition = ({conditions})=>{

    //   let code = '(item)=>{ return ';
    //   conditions.forEach(c=>{
    //     if(c.op=='equal' && c.value){
    //       code+=` ${c.not?'!':''}(item['${c.field}']==${c.value}) `;
    //     }
    //     if(c.op=='equal' && c.values){
    //       code+=` ${c.not?'!':''}([${JSON.stringify(c.values)}].filter(v=>v==item['${c.field}']).length>0) `;
    //     }
    //     if(c.op=='between'){
    //       code+=` ${c.not?'!':''}(item['${c.field}']>${c.value1} && item['${c.field}']<${c.value2}) `;
    //     }
    //     if(c.op=='contains'){
    //       code+=` ${c.not?'!':''}(item['${c.field}'].toString().toLowerCase().indexOf(${c.value.trim().toString().toLowerCase()})>-1) `;
    //     }
    //     if(c.op=='gte' ){
    //       code+=` ${c.not?'!':''}(item['${c.field}']>=${c.value}) `;
    //     }
    //     if(c.op=='lte' ){
    //       code+=` ${c.not?'!':''}(item['${c.field}']<=${c.value}) `;
    //     }
    //     if(c.condition=='and'){
    //       code+=` && `;
    //     }
    //     if(c.condition=='or'){
    //       code+=` || `;
    //     }
    //   });
    //   code += '}';
    // }

    const prepareColorConditionFunction = () => {
        if (gridState.conditionalColoring.hasValue) {
            gridState.conditionalColoring.options.value.forEach((c) => {
                if (c.filter && c.enabled && c.filter.condition) {
                    let conditionFunction = `(item)=>{  
                            function convertToInt(value){ var valueToConvert = -999999999999;  try{  valueToConvert = parseInt(value) || -999999999999; } catch(ex) {  valueToConvert = -999999999999;  } return valueToConvert;  } 
                            function convertToBoolean(value){ var valueToConvert = false;  try{  valueToConvert = (value==="1" || value==="true" || value===true) ? true:false; } catch(ex) {  valueToConvert = false;  } return valueToConvert;  } return `;
                    conditionFunction += c.filter.condition;
                    conditionFunction += '}';
                    try {
                        c.condition = eval(conditionFunction);
                    } catch (ex) {
                        c.condition = () => {
                            return false;
                        };
                    }
                }
            });
        }
    };

    const getRowClass = ({ item, systemData }) => {
        let cls = '';
        if (gridState.conditionalColoring.hasValue && gridState.conditionalColoring.enabled) {
            try {
                prepareColorConditionFunction();
            } catch (ex) {}

            try {
                let colorsToApply = gridState.conditionalColoring.options.value.filter(
                    (c) => c.enabled && c.condition && c.condition(item)
                );

                if (colorsToApply.length > 0) {
                    cls += colorsToApply[colorsToApply.length - 1].color + ' ';
                }
            } catch (ex) {
                console.log(ex);
            }
        }

        if (props.highlightNewRecords && props.recordDateField) {
            if (tmo.string.isDateInRange(item[props.recordDateField], -30)) {
                cls += ' new-record ';
            }
        }

        if (props.disabledField) {
            if (item[props.disabledField]) {
                cls += ' disabled ';
            }
        }

        if (props.enabledField) {
            if (!item[props.enabledField]) {
                cls += ' disabled ';
            }
        }

        if (props.tuneDownField) {
            if (item[props.tuneDownField]) {
                cls += ' tune-down ';
            }
        }

        if (props.tuneDownCondition) {
            if (props.tuneDownCondition(item)) {
                cls += ' tune-down ';
            }
        }

        if (systemData.checked) {
            cls += ' checked-row ';
        }
        if (item.title && item.title.indexOf('NFL') > -1) {
            cls += ' nfl ';
        }
        return cls;
    };

    const filterChanged = (filter, keyword) => {
        //  if(props.onFilterChanged){
        //let params =  managerRef.current.getDataParameters();
        //dataListingParametersChanged(params);
        // {
        // pageSize: state.paging.pageSize,
        //   pageSize:paging.pageSize, currentPage:state.paging.currentPage, offset:state.paging.pageSize*state.paging.currentPage, filter:state.filter,
        //   keyword:state.keyword,
        //   sort:state.sort,
        //   // sort:columns.filter(c=>c.sort).map(c=> { return {field:c.field, sort:c.sort} } ) ,
        //   groupby:state.group
        // });
        // }
    };

    const dataListingParametersChanged = ({
        pageSize,
        currentPage,
        offset,
        filter,
        keyword,
        sort,
        groupby,
        display_settings,
    }) => {
        if (props.onDataListingParametersChanged) {
            props.onDataListingParametersChanged({
                pageSize,
                currentPage,
                offset,
                filter,
                keyword,
                sort,
                groupby,
                display_settings,
            });
        }
    };

    const pagingChanged = ({ page, pageSize }) => {
        setPaging({ ...paging, pageSize: pageSize, currentPage: page });

        if (props.onPageChanged) {
            props.onPageChanged({ pageSize: pageSize, currentPage: page, offset: pageSize * page });
        }
    };

   
    let groupedFields = ['gender', 'os'];

    const checkGroupHeader = (records, index) => {
        let hasGroupHeader = false;
        let prevRec = null;
        let currRec = null;
        groupedFields.forEach((g) => {
            if (index === 0) {
                hasGroupHeader = true;
                return;
            }
            if (records[index - 1]) {
                prevRec =
                    records[index - 1][g] && records[index - 1][g].value
                        ? records[index - 1][g].value
                        : records[index - 1][g];
                currRec =
                    records[index][g] && records[index][g].value
                        ? records[index][g].value
                        : records[index][g];
                if (index === 0 || prevRec !== currRec) {
                    hasGroupHeader = true;
                    return;
                }
            }
        });

        return hasGroupHeader;
    };

    const onSortChanged = ({ column, currentSort }) => {
        managerRef.current.sort({ column, currentSort });
    };

    const onGroupingChanged = ({ fields }) => {
        managerRef.current.group({ fields });
    };

    const onShowMore = ({ column, target }) => {
        managerRef.current.showMore({ column, target });
    };

    let gridBody = null;
    let gridScroller = null;
    const onScrollerMoved = (e) => {
        gridBody = gridBody || e.target.closest('.datagrid').querySelector('.grid-body');
        gridScroller = gridScroller || e.target.closest('.grid-scroller');
        gridBody.scrollLeft = gridScroller.scrollLeft;
    };

    const onGridSizeChanged = () => {
        if (
            document.querySelector('.datagrid .grid-scroller-inner') &&
            document.querySelector('.datagrid .grid-body')
        ) {
            document.querySelector('.datagrid .grid-scroller-inner').style.width =
                document.querySelector('.datagrid .grid-body').scrollWidth + 'px';
        }
    };
    setTimeout(() => {
        onGridSizeChanged();
    }, 500);

    // let selectColumn = visibleColumns.filter((c) => c.type === 'system-check')[0];

    const checkGridSizes = (ref) => {
        
        if (visualsRefreshed || !totalWidth || !visibleColumns.length) {
            return;
        }
        if (!ref || !ref) {
            return;
        }
       
        let wrapperWidth = ref.getBoundingClientRect().width;

        console.log('SIZES:',wrapperWidth, totalWidth);
        if (wrapperWidth > totalWidth) {
           
            let columnWidthUpdates = {};
            visibleColumns.forEach((c) => {
                if (c.type !== 'system-check' && c.type !== 'action') {
                    c.width = Math.floor((wrapperWidth * c.width) / totalWidth);
                    columnWidthUpdates[c.key] = c.width;
                }
            });
            let rid = new Date().getTime();
            console.log(visibleColumns,rid);
            setRecalculatedColumnWidths({...columnWidthUpdates})
            refreshVisuals(rid);
        }
    };

    const fireEvent = (name, params) => {
        if (props.onEvent) {
            props.onEvent(name, params);
        }
    };

    const onRowChanged = () => {
        if (props.onChange) {
            props.onChange();
        }
    };

    const onGridHeaderChanged = () => {
        if (props.onChange) {
            props.onChange();
        }
    };

    const onGridToolsChanged = () => {
        if (props.onChange) {
            props.onChange();
        }
    };

    const renderRowColumns = (rowKey, columns, i, isChildren = false) => {
        const hasChildren = i.children && i.children.length;
        const renderTemplate = (c, params) =>
            isChildren
                ? c.templateChildren
                    ? c.templateChildren(params)
                    : c.template(params)
                : c.template(params);

        if (hasChildren) {
            return (
                <div className="datagrid-row-group">
                    {renderRowColumns(rowKey, columns, { ...i, children: [] })}
                    {i.children.map((child, index) =>
                        renderRowColumns(rowKey + index, columns, child, true)
                    )}
                </div>
            );
        }

       
        return columns.map((c) => {
            switch (c.type) {
                case 'check':
                    return (
                        <DataGridCellCheck key={rowKey + c.key} column={c} onChange={itemChanged} />
                    );
                case 'text':
                    if (!c.template) {
                        return (
                            <DataGridCellText
                                key={rowKey + c.key}
                                column={c}
                                onChange={itemChanged}
                            />
                        );
                    }

                    return (
                        <DataGridCell
                            key={rowKey + c.key}
                            column={c}
                            onChange={itemChanged}
                            className={c.multiline ? ' multiline ' : ' '}
                        >
                            {c.template && renderTemplate(c, { column: c, item: i })}
                        </DataGridCell>
                    );

                case 'html':
                    if (!c.template) {
                        return (
                            <DataGridCellHtml
                                key={rowKey + c.key}
                                column={c}
                                onChange={itemChanged}
                            />
                        );
                    }
                    // TODO: we are missing here defaults for HTML
                    return null;
                case 'json':
                    return (
                        <DataGridCellJson key={rowKey + c.key} column={c} onChange={itemChanged}>
                            {c.template && renderTemplate(c, { column: c, item: i })}
                        </DataGridCellJson>
                    );
                case 'link':
                    return (
                        <DataGridCellUrl key={rowKey + c.key} column={c} onChange={itemChanged} />
                    );
                case 'datetime':
                    return (
                        <DataGridCellDatetime
                            key={rowKey + c.key}
                            column={c}
                            onChange={itemChanged}
                        />
                    );

                case 'currency':
                    if (!c.template) {
                        return (
                            
                            <DataGridCellCurrency key={rowKey + c.key} column={c} onChange={itemChanged} />
                        );
                    }

                    return ( 
                        <DataGridCell
                            key={rowKey + c.key}
                            column={c}
                            onChange={itemChanged}
                            className={c.multiline ? ' multiline ' : ' '}
                        >
                            {c.template && renderTemplate(c, { column: c, item: i })}
                        </DataGridCell>
                    );
 
                case 'number':
                    if (!c.template) {
                        return (
                            <DataGridCellText key={rowKey + c.key} column={c} onChange={itemChanged} />
                        );
                    }

                    return ( 
                        <DataGridCell
                            key={rowKey + c.key}
                            column={c}
                            onChange={itemChanged}
                            className={c.multiline ? ' multiline ' : ' '}
                        >
                            {c.template && renderTemplate(c, { column: c, item: i })}
                        </DataGridCell>
                    );
 
                case 'parent':
                    return (
                        <DataGridCell
                            key={rowKey + c.key}
                            item={i}
                            column={c}
                            onChange={itemChanged}
                        >
                            {c.template && renderTemplate(c, { column: c, item: i })}
                        </DataGridCell>
                    );
                case 'custom':
                    return (
                        <DataGridCell
                            item={i}
                            key={rowKey + c.key}
                            column={c}
                            onChange={itemChanged}
                            className={c.className}
                        >
                            {c.template && renderTemplate(c, { column: c, item: i })}
                        </DataGridCell>
                    );
                case 'action':
                    return (
                        <DataGridCell
                            key={rowKey + c.key}
                            item={i}
                            column={c}
                            onChange={itemChanged}
                            className={c.multiline ? ' multiline ' : ' '}
                        >
                            <span>
                                {c.template &&
                                    renderTemplate(c, {
                                        column: c,
                                        item: i,
                                        fireEvent,
                                    })}
                            </span>
                        </DataGridCell>
                    );
                case 'bool':
                case 'array':
                case 'dropdown':
                    return (
                        <DataGridCell
                            key={rowKey + c.key}
                            item={i}
                            column={c}
                            onChange={itemChanged}
                        >
                            {c.noSpan ? (
                                c.template ? (
                                    renderTemplate(c, { column: c, item: i })
                                ) : i[c.field] && i[c.field].label ? (
                                    i[c.field].label
                                ) : (
                                    ''
                                )
                            ) : (
                                <span>
                                    {c.template
                                        ? renderTemplate(c, {
                                              column: c,
                                              item: i,
                                          })
                                        : i[c.field] && i[c.field].label
                                        ? i[c.field].label
                                        : ''}
                                </span>
                            )}
                        </DataGridCell>
                    );
                case 'tag':
                    return (
                        <DataGridCellTag key={rowKey + c.key} column={c} onChange={itemChanged} />
                    );
                case 'segment':
                    return (
                        <DataGridCellSegment
                            key={rowKey + c.key}
                            column={c}
                            onChange={itemChanged}
                        />
                    );
                case 'user':
                    return (
                        <DataGridCellUser key={rowKey + c.key} column={c} onChange={itemChanged} />
                    );
                case 'image':
                    return (
                        <DataGridCellImage key={rowKey + c.key} column={c} onChange={itemChanged}>
                            {c.template && renderTemplate(c, { column: c, item: i })}
                        </DataGridCellImage>
                    );
                default:
                    return null;
            }
        });
    };

    const getRow = (i, columns) => {
        let rowKey = i[keyField];

        return (
            <DataGridRow
                renderRowChild={props.renderRowChild}
                item={i}
                key={rowKey}
                keyField={keyField}
                store={gridStore}
                columns={columns}
                classCondition={getRowClass}
                gridId={gridState.gridId}
                selectColumn={visibleColumns.find((c) => c.type === 'system-check')}
                disabledField={props.disabledField}
                enabledField={props.enabledField}
                tuneDownField={props.tuneDownField}
                isSelectMode={props.isSelectMode}
                isReadOnly={props.isReadOnly}
                isMultiSelect={props.isMultiSelect}
                onChange={onRowChanged}
                hasChildren={props.hasChildren}
            >
                {renderRowColumns(rowKey, columns, i)}
            </DataGridRow>
        );
    };

    const renderGridData = () => {
        if (!isEmpty(gridData)) {
            return gridData;
        }

        return props?.emptyState?.mock || [];
    };

    let visualSettings = gridState.visualSettings.options;
    let gridClassNames =
        'datagrid custom ' +
        (props.disableDelete ? ' disable-delete ' : ' ') +
        (!isEmpty(gridData) && gridData[0]?.children ? ' --with-child-rows ' : '') +
        (visualSettings.showCellBordersWeb ? ' --with-cell-borders-web ' : '') +
        (visualSettings.highlightBookmarkedWeb ? ' --with-bookmark-highlight-web ' : '') +
        (visualSettings.highlightSharedWeb ? ' --with-shared-highlight-web ' : '') +
        (visualSettings.showColumnHeadersMobile ? ' --with-column-headers-mobile ' : '') +
        (visualSettings.highlightBookmarkedMobile ? ' --with-bookmark-highlight-mobile ' : '') +
        (visualSettings.highlightSharedMobile ? ' --with-shared-highlight-mobile ' : '') +
        (visualSettings.visibleColumnsMobile || ' mobile-show-top-3-columns ') +
        (visualSettings.cardViewMobile ? ' --cardview-mobile ' : '') +
        (gridState.loading ? ' --grid-loading ' : '') +
        (visualSettings.tableViewMobile ? ' --tableview-mobile ' : '');

    return (
        <div
            className={gridClassNames}
            style={props.style}
            data-element="grid"
            data-id={gridState.gridId}
            data-refresh-id={visualsRefreshed}
            ref={checkGridSizes}
        >
            {gridState.loading && (
                <div className={'grid-loading loading '}>
                    Loading... <Icon name="refresh" />{' '}
                </div>
            )}
            

            <DataGridManager
                ref={managerRef}
                keyField={keyField}
                columns={gridState.columns}
                store={gridStore}
                onDataListingParametersChanged={dataListingParametersChanged}
            />
            <DataGridTools
                store={gridStore}
                isMultiSelect={props.isMultiSelect}
                isSelectMode={props.isSelectMode}
                isReport={props.isReport}
                isViewMode={props.isViewMode}
                viewOnly={props.viewOnly}
                columns={gridState.columns}
                onEdit={props.onEdit}
                onFilterChanged={filterChanged}
                onGroupingChanged={onGroupingChanged}
                onChange={onGridToolsChanged}
                ref={toolsRef}
                keyField={keyField}
            />
            <div style={{ width: '100%', overflow: 'auto' }} className="grid-body">
                <DataGridHeader
                    hasChildren={props.hasChildren}
                    isMultiSelect={props.isMultiSelect}
                    visibleColumns={visibleColumns}
                    store={gridStore}
                    gridId={gridState.gridId}
                    isSelectMode={props.isSelectMode}
                    isReadOnly={props.isReadOnly}
                    viewOnly={props.viewOnly}
                    onColumnOrderChanged={columnOrderChanged}
                    onColumnResized={columnResized}
                    onColumnGroupChanged={columnGrouped}
                    onSortChanged={onSortChanged}
                    onShowMore={onShowMore}
                    onChange={onGridHeaderChanged}
                />
                <div className="datagrid-rows">
                    {!gridData &&
                        !props.disableSkeletonLoading &&
                        dummyData.map((i, ind) => (
                            <div key={ind} className="datagrid-row-skeleton-wrapper">
                                <Skeleton height={16} />
                            </div>
                        ))}
                    {gridData &&
                        renderGridData().map((i, ind) => (
                            <React.Fragment key={i[keyField] || i.id || ind}>
                                {gridState.grouping.enabled &&
                                    gridState.grouping.hasValue &&
                                    checkGroupHeader(gridData, ind) && (
                                        <div
                                            className="group-header"
                                            key={i[keyField] + 'grp'}
                                            style={{ width: totalWidth + 'px' }}
                                        >
                                            <div className="content">
                                                {gridState.grouping.options.map((g, gi) => (
                                                    <span key={gi}>
                                                        {tmo.helpers.readOr(i[g], 'label', 'N/A')}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                {getRow(i, visibleColumns)}
                            </React.Fragment>
                        ))}
                    {gridData && isEmpty(gridData) && (
                        <DataGridEmptyState config={props.emptyState} />
                    )}
                </div>
            </div>
            <div
                className="grid-scroller"
                style={{ width: '100%', overflow: 'auto', display: 'none' }}
                onScroll={onScrollerMoved}
            >
                <div className="grid-scroller-inner" />
            </div>
            <DataGridFooter store={gridStore} />
        </div>
    );
}

export default DataGrid;

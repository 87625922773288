import { Globals } from '../../Globals';
import { isEmpty } from '../../tmo/tmo.utils';

export class AddressApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        system_filters,
    }) => {
        // let appIdSystemFilters = [];
        // let storeIdSystemFilters = [];

        // if (!isEmpty(system_filters)) {
        //     appIdSystemFilters = system_filters.filter(
        //         (filter) => filter.field === 'application_id'
        //     );

        //     storeIdSystemFilters = system_filters.filter((filter) => filter.field === 'store_id');
        // }

        return await Globals.callApi({
            url: 'shop/address/search',
            params: {
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters: system_filters, //[...appIdSystemFilters, ...storeIdSystemFilters],
            },
        });
    };

    static get = async ({ id }) => {
        return await Globals.callApi({
            url: 'shop/address/get',
            params: { address_id: id },
        });
    };

    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'shop/address/save',
            params: params,
        });
    };

    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'shop/address/remove',
            params: { address_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'shop/address/rollback',
            params: { address_id: id },
        });
    };

    static history = async ({ id }) => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'address' },
        });
    };
}

import { GlobalStore, USER_PREFERENCE_TYPES } from '../../GlobalStore';
import { Globals } from '../../Globals';
import { DataEvent, DataStore } from '../../tmo/Store';
import tmoLib from '../../tmo/tmo.lib';

export class DataGridState {
    keyField = '';
    gridId = '';
    showRowNumbers = false;

    searchKeyword = '';

    loading = false;

    features = {
        addNew: true,
        columnManager: true,
        attributeManager: true,
        filter: true,
        dataDisplaySettings: true,
        conditionalColoring: true,
        import: true,
        export: true,
        tagging: true,
        bookmark: true,
        share: true,
        visualSettings: true,
        permissions: true,
        fullScreen: true,
        grouping: true,
        search: true,
        paging: true,
        inlineEditing: true,
    };

    actions = {
        enableRowResizing: false,
        enableRightClick: true,
        enableDoubleClick: true,
        enableSelectWithMouse: true,
        enableSelectWithShift: true,
        enableDragDrop: true,
        enableCopyRow: true,
        enablePasteRow: true,
        enableEscKey: true,
        enableInfiniteScroll: false,
    };

    inlineEditing = {
        enabled: true,
        showAsForm: false,
    };

    columnManager = {
        enabled: true,
        hasValue: false,
    };

    paging = {
        enabled: false,
        hasValue: false,
        requestedPage: 0,
        currentPage: 0,
        pageSize: 30,
        totalRecords: 2000,
        disablePageSize: false,
    };

    columns = [];

    selection = {
        enabled: true,
        isMultiSelect: true,
        showOnlySelected: false,
        showOnlyDeselected: false,
        selectAllFilteredRecords: false,
        //  selectAll:false,
        sortBySelected: null,
        selectionFilterEnabled: false,
    };

    filter = {
        enabled: true,
        hasValue: false,
        options: {},
    };
    sort = {
        enabled: true,
        hasValue: false,
        options: {},
    };
    grouping = {
        enabled: true,
        hasValue: false,
        options: {},
    };
    headrow = {
        enabled: false,
        hasValue: false,
        options: {},
    };
    dataDisplaySettings = {
        enabled: true,
        hasValue: false,
        options: {},
    };
    conditionalColoring = {
        enabled: true,
        hasValue: false,
        options: {},
    };
    visualSettings = {
        enabled: true,
        hasValue: false,
        options: {
            selectRowOnClickWeb: false,
            showCellBordersWeb: false,
            highlightBookmarkedWeb: false,
            highlightSharedWeb: false,
            showColumnHeadersMobile: false,
            highlightBookmarkedMobile: false,
            highlightSharedMobile: false,
            visibleColumnsMobile: 'mobile-show-top-3-columns',
            cardViewMobile: false,
            tableViewMobile: true,
        },
    };
    permissions = {
        hasValue: false,
        options: {},
    };

    hash = '';

    static clone = (state, options) => {
        var cloned = { ...state };
        cloned.columns = cloned.columns.map((c) => {
            return { ...c };
        });
        cloned.filter = { ...cloned.filter };
        cloned.visualSettings = { ...cloned.visualSettings };
        cloned.paging = { ...cloned.paging };
        cloned.features = { ...cloned.features };
        cloned.conditionalColoring = { ...cloned.conditionalColoring };
        cloned.selection = { ...cloned.selection };
        cloned.sort = { ...cloned.sort };
        cloned.grouping = { ...cloned.grouping };
        cloned.headrow = { ...cloned.headrow };
        cloned.dataDisplaySettings = { ...cloned.dataDisplaySettings };
        cloned.permissions = { ...cloned.permissions };
        cloned.actions = { ...cloned.actions };
        cloned.inlineEditing = { ...cloned.inlineEditing };
        

        if (options && options.columnsExcluded) {
            cloned.columns = cloned.columns.filter(
                (c) => options.columnsExcluded.indexOf(c.key) == -1
            );
        }
        return cloned;
    };

    static getHash = async (state) => {
        async function hash(string) {
            const utf8 = new TextEncoder().encode(string);
             try{
                return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
                    const hashArray = Array.from(new Uint8Array(hashBuffer));
                    const hashHex = hashArray
                        .map((bytes) => bytes.toString(16).padStart(2, '0'))
                        .join('');
                    return hashHex;
                });
             }
             catch(ex){
 
             }
            
             return tmoLib.string.hashText(string);
         
        }

        var dataToHash = { ...state, paging: { ...state.paging } };
        delete dataToHash.loading;
        delete dataToHash.hash;
        delete dataToHash.paging.totalRecords;

        dataToHash.columns = state.columns.map((c) => {
            let col = { ...c };
            delete col.template;
            delete col.export;
            delete col.data;
            delete col.dropdownProps;
            if (col.children && col.children.length > 0) {
                col.children = col.children.map((cc) => {
                    let child = { ...cc };
                    delete child.template;
                    delete child.export;
                    delete child.data;
                    delete child.dropdownProps;
                    return child;
                });
            }
            return col;
        });

        return {
            hash: await hash(JSON.stringify(dataToHash)),
            _hash: JSON.stringify(dataToHash), //debug purpose
        };
    };

    // [
    //         this.keyField,
    //         this.gridId,
    //         this.showRowNumbers.toString(),
    //         this.searchKeyword.toString(),
    //         this.features.addNew.toString(),
    //         this.features.columnManager.toString(),
    //         this.features.attributeManager.toString(),
    //         this.features.filter.toString(),
    //         this.features.dataDisplaySettings.toString(),
    //         this.features.conditionalColoring.toString(),
    //         this.features.import.toString(),
    //         this.features.export.toString(),
    //         this.features.tagging.toString(),
    //         this.features.bookmark.toString(),
    //         this.features.share.toString(),
    //         this.features.visualSettings.toString(),
    //         this.features.permissions.toString(),
    //         this.features.fullScreen.toString(),
    //         this.features.grouping.toString(),
    //         this.features.search.toString(),
    //         this.features.paging.toString(),
    //         this.features.inlineEditing.toString(),
    //         this.actions.enableRowResizing.toString(),
    //         this.actions.enableRightClick.toString(),
    //         this.actions.enableDoubleClick.toString(),
    //         this.actions.enableSelectWithMouse.toString(),
    //         this.actions.enableSelectWithShift.toString(),
    //         this.actions.enableDragDrop.toString(),
    //         this.actions.enableCopyRow.toString(),
    //         this.actions.enablePasteRow.toString(),
    //         this.actions.enableEscKey.toString(),
    //         this.actions.enableInfiniteScroll.toString(),
    //         this.inlineEditing.enabled.toString(),
    //         this.inlineEditing.showAsForm.toString(),
    //         this.paging.enabled.toString(),
    //         this.paging.hasValue.toString(),
    //         this.paging.requestedPage.toString(),
    //         this.paging.currentPage.toString(),
    //         this.paging.pageSize.toString(),
    //         this.paging.totalRecords.toString(),
    //         this.paging.disablePageSize.toString(),
    //         this.selection.enabled.toString(),
    //         this.selection.isMultiSelect.toString(),
    //         this.selection.showOnlySelected.toString(),
    //         this.selection.showOnlyDeselected.toString(),
    //         this.selection.selectAllFilteredRecords.toString(),
    //         this.selection.sortBySelected ? this.selection.sortBySelected.toString() : 'null',
    //         this.filter.enabled.toString(),
    //         this.filter.hasValue.toString(),
    //         this.filter.options.value,
    //         this.sort.enabled.toString(),
    //         this.sort.hasValue.toString(),
    //         this.grouping.enabled.toString(),
    //         this.grouping.hasValue.toString(),
    //         this.headrow.enabled.toString(),
    //         this.headrow.hasValue.toString(),
    //         this.childrow.enabled.toString(),
    //         this.childrow.hasValue.toString(),
    //         this.dataDisplaySettings.enabled.toString(),
    //         this.dataDisplaySettings.hasValue.toString(),
    //         this.conditionalColoring.enabled.toString(),
    //         this.conditionalColoring.hasValue.toString(),
    //         this.visualSettings.enabled.toString(),
    //         this.visualSettings.hasValue.toString(),
    //         this.visualSettings.options.selectRowOnClickWeb.toString(),
    //         this.visualSettings.options.showCellBordersWeb.toString(),
    //         this.visualSettings.options.highlightBookmarkedWeb.toString(),
    //         this.visualSettings.options.highlightSharedWeb.toString(),
    //         this.visualSettings.options.showColumnHeadersMobile.toString(),
    //         this.visualSettings.options.highlightBookmarkedMobile.toString(),
    //         this.visualSettings.options.highlightSharedMobile.toString(),
    //         this.visualSettings.options.visibleColumnsMobile.toString(),
    //         this.visualSettings.options.cardViewMobile.toString(),
    //         this.visualSettings.options.tableViewMobile.toString(),
    //         this.permissions.hasValue.toString(),
    //         this.columns.map(c=> [c.title, c.index || '', c.mIndex || '', c.visible || '', c.mVisible || '', c.type || '', c.key, c.width || '', c.stickRight || '',
    //                 c.disableSort || '', c.disableFilter || '', c.disableHide || '', c.disableResize || '',c.disableIndex || '', c.disableMore || '',
    //                 c.disableGrouping || '' , c.disablePermission || ''].join(',')).join('#')

    //     ].join(',');

    // }
}

export class DataGridStore {
    defaultState = null;
    defaultDisplaySettings = null;
    // defaultData = {};
    translator = {};
    ready = false;
    constructor(state, displaySettings, data, translator) {
        // if(GlobalStore.gridSettings.get().length>0){
        //     this.load(state, displaySettings, data);
        // }
        // else{
        this.defaultState = this.defaultState || DataGridState.clone(state);
        this.defaultDisplaySettings = displaySettings;
        // this.defaultData = data;
        this.translator = translator;

        this.gridState = new DataStore(state);
        this.gridData = new DataStore(data);
        this.displaySettings = new DataStore(displaySettings);

        // this.load(this.defaultState, this.defaultDisplaySettings, this.defaultData);

        // }
    }

    beforeLoad = async () => {
        console.error('default before load')
    };

    renderDefaultSettings = () => {
        this.load(
            DataGridState.clone(this.defaultState),
            this.defaultDisplaySettings,
            this.gridData.get()
        );
    };

    render = async () => {
        await this.beforeLoad(this);
        this.load(this.gridState.get(), this.displaySettings.get(), this.gridData.get());
        this.onRendered.fire(this.gridState.get());
    };

    getState = async () => {
        if (this.ready) {
            return this.gridState.get();
        } else {
            function __delay__(timer) {
                return new Promise((resolve) => {
                    timer = timer || 2000;
                    setTimeout(function () {
                        resolve();
                    }, timer);
                });
            }

            while (!this.ready) {
                await __delay__(100);
            }

            return this.gridState.get();
        }
    };

    saveState = async (key, newState) => {
        newState = newState || this.gridState.get();
        key = key || newState.gridId;
        // console.log('...saveState');
        if (!this.ready) {
            console.log('not ready');
            return;
        }
        let stateToSave = { ...newState };

        stateToSave.columns = newState.columns.map((c) => {
            let col = { ...c };
            delete col.template;
            delete col.export;
            delete col.data;
            delete col.dropdownProps;
            if (col.children && col.children.length > 0) {
                col.children = col.children.map((cc) => {
                    let child = { ...cc };
                    delete child.template;
                    delete child.export;
                    delete child.data;
                    delete child.dropdownProps;
                    return child;
                });
            }
            return col;
        });

        delete stateToSave.hash;

        let newHash = await DataGridState.getHash(newState);

        // console.log('newHash');
        // console.log( newHash );
        // console.log('newState');
        // console.log( newState )
        // console.log('newState hash');
        // console.log(newState.hash)
        // console.log('current hash');
        // console.log(this.gridState.get().hash)
        //let currentHash =  DataGridState.getHash(this.gridState.get());
        // let oldHash = this.gridState.get().hash;
        // if (newHash.hash === oldHash.hash) {
        //     console.log(' SAME', stateToSave, this.gridState.get());
        //     return;
        // }

        
        // console.log('NOT SAME', stateToSave.columns.map(c=>({ title:c.title, index:c.index })), this.gridState.get().columns.map(c=>({ title:c.title, index:c.index })), oldHash, newHash);

        // console.log('NOT SAME', stateToSave, this.gridState.get(), oldHash, newHash);
        //  console.log(newHash===currentHash, newHash, '-------', currentHash);
        GlobalStore.gridSettings.upsert(stateToSave, 'gridId');
        
        localStorage['grid_state_' + newState.gridId] = JSON.stringify(stateToSave);

        newState.hash = newHash;
        this.gridState.set(newState);
        
        if(!Globals.noCache){
            Globals.callApi({
                url: 'user_preference/save',
                params: {
                    key: key,
                    type: USER_PREFERENCE_TYPES.GRID_SETTING,
                    options: { data: stateToSave },
                },
            });
        }
       
    };

    load = async (state, displaySettings, data) => {
          console.log( 'gridSettings', state.gridId, GlobalStore.gridSettings.get());
        let setting = null;

        // (GlobalStore.gridSettings.get() || []).filter(
        //     (s) => s.gridId === state.gridId
        // )[0];
        let oldStateData =  null;// getLocalStorageValue('grid_state_' + state.gridId);
        let selectedKeys = getLocalStorageValue('grid_selectedkeys_' + state.gridId);
        let deselectedKeys = getLocalStorageValue('grid_deselectedkeys_' + state.gridId);

        if (!oldStateData || !oldStateData.gridId) {
            oldStateData = null;
        }
        try {
            if (oldStateData && oldStateData.gridId) {
                let columnsNotExistInState = oldStateData.columns.filter(
                    (sc) => state.columns.filter((c) => c.key === sc.key).length === 0
                );

                oldStateData.columns = state.columns.map((c) => {
                    let oldColumn = oldStateData.columns.filter((oc) => oc.key === c.key)[0];
                    if (c.children && c.children.length > 0) {
                        oldColumn.children = c.children.map((cc) => {
                            let oldChild = oldColumn.children.filter(
                                (occ) => occ.key === cc.key
                            )[0];
                            return { ...cc, ...oldChild };
                        });
                    }
                    return { ...c, ...oldColumn };
                });

                oldStateData.columns.push(...columnsNotExistInState);
            }
        } catch (ex) {
            oldStateData = null;
            selectedKeys = null;
            deselectedKeys = null;
        }

        let stateToSet = oldStateData || state;
        stateToSet.hash = await DataGridState.getHash(stateToSet);
        console.log('load');
        stateToSet.loading = false;
        this.gridState.set(stateToSet);

        this.gridData = new DataStore(data);
        this.displaySettings = new DataStore(displaySettings);
        this.selectedRecordKeys.set(selectedKeys);
        this.deselectedRecordKeys.set(deselectedKeys);

        this.ready = true;
    };

    __defaultGridState = new DataGridState();

    gridState = null; // new DataStore(this.__defaultGridState);
    gridData = null; // new DataStore([]);
    displaySettings = null; // new DataStore([]);
    selectedRecordKeys = new DataStore([]);
    deselectedRecordKeys = new DataStore([]);

    selectedRecords = new DataStore([]);

    onColumnResize = new DataEvent();
    onColumnSort = new DataEvent();
    onGridStateChanged = new DataEvent();
    onGridLoadingChanged = new DataEvent();
    onForceUpdate = new DataEvent();
    onDataOptionsChanged = new DataEvent(); //filter paging etc

    onMassSelect = new DataEvent(); //filter paging etc
    onMassDeSelect = new DataEvent(); //filter paging etc
    onDeleteSelected = new DataEvent(); //filter paging etc
    onDelete = new DataEvent(); //filter paging etc
    onRendered = new DataEvent(); //store is rendered

    gridStateChanged = (newState, dontFireEvent) => {
        // let stateToSave = { ...newState };
        // stateToSave.columns = newState.columns.map((c) => {
        //     let col = { ...c };
        //     delete col.template;
        //     delete col.data;
        //     delete col.dropdownProps;
        //     if (col.children && col.children.length > 0) {
        //         col.children = col.children.map((cc) => {
        //             let child = { ...cc };
        //             delete child.template;
        //             delete child.data;
        //             delete child.dropdownProps;
        //             return child;
        //         });
        //     }
        //     return col;
        // });

        console.log('new state', newState);
         this.gridState.set(newState);
        //localStorage['grid_state_' + newState.gridId] = JSON.stringify(stateToSave);

        // console.log('gridStateChanged');
        // this.saveState(newState.gridId, newState);

        //    GlobalStore.gridSettings.upsert(stateToSave, 'gridId');
        //     Globals.callApi({
        //         url: 'user_preference/save',
        //         params: {
        //             key: newState.gridId,
        //             type: USER_PREFERENCE_TYPES.GRID_SETTING,
        //             options: { data: stateToSave },
        //         },
        //     });

        if (!dontFireEvent) {
            this.onGridStateChanged.fire(newState);
        }
    };

    showLoading = () => {
        let gridState = this.gridState.get();
        gridState.loading = true;
        this.gridState.set(gridState); 
        this.onGridLoadingChanged.fire(gridState.loading);
    };

    loadingSilently = () => {
        let gridState = this.gridState.get();
        gridState.loading = true;
        this.gridState.set(gridState);
        this.onGridStateChanged.fire(this.gridState.get());
    };

    selectedRecordKeysChanged = (newKeys) => {
        this.selectedRecordKeys.set(newKeys);
        localStorage['grid_selectedkeys_' + this.gridState.get().gridId] = JSON.stringify(newKeys);
    };

    selectedRecordsChanged = (records) => {
        this.selectedRecords.set(records);
        console.log(records);
        localStorage['grid_selectedrecords_' + this.gridState.get().gridId] =
            JSON.stringify(records);
    };

    setRecordSelected = (record, selected) => {
        let selectedRecordItems = this.selectedRecords.get() || [];

        let gridState = this.gridState.get();

        selectedRecordItems = selectedRecordItems.filter(
            (i) => i[gridState.keyField] !== record[gridState.keyField]
        );
        if (selected) {
            selectedRecordItems.push(record);
        }

        this.selectedRecordsChanged(selectedRecordItems);
    };

    getSelectedRecordsInCurrentPage = () => {
        let gridState = this.gridState.get();

        let allData = this.gridData.get();
        if (gridState.selection.selectAllFilteredRecords) {
            return allData;
        }
        let keys = this.selectedRecordKeys.get() || [];
        return allData.filter((a) => {
            return keys.indexOf(a[gridState.keyField]) > -1;
        });
    };

    deselectedRecordKeysChanged = (newKeys) => {
        this.deselectedRecordKeys.set(newKeys);
        localStorage['grid_deselectedkeys_' + this.gridState.get().gridId] =
            JSON.stringify(newKeys);
    };

    clearAllSelection = () => {
        this.selectedRecordKeys.set([]);
        this.selectedRecords.set([]);
        this.deselectedRecordKeys.set([]);

        localStorage['grid_selectedrecords_' + this.gridState.get().gridId] = "[]";
        localStorage['grid_selectedkeys_' + this.gridState.get().gridId] = "[]";
        localStorage['grid_deselectedkeys_' + this.gridState.get().gridId] = "[]";
 
    };

    



    forceUpdate = async () => {
        this.onForceUpdate.fire(this.gridState.get());
    };

    resetToFactorySettings = async () => {
       
        localStorage['grid_selectedkeys_' + this.gridState.get().gridId] = '';
        // localStorage['grid_state_' + this.gridState.get().gridId] = '';

        // this.saveState(this.gridState.get().gridId, DataGridState.clone(this.defaultState));

        // GlobalStore.gridSettings.upsert(this.defaultState, this.gridState.get().gridId);
        // Globals.callApi({
        //     url: 'user_preference/save',
        //     params: {
        //         key: this.gridState.get().gridId,
        //         type: USER_PREFERENCE_TYPES.GRID_SETTING,
        //         options: { data: this.defaultState },
        //     },
        // });

        this.renderDefaultSettings();
        this.onForceUpdate.fire(this.gridState.get());
          tmoLib.ui.navigator.restartSettings();
        // this.defaultState.false = true;
        // this.gridStateChanged(this.gridState.get());
        //document.location.reload();
    };

    duplicate = (gridId, options) => {
        console.log(gridId + ' dublicated');
        ///let state = { , gridId };
        let state = DataGridState.clone(this.gridState.get(), options);
        state.gridId = gridId;
        console.log('new state' , state);
        let newStore = new DataGridStore(state, this.displaySettings.get(), this.translator);
        newStore.beforeLoad = this.beforeLoad;
        newStore.onGridStateChanged = this.onGridStateChanged;
        return newStore;
    };
}


 


const getLocalStorageValue = (key) => {
    let data = localStorage[key] || null;
    if (data && data.length > 0) {
        try {
            data = JSON.parse(data);
        } catch (ex) {
            data = null;
        }
    }
    return data;
};

import React, { useEffect, useState } from 'react';

import Dropdown from '../../components/basic/Dropdown';
import { Globals } from '../../Globals';
import './MemberSelect.scss';

const MemberSelect = ({ placeholder, className, useLabel, isMultiSelect, onChange, value }) => {
    const userSelected = (items) => {
        if (onChange) {
            onChange(items);
        }
    };

    const loadData = async () => {
        return Globals.callApi({
            url: 'auth/member/list',
            params: {},
        });
    };

    const [data, setData] = useState([]);

    useEffect(() => {
        loadData().then((d) => {
            setData(d);
        });
    }, []);

    if (!data || data.length === 0) {
        return <></>;
    }

    return (
        <Dropdown
            placeholder={placeholder}
            className={className}
            useLabel={useLabel}
            valueField="id"
            labelField="short_name"
            options={data}
            value={value}
            onChange={({ items }) => userSelected(items)}
            selectOptions={{ closeOnSelect: true, isMultiSelect: isMultiSelect }}
            searchOptions={{ enable: true, searchOnServer: false }}
            customTemplate={({ label, data }) => (
                <div className={'user-avatar '}>
                    <img src={data.avatar} />
                    <div className="info">
                        <div className="name">{label}</div>
                        <div className="city">{data.department || data.long_name}</div>
                    </div>
                </div>
            )}
            showSelectedItemsFullWidth
            showOnlySelectedItemCount={false}
        />
    );
};

export default MemberSelect;

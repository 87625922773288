import React, { Component, useRef, useState, useEffect } from 'react';

import './LeftPanel.scss';

import tmo from '../tmo/tmo.lib';
import Accordion from '../components/basic/Accordion';
import { ChatStore } from './Chat.Store';
import { CHANNELS, SESSION_FILTER_TYPE } from './Chat.Static';
import { ChatData } from './Chat.Data';
import { Globals } from '../Globals';
import { APPLICATION_TYPES } from '../Auth/Application/Application.Api';

import t from './Localization/i18n';

function LeftPanel({}) {
    const onShowSettings = (id) => {
        tmo.ui.global.openPage('/chat/wecom/settings');
    };

    let applications = Globals.currentUser.applications.filter((a) => {
        let app = APPLICATION_TYPES.filter((at) => at.value == a.type)[0];
        return app.hasChat;
    });

    const tags = ChatStore.currentTags.follow();
    const subjects = ChatStore.allSubjects.follow();
    const sessionFilter = ChatStore.sessionFilter.follow();
    // const currentChannel = ChatStore.currentChannel.follow();
    const currentApplication = ChatStore.currentApplication.follow();
    const appNotifications = ChatStore.applicationNotifications.follow();

    //  if((!currentApplication || !currentApplication.type) && applications.length>0){
    //   ChatData.applicationSelected({application:applications[0]});
    //  }

    // const [ selectedChannel, setSelectedChannel ] = useState('wecom');
    // const [ selectedFilter, setSelectedFilter ] = useState('all');
    // const [ selectedLabel, setSelectedLabel ] = useState('all');

    // const openChannel=(name)=>{
    //   // setSelectedChannel(name);
    //   ChatData.channelSelected({channel:name});

    // }

    const selectApplication = (application) => {
        // setSelectedChannel(name);
        
        ChatData.applicationSelected({ application });
    };

    const filterSessions = (type) => {
        // setSelectedFilter(type);
        // ChatStore.sessionFilterType.set(type);
        ChatData.setSessionFilter({ type });
        // setSessionFilter = async ({type, tag, subject, status, keyword, sort})=>{
        // if(onConversationFilterChange){
        //   onConversationFilterChange(type);
        // }
    };

    const labelClicked = (tag) => {
        // ChatStore.sessionFilterTag.set(id);
        ChatData.setSessionFilter({ tag });

        // setSelectedLabel(id);

        // if(onLabelChange){
        //   onLabelChange(id);
        // }
    };

    const subjectClicked = (subject) => {
        // ChatStore.sessionFilterSubject.set(id);
        ChatData.setSessionFilter({ subject });
    };

    applications.forEach((a) => {
        a.notification = (appNotifications.filter((n) => n.id == a.id)[0] || {}).notify;
    });

    return (
        <div className="chat-left-panel">
            <Accordion label={t('Channels')} open={true}>
                {/*
              <div className='channel-link-inner' onClick={()=>selectApplication(a)}>
                  {a.name}
              </div> */}
                <div
                        key={'all-channels'}
                        className={
                            'channel-link ' +
                            (!currentApplication || !currentApplication.id ? 'selected' : '')
                        }
                    >
                    <div className="channel-link-inner" onClick={() => selectApplication(null)}>
                        {t('All Channels')}
                    </div>
                    
                </div>

                {applications.map((a) => (
                    <div
                        key={a.id}
                        className={
                            'channel-link ' +
                            (currentApplication && currentApplication.id == a.id ? 'selected' : '')
                        }
                    >
                        <div className="channel-link-inner" onClick={() => selectApplication(a)}>
                            {a.name}
                        </div>

                        {a.notification ? (
                            <div className="channel-link-notification">{a.notification}</div>
                        ) : null}
                        {/* <div className='channel-link-settings' > */}
                        {/* <IconButton name="settings" onClick={()=>onShowSettings()}></IconButton>  */}
                        {/* </div> */}
                    </div>
                ))}

                {/*
<div key={a.id} className={'channel-link ' + (currentChannel==CHANNELS.WECOM?'selected':'')} >
                      <div className='channel-link-inner' onClick={()=>openChannel(CHANNELS.WECOM)}>
                        {a.name}
                      </div>
                      <div className='channel-link-settings' >
                        <IconButton name="settings" onClick={()=>onShowSettings()}></IconButton>
                      </div>
                    </div>  */}

                {/* <div className={'channel-link ' + (currentChannel==CHANNELS.WEBCHAT?'selected':'')} onClick={()=>openChannel(CHANNELS.WEBCHAT)}>Web Chat</div>   */}
            </Accordion>
            <div className="draw-line"></div>
            <Accordion label={t('Conversations')} open={true}>
                <div
                    className={
                        'conversation-filter ' +
                        (sessionFilter.type == SESSION_FILTER_TYPE.ALL ? 'selected' : '')
                    }
                    onClick={() => filterSessions(SESSION_FILTER_TYPE.ALL)}
                >
                    {t('All Conversations')}
                </div>
                <div
                    className={
                        'conversation-filter ' +
                        (sessionFilter.type == SESSION_FILTER_TYPE.ASSIGNED_TO_ME ? 'selected' : '')
                    }
                    onClick={() => filterSessions(SESSION_FILTER_TYPE.ASSIGNED_TO_ME)}
                >
                    {t('Assigned To Me')}
                </div>
                <div
                    className={
                        'conversation-filter ' +
                        (sessionFilter.type == SESSION_FILTER_TYPE.PENDING ? 'selected' : '')
                    }
                    onClick={() => filterSessions(SESSION_FILTER_TYPE.PENDING)}
                >
                    {t('Pending Assign')}
                </div>
            </Accordion>
            <div className="draw-line"></div>
            <Accordion label={t('Tags')} open={true}>
                <div
                    key={'all'}
                    className={'label-filter ' + (sessionFilter.tag == 'all' ? 'selected' : '')}
                    onClick={() => labelClicked('all')}
                >
                    {t('All Records')}
                </div>
                {tags.map((l) => (
                    <div
                        key={l.id}
                        className={'label-filter ' + (sessionFilter.tag == l.id ? 'selected' : '')}
                        onClick={() => labelClicked(l.id)}
                    >
                        {l.name} <div className="session-count">{l.total_typed_records}</div>
                    </div>
                ))}
            </Accordion>
            <div className="draw-line"></div>
            <Accordion label={t('Subjects')} open={true}>
                <div
                    key={'all'}
                    className={'label-filter ' + (sessionFilter.subject == 'all' ? 'selected' : '')}
                    onClick={() => subjectClicked('all')}
                >
                    {t('All Records')}
                </div>
                {subjects.map((l) => (
                    <div
                        key={l.id}
                        className={
                            'label-filter ' + (sessionFilter.subject == l.id ? 'selected' : '')
                        }
                        onClick={() => subjectClicked(l.id)}
                    >
                        # {l.title} <div className="session-count">{l.total_sessions}</div>
                    </div>
                ))}
            </Accordion>
        </div>
    );
}

export default LeftPanel;

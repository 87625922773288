import { Globals } from '../../Globals';

export class CustomerProfileApi {
    static search = async ({
        filter,
        page_size,
        offset,
        keyword,
        sort,
        groupby,
        display_settings,
        application_ids,
        system_filters = null,
        selection,
        guest_users,
        registered_users,
    }) => {
        if (application_ids && application_ids.length > 0) {
            system_filters = [];

        //   application_ids.forEach((aid) => {
                system_filters.push({ field: 'application_ids', value: application_ids });
        //    });
        } 
        return await Globals.callApi({
            url: 'customer/search',
            params: { 
                guest_users,
                registered_users,
                filter: filter,
                page_size: page_size,
                offset: offset,
                keyword: keyword,
                sort,
                groupby,
                display_settings,
                system_filters,
                selection,
            },
        });
    };
    static getBySegments = async ({
       segment_ids
    }) => {
       
        return await Globals.callApi({
            url: 'customer/get_by_segments',
            params: { 
                segment_ids 
            },
        });
    };


    static get = async ({ id }) => {
       return await Globals.callApi({
            url: 'customer/get',
            params: { customer_id: id },
        });
        // TODO: remove this once api is deployed and we can load defaults
        // return {
        //     ...data,
        //     customer: {
        //         ...data.customer,
        //         // total_visits: 100,
        //         // app_visits: 10,
        //         // wechat_visits: 20,
        //         // web_visits: 30,
        //         // douyin_visits: 40,
        //         // linkedin_visits: 50,
        //     },
        //     detail: {
        //         // app_interaction_value: 1,
        //         // app_conversion_value: 2,
        //         // app_read_value: 3,
        //         // app_coupon_take_value: 4,
        //         // app_use_menu_value: 5,
        //         // app_share_value: 6,
        //         // app_to_original_value: 7,
        //         // app_purchase_value: 8,
        //         // wechat_interaction_value: 9,
        //         // wechat_conversion_value: 10,
        //         // wechat_read_value: 11,
        //         // wechat_coupon_take_value: 12,
        //         // wechat_use_menu_value: 13,
        //         // wechat_share_value: 14,
        //         // wechat_to_original_value: 15,
        //         // wechat_purchase_value: 16,
        //         // web_interaction_value: 17,
        //         // web_conversion_value: 18,
        //         // web_read_value: 19,
        //         // web_coupon_take_value: 20,
        //         // web_use_menu_value: 21,
        //         // web_share_value: 22,
        //         // web_to_original_value: 23,
        //         // web_purchase_value: 24,
        //         // douyin_interaction_value: 25,
        //         // douyin_conversion_value: 26,
        //         // douyin_read_value: 27,
        //         // douyin_coupon_take_value: 28,
        //         // douyin_use_menu_value: 29,
        //         // douyin_share_value: 30,
        //         // douyin_to_original_value: 31,
        //         // douyin_purchase_value: 32,
        //         // linkedin_interaction_value: 33,
        //         // linkedin_conversion_value: 34,
        //         // linkedin_read_value: 35,
        //         // linkedin_coupon_take_value: 36,
        //         // linkedin_use_menu_value: 37,
        //         // linkedin_share_value: 38,
        //         // linkedin_to_original_value: 39,
        //         // linkedin_purchase_value: 40,
        //     },
        // };
    };



    static getByChatUserId = async ({ id }) => {
        return await Globals.callApi({
            url: 'customer/get_by_chat_user_id',
            params: { chat_user_id: id },
        });
        
    };


    static save = async ({ params }) => {
        return await Globals.callApi({
            url: 'customer/save',
            params: params,
        });
    };

    static getSuggestions = async ({ id }) => {
        return await Globals.callApi({
            url: 'customer/get_suggestions',
            params: { id: id },
        });
    };
    static remove = async ({ id }) => {
        return await Globals.callApi({
            url: 'customer/remove',
            params: { customer_id: id },
        });
    };

    static rollback = async ({ id }) => {
        return await Globals.callApi({
            url: 'customer/rollback',
            params: { customer_id: id },
        });
    };

    static history = async () => {
        return await Globals.callApi({
            url: 'history/listlast200',
            params: { module: 'customer' },
        });
    };
}

import React from 'react';
import { Route } from 'react-router-dom';

import { Tab, Tabs } from '../components/basic/Tab';

import Settings from './Setting/Main';
import ScheduledJobList from './ScheduledJob/ScheduledJob.List';
// import ScheduledJobLogList from './ScheduledJobLog/ScheduledJobLog.List';
import TaskList from './Task/Task.List';
import NotificationList from './Notification/Notification.List';
import ConstantList from './StaticVar/StaticVar.List';
import HistoryLogger from '../ShopNow/Components/TopLevel/HistoryLogger';

export const Main = () => {
    return (
        <Tabs navigate>
        
                <Tab title="Settings" hasSubUrls url="/system/settings">
                    <Settings />
                </Tab>
           
            {/* <Tab title="Scheduled Jobs" url="/system/scheduled_jobs">
                <ScheduledJobList />
            </Tab> */}
            <Tab title="Tasks" url="/system/tasks">
                <TaskList />
            </Tab>
            <Tab title="Notifications" url="/system/notifications">
                <NotificationList />
            </Tab>
            <Tab title="Constants" url="/system/constants">
                <ConstantList />
            </Tab>
            {false && (
                <Tab title="Url Whitelist" url="/system/url_whitelist">
                    <ConstantList />
                </Tab>
            )}
            <Tab title="History Log" url="/system/history">
                <HistoryLogger showType />
            </Tab>
        </Tabs>
    );
};

export const SystemRoutes = [
    <Route path="/system/" element={<Main />} />,
    <Route path="/system/settings" element={<Main />} />,
    <Route path="/system/settings/currency" element={<Main />} />,
    <Route path="/system/settings/conversation" element={<Main />} />,
    <Route path="/system/settings/notification" element={<Main />} />,
    <Route path="/system/settings/ui" element={<Main />} />,
    <Route path="/system/scheduled_jobs" element={<Main />} />,
    <Route path="/system/tasks" element={<Main />} />,
    <Route path="/system/notifications" element={<Main />} />,
    <Route path="/system/constants" element={<Main />} />,
    // <Route path="/system/url_whitelist" element={<Main />} />,
    <Route path="/system/history" element={<Main />} />,
    // <Route path="/system/scheduled_job/logs/:id" element={<ScheduledJobLogList />} />,
];

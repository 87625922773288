import React, { useEffect, useState } from 'react';
import { Route, useLocation } from 'react-router-dom';

import { Globals } from '../Globals';
import { StoreApi } from './Store/Store.Api';
import { GlobalStore, TOOLBAR_KEY_TYPE, TOOLBAR_KEYS, USER_PREFERENCE_TYPES } from '../GlobalStore';
import { Tab, Tabs } from '../components/basic/Tab';
import ProductList from './Product/Product.List';
import UserList from './User/User.List';
import OrderList from './Order/Order.List';
import BasketList from './Basket/Basket.List';
import CategoryList from './Category/Category.List';
import BrandList from './Brand/Brand.List';
import StoreList from './Store/Store.List';
import AddressList from './Address/Address.List';
import EcommerceOverview from './Overview/EcommerceOverview.Main';
import ProductEdit from './Product/Product.Edit';
import Toolbar from '../ShopNow/Components/TopLevel/Toolbar';
import Dropdown from '../components/basic/Dropdown';
import { getTimeRangeDates, TIME_FRAME } from '../ShopNow/Components/Report/Filters/utils';
import { ECommerceGenericApi } from './ECommerceGeneric.Api';
import EcommerceMainPageListeners from './EcommerceMainPageListeners';
import NoAppInfo from '../ShopNow/Components/TopLevel/NoAppInfo';
import './Main.scss';
import WaitingData from '../ShopNow/Components/TopLevel/WaitingData';
import tmoLib from '../tmo/tmo.lib';
import OverviewPage from '../components/basic/OverviewPage';
import AppToolbar from '../components/basic/AppToolbar';
import { OVERVIEW_PAGE_TYPE } from '../components/basic/OverviewCardUtil';

export const Main = () => {

    const location = useLocation();

    const toolbarOptions = GlobalStore[TOOLBAR_KEYS.ecommerce_toolbar].follow();
    const dateCyclesLastFour = GlobalStore.dateCyclesLastFour.follow();
    const emptyFilters = {
        timeframe: TIME_FRAME.ALL,
        customTimeframe: null,
        applications: null,
        stores: null,
    };

    const [filters, setFilters] = useState({
        timeframe: toolbarOptions?.timeframe ?? emptyFilters.timeframe,
        customTimeframe: toolbarOptions?.customTimeframe ?? emptyFilters.customTimeframe,
        applications: toolbarOptions?.applications ?? emptyFilters.applications,
        stores: toolbarOptions?.stores ?? emptyFilters.stores,
    });
    const [data, setData] = useState({ stores: [] });
    const [isLoading, setLoading] = useState(true);
    const [systemFilter, setSystemFilter] = useState([]);

    const formatSystemFilters = (currentFilters) => {
        const isEmptyValue = (value) => {
            if (Array.isArray(value) && value.length === 0) return true;
            if (value === '') return true;
            return value === null;
        };
        const formattedDateValue = !currentFilters.customTimeframe
            ? getTimeRangeDates(currentFilters.timeframe, dateCyclesLastFour)
            : {
                  current_start: currentFilters.customTimeframe.startDate,
                  current_end: currentFilters.customTimeframe.endDate,
              };
        const { current_start, current_end } = formattedDateValue;

        return [
            { field: 'application_id', value: currentFilters.applications },
            { field: 'store_id', value: currentFilters.stores },
            { field: 'updated_at', op: 'gte', value: current_start },
            { field: 'updated_at', op: 'lte', value: current_end },
        ].filter((item) => !isEmptyValue(item.value));
    };

    const handleFilterChange = (value, type) => {
        const filtersUpdated = { ...filters, [type]: value.value };

        if (type !== 'customTimeframe') {
            filtersUpdated.customTimeframe = null;
        } else {
            filtersUpdated.timeframe = TIME_FRAME.CUSTOM_RANGE;
        }

        GlobalStore.toolbarEcommerceOptions.set(filtersUpdated);
        const newSystemFilters = formatSystemFilters({ ...filtersUpdated });

        Globals.callApi({
            url: 'user_preference/save',
            params: {
                key: TOOLBAR_KEY_TYPE.ECOMMERCE,
                type: USER_PREFERENCE_TYPES.TOOLBAR,
                options: { data: filtersUpdated },
            },
        });

        setFilters(filtersUpdated);
        setSystemFilter(newSystemFilters);
    };

    const handleDataRefresh = () => {
        ECommerceGenericApi.initSync({
            application_ids: (
                systemFilter.filter((sf) => sf.field === 'application_id')[0] || { value: [] }
            ).value,
        });
    };

    const handleResetSettings = async () => {
        await Globals.callApi({
            url: 'user_preference/remove',
            params: { key: TOOLBAR_KEY_TYPE.ECOMMERCE },
        });
        setFilters(emptyFilters);
        GlobalStore.toolbarEcommerceOptions.set(emptyFilters);
        setSystemFilter([]);
    };


    const [summary, setSummary] = useState({});
    const getData = async () => {
        let summaryData = await ECommerceGenericApi.summary({ application_ids: (
            systemFilter.filter((sf) => sf.field === 'application_id')[0] || { value: [] }
        ).value
        });
        setSummary(summaryData);
     } 
    useEffect(() => {
        getData();
    }
    , [systemFilter]);

    
    useEffect(() => {
        const getStores = async () => {
            const stores = await StoreApi.search({});

            const formattedStores = stores.records.map((store) => ({
                label: store.name,
                value: store.id,
            }));

            setLoading(false);
            setData({ ...data, stores: formattedStores });
        };

        if (toolbarOptions) {
            const initialOptions = formatSystemFilters(toolbarOptions);
            setSystemFilter(initialOptions);
        }

        getStores();
    }, []);

    let trackingApps =   window.currentUser.applications.filter(
        (a) =>
            a.account_id === Globals.currentAccount.account_id && a.type === 'shopnow_tracking'
    );

    if (trackingApps.length===0) {
        return (
            <NoAppInfo
                buttonText="Bind your E-Commerce Application"
                text="You haven't connected any E-Commerce Api to ShopNow. Please go to your account settings and add a E-Commerce application."
            />
        );
    }

    if (
        trackingApps.length>0 
          && 
         trackingApps.filter((a)=> a && a.created_at && new Date(a.created_at).getTime()>new Date().setDate(-1)).length===trackingApps.length
    ) {
        return (
            <WaitingData />
        );
    }
    


    if (isLoading) return <div className="loading-overlay" />;

 
    return (
        <>

            <AppToolbar hideTimeSelect={location.pathname!='/ecommerce'} showEcommerceFilter applicationTypes={['shopnow_tracking', 'magento']} />
{/*         
            <div className="toolbar-wrapper">
                <Toolbar
                    filters={filters}
                    onFilterChange={handleFilterChange}
                    slots={{
                        sync: true,
                        bots: false,
                        timeframe:true,
                        reset:true,
                        other: (
                            <Dropdown
                                className="toolbar-selection-filter"
                                useLabel={false}
                                placeholder="All stores"
                                options={data.stores}
                                value={filters.stores}
                                onChange={(val) => handleFilterChange(val, 'stores')}
                                selectOptions={{
                                    closeOnSelect: false,
                                    isMultiSelect: true,
                                    selectMode: 'highlight',
                                }}
                                selectedItemShape="square"
                                searchOptions={{ enable: true, searchOnServer: false }}
                            />
                        ),
                    }}
                    applicationSelectorOptions={{
                        selectOptions: {
                            closeOnSelect: false,
                            isMultiSelect: true,
                            selectMode: 'highlight',
                        },
                    }}
                    onRefresh={handleDataRefresh}
                    onReset={handleResetSettings}
                />
            </div>
  */}
            <EcommerceMainPageListeners />
            <Tabs navigate>
                <Tab title="Overview" url="/ecommerce">
                    {/* <EcommerceOverview systemFilter={systemFilter} /> */}
                    <OverviewPage id="ecommerce"  overviewType={OVERVIEW_PAGE_TYPE.ANALYTICS} />
                  
                </Tab>
                <Tab title={`Customers (${tmoLib.string.readNumber(summary, 'user')})`} url="/ecommerce/users">
                    <UserList systemFilter={systemFilter} />
                </Tab>
                {summary.category>0 && 
                    <Tab title={`Categories (${tmoLib.string.readNumber(summary, 'category')})`} url="/ecommerce/categories">
                        <CategoryList systemFilter={systemFilter} />
                    </Tab>
                }
                {summary.brand>0 && Globals.isAdmin && 
                    <Tab title={`Brands (${tmoLib.string.readNumber(summary, 'brand')}) (${summary.brand})`} url="/ecommerce/brands">
                        <BrandList systemFilter={systemFilter} />
                    </Tab>
                }
                {summary.store>0 && Globals.isAdmin && 
                    <Tab title={`Stores (${tmoLib.string.readNumber(summary, 'store')})`} url="/ecommerce/stores">
                        <StoreList systemFilter={systemFilter} />
                    </Tab>
                }
                
                    <Tab title={`Products (${tmoLib.string.readNumber(summary, 'product')})`} url="/ecommerce/products">
                        <ProductList systemFilter={systemFilter} />
                    </Tab>
                
                {summary.address>0 && 
                    <Tab title={`Address (${tmoLib.string.readNumber(summary, 'address')})`} url="/ecommerce/address">
                        <AddressList systemFilter={systemFilter} />
                    </Tab>
                }

                 
                    <Tab title={`Orders (${tmoLib.string.readNumber(summary, 'order')})`} url="/ecommerce/orders">
                        <OrderList systemFilter={systemFilter} />
                    </Tab>
                 
                {summary.basket>0 && 
                    <Tab title={`Baskets (${tmoLib.string.readNumber(summary, 'basket')})`} url="/ecommerce/baskets">
                        <BasketList systemFilter={systemFilter} />
                    </Tab>
                }
            </Tabs>
        </>
    );
};

export const ECommerceRoutes = [
    <Route path="/ecommerce" element={<Main />} />,
    <Route path="/ecommerce/users" element={<Main />} />,
    <Route path="/ecommerce/categories" element={<Main />} />,
    <Route path="/ecommerce/brands" element={<Main />} />,
    <Route path="/ecommerce/stores" element={<Main />} />,
    <Route path="/ecommerce/products" element={<Main />} />,
    <Route path="/ecommerce/address" element={<Main />} />,
    <Route path="/ecommerce/orders" element={<Main />} />,
    <Route path="/ecommerce/baskets" element={<Main />} />,

    <Route path="/ecommerce/product/edit/:id" element={<ProductEdit />} />,

    // <Route path="/marketing/campaign/edit/:id" element={<CampaignEdit />} />,
    // <Route path="/marketing/campaign/:id" element={<CampaignView />} />,

    // <Route path="/marketing/landing_page/edit/:id" element={<LandingPageEdit />} />,
    // <Route path="/marketing/landing_page/:id" element={<LandingPageView />} />,

    // <Route path="/marketing/referral/edit/:id" element={<ReferralEdit />} />,
    // <Route path="/marketing/referral/:id" element={<ReferralView />} />,

    // <Route path="/marketing/personalized_content/edit/:id" element={<PersonalizedContentEdit />} />,

    // <Route path="/marketing/automation/list" element={<AutomationList />} />,
    // <Route path="/marketing/automation/edit/:id" element={<AutomationEdit />} />,
    // <Route path="/marketing/automation/:id" element={<AutomationView />} />,

    // <Route path="/marketing/segment/edit/:id" element={<WechatGiftCardEdit />} />,
    // <Route path="/marketing/segment/:id" element={<WechatGiftCardView />} />,
];

import React from 'react';

import './ChatCsr.List.scss';

import { ListDataGridStore, Navigations } from './ChatCsr.GridStore';
import { ChatCsrApi } from './ChatCsr.Api';
import GridListPage from '../../ShopNow/Components/TopLevel/GridListPage';

function ChatCsrList() {
    return (
        <GridListPage
            store={ListDataGridStore}
            onSearch={ChatCsrApi.search}
            onHistory={ChatCsrApi.history}
            onGotoEdit={Navigations.gotoEdit}
        />
    );
}

export default ChatCsrList;

import React from 'react';

import tmo from '../../tmo/tmo.lib';
import { DataGridState, DataGridStore } from '../../components/grid/DataGrid.Store';
import { Globals } from '../../Globals';
import ApplicationTag from '../../ShopNow/Components/ApplicationTag';
import CustomerInfo from '../../ShopNow/Components/TopLevel/CustomerInfo';
import { GlobalStore } from '../../GlobalStore';
import tmoLib from '../../tmo/tmo.lib';
import Constants from '../../System/StaticVar/Constants';

export const Navigations = {
    gotoEdit: (id) => tmo.ui.global.openPage('/basket/edit/' + id),
    gotoList: () => tmo.ui.global.openPage('/basket/list'),
    gotoView: (id) => tmo.ui.global.openPage('/basket/' + id),
};

export const DownloadReport = (data) =>
    tmo.task.startPrintTask({
        name: 'Downloading Basket Report: ' + data.name,
        task_input: { basket_id: data.id },
        related_module_id: 'basket',
        related_record_id: data.id,
    });

const storeDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

const appDropdownProps = {
    valueField: 'id',
    labelField: 'name',
    options: [],
    enableSearchInput: false,
    searchOnServer: false,
    searchOptions: { enable: true, searchOnServer: false },
};

export const columns = [
    {
        title: '#',
        index: 0,
        mIndex: 0,
        visible: true,
        mVisible: true,
        type: 'system-check',
        key: 'col_0',
        field: 'selected',
        width: 50,
        stickLeft: true,
        disableSort: true,
        disableFilter: true,
        disableHide: true,
        disableResize: true,
        disableIndex: true,
        disableGrouping: true,
        disablePermission: true,
    },
    {
        title: 'Customer',
        index: 1,
        mIndex: 1,
        visible: true,
        mVisible: true,
        type: 'parent',
        field: 'customer',
        db_field: 'customer',
        width: 240,
        key: 'col_1',
        stickLeft: false,
        disableGrouping: true,
        disableSort: true,
        children: [
            {
                title: 'Customer Name',
                type: 'text',
                key: 'col_1_1',
                field: 'name',
                db_field: 'name',
            },
            {
                title: 'Customer Description',
                type: 'text',
                key: 'col_1_2',
                field: 'desc',
                db_field: 'desc',
            },
        ],
        template: ({ item }) => {
            return (
                <span>
                    <CustomerInfo
                        id={item.id}
                        avatar={item.customer?.avatar ?? '/noavatar.png'}
                        name={item.customer?.name}
                        description={item.customer?.desc}
                        customer={item.customer}
                        active={item.customer?.active}
                    />
                </span>
            );
        },
    },
    {
        title: 'Total',
        index: 2,
        mIndex: 2,
        visible: true,
        mVisible: true,
        type: 'parent',
        width: 120,
        key: 'col_2',
        db_field: 'price',
        template: ({ item }) => (
            // <span className="price">
            //     <span className="price-old">{`${item.currency.toUpperCase()} ${
            //         item.total_old ?? '0'
            //     }`}</span>
            //     <span className="price-new">{`${item.currency.toUpperCase()} ${item.total}`}</span>
            // </span>

            <span className="price">
                {
                    item.total_old && item.total_old!=='0' && item.total!==item.total_old ?
                        <span className="price-old">
                            {tmoLib.string.readPrice(item, 'total_old', null, item.currency)}
                        </span>
                    :null
                }
                <span className="price-new">
                    {tmoLib.string.readPrice(item, 'total', null, item.currency)} 
                </span>
            </span>


        ),
        children: [
            { title: 'Total', type: 'text', key: 'col_2_1', field: 'total', db_field: 'total' },
            {
                title: 'Total old',
                type: 'text',
                key: 'col_2_2',
                field: 'total_old',
                db_field: 'total_old',
            },
        ],
    },
    {
        title: 'Num. of Items',
        type: 'number',
        width: 120,
        key: 'col_3',
        disableGrouping: true,
        index: 3,
        mIndex: 3,
        visible: true,
        mVisible: true,
        field: 'total_items',
        db_field: 'total_items',
    },
    // {
    //     title: 'Shipping Address',
    //     index: 4,
    //     mIndex: 4,
    //     visible: true,
    //     mVisible: true,
    //     type: 'parent',
    //     width: 220,
    //     key: 'col_4',
    //     field: 'shipping_address',
    //     db_field: 'shipping_address',
    //     disableSort: true,
    //     template: ({ item }) => (
    //         <span className="shipping-address">
    //             {item.shipping_address && (
    //                 <>
    //                     <span className="address">{item.shipping_address.address}</span>
    //                     <span className="country">
    //                         {item.shipping_address.receiver_name &&
    //                             item.shipping_address.receiver_name}
    //                     </span>
    //                 </>
    //             )}
    //         </span>
    //     ),
    //     children: [
    //         {
    //             title: 'Address',
    //             type: 'text',
    //             key: 'col_4_1',
    //             field: 'shipping_address',
    //             db_field: 'shipping_address',
    //         },
    //         {
    //             title: 'Country',
    //             type: 'text',
    //             key: 'col_4_2',
    //             field: 'shipping_address',
    //             db_field: 'shipping_address',
    //         },
    //         {
    //             title: 'City',
    //             type: 'text',
    //             key: 'col_4_3',
    //             field: 'shipping_address',
    //             db_field: 'shipping_address',
    //         },
    //     ],
    // },
 
    {
        title: 'Channel',
        type: 'dropdown',
        width: 100,
        key: 'col_channel',
        index: 5, 
        visible: true,
        field: 'channel',
        db_field: 'channel',
        dropdownProps: Constants.CHANNEL_DROWNDOWN_PROPS,
        template: ({ item }) => <span>{Constants.CHANNELS[item.channel]}</span>,
    },
    {
        title: 'Application',
        type: 'dropdown',
        width: 100,
        key: 'col_6',
        index: 6,
        mIndex: 6,
        visible: true,
        mVisible: true,
        noSpan:true,
        field: 'application_id',
        db_field: 'application_id',
        dropdownProps: appDropdownProps,
        template: ({ item }) => <ApplicationTag application={item.application} />,
    },
    // {
    //     title: 'Store',
    //     type: 'dropdown',
    //     width: 100,
    //     key: 'col_7',
    //     index: 7,
    //     mIndex: 7,
    //     visible: true,
    //     mVisible: true, 
    //     field: 'store_id',
    //     db_field: 'store_id',
    //     dropdownProps: storeDropdownProps,
    //     template: ({ item }) => (
    //         <span className="translated-text">
    //             <span className="translated-text-main">
    //                 {tmo.helpers.readOr(item.store, 'name', 'N/A')}
    //             </span>
    //             <span className="translated-text-sub">
    //                 {tmo.helpers.readOr(item.store, 'name_sub', '')}
    //             </span>
    //         </span>
    //     ),
    // },
    {
        title: 'Updated At',
        type: 'datetime',
        width: 110,
        key: 'col_updated_at',
        disableGrouping: true,
        index: 8,
        mIndex: 8,
        visible: true,
        mVisible: true,
        field: 'updated_at',
        db_field: 'updated_at',
    },
];

const defaultGridState = new DataGridState();
defaultGridState.gridId = 'basket_list';
defaultGridState.keyField = 'id';
defaultGridState.features = {
    addNew: false,
    columnManager: true,
    attributeManager: true,
    filter: true,
    dataDisplaySettings: false,
    conditionalColoring: true,
    import: true,
    export: true,
    tagging: false,
    bookmark: true,
    share: true,
    visualSettings: true,
    permissions: true,
    fullScreen: true,
    grouping: true,
    search: true,
    paging: true,
    inlineEditing: false,
};
 

defaultGridState.columns = columns;

let displaySettings = [
    {
        label: 'Display ShopNow Basket',
        simpleLabel: 'ShopNow Basket',
        key: 'converted_users',
        value: false,
    },
    {
        label: 'Display Wechat Basket',
        simpleLabel: 'Wechat Basket',
        key: 'unconverted_users',
        value: false,
    },
    {
        label: 'Display Weibo Basket',
        simpleLabel: 'Weibo Basket',
        key: 'wechat_followers',
        value: false,
    },
    {
        label: 'Display TikTok Basket',
        simpleLabel: 'TikTok Basket',
        key: 'weibo_followers',
        value: false,
    },
    {
        label: 'Display LinkedIn Basket',
        simpleLabel: 'LinkedIn Basket',
        key: 'linkedin_followers',
        value: false,
    },
    { label: 'Display QQ Basket', simpleLabel: 'QQ Basket', key: 'qq_followers', value: false },
    { label: 'Display App Basket', simpleLabel: 'App Basket', key: 'app_users', value: false },
];

export const ListDataGridStore = new DataGridStore(defaultGridState, displaySettings, null);

ListDataGridStore.beforeLoad = async (store) => {
    const ecommerceApps = GlobalStore.toolbarEcommerceOptions.get().applications; // TODO: later when ecommerce stores have application data, filter stores from toolbar selection (dont show all)
    const storeTypesRecords = await Globals.callApi({
        url: 'shop/store/search',
        params: {
            offset: 0,
            page_size: 1000,
        },
    });
    appDropdownProps.options = Globals.currentUser.applications;
    storeDropdownProps.options = storeTypesRecords.records;
};

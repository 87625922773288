 
 import React, { Component, useRef, useState, useEffect }  from 'react';
    
 import  './LeftPanel.scss'; 
 
 
import tmo from '../../../tmo/tmo.lib';  
import Accordion from '../../../components/basic/Accordion'; 
import { EmailStore } from './Email.Store';
import { CHANNELS, SESSION_FILTER_TYPE } from './Email.Static';
import { EmailData } from './Email.Data'; 
import { Globals } from '../../../Globals';
import { APPLICATION_TYPES } from '../../../Auth/Application/Application.Api';

import t from './Localization/i18n';
 
function LeftPanel({}) { 

  const onShowSettings = (id)=>{
    tmo.ui.global.openPage('/email/wecom/settings');
  }
   

  console.log('apppppps',Globals.currentUser.applications);

  let applications = Globals.currentUser.applications.filter(a=>{
    let app = APPLICATION_TYPES.filter(at=>at.value==a.type)[0];
    return app.hasEmail;
  });  
  
 
  const tags = EmailStore.currentTags.follow();
  const subjects = EmailStore.allSubjects.follow();
  const sessionFilter = EmailStore.sessionFilter.follow(); 
  // const currentChannel = EmailStore.currentChannel.follow(); 
   const currentApplication = EmailStore.currentApplication.follow(); 
  const appNotifications = EmailStore.applicationNotifications.follow();

  //  if((!currentApplication || !currentApplication.type) && applications.length>0){
  //   EmailData.applicationSelected({application:applications[0]}); 
  //  }

  // console.log(currentChannel);

  // const [ selectedChannel, setSelectedChannel ] = useState('wecom');
  // const [ selectedFilter, setSelectedFilter ] = useState('all');
  // const [ selectedLabel, setSelectedLabel ] = useState('all');

 
  // const openChannel=(name)=>{ 
  //   // setSelectedChannel(name);
  //   EmailData.channelSelected({channel:name}); 

  // }

  const selectApplication=(application)=>{ 
    // setSelectedChannel(name);
    EmailData.applicationSelected({application}); 

  }


  const filterSessions=(type)=>{
    // setSelectedFilter(type); 
    // EmailStore.sessionFilterType.set(type);
    EmailData.setSessionFilter({type});
// setSessionFilter = async ({type, tag, subject, status, keyword, sort})=>{ 
    // if(onConversationFilterChange){
    //   onConversationFilterChange(type);
    // }
  }

  const labelClicked=(tag)=>{
    // EmailStore.sessionFilterTag.set(id);
    EmailData.setSessionFilter({tag});

    // setSelectedLabel(id);

    // if(onLabelChange){
    //   onLabelChange(id);
    // }
  }


  const subjectClicked=(subject)=>{
    // EmailStore.sessionFilterSubject.set(id); 
    EmailData.setSessionFilter({subject});

  }

  applications.forEach(a=>{
    a.notification = (appNotifications.filter(n=>n.id==a.id)[0] || {}).notify
  });
  
 
  return (
      <div className='email-left-panel'>
              <Accordion label={t('Channels')} open={true}>
{/* 
              <div className='channel-link-inner' onClick={()=>selectApplication(a)}>
                  {a.name}
              </div> */}

                {applications.map(a=>(               
                   <div key={a.id} className={'channel-link ' + (currentApplication && currentApplication.id==a.id?'selected':'')} >
                      <div className='channel-link-inner' onClick={()=>selectApplication(a)}>
                        {a.name}
                      </div>

                      {a.notification ? 
                        <div className='channel-link-notification' >
                          {a.notification}
                        </div>
                      :null}
                      {/* <div className='channel-link-settings' > */}
                        {/* <IconButton name="settings" onClick={()=>onShowSettings()}></IconButton>  */}
                      {/* </div> */}
                    </div> 

                ))}

{/*   
<div key={a.id} className={'channel-link ' + (currentChannel==CHANNELS.WECOM?'selected':'')} >
                      <div className='channel-link-inner' onClick={()=>openChannel(CHANNELS.WECOM)}>
                        {a.name}
                      </div>
                      <div className='channel-link-settings' >
                        <IconButton name="settings" onClick={()=>onShowSettings()}></IconButton> 
                      </div>
                    </div>  */}

                {/* <div className={'channel-link ' + (currentChannel==CHANNELS.WEBEMAIL?'selected':'')} onClick={()=>openChannel(CHANNELS.WEBEMAIL)}>Web Email</div>   */}
              </Accordion>
              <div className='draw-line' ></div>
              <Accordion label={t('Conversations')} open={true}>
                <div className={'conversation-filter ' + (sessionFilter.type==SESSION_FILTER_TYPE.ALL?'selected':'')} onClick={()=>filterSessions(SESSION_FILTER_TYPE.ALL)}>{t('All Conversations')}</div>
                <div className={'conversation-filter ' + (sessionFilter.type==SESSION_FILTER_TYPE.ASSIGNED_TO_ME?'selected':'')} onClick={()=>filterSessions(SESSION_FILTER_TYPE.ASSIGNED_TO_ME)}>{t('Assigned To Me')}</div> 
                <div className={'conversation-filter ' + (sessionFilter.type==SESSION_FILTER_TYPE.PENDING?'selected':'')} onClick={()=>filterSessions(SESSION_FILTER_TYPE.PENDING)}>{t('Pending Assign')}</div>  
              </Accordion> 
              <div className='draw-line' ></div>
              <Accordion label={t('Tags')} open={true}>
                <div key={'all'} className={'label-filter ' + (sessionFilter.tag=='all'?'selected':'')} onClick={()=>labelClicked('all')}>{t('All Records')}</div>
                {tags.map(l=><div key={l.id} className={'label-filter ' + (sessionFilter.tag==l.id?'selected':'')} onClick={()=>labelClicked(l.id)}>{l.name} <div className='session-count'>{l.total_typed_records}</div></div>  )}
              </Accordion> 
              <div className='draw-line' ></div>
              <Accordion label={t('Subjects')} open={true}>
                <div key={'all'} className={'label-filter ' + (sessionFilter.subject=='all'?'selected':'')} onClick={()=>subjectClicked('all')}>{t('All Records')}</div>
                {subjects.map(l=><div key={l.id} className={'label-filter ' + (sessionFilter.subject==l.id?'selected':'')} onClick={()=>subjectClicked(l.id)}># {l.title} <div className='session-count'>{l.total_sessions}</div></div>  )}
              </Accordion> 
             
      </div>
  );

  

}

export default LeftPanel;

 
 import React, { Component, useRef, useState, useEffect, forwardRef, useImperativeHandle }  from 'react';
    
 import  './MessageBox.scss'; 
 
 
import tmo from '../../../tmo/tmo.lib';     

 
import t from './Localization/i18n';
import WechatArticleSelect from '../../../Wechat/WechatArticle/WechatArticle.Select';
import WechatArticleSelectForEmail from '../../../Wechat/WechatArticle/WechatArticle.SelectForChat';
import RecordSenderDirect from '../../../ShopNow/Components/TopLevel/RecordSenderDirect';
 import { CampaignApi } from '../../../Marketing/Campaign/Campaign.Api';

const MessageRecordSender = forwardRef(({onSend, onArticleSelected}, ref) => {
  

  useImperativeHandle(ref, () => ({
    chooseWechatArticle,
    choosePublishedWechatArticle
  }));

  let isMobile = tmo.helpers.isMobile();
 

   const articleSelectRef = useRef(); 
   const articleSelectForEmailRef = useRef(); 
   
   const campaignSelectRef = useRef(); 
   const recordSenderDirectRef = useRef(); 
   
   const [selectedObject, setSelectedObject] = useState({});
   const [customerId, setCustomerId] = useState(null);
   const [customerId2, setCustomerId2] = useState(111); 
     
   
    const chooseWechatArticle = ({customer_id, onDone}) =>{
      console.log('chooseWechatArticle', customer_id);
        setCustomerId(customer_id);
        setCustomerId2('123123');
        articleSelectRef.current.show({isMultiSelect:false});
    } 


    const choosePublishedWechatArticle = ({customer_id}) =>{
      console.log('chooseWechatArticle', customer_id);
        setCustomerId(customer_id);
        setCustomerId2('123123');
       
        articleSelectForEmailRef.current.show({isMultiSelect:false});
    } 

    const articleSelected =  (records)=>{  

      console.log('---------->    ARTICLE SELECTED' , customerId, customerId2);
        let article = records[0];
        setSelectedObject({ 
          id:article.id,
          type:'wechat_article',
          image:(article.thumb_media || {}).url,
          title:article.title,
          description:article.digest,
          data: article
        }); 
        recordSenderDirectRef.current.show({customer_id:customerId}); 
        console.log('---------->    ARTICLE SELECTED');
    }


    const articleSelectedForEmail =  (records)=>{  
    
      console.log('---------->    ARTICLE SELECTED' , customerId, customerId2);
        let article = records[0];
        setSelectedObject({ 
          id:article.id,
          type:'wechat_article',
          image:(article.thumb_media || {}).url,
          title:article.title,
          description:article.digest,
          data: article
        });  
        if(onArticleSelected){
          onArticleSelected(article);
        }
    }
 
 
    const sendCampaign =async ({campaign, receivers}) =>{ 
      
    }


    // const onSendNewCampaign = () =>{ 
    //   recordSenderDirectRef.current.hide(); 
    // }
  
    // const onSendExistingCampaign = () =>{ 
    //   campaignSelectRef.current.show({onDone:campaignSelected, isMultiSelect:false});
    //   recordSenderDirectRef.current.hide(); 
    // }

    const onSendCampaign = async ({campaign, receivers}) =>{ 
      let campaignParams = {
        record_title:selectedObject.title,
        record_description:selectedObject.description,
        account_id:'',
        record_image:selectedObject.image,
        record_type:selectedObject.type,
        record_data:selectedObject.data,
        record_id:selectedObject.id,
  
        tag_selection:receivers.tag,
        customer_selection:receivers.customer,
        segment_selection:receivers.segment,
        campaign_id:campaign.id
       };

       let camp = await CampaignApi.for_record({params:campaignParams});
       alert('campaign created ' + camp.id);
       
    }



    return(<>
        <WechatArticleSelect ref={articleSelectRef} onDone={articleSelected} />  
        <WechatArticleSelectForEmail ref={articleSelectForEmailRef} onDone={articleSelectedForEmail} />  
        
        <RecordSenderDirect onSendCampaign={onSendCampaign}  ref={recordSenderDirectRef} />  
        {/* <CampaignSelect ref={campaignSelectRef} /> */}
    </>); 
 
});

export default MessageRecordSender;

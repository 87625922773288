import Icon from '../../../components/basic/Icon'; 
import Audio from '../../../components/basic/Audio';
import { EmailData } from './Email.Data';
import './EmailMessageContent.scss';
import React, { Component, useRef, useState } from 'react';

const StringHelpers = {};

function EmailMessageContent({
    message,
    onMessageMediaClick,
    onResend,
    isSuggestion,
    onMessageEdit,
}) {
    const [data, setData] = useState(message);

    const IMAGE_RESOLUTIONS = {
        SMALL: 128,
        MEDIUM: 512,
        LARGE: 1024,
    };

    const getFileUrl = (media, resolutionForImage) => {
        if (media) {
            if (resolutionForImage) {
                return (
                    'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/' +
                    (media.file_name || media.name) +
                    '_' +
                    resolutionForImage +
                    '.' +
                    (media.ext || media.extension)
                );
            } else {
                return (
                    'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/' +
                    (media.file_name || media.name) +
                    '.' +
                    (media.ext || media.extension)
                );
            }
        } else {
            if (resolutionForImage) {
                return '/noimage.png';
            } else {
                return null;
            }
        }
        return media;
    };

    const FILE_TYPES = {
        PDF: 'Adobe acrobat reader file',
        ZIP: 'Compressed file',
        RAR: 'Compressed file',
        '7Z': 'Compressed file',
        GZIP: 'Compressed file',
        DOC: 'Microsoft Word file',
        DOCX: 'Microsoft Word file',
        XLS: 'Microsoft Excel file',
        XLSX: 'Microsoft Excel file',
        PPT: 'Microsoft Powerpoint file',
        PPTX: 'Microsoft Powerpoint file',
        KEY: 'MacOs Keynote file',
        PAGES: 'MacOs Pages file',
        HTML: 'Html file (Be careful)',
        JS: 'Javascript Code file',
        CSS: 'Stylesheet Code file',
        AVI: 'A Movie File',
        MOV: 'A Movie File',
        DLL: 'System file',
        BAT: 'System file',
        SH: 'System file (Be careful)',
        EXE: 'Windows Executable file (Be careful)',
        APP: 'App file (Be careful)',
        APK: 'Android app file (Be careful)',
        MP3: 'Audio file',
        WAV: 'Audio file',
        MIDI: 'Audio file',
        AMR: 'Audio file',
        MP4: 'Video file',
        UNKNOWN: 'Not a known file (Be careful)',
    };

    const getFileDesc = (extension) => {
        const fileExtension = (extension || '').toUpperCase();
        return FILE_TYPES[fileExtension] || FILE_TYPES.UNKNOWN;

        // switch(extension){
        //   case 'pdf': return 'Adobe acrobat reader file';
        //   case 'zip': return 'Compressed file';
        //   case 'rar': return 'Compressed file';
        //   case '7z': return 'Compressed file';
        //   case 'gzip': return 'Compressed file';
        //   case 'doc': return 'Microsoft Word file';
        //   case 'docx': return 'Microsoft Word file';
        //   case 'xls': return 'Microsoft Excel file';
        //   case 'xlsx': return 'Microsoft Excel file';
        //   case 'ppt': return 'Microsoft Powerpoint file';
        //   case 'pptx': return 'Microsoft Powerpoint file';
        //   case 'key': return 'MacOs Keynote file';
        //   case 'pages': return 'MacOs Pages file';
        //   case 'html': return 'Html file (Be careful)';
        //   case 'js': return 'Javascript Code file';
        //   case 'css': return 'Stylesheet Code file';
        //   case 'avi': return 'A Movie File';
        //   case 'mov': return 'A Movie File';
        //   case 'dll': return 'System file';
        //   case 'bat': return 'System file';
        //   case 'sh': return 'System file (Be careful)';
        //   case 'exe': return 'Windows Executable file (Be careful)';
        //   case 'app': return 'App file (Be careful)';
        //   case 'apk': return 'Android app file (Be careful)';
        //   case 'mp3': return 'Audio file';
        //   case 'wav': return 'Audio file';
        //   case 'midi': return 'Audio file';
        //   case 'amr': return 'Audio file';
        //   case 'mp4': return 'Video file';
        //   default: return 'Not a known file (Be careful)'
        // }
    };

    const getMessageContentByType = (contentType, content) => {
        if (!content) {
            return '';
        }
        if (contentType == 'text') {
            // if(content.content.indexOf('http')==0){
            //     return <a href={content.content}>{content.content}</a>;
            // }

            var str = content.content
                .replace(/((http:|https:)[^\s]+[\w])/g, '<a href="$1" target="_blank">$1</a>')
                .replace(/\[\#(.*?)\]/g, '<u class="subject">#$1</u>')
                .replace(/\[\$(.*?)\]/g, '<u class="subject">$1</u>')
                .replace(/\r\n|\r|\n/g, '<br />');

            return (
                <div
                    className="text-message-content"
                    onClick={() => onTextMessageClicked(content)}
                    dangerouslySetInnerHTML={{ __html: str }}
                ></div>
            );
        } else if (contentType == 'image') {
            return (
                <img
                    className="image-message"
                    src={getFileUrl(content.media, IMAGE_RESOLUTIONS.MEDIUM)}
                    onClick={() => onMessageMediaClick(content)}
                />
            );
        } else if (contentType == 'video') {
            return (
                <div className="video-message" onClick={() => onMessageMediaClick(content)}>
                    <video src={getFileUrl(content.media)} />
                    <Icon className="play-icon" name="smart_display"></Icon>
                </div>
            );
        } else if (contentType == 'file') {
            return (
                <div className="file-message">
                    <div
                        className="message-image"
                        style={{
                            backgroundImage:
                                'url(/fileicons/' + (content.media || {}).ext + '.png)',
                        }}
                    ></div>
                    <div className="message-name">
                        {'File with "' + (content.media || {}).ext + '" extension.'}
                    </div>
                    <div className="message-desc">{getFileDesc((content.media || {}).ext)}</div>
                    <a
                        className="message-download"
                        target="_blank"
                        href={getFileUrl(content.media)}
                    >
                        <Icon name="download"></Icon>
                    </a>
                    <div className="message-info">Shared a file</div>
                </div>
            );
        } else if (contentType == 'voice') {
            return <Audio className="audio-message" src={getFileUrl(content.media)} />;
            return <audio className="audio-message" src={getFileUrl(content.media)} controls />;
        } else if (contentType == 'link') {
            return (
                <div className="link-message">
                    <div className="message-title">{content.title}</div>
                    <div className="message-desc">{content.description}</div>
                    <div
                        className="message-image"
                        style={{
                            backgroundImage:
                                'url(' +
                                getFileUrl(content.thumb_media, IMAGE_RESOLUTIONS.MEDIUM) +
                                ')',
                        }}
                    ></div>
                    <a className="message-url" target="_blank" href={content.url}>
                        {content.url}
                    </a>
                </div>
            );
        } else if (contentType == 'miniprogram') {
            return (
                <div className="miniprogram-message">
                    <div className="message-title">{content.title}</div>
                    <div
                        className="message-image"
                        style={{
                            backgroundImage:
                                'url(' +
                                getFileUrl(content.thumb_media, IMAGE_RESOLUTIONS.MEDIUM) +
                                ')',
                        }}
                    ></div>
                    <div className="message-info">Wechat Mini Program</div>
                </div>
            );
        } else if (contentType == 'msgmenu') {
            return <div className="msgmenu-message"></div>;
        } else if (contentType == 'location') {
            return (
                <div className="location-message">
                    <div className="message-name">{content.name}</div>
                    <div className="message-address">{content.address}</div>
                    <div className="message-map"></div>
                    <div className="message-info">
                        Lng:{content.longitude}, Lat:{content.latitude}
                    </div>
                </div>
            );
        } else if (contentType == 'location') {
            return (
                <div className="location-message">
                    <div className="message-name">{content.name}</div>
                    <div className="message-address">{content.address}</div>
                    <div className="message-map"></div>
                    <div className="message-info">
                        Lng:{content.longitude}, Lat:{content.latitude}
                    </div>
                </div>
            );
        } else if (contentType == 'event') {
            var str = (content.content || content.text || '')
                .replace(/((http:|https:)[^\s]+[\w])/g, '<a href="$1" target="_blank">$1</a>')
                .replace(/\[\#(.*?)\]/g, '<u class="subject">#$1</u>')
                .replace(/\[\$(.*?)\]/g, '<u class="subject">$1</u>')
                .replace(/\r\n|\r|\n/g, '<br />');
            if (str.trim().length == 0) {
                return <div className="empty"></div>;
            }
            return <div dangerouslySetInnerHTML={{ __html: str }}></div>;
        }

        var str = content;
        // .replace(/((http:|https:)[^\s]+[\w])/g,'<a href="$1" target="_blank">$1</a>')
        // .replace(/\r\n|\r|\n/g,"<br />");

        return <div dangerouslySetInnerHTML={{ __html: str }}></div>;
    };

    const getRoleElement = (role) => {
        if (role == 'customer') return <div className="role customer">Customer</div>;
        if (role == 'user') return <div className="role user">User</div>;
        if (role == 'admin') return <div className="role admin">Admin</div>;
        if (role == 'watcher') return <div className="role watcher">Watcher</div>;
    };
    const getChannelIcon = (channel) => {
        if (channel == 'wechat')
            return <Icon className="channel wechat" logo={true} name="wechat" />;
        if (channel == 'wecom') return <Icon className="channel wecom" logo={true} name="wechat" />;
        if (channel == 'qq') return <Icon className="channel qq" logo={true} name="qq" />;
        if (channel == 'app') return <Icon className="channel app" name="devices" />;
        if (channel == 'web') return <Icon className="channel web" name="web_asset" />;
        if (channel == 'email') return <Icon className="channel email" name="mail" />;
        if (channel == 'sms') return <Icon className="channel sms" name="sms" />;
        if (channel == 'magento') return <Icon className="channel magento" name="storefront" />;
    };

    const resendMessage = () => {
        EmailData.resendMessage({ message: data });
    };

    const onTextMessageClicked = async () => {
        if (isSuggestion) {
            EmailData.sendMessage({
                messageType: 'text',
                isInternal: false,
                text: data.message_content.content,
                fileData: {},
            });
            EmailData.resetSuggestions({});
        }
    };

    const editMessage = () => {
        if (onMessageEdit) {
            onMessageEdit({ message: data });
        }
    };

    const renderText = (text) => {
        var str = text
            .replace(/((http:|https:)[^\s]+[\w])/g, '<a href="$1" target="_blank">$1</a>')
            .replace(/\[\#(.*?)\]/g, '<u class="subject">#$1</u>')
            .replace(/\[\$(.*?)\]/g, '<u class="subject">$1</u>')
            .replace(/\r\n|\r|\n/g, '<br />');
        if (str.trim().length == 0) {
            return <div className="empty"></div>;
        }
        return <div dangerouslySetInnerHTML={{ __html: str }}></div>;
    };

    return (
        <div className="email-message-content">
            <div className="message-header">
                {!data.is_system_message && getChannelIcon(data.sender_channel)}
                {!data.is_system_message && <div className="sender">{data.sender.name}</div>}
                <div className="time">{data.sent_at_text}</div>
                {/* {!data.is_system_message &&  getRoleElement(data.sender.session_role)} */}
                {data.is_internal && <div className="internal">INTERNAL</div>}
                {data.is_system_message && <div className="internal">SYSTEM</div>}
            </div>
            <div
                className={
                    'message type-' +
                    data.message_type +
                    ' ' +
                    (data.translated ? ' --translated ' : '')
                }
            >
                {data.is_internal && data.internal_event_message && (
                    <div
                        className={
                            'internal-event ' +
                            (data.is_internal && data.message_content ? ' --with-broder ' : '')
                        }
                    >
                        {renderText(data.internal_event_message)}
                    </div>
                )}
                {getMessageContentByType(data.message_type, data.message_content)}

                {/*
                              {getMessageContentByType(data.message_type, data.message_content)} */}
                {/* {data.translated?
                                <div className='message-translate'>
                                  {getMessageContentByType(data.message_type, data.translatedContent)}
                                </div>
                              :null} */}

                {isSuggestion && (
                    <div className={'message-edit'} onClick={() => editMessage()}>
                        <Icon name="edit_note" />
                    </div>
                )}

                {data.is_failed && (
                    <div
                        className={'message-delivery message-failed'}
                        onClick={() => resendMessage()}
                    >
                        <Icon name="sync" />
                    </div>
                )}
                {!data.is_failed && !data.is_synced && (
                    <div className={'message-delivery message-sent'}>
                        <Icon name="done" />
                    </div>
                )}
                {!data.is_failed && data.is_synced && (
                    <div className={'message-delivery message-synced'}>
                        <Icon name="done_all" />
                    </div>
                )}
            </div>
            {data.extras && (
                <div className="extras">
                    {data.extras.ai_notes &&
                        data.extras.ai_notes.ai_response &&
                        data.extras.ai_notes.ai_response.length > 0 && (
                            <div className="possible-response">
                                {data.extras.ai_notes.ai_response} <a>USE</a>
                            </div>
                        )}
                    {data.extras.ai_notes &&
                        data.extras.ai_notes.possible_tags &&
                        data.extras.ai_notes.possible_tags.length > 0 && (
                            <div className="possible-tags">
                                {data.extras.ai_notes.possible_tags.map((t) => (
                                    <div classNAme="possible-tag">
                                        {t.tag + ' (' + t.reason + ')'}
                                    </div>
                                ))}
                            </div>
                        )}
                    {data.extras.ai_notes &&
                        data.extras.ai_notes.reason_buying_products &&
                        data.extras.ai_notes.reason_buying_products.length > 0 && (
                            <div className="possible-reason">
                                {data.extras.ai_notes.reason_buying_products}
                            </div>
                        )}
                </div>
            )}
            {data.favourite ? (
                <div className="starred" style={{ backgroundImage: 'url(/star.png)' }}></div>
            ) : null}
        </div>
    );
}

export default EmailMessageContent;

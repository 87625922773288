import React, { useImperativeHandle, forwardRef, useState, useEffect } from 'react';

import { usesDataGridRowDataChangeListener } from './DataGridEvents';
import Icon from '../basic/Icon';
import { DataGridCellSystemCheck, DataGridCellSystemRadio } from './DataGridCell';

const DataGridRow = forwardRef((props, ref) => {
    let gridState = props.store.gridState.get();

    props.store.onMassSelect.follow((keys) => {
        gridState = props.store.gridState.get();
        if (
            gridState.selection.selectAllFilteredRecords ||
            keys.indexOf(item[gridState.keyField]) > -1
        ) {
            setSystemData({ ...systemData, checked: true });
        }
    });

    props.store.onMassDeSelect.follow((keys) => {
        gridState = props.store.gridState.get();
        if ((!keys || keys.length === 0) && !gridState.selection.selectAllFilteredRecords) {
            setSystemData({ ...systemData, checked: false });
        } else {
            if (keys.indexOf(item[gridState.keyField]) > -1) {
                setSystemData({ ...systemData, checked: false });
            }
        }
    });

    const [item, setItem] = useState(props.item);

    const [systemData, setSystemData] = useState({
        checked: false,
        disabled: false,
        mobileCollapsed: true,
        collapsed: true,
        children:null,
        renderingChildren:false
    });

    useEffect(() => {
        let gridState = props.store.gridState.get();
        let selectedRecordKeys = props.store.selectedRecordKeys.get();
        let deselectedRecordKeys = props.store.deselectedRecordKeys.get();

        let checked = false;

        if (gridState.selection.selectAllFilteredRecords) {
            checked = true;
            if (deselectedRecordKeys) {
                if (deselectedRecordKeys.indexOf(item[gridState.keyField]) > -1) {
                    checked = false;
                }
            }
        } else {
            if (selectedRecordKeys) {
                if (selectedRecordKeys.indexOf(item[gridState.keyField]) > -1) {
                    checked = true;
                }
            }
        }

        setSystemData({ ...systemData, checked: checked });

        if (props.selectColumn) {
            item[props.selectColumn.field] = checked;
        }

        usesDataGridRowDataChangeListener({
            gridId: props.gridId,
            rowKey: item[props.keyField],
            callback: ({ rowData, field, newValue }) => {
                if (field === 'selected') {
                    itemChecked(newValue);
                }
            },
        });
    }, [item]);

    useImperativeHandle(ref, () => {
        return {
            onItemChanged: onItemChanged,
        };
    });

    const onItemChanged = (item) => {
        setItem(item);
    };

    const updateChildrenWithProps = (children = props.children, wrapper) =>
        React.Children.toArray(children).map((child, i) => {
            const renderClone = () =>
                React.cloneElement(child, {
                    ...child.props,
                    item,
                    gridId: props.gridId,
                    keyField: props.keyField,
                    columns: props.columns,
                    wrapper,
                });

            if (child.props.className === 'datagrid-row-group') {
                return (
                    <div className="datagrid-row-group">
                        {child.props.children.map((item, index) => {
                            // first one is parent of the group
                            if (index === 0) {
                                return (
                                    <div className="datagrid-row-group-parent">
                                        {updateChildrenWithProps(item)}
                                    </div>
                                );
                                // rest are children of the group
                            } else {
                                return (
                                    <div className="datagrid-row-group-children">
                                        {item.map((i) => (
                                            <div className="datagrid-row-group-children-item">
                                                {updateChildrenWithProps(i)}
                                            </div>
                                        ))}
                                    </div>
                                );
                            }
                        })}
                    </div>
                );
            }

            if (wrapper) {
                return <div className={wrapper}>{renderClone()}</div>;
            }

            return renderClone();
        });

    let className = 'datagrid-row  ' + (systemData.mobileCollapsed ? ' collapsed ' : '');
    if (props.classCondition) {
        const conditionalClass = props.classCondition({ item, systemData });
        if (conditionalClass) {
            className += ' conditional ' + conditionalClass;
        }
    }

    if (!props.isMultiSelect) {
        if ((props.store.selectedRecordKeys.get() || []).indexOf(item[gridState.keyField]) > -1) {
            // className+=' checked ';
        }
    } else {
        if (systemData.checked) {
            className += ' checked ';
        }
    }

    if (props.className) {
        className += ' ' + props.className + ' ';
    }

    const itemChecked = (newValue) => {
        setSystemData({ ...systemData, checked: newValue });

        let selectedRecordKeys = props.store.selectedRecordKeys.get() || [];
        let deselectedRecordKeys = props.store.deselectedRecordKeys.get() || [];
        let gridState = props.store.gridState.get();

        if (gridState.selection.selectAllFilteredRecords) {
            deselectedRecordKeys = deselectedRecordKeys.filter(
                (i) => i !== item[gridState.keyField]
            );
            if (!newValue) {
                deselectedRecordKeys.push(item[gridState.keyField]);
            }
            props.store.deselectedRecordKeysChanged(deselectedRecordKeys);
        } else {
            selectedRecordKeys = selectedRecordKeys.filter((i) => i !== item[gridState.keyField]);
            if (newValue) {
                selectedRecordKeys.push(item[gridState.keyField]);
            }
            props.store.selectedRecordKeysChanged(selectedRecordKeys);
        }

        props.store.setRecordSelected(item, newValue);

        if (props.onChange) {
            props.onChange();
        }
    };

    const itemCellChecked = (newValue) => itemChecked(newValue);

    const itemCellSelected = (newValue) => itemSelected(newValue);

    const itemSelected = (newValue) => {
        setSystemData({ ...systemData, checked: newValue });

        props.store.deselectedRecordKeysChanged([]);
        props.store.selectedRecordKeysChanged([item[gridState.keyField]]);
        props.store.selectedRecordsChanged([item]);

        if (props.onChange) {
            props.onChange();
        }
    };

    const mobileElapse = () =>
        setSystemData({ ...systemData, mobileCollapsed: !systemData.mobileCollapsed });


    const elapse = () =>
        setSystemData({ ...systemData, collapsed: !systemData.collapsed });
 
    let renderRowChild =  () =>{ 
        if(systemData.children){
            return systemData.children;
        }
        setRowChild();
    }

    let setRowChild =  async () =>{ 
        if(props.renderRowChild && !systemData.renderingChildren){ 
            systemData.renderingChildren = true;
            setSystemData({ ...systemData,  renderingChildren:true });
            let rowChild = await props.renderRowChild({ columns:gridState.columns, data:item});
            setSystemData({ ...systemData, children:rowChild, renderingChildren:false });
          }
    }

    return (
        <>
        <div className={className} key={item.hash}>

            {props.hasChildren && props.renderRowChild && <div className="show-detail" onClick={elapse}>
                {systemData.collapsed ? 'Details' : 'Hide Details'}{' '}
                <Icon name={systemData.collapsed ? 'expand_more' : 'expand_less'} />
            </div>}
            {gridState.selection.enabled && props.isMultiSelect && (
                <DataGridCellSystemCheck
                    item={item}
                    value={systemData.checked}
                    isReadOnly={props.isReadOnly}
                    gridId={gridState.gridId}
                    keyField={gridState.keyField}
                    columns={gridState.columns}
                    column={props.selectColumn}
                    onChange={itemCellChecked}
                />
            )}
            {gridState.selection.enabled && !props.isMultiSelect && (
                <DataGridCellSystemRadio
                    item={item}
                    gridId={gridState.gridId}
                    isReadOnly={props.isReadOnly}
                    keyField={gridState.keyField}
                    columns={gridState.columns}
                    column={props.selectColumn}
                    onChange={itemCellSelected}
                />
            )}
            {updateChildrenWithProps()}
            <div className="show-more" onClick={mobileElapse}>
                {systemData.mobileCollapsed ? 'Show All' : 'Show Less'}{' '}
                <Icon name={systemData.mobileCollapsed ? 'expand_more' : 'expand_less'} />
            </div>
        </div>
        {!systemData.collapsed && <div className='datagrid-row row-child-component'> 
            {renderRowChild({ columns:gridState.columns, data:item})}
            {systemData.renderingChildren? <div className={'grid-loading loading row-loading '}>
                    Loading... <Icon name="refresh" />{' '}
                </div>:null}

        </div>}
        </>
    );
});

export default DataGridRow;

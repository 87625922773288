import React from 'react';

import { Tab, Tabs } from '../../components/basic/Tab';
import EmailTemplateList from './EmailTemplate/EmailTemplate.List';
import EmailTemplateEdit from './EmailTemplate/EmailTemplate.Edit';
import Inbox from './Inbox/Main';
import './Main.scss';

import { Route } from 'react-router-dom';
import EmailOverview from './EmailOverview/EmailOverview.Main';

export const Main = () => {
    return (
        <Tabs navigate={true}>
            {/* <Tab title="Overview" url="/email">
                <EmailOverview />
            </Tab> */}
            <Tab title="Templates" url="/email">
                <EmailTemplateList />
            </Tab>
            <Tab title="Inbox" url="/email/mailbox">
                <Inbox />
            </Tab>
        </Tabs>
    );
};

export const EmailRoutes = [
    <Route path="/email/" element={<Main />} />,
    <Route path="/email/templates" element={<Main />} />,
    <Route path="/email/signatures" element={<Main />} />,
    <Route path="/email/mailbox" element={<Main />} />,
    <Route path="/email/template/edit/:id" element={<EmailTemplateEdit />} />,
    <Route path="/email/template/:id" element={<EmailTemplateEdit />} />,
];

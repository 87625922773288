import React, { useCallback, useState } from 'react';

import { ENVIRONMENTS, Globals } from '../../Globals';
import { GlobalStore } from '../../GlobalStore';
import tmoLib from '../../tmo/tmo.lib';
import Icon from './Icon';
import IconButton from './IconButton';
import UIHint from '../../ShopNow/Components/TopLevel/UIHint';
import './AppHeader.scss';
import useInterval from '../../hooks/useInterval';

export default function AppHeader(props) {
    const currentUser = Globals.currentUser;
    const currentAccount = GlobalStore.currentAccount.follow();

    const getCurrentLogo = useCallback(() => {
        const account = currentUser.accounts.find((a) => a.id === currentAccount.account_id);
        return account ? account.logo : '/noimage.jpeg';
    }, [currentAccount.account_id, currentUser.accounts]);

    const getAccountSelectPopover = useCallback(() => {
        const selectAccount = (acc) => {
            Globals.currentAccount = acc;
            GlobalStore.currentAccount.set({
                account_name: acc.name,
                account_id: acc.id,
                logo: acc.logo,
            });
            localStorage['current_account'] = JSON.stringify({
                ...acc, 
                account_name: acc.name,
                account_id: acc.id,
                logo: acc.logo,
            });
            window.location.reload();
        };

        return (
            <div className="account-select-popover">
                {currentUser.accounts.map((a) => (
                    <div className="account-item" onClick={() => selectAccount(a)}>
                        <img
                            alt="account logo"
                            src={a.logo || '/noimage.jpeg'}
                            className="account-logo"
                        />
                        <div className="account-name">{a.name}</div>
                    </div>
                ))}
            </div>
        );
    }, [currentUser.accounts]);

    const gotoAuth = () => tmoLib.ui.global.openPage('/auth/profile');

    const showSelection = (e) =>
        tmoLib.ui.popover.open({
            opener: e.target,
            content: getAccountSelectPopover(),
            enableBgOverlay: true,
        });

    const showAssistant = () => {
        if (props.assistantRef.current) {
            props.assistantRef.current.show();
        }
    };

    const showTasks = (e) =>
        tmoLib.ui.popover.open({
            opener: e.target,
            content: props.taskPopoverContent,
            enableBgOverlay: true,
        });

    const showNotifications = (e) =>
        tmoLib.ui.popover.open({
            opener: e.target,
            content: props.notificationPopoverContent,
            enableBgOverlay: true,
        });

    
    const [time, setTime] =  useState('--:--:--');
    useInterval(() => { 
        let machineTimeZone = new Date().getTimezoneOffset();
        if(machineTimeZone===currentAccount.timezone_minutes ){
            if(time) setTime(null);
            return;
        }   
        let date = new Date(
            new Date().getTime() + ((machineTimeZone - (currentAccount.timezone_minutes || -480)) * (1000 * 60))  
          ); 
        setTime(tmoLib.string.printTime(date));
    }
    , 1000, []);


    return (
        <div className="header">
            <div className="logo" />
            {currentUser.accounts && currentUser.accounts.length > 1 ? (
                <div className="account-select" onClick={(e) => showSelection(e)}>
                    <img alt="account logo" src={getCurrentLogo()} className="account-logo" />
                    <div className="account-name">{currentAccount.account_name}</div>
                    <Icon name="expand_more" className="arrow" />
                </div>
            ) : (
                <div className="account-select" />
            )}
            {time &&  
                    <div className="account-time" data-tooltip="Time based on selected account timezone">{time}</div>
            }
            <div className="middle" />
            <div className="right-menu">
                <UIHint className="uihint" />
                {/* <IconButton name="assistant" className="chat" onClick={showAssistant} /> */}
                <IconButton
                    name="notifications"
                    className="notification"
                    onClick={showNotifications}
                />
               {Globals.environment!=ENVIRONMENTS.PRODUCTION && 
                <IconButton name="task" className="task" onClick={showTasks} />
               }
                <div className="profile" onClick={gotoAuth}>
                    <Icon name="person" />
                    <span>{currentUser.short_name}</span>
                </div>
            </div>
        </div>
    );
}

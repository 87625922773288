import * as Socket from '../Socket';
import tmoLib from '../tmo/tmo.lib';
import { Globals } from '../Globals';
import { ChatStore } from './Chat.Store';
import { ChatApi } from './Chat.Api';
import { SESSION_FILTER_TYPE, SESSION_USER_ROLES } from './Chat.Static';
import { APPLICATION_TYPES } from '../Auth/Application/Application.Api';

export class ChatData {
    //   static init = async ({channel})=>{
    //     if(channel==CHANNELS.WECOM){
    //       this.initWecom();
    //     }
    //     if(channel==CHANNELS.WEBCHAT){
    //       this.initWebchat();
    //     }
    //     this.__listenSocket();

    // }

    static init = async () => {
        this.__listenSocket();
    };

    static initForApp = async ({ application }) => {
        this.initWecom({ application });
    };

    static __listenSocket = () => {
        Socket.listenEvent(Socket.SOCKET_EVENTS.new_message, true, (eventData) => {
            if (eventData.data.session) {
                let session = this.__renderSession({ session: eventData.data.session });
                let message = this.__renderMessage({
                    session: eventData.data.session,
                    message: eventData.data.message,
                    currentSessionUser: {},
                    extras: { ai_notes: eventData.data.ai_notes },
                });
                ChatStore.onNewMessage.fire({ session, message });
                let currentSession = ChatStore.currentSession.get();
                if (currentSession && currentSession.id == session.id) {
                    ChatStore.currentSession.set(session);
                    ChatStore.currentMessages.add(message);
                } else {
                    if (eventData.data.notification) {
                        eventData.data.session.notify = eventData.data.session.notify || 0;
                        eventData.data.session.notify++;
                        

                        let currentApp = ChatStore.currentApplication.get();
                        if (!currentApp || session.application_id != currentApp.id) {
                            let app = (ChatStore.applicationNotifications.get() || []).filter(
                                (n) => n.id == session.application_id
                            )[0] || { id: session.application_id, notify: 0 };
                            app.notify++;
                            
                            ChatStore.applicationNotifications.upsert(app, 'id');
                        }

                        tmoLib.ui.global.showNotification({
                            text: eventData.data.notification.description,
                            title: eventData.data.notification.title,
                            image: eventData.data.notification.image || './noavatar.png',
                            time: 4,
                            action: eventData.data.notification.action,
                        });
                    }

                    ChatStore.sessions.upsert(session, 'id');

                    ChatStore.sessions.set(
                        ChatStore.sessions
                            .get()
                            .sort((a, b) => {
                                return a.last_message_time > b.last_message_time ? -1 : 1;
                            })
                            .sort((a, b) => {
                                return a.pin_top ? -1 : 1;
                            })
                    );
                }
            }
        });

        Socket.listenEvent(Socket.SOCKET_EVENTS.update_session, true, (eventData) => {
            if (eventData.data.session) {
                let session = this.__renderSession({ session: eventData.data.session });
                let message = this.__renderMessage({
                    session: eventData.data.session,
                    message: eventData.data.message,
                    currentSessionUser: {},
                    extras: { ai_notes: eventData.data.ai_notes },
                });

                let currentSession = ChatStore.currentSession.get();
                if (currentSession && currentSession.id == session.id) {
                    ChatStore.currentSession.set(session);
                    ChatStore.currentMessages.add(message);
                } else {
                    if (eventData.data.notification) {
                        eventData.data.session.notify = eventData.data.session.notify || 0;
                        eventData.data.session.notify++;

                        let currentApp = ChatStore.currentApplication.get();
                        if (!currentApp || session.application_id != currentApp.id) {
                            let app = (ChatStore.applicationNotifications.get() || []).filter(
                                (n) => n.id == session.application_id
                            )[0] || { id: session.application_id, notify: 0 };
                            app.notify++;
                         
                            ChatStore.applicationNotifications.upsert(app, 'id');
                        }

                        tmoLib.ui.global.showNotification({
                            text: eventData.data.notification.description,
                            title: eventData.data.notification.title,
                            image: eventData.data.notification.image || './noavatar.png',
                            time: 4,
                            action: eventData.data.notification.action,
                        });
                    }

                    ChatStore.sessions.upsert(session, 'id');

                    ChatStore.sessions.set(
                        ChatStore.sessions
                            .get()
                            .sort((a, b) => {
                                return a.last_message_time > b.last_message_time ? -1 : 1;
                            })
                            .sort((a, b) => {
                                return a.pin_top ? -1 : 1;
                            })
                    );

                    // ChatStore.sessions.upsert(session,'id');
                }
            }
        });
    };

    static initWebchat = async () => {
        let sessions = await ChatApi.getSessions({});
        sessions = sessions.map((s) => this.__renderSession({ session: s }));
        ChatStore.sessions.set(sessions);
        ChatStore.currentMedias.set([]);
        ChatStore.currentMessages.set([]);
        ChatStore.currentSession.set(null);
        ChatStore.currentSessionUser.set([]);
        ChatStore.currentTags.set([]);
        ChatStore.allSubjects.set([]);
    };

    static initWecom = async ({ application }) => {

        ChatStore.loadingSessions.set(true);

        let emojis = await ChatApi.getEmojis();
        let sessions = await ChatApi.getWecomSessions({
            application_id: application?.id,
        });
        let tags = await ChatApi.getChatTags({ offset: 0, limit: 100 });
        let subjects = await ChatApi.getAllSubjects({ offset: 0, limit: 100 });

        sessions = sessions.map((s) => this.__renderSession({ session: s }));
        ChatStore.sessions.set(sessions);
        ChatStore.emojis.set(emojis);
        ChatStore.currentTags.set(tags);
        ChatStore.allSubjects.set(subjects);
        ChatStore.loadingSessions.set(false);
    };

    static applicationSelected = async ({ application }) => {
        let currentApp = ChatStore.currentApplication.get();

        if (currentApp && currentApp?.id == application?.id) {
            return;
        }
        ChatStore.applicationNotifications.upsert({ id: application?.id, notify: 0 }, 'id');
        ChatStore.currentApplication.set(application);
        ChatStore.currentSession.set(null);
        ChatData.resetSuggestions({});
        this.initForApp({ application });
    };

    // static channelSelected = async ({channel})=>{
    //   ChatStore.currentChannel.set(channel);
    //   this.init({channel});
    // }

    static setSessionFilter = async ({ type, tag, subject, status, keyword, sort }) => {
        let currentFilter = ChatStore.sessionFilter.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentApplication.get();

        if (type) currentFilter.type = type;
        if (tag) currentFilter.tag = tag;
        if (subject) currentFilter.subject = subject;
        if (status) currentFilter.status = status;
        if (keyword != null && keyword != undefined) currentFilter.keyword = keyword;
        if (sort) currentFilter.sort = sort;

        ChatStore.sessionFilter.set(currentFilter);
        ChatStore.currentSession.set(null);

        let sessions = await ChatApi.getWecomSessions({
            application_id: currentApplication?.id,
            status:
                currentFilter.type == SESSION_FILTER_TYPE.PENDING
                    ? SESSION_FILTER_TYPE.PENDING
                    : currentFilter.status,
            only_show_assigned_to_member: currentFilter.type == SESSION_FILTER_TYPE.ASSIGNED_TO_ME,
            keyword: currentFilter.keyword,
            sort: (currentFilter.sort || '').replace('#', ' '),
            tag: currentFilter.tag,
            subject: currentFilter.subject,
        });
        sessions = sessions.map((s) => this.__renderSession({ session: s }));

        ChatStore.sessions.set(sessions);
    };

    static loadMedias = async ({ selectedMediaId }) => {
        let currentSessionUser =
            ((ChatStore.currentSessionUser.get() || {}).users || []).filter(
                (u) => u.member_id == Globals.currentUser.id
            )[0] || {};

        let currentApplication = ChatStore.currentSessionApplication.get();

        let medias = await ChatApi.getMessageMedias({
            application_id: currentApplication?.id,
            session: ChatStore.currentSession.get(),
            currentSessionUser,
        });
        medias = medias.map((m) => this.__renderChatMedia({ message: m }));

        ChatStore.currentMedias.set(medias);

        for (let i = 0; i < medias.length; i++) {
            if (medias[i].id == selectedMediaId) {
                return i;
            }
        }
    };

    static sessionFiltered = async ({ session }) => {
        let currentSessionUser =
            ((session || {}).users || []).filter((u) => u.member_id == Globals.currentUser.id)[0] ||
            {};

        ChatStore.currentSession.set(session);
        ChatStore.currentSessionUser.set(currentSessionUser);
        let currentApplication = ChatStore.currentApplication.get();

        // ChatStore.currentMessages.set(defaultMessages);

        let messages = await ChatApi.getMessages({
            application_id: currentApplication?.id,
            session,
            currentSessionUser,
            offset: 0,
            limit: 100,
            keyword: '',
            message_type: null,
        });
        messages = messages.map((m) =>
            this.__renderMessage({ session, currentSessionUser, message: m })
        );

        if (session.notify > 0) {
            session.notify = 0;
        }

        ChatStore.currentMessages.set(messages);
    };

    static sessionSelected = async ({ session }) => {
        let currentSessionUser =
            ((session || {}).users || []).filter(
                (u) => u.member_id === Globals.currentUser.id
            )[0] || {};

        let currentSessionApplication = ChatStore.currentSessionApplication.get();
        let app = window.currentUser.applications.filter(a=>a.id==session.application_id);
        if(!currentSessionApplication || currentSessionApplication.id!=app.id){
            ChatStore.currentSessionApplication.set(app);
        }
        app = app || currentSessionApplication;

        if (session.notify > 0) {
            session.notify = 0;
            ChatApi.readAllNotifications({ application_id: app?.id, session });
        }
        ChatStore.currentMessages.set([]);
        ChatStore.currentSession.set(session);
        ChatStore.currentSessionUser.set(currentSessionUser);
        // ChatStore.currentMessages.set(defaultMessages);

        let messages = await ChatApi.getMessages({
            application_id: app?.id,
            session,
            currentSessionUser,
            offset: 0,
            limit: 100,
            keyword: '',
            message_type: null,
        });
        messages = messages.map((m) =>
            this.__renderMessage({ session, currentSessionUser, message: m })
        );

        ChatStore.currentMessages.set(messages);
        ChatData.resetSuggestions({});
    };

    static getMessageHistory = async ({ session, offset, limit, message_type }) => {
        let currentSessionUser =
            ((session || {}).users || []).filter(
                (u) => u.member_id === Globals.currentUser.id
            )[0] || {};

        let currentApplication = ChatStore.currentSessionApplication.get();
        let messages = await ChatApi.getMessages({
            application_id: currentApplication?.id,
            session,
            currentSessionUser,
            offset: offset || 0,
            limit: limit || 100,
            keyword: '',
            message_type,
        });
        messages = messages.map((m) =>
            this.__renderMessage({ session, currentSessionUser, message: m })
        );

        return messages.reverse();
    };

    static getSessionNotes = async ({ session }) => {
        let currentSessionUser =
            ((session || {}).users || []).filter(
                (u) => u.member_id === Globals.currentUser.id
            )[0] || {};

        let currentApplication = ChatStore.currentSessionApplication.get();
        let notes = await ChatApi.getWecomChatNotes({
            application_id: currentApplication?.id,
            session,
            currentSessionUser,
        });

        notes = notes.map((m) => this.__renderNote({ session, currentSessionUser, note: m }));

        return notes;
    };

    static addSessionNote = async ({ session, note }) => {
        let currentSessionUser =
            ((session || {}).users || []).filter(
                (u) => u.member_id === Globals.currentUser.id
            )[0] || {};

        let currentApplication = ChatStore.currentSessionApplication.get();

        let noteAdded = await ChatApi.addWecomChatNote({
            application_id: currentApplication?.id,
            session,
            currentSessionUser,
            note,
        });
        return this.__renderNote({ session, currentSessionUser, note: noteAdded });
    };

    static getSuggestions = async ({}) => {
        let session = ChatStore.currentSession.get();
        let customerUser = session.users.filter(
            (u) => u.session_role === SESSION_USER_ROLES.CUSTOMER
        )[0];

        if (customerUser.customer_id) {
            let suggestions = await ChatApi.getMessageSuggestions({
                customer_id: customerUser.customer_id,
            });
            if (suggestions && suggestions.answers) {
                ChatStore.suggestions.set(suggestions.answers);
            }
        }
        return [];
    };

    static resetSuggestions = async ({}) => {
        ChatStore.suggestions.set([]);
    };

    static muteSession = async ({ muted }) => {
        let session = ChatStore.currentSession.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentSessionApplication.get();

        session = await ChatApi.muteSession({
            application_id: currentApplication?.id,
            session,
            currentSessionUser,
            muted,
        });
        if (session) {
            session = this.__renderSession({ session });
            ChatStore.currentSession.set(session);
            ChatStore.sessions.upsert(session, 'id');
        }
    };

    static blockSession = async ({}) => {
        let session = ChatStore.currentSession.get();
    };

    static pinSession = async ({ pin_top }) => {
        let session = ChatStore.currentSession.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentSessionApplication.get();

        session = await ChatApi.pinSession({
            application_id: currentApplication?.id,
            session,
            currentSessionUser,
            pin_top,
        });
        if (session) {
            session = this.__renderSession({ session });
            ChatStore.currentSession.set(session);
            ChatStore.sessions.upsert(session, 'id');
            ChatStore.sessions.set(
                ChatStore.sessions.get().sort((a, b) => {
                    return a.pin_top ? -1 : 1;
                })
            );
        }
    };

    //  static blockSession = async ({pin_top})=>{
    //     let session =   ChatStore.currentSession.get();
    //     let currentSessionUser =   ChatStore.currentSessionUser.get();
    //     let currentApplication = ChatStore.currentApplication.get();

    //     session = await ChatApi.pinSession({application_id:currentApplication.id, session, currentSessionUser, pin_top});
    //     if(session){
    //       session = this.__renderSession({session});
    //       ChatStore.currentSession.set(session);
    //       ChatStore.sessions.upsert(session,'id');
    //       ChatStore.sessions.set(ChatStore.sessions.get().sort((a,b)=>{ return a.pin_top?-1:1 }));
    //     }
    // }

    static saveConversationProfile = async ({ image, conversation_title, description }) => {
        let session = ChatStore.currentSession.get();
        let currentApplication = ChatStore.currentSessionApplication.get();

        session = await ChatApi.updateSessionProfile({
            application_id: currentApplication.id,
            session,
            conversation_title,
            description,
            image,
        });
        if (session) {
            session = this.__renderSession({ session });
            ChatStore.currentSession.set(session);
            ChatStore.sessions.upsert(session, 'id');
            ChatStore.sessions.set(
                ChatStore.sessions.get().sort((a, b) => {
                    return a.pin_top ? -1 : 1;
                })
            );
        }
    };

    static closeSession = async ({}) => {
        let session = ChatStore.currentSession.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentSessionApplication.get();

        let currentUser = Globals.currentUser;
        let currentWecomUser = Globals.currentWecomUser;

        let currentUserCsr = {};
        if (currentUser.csrs && currentUser.csrs.length > 0) {
            currentUserCsr =
                currentUser.csrs.filter(
                    (c) => c.account_id == Globals.currentApplication.account_id
                )[0] || {};
        }

        let newMessageObj = this.__prepareMessage({
            currentCSR: currentUserCsr,
            application_id: currentApplication?.id,
            messageType: 'event',
            session,
            currentSessionUser,
            currentUser,
            isInternal: true,
            text: '',
            eventType: 'close',
        });
        ChatStore.currentMessages.add(newMessageObj);

        let closeResult = await ChatApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({
            session: closeResult.session,
            currentSessionUser,
            message: closeResult.message,
        });
        ChatStore.currentMessages.update(newMessageObj, message, 'id');

        if (session) {
            let session = this.__renderSession({ session: closeResult.session });
            ChatStore.currentSession.set(session);
            ChatStore.sessions.upsert(session, 'id');
        }
    };

    static tagSession = async ({ tags }) => {
        let session = ChatStore.currentSession.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentSessionApplication.get();

        let currentUser = Globals.currentUser;
        let currentWecomUser = Globals.currentWecomUser;

        let currentUserCsr = {};
        if (currentUser.csrs && currentUser.csrs.length > 0) {
            currentUserCsr =
                currentUser.csrs.filter(
                    (c) => c.account_id == Globals.currentApplication?.account_id
                )[0] || {};
        }

        let newMessageObj = this.__prepareMessage({
            currentCSR: currentUserCsr,
            application_id: currentApplication?.id,
            messageType: 'event',
            session,
            currentSessionUser,
            currentUser,
            isInternal: true,
            text: '',
            eventType: 'tag',
            tags,
        });
        if (!newMessageObj) {
            return;
        }
        ChatStore.currentMessages.add(newMessageObj);

        let closeResult = await ChatApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({
            session: closeResult.session,
            currentSessionUser,
            message: closeResult.message,
        });
        ChatStore.currentMessages.update(newMessageObj, message, 'id');

        if (session) {
            let session = this.__renderSession({ session: closeResult.session });
            ChatStore.currentSession.set(session);
            ChatStore.sessions.upsert(session, 'id');
        }
    };

    static resumeSession = async ({}) => {
        let session = ChatStore.currentSession.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentSessionApplication.get();

        let currentUser = Globals.currentUser;
        let currentWecomUser = Globals.currentWecomUser;

        let currentUserCsr = {};
        if (currentUser.csrs && currentUser.csrs.length > 0) {
            currentUserCsr =
                currentUser.csrs.filter(
                    (c) => c.account_id == Globals.currentApplication?.account_id
                )[0] || {};
        }

        let newMessageObj = this.__prepareMessage({
            currentCSR: currentUserCsr,
            application_id: currentApplication?.id,
            messageType: 'event',
            session,
            currentSessionUser,
            currentUser,
            isInternal: true,
            text: '',
            eventType: 'resume',
        });
        ChatStore.currentMessages.add(newMessageObj);

        let resumeResult = await ChatApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({
            session: resumeResult.session,
            currentSessionUser,
            message: resumeResult.message,
        });
        ChatStore.currentMessages.update(newMessageObj, message, 'id');
        ChatStore.sessions.upsert(resumeResult.session, 'id');
        ChatStore.currentSession.set(resumeResult.session);
    };

    static endSubject = async ({ current_chat_subject }) => {
        this.__startOrEndSubject({
            current_chat_subject_id: current_chat_subject.chat_subject_id,
            text: 'Ended to talk about [#' + current_chat_subject.title + ']',
            subject_started: false,
        });
    };

    static startSubject = async ({ text }) => {
        if (text && text.indexOf('#') > -1) {
            text = text.replace('#', '');
        }
        this.__startOrEndSubject({
            subject_text: text,
            text: 'Started to talk about [#' + text + ']',
            subject_started: true,
        });
    };
    static __startOrEndSubject = async ({
        current_chat_subject_id,
        text,
        subject_text,
        subject_started,
    }) => {
        let session = ChatStore.currentSession.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentSessionApplication.get();

        let currentAccountId = Globals.currentApplication?.account_id;
        let currentUser = Globals.currentUser;
        let currentUserCsr = {};
        if (currentUser.csrs && currentUser.csrs.length > 0) {
            currentUserCsr =
                currentUser.csrs.filter((c) => c.account_id == currentAccountId)[0] || {};
        }

        let newMessageObj = this.__prepareMessage({
            currentCSR: currentUserCsr,
            application_id: currentApplication?.id,
            messageType: 'event',
            session,
            currentSessionUser,
            currentUser,
            isInternal: true,
            text,
            eventType: 'subject',
            assign_to_user: false,
            current_chat_subject_id,
            subject_text,
            subject_started,
        });
        ChatStore.currentMessages.add(newMessageObj);
        let subjectResult = await ChatApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({
            session: subjectResult.session,
            currentSessionUser,
            message: subjectResult.message,
        });
        session = this.__renderSession({ session: subjectResult.session });

        currentSessionUser =
            ((session || {}).users || []).filter((u) => u.member_id == Globals.currentUser.id)[0] ||
            {};
        if (session.notify > 0) {
            session.notify = 0;
            ChatApi.readAllNotifications({ application_id: currentApplication?.id, session });
        }

        ChatStore.currentMessages.update(newMessageObj, message, 'id');
        ChatStore.currentSession.set(session);
        ChatStore.sessions.upsert(session, 'id');
        ChatStore.currentSessionUser.set(currentSessionUser);
    };

    static assignSession = async ({ note, selectedUser }) => {
        let session = ChatStore.currentSession.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentSessionApplication.get();

        let currentAccountId = Globals.currentApplication?.account_id;
        let currentUser = Globals.currentUser;
        let currentUserCsr = {};
        if (currentUser.csrs && currentUser.csrs.length > 0) {
            currentUserCsr =
                currentUser.csrs.filter((c) => c.account_id === currentAccountId)[0] || {};
        }

        // let currentWecomUser = Globals.currentWecomUser;
        if (!selectedUser && currentUserCsr.id) {
            selectedUser = currentUserCsr;
        }

        let newMessageObj = this.__prepareMessage({
            currentCSR: currentUserCsr,
            application_id: currentApplication?.id,
            messageType: 'event',
            session,
            currentSessionUser,
            currentUser,
            selectedUser,
            isInternal: true,
            text: note,
            eventType: selectedUser?.id === currentUserCsr?.id ? 'assignme' : 'assign',
            assign_to_user: true,
        });
        ChatStore.currentMessages.add(newMessageObj);
        let assignResult = await ChatApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({
            session: assignResult.session,
            currentSessionUser,
            message: assignResult.message,
        });
        session = this.__renderSession({ session: assignResult.session });

        currentSessionUser =
            ((session || {}).users || []).filter((u) => u.member_id == Globals.currentUser.id)[0] ||
            {};
        if (session.notify > 0) {
            session.notify = 0;
            ChatApi.readAllNotifications({ application_id: currentApplication?.id, session });
        }

        ChatStore.currentMessages.update(newMessageObj, message, 'id');
        ChatStore.currentSession.set(session);
        ChatStore.sessions.upsert(session, 'id');
        ChatStore.currentSessionUser.set(currentSessionUser);
    };

    static joinConversation = async ({ note, role, selectedUser }) => {
        let session = ChatStore.currentSession.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentSessionApplication.get();

        let currentUser = Globals.currentUser;
        let currentWecomUser = Globals.currentWecomUser;
        selectedUser = selectedUser || currentUser;

        let currentUserCsr = {};
        if (currentUser.csrs && currentUser.csrs.length > 0) {
            currentUserCsr =
                currentUser.csrs.filter(
                    (c) => c.account_id === Globals.currentApplication?.account_id
                )[0] || {};
        }

        let newMessageObj = this.__prepareMessage({
            currentCSR: currentUserCsr,
            application_id: currentApplication?.id,
            messageType: 'event',
            session,
            currentSessionUser,
            currentUser,
            selectedUser,
            isInternal: true,
            text: note,
            eventType: 'join',
            assign_to_user: false,
        });
        ChatStore.currentMessages.add(newMessageObj);
        let assignResult = await ChatApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({
            session: assignResult.session,
            currentSessionUser,
            message: assignResult.message,
        });
        session = this.__renderSession({ session: assignResult.session });
        ChatStore.currentMessages.update(newMessageObj, message, 'id');
        ChatStore.currentSession.set(session);
        ChatStore.sessions.upsert(session, 'id');
    };

    static leaveConversation = async ({ note, selectedUser }) => {
        let session = ChatStore.currentSession.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentSessionApplication.get();
        let currentUser = Globals.currentUser;

        selectedUser = selectedUser || currentUser;

        let currentUserCsr = {};
        if (currentUser.csrs && currentUser.csrs.length > 0) {
            currentUserCsr =
                currentUser.csrs.filter(
                    (c) => c.account_id === Globals.currentApplication?.account_id
                )[0] || {};
        }

        // notify the chat that user is leaving the conversation
        let newMessageObj = this.__prepareMessage({
            currentCSR: currentUserCsr,
            application_id: currentApplication?.id,
            messageType: 'event',
            session,
            currentSessionUser,
            currentUser,
            selectedUser,
            isInternal: true,
            text: note,
            eventType: 'leave',
            assign_to_user: false,
        });
        ChatStore.currentMessages.add(newMessageObj);
        let assignResult = await ChatApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({
            session: assignResult.session,
            currentSessionUser,
            message: assignResult.message,
        });

        session = this.__renderSession({ session: assignResult.session });
        ChatStore.currentMessages.update(newMessageObj, message, 'id');
        ChatStore.currentSession.set(session);
        ChatStore.sessions.upsert(session, 'id');
    };

    static addUserToConversation = async ({ note, role, selectedUser }) => {
        let session = ChatStore.currentSession.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentSessionApplication.get();

        let currentUser = Globals.currentUser;
        selectedUser = selectedUser || currentUser;

        let currentUserCsr = {};
        if (currentUser.csrs && currentUser.csrs.length > 0) {
            currentUserCsr =
                currentUser.csrs.filter(
                    (c) => c.account_id == Globals.currentApplication?.account_id
                )[0] || {};
        }

        let newMessageObj = this.__prepareMessage({
            currentCSR: currentUserCsr,
            role,
            application_id: currentApplication?.id,
            messageType: 'event',
            session,
            currentSessionUser,
            currentUser,
            selectedUser,
            isInternal: true,
            text: note,
            eventType: 'add_user',
            assign_to_user: false,
        });
        ChatStore.currentMessages.add(newMessageObj);
        let assignResult = await ChatApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({
            session: assignResult.session,
            currentSessionUser,
            message: assignResult.message,
        });
        session = this.__renderSession({ session: assignResult.session });
        ChatStore.currentMessages.update(newMessageObj, message, 'id');
        ChatStore.currentSession.set(session);
        ChatStore.sessions.upsert(session, 'id');
    };

    static recallMessage = async ({ message }) => {};

    static resendMessage = async ({ message }) => {
        let session = ChatStore.currentSession.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentSessionApplication.get();
        let currentUser = Globals.currentUser;

        let newMessageObj = { ...message };
        newMessageObj.id = 'mes' + session.id + '_' + new Date().getTime();
        newMessageObj.sent_at =  tmoLib.string.getAccountDateTime(); 
        // newMessageObj.sent_at = new Date(newMessageObj.sent_at.getTime() + (newMessageObj.sent_at.getTimezoneOffset() * 60 * 1000));

        newMessageObj.created_at = new Date();
        newMessageObj.sent_at_text = tmoLib.string.printAccountDateTime();
        newMessageObj.is_failed = false;
        newMessageObj.is_synced = false;
        newMessageObj.sender_id = currentSessionUser.id;
        newMessageObj.member_id = currentUser.id;
        newMessageObj.sender_chat_session_user_id = currentSessionUser.id;
        newMessageObj.sender_channel = 'shopnow';
        newMessageObj.assign_to_user = false;

        ChatStore.currentMessages.add(newMessageObj);
        ChatStore.onNewMessage.fire({ session, message: newMessageObj });

        let messageFromServer = await ChatApi.resendMessage({
            application_id: currentApplication?.id,
            session,
            currentSessionUser,
            chat_message_id: message.id,
        });

        messageFromServer = this.__renderMessage({
            session,
            currentSessionUser,
            message: messageFromServer,
        });
        // ChatStore.currentMessages.add(messageFromServer);
        ChatStore.currentMessages.update(newMessageObj, messageFromServer, 'id');
    };

    static sendMessage = async ({
        messageType,
        isInternal,
        text,
        fileData,
        linkData,
        assign_to_user,
    }) => {
        let session = ChatStore.currentSession.get();
        let currentSessionUser = ChatStore.currentSessionUser.get();
        let currentApplication = ChatStore.currentSessionApplication.get();

        let currentUser = Globals.currentUser;

        let currentUserCsr = {};
        if (currentUser.csrs && currentUser.csrs.length > 0) {
            currentUserCsr =
                currentUser.csrs.filter(
                    (c) => c.account_id == Globals.currentApplication?.account_id
                )[0] || {};
        }

        let newMessageObj = this.__prepareMessage({
            currentCSR: currentUserCsr,
            application_id: currentApplication?.id,
            session,
            currentSessionUser,
            currentUser,
            messageType,
            isInternal,
            text,
            fileData,
            linkData,
            assign_to_user,
        });

        ChatStore.currentMessages.add(newMessageObj);
        ChatStore.onNewMessage.fire({ session, message: newMessageObj });

        let sendResult = await ChatApi.sendMessage(newMessageObj);
        let message = this.__renderMessage({
            session: sendResult.session,
            currentSessionUser,
            message: sendResult.message,
        });
        session = this.__renderSession({ session: sendResult.session });
        ChatStore.currentMessages.update(newMessageObj, message, 'id');
        ChatStore.sessions.upsert(session, 'id');
    };

    static saveWecomChatSetting = async ({ setting }) => {
        return ChatApi.saveWecomChatSetting({ setting });
    };
    static getWecomChatSetting = async ({}) => {
        return ChatApi.getWecomChatSetting({});
    };

    static __renderMessage = ({ session, currentSessionUser, message, extras }) => {
        if (message.sender_chat_session_user_id == 'ai') {
            message.sender = { id: 'ai', name: 'AI', session_role: 'user', avatar: 'csrai.png' };
        } else {
            message.sender =
                [...session.users, currentSessionUser].filter(
                    (s) => s.id == message.sender_chat_session_user_id
                )[0] || {};
        }
        message.created_at = new Date(message.created_at);
        message.sent_at = new Date(message.sent_at);
        message.extras = extras;
        return message;
    };

    static __renderNote = ({ session, currentSessionUser, note }) => {
        note.sender =
            [...session.users, currentSessionUser].filter(
                (s) => s.id == note.chat_session_user_id
            )[0] || {};

        return note;
    };

    static __renderSession = ({ session }) => {
        let customersInSession = session.users.filter(
            (u) => u.session_role == SESSION_USER_ROLES.CUSTOMER
        );
        if (customersInSession.length == 1) {
            session.is_user_online = customersInSession.is_online;
        }
        session.assignedTo = session.users.filter(
            (u) => u.id == session.assigned_chat_session_user_id
        )[0];
        session.image = session.image || '/noavatar.png';

        let app = window.currentUser.applications.find(a=>a.id==session.application_id);
        if(app){
          //  console.log('app found' , app)
            let appLogo = APPLICATION_TYPES.find(t=>t.value==app.type);
            if(appLogo){
                session.channel = {
                    logo:appLogo.logo,
                    name:appLogo.name,
                    color:appLogo.color,
                    type:appLogo.value,
                }
            }
        }
        return session;
    };

    static __renderChatMedia = ({ message }) => {
        let media = message.message_content.media || {};
        return {
            id: message.id,
            type: message.message_type,
            url:
                'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/' +
                media.file_name +
                '.' +
                media.ext,
            thumb:
                'https://shopnow-content.s3.cn-north-1.amazonaws.com.cn/shopnow/' +
                media.file_name +
                '.' +
                media.ext,
        };
    };

    static __prepareMessage = ({
        application_id,
        session,
        currentSessionUser,
        currentUser,
        currentCSR,
        selectedUser,
        messageType,
        isInternal,
        text,
        fileData,
        linkData,

        assign_to_user,
        role,
        tags,

        current_chat_subject_id,
        subject_text,
        subject_started,

        eventType,
    }) => {
        let content = {};
        if (messageType == 'text') {
            let value = text;
            value = value
                .replace(new RegExp('<span class="emoji">', 'g'), '')
                .replace(new RegExp('</span>', 'g'), '')
                .trim();
            content = { content: value };
            if (content.content.length > 4000) {
                alert('Unable to send very long messages');
                return;
            }
        }
        if (
            messageType == 'file' ||
            messageType == 'video' ||
            messageType == 'image' ||
            messageType == 'voice'
        ) {
            content = {
                media_id: fileData.name,
                media: { file_name: fileData.name, ext: fileData.extension },
            };
        }
        if (messageType == 'link') {
            content = {
                title: linkData.title,
                description: linkData.description,
                url: linkData.url,
                thumb_url: linkData.thumb_url,
                thumb_media: linkData.thumb_media,
                thumb_media_id: linkData.thumb_media_id,
            };
        }
        let internal_event_message = '';
        if (messageType == 'event') {
            content = { content: text || '' };
            // if(eventType=='assign'){
            //   internal_event_message='Assigned this session to '+selectedUser.name
            // }
            // if(eventType=='assignme'){
            //   internal_event_message='Assigned this session to own user'
            // }
            // if(eventType=='close'){
            //   internal_event_message='Closed the session'
            // }
            // if(eventType=='resume'){
            //   internal_event_message='Resumed the closed session'
            // }
            // if(eventType=='join'){
            //   internal_event_message='Joined to this conversaion'
            // }
            // if(eventType=='add_user'){
            //   internal_event_message='Added '+selectedUser.short_name+' as '+role.toUpperCase()+' to this conversation'
            // }
            // if(eventType=='tag'){
            //   internal_event_message='Joined to this conversaion'
            // }
            switch (eventType) {
                case 'assign':
                    internal_event_message = 'Assigned this session to ' + selectedUser.name;
                    break;
                case 'assignme':
                    internal_event_message = 'Assigned this session to own user';
                    break;
                case 'close':
                    internal_event_message = 'Closed the session';
                    break;
                case 'resume':
                    internal_event_message = 'Resumed the closed session';
                    break;
                case 'join':
                    internal_event_message = 'Joined to this conversation';
                    break;
                case 'leave':
                    internal_event_message = 'Left the conversation';
                    break;
                case 'add_user':
                    internal_event_message =
                        'Added ' +
                        selectedUser.short_name +
                        ' as ' +
                        role.toUpperCase() +
                        ' to this conversation';
                    break;
                case 'tag':
                    let removedTags = (session.tags || []).filter(
                        (st) =>
                            (tags || []).filter((t) => {
                                return t.id == st.id;
                            }).length == 0
                    );
                    let addedTags = (tags || []).filter(
                        (t) =>
                            (session.tags || []).filter((st) => {
                                return t.id == st.id;
                            }).length == 0
                    );
                    if (addedTags.length > 0) {
                        internal_event_message =
                            'Added tag [$' +
                            (addedTags || []).map((t) => t.name.toUpperCase()).join('] , [$') +
                            ']';
                    }
                    if (removedTags.length > 0) {
                        internal_event_message =
                            'Removed tag [$' +
                            (removedTags || []).map((t) => t.name.toUpperCase()).join('] , [$') +
                            ']';
                    }
                    if (removedTags.length == 0 && addedTags.length == 0) {
                        return null;
                    }
                    break;
            }
        }

        let sessionUser = {};
        if (!currentSessionUser || !currentSessionUser.id) {
            sessionUser = {};
        } else {
            sessionUser = currentSessionUser;
        }

        let newMessage = {
            application_id,

            chat_csr_id: selectedUser ? selectedUser.id : '',

            id: 'mes' + session.id + '_' + new Date().getTime(),
            message_content: content,
            message_type: messageType,
            chat_session_id: session.id,
            // send_time:new Date(),
            sent_at: tmoLib.string.getAccountDateTime(),
            sent_at_text:  tmoLib.string.printAccountDateTime(),
            is_recalled: false,
            is_internal: isInternal,
            is_system_message: false,
            is_failed: false,
            is_auto_message: false,
            internal_event_message: internal_event_message,
            wechat_auto_message_id: null,

            sender_id: sessionUser.id,
            member_id: currentUser.id,
            sender_chat_session_user_id: sessionUser.id,
            sender_channel: 'wecom',

            assign_to_user: assign_to_user,
            eventType,

            added_member_id: selectedUser ? selectedUser.id : '',
            added_by_chat_csr_id: currentCSR ? currentCSR.id : '',
            role: role,

            current_chat_subject_id,
            subject_text,
            subject_started,

            tag_ids: (tags || []).map((t) => t.id),
        };

        newMessage.sender = sessionUser; //[...session.users, currentUser].filter(s=>s.id==newMessage.sender_id)[0];
        return newMessage;
    };
}
